import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MultiSelect from '../../../../components/MultiSelect';
import RangeDatePicker from '../../../../components/RangeDatePicker';
import OutlinedSelector from '../../../../components/OutlinedSelector';
import {
  DIRECTION_FILTER_OPTIONS,
  FAILURE_FILTER_OPTIONS,
  TRANSPORTING_STATUS_OPTIONS,
} from '../../../../utils/constants';
import { getTMSListAsSelectOptions } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  selectorsRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    margin: '-4px',
    '& > div': {
      margin: 4,
    },
    [theme.breakpoints.down(1087)]: {
      marginTop: 15,
      flexWrap: 'nowrap',
    },
  },
}));

const TransportsFilters = ({
  startDateFilter,
  endDateFilter,
  handleDateFilter,
  showHideFailedItems,
  setShowHideFailedItems,
  typeFilter,
  setTypeFilter,
  tmsId,
  setTmsId,
  status,
  setStatus,
  TMSList,
  isLoadingTMSList,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down(1031));

  const statusOptions = useMemo(
    () =>
      Object.values(TRANSPORTING_STATUS_OPTIONS)
        // Hide statuses that handle on frontend part
        .filter(
          ({ value }) =>
            value !== TRANSPORTING_STATUS_OPTIONS.FAILED.value &&
            value !== TRANSPORTING_STATUS_OPTIONS.PARTIAL.value,
        )
        .map(({ value, label }) => ({
          title: t(label),
          value,
        })),
    [i18n.language],
  );

  const tmsOptions = getTMSListAsSelectOptions(TMSList);

  const directionFilterOption = useMemo(
    () =>
      DIRECTION_FILTER_OPTIONS.map(({ value, name }) => ({
        value,
        name: t(name),
      })),
    [i18n.language],
  );

  const failureFilterOptions = useMemo(
    () =>
      FAILURE_FILTER_OPTIONS.map(({ value, name }) => ({
        value,
        name: t(name),
      })),
    [i18n.language],
  );

  return (
    <Box className={classes.selectorsRow}>
      <MultiSelect
        label={t('Status')}
        width={170}
        options={statusOptions}
        onChange={setStatus}
        value={status}
        limitTags={isMd ? 1 : 2}
      />
      <OutlinedSelector
        label={t('TMS')}
        options={tmsOptions}
        onFilterChange={setTmsId}
        filterValue={tmsId}
        width={100}
        disabled={isLoadingTMSList}
      />
      <OutlinedSelector
        label={t('Direction')}
        options={directionFilterOption}
        onFilterChange={setTypeFilter}
        filterValue={typeFilter}
        width={100}
      />
      <OutlinedSelector
        label={t('Failure status')}
        options={failureFilterOptions}
        onFilterChange={setShowHideFailedItems}
        filterValue={showHideFailedItems}
        width={180}
      />
      <RangeDatePicker
        startDate={startDateFilter}
        endDate={endDateFilter}
        onChange={handleDateFilter}
      />
    </Box>
  );
};
TransportsFilters.propTypes = {
  startDateFilter: PropTypes.object,
  endDateFilter: PropTypes.object,
  handleDateFilter: PropTypes.func,
  showHideFailedItems: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setShowHideFailedItems: PropTypes.func,
  typeFilter: PropTypes.string,
  setTypeFilter: PropTypes.func,
  tmsId: PropTypes.string,
  setTmsId: PropTypes.func,
  status: PropTypes.array,
  setStatus: PropTypes.func,
  TMSList: PropTypes.array,
  isLoadingTMSList: PropTypes.bool,
};
export default TransportsFilters;
