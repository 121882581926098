import { isNil } from 'lodash';

const getBillingName = (firstName, lastName) => {
  if (
    isNil(firstName) ||
    isNil(lastName) ||
    firstName.error ||
    lastName.error
  ) {
    return { error: true };
  }
  return `${firstName} ${lastName}`;
};

export default getBillingName;
