import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  warningDialog: {
    margin: '10px',
    '& .MuiListItem-root': {
      padding: 0,
    },
  },
  title: {
    textTransform: 'uppercase',
  },
  warningMessage: {
    margin: '20px 0',
    fontSize: 14,
    lineHeight: '20px',
  },
  closeWarningButton: {
    fontSize: '18',
    width: 100,
    margin: 15,
  },
}));

const VisibilityWarningPopUp = ({
  isOpen,
  handleClose,
  bookingsArray = [],
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={classes.warningDialog}
    >
      <DialogContent>
        <Typography variant='body2' className={classes.title}>
          {t('Booking exists')}
        </Typography>
        <Typography variant='body1' className={classes.warningMessage}>
          {t(
            'This product exists in future bookings. Please replace product in below orders before deletion.',
          )}
        </Typography>
        <Typography variant='body2' className={classes.title}>
          {t('Affected orders')}
        </Typography>
        <List>
          {bookingsArray.length > 0 &&
            bookingsArray
              .filter(({ deliveryDate }) => new Date(deliveryDate) > new Date())
              .map((item) => (
                <ListItem>
                  <Typography variant='subtitle2'>#{item.orderId}</Typography>
                </ListItem>
              ))}
        </List>
        <Divider light />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          type='submit'
          size='small'
          variant='contained'
          className={classes.closeWarningButton}
        >
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

VisibilityWarningPopUp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  bookingsArray: PropTypes.array,
};

export default VisibilityWarningPopUp;
