import { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  MenuItem,
  Typography,
  Box,
  Select,
  Checkbox,
  Dialog,
  DialogContent,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import VisibilityComponent from '../../../../Instances/components/VisibilityComponent';
import belecoApi from '../../../../../api';
import downloadImageFromBlob from '../../../../../utils/downloadImageFromBlob';
import { WAREHOUSE_STATUS } from '../../../../../utils/constants';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import { PrintLabelsDialogStyles } from '../../../styles';
import LocationContext from '../../../../../context/Location/LocationContext';

const useStyles = makeStyles(PrintLabelsDialogStyles);

const useFilterGroupStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    width: 90,
    textTransform: 'uppercase',
    padding: '0px 10px',
    [theme.breakpoints.down('lg')]: {
      width: 90,
    },
  },
}));

const RelatedInstancesTableRow = ({ children }) => (
  <div
    style={{
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '60px 100px repeat(2, 1fr) 150px',
      gridColumnGap: '10px',
      alignItems: 'center',
    }}
  >
    {children}
  </div>
);

RelatedInstancesTableRow.propTypes = {
  children: PropTypes.node,
};

const FilterGroup = ({ label, children }) => {
  const classes = useFilterGroupStyles();

  return (
    <div className={classes.container}>
      <Typography variant='body2' className={classes.label}>
        {label}
      </Typography>
      {children}
    </div>
  );
};

FilterGroup.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

const HeaderRow = ({ selectAllHandler, isSelectedAll }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div style={{ borderBottom: '2px solid #CCC' }}>
      <RelatedInstancesTableRow>
        <Checkbox
          style={{ marginLeft: 10 }}
          color='primary'
          checked={isSelectedAll}
          onChange={selectAllHandler}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <Typography className={classes.headerTitle} variant='subtitle2'>
          {t('Instance ID')}
        </Typography>
        <Typography
          className={classes.headerTitle}
          variant='subtitle2'
          align='center'
        >
          {t('Storage')}
        </Typography>
        <Typography
          className={classes.headerTitle}
          variant='subtitle2'
          align='center'
        >
          {t('Status')}
        </Typography>
        <Typography
          className={classes.headerTitle}
          variant='subtitle2'
          align='center'
        >
          {t('Visibility')}
        </Typography>
      </RelatedInstancesTableRow>
    </div>
  );
};

HeaderRow.propTypes = {
  selectAllHandler: PropTypes.func,
  isSelectedAll: PropTypes.bool,
};

const DataTableRow = ({ item, handleCheck, checkedInstances }) => {
  const { t } = useTranslation();
  const { publicId, state, warehouseStatus } = item;

  return (
    <div
      key={`related-instance-row-key-${publicId}`}
      style={{
        padding: '0px',
        border: '1px solid #CCC',
        marginTop: 10,
      }}
    >
      <RelatedInstancesTableRow>
        <Checkbox
          style={{ marginLeft: 10 }}
          color='primary'
          disableRipple
          checked={checkedInstances[publicId]}
          onChange={() => {
            handleCheck(publicId);
          }}
        />
        <Typography variant='body2' style={{ fontWeight: 600 }}>
          {publicId}
        </Typography>
        <Typography variant='body2' align='center'>
          {state}
        </Typography>
        <Typography
          variant='body2'
          align='center'
          style={{ textTransform: 'uppercase' }}
        >
          {t(WAREHOUSE_STATUS[warehouseStatus]?.label) || t('N/A')}
        </Typography>
        <VisibilityComponent item={item} />
      </RelatedInstancesTableRow>
    </div>
  );
};

DataTableRow.propTypes = {
  item: PropTypes.object,
  handleCheck: PropTypes.func,
  checkedInstances: PropTypes.object,
};

const WarningMessage = ({ filteredItems, allItems }) => {
  const { t } = useTranslation();

  if (!allItems.length) {
    return (
      <Box style={{ padding: 10, margin: '10px 0px' }}>
        <Typography variant='h5' style={{ color: '#E3757F' }}>
          {t('This product has no instances to show')}
        </Typography>
      </Box>
    );
  }
  if (!filteredItems.length) {
    return (
      <Box style={{ padding: 10, margin: '10px 0px' }}>
        <Typography variant='h5' style={{ color: '#E3757F' }}>
          {t('There is no instances that match to selected filter criteria.')}
        </Typography>
      </Box>
    );
  }
  return null;
};

WarningMessage.propTypes = {
  filteredItems: PropTypes.array,
  allItems: PropTypes.array,
};

const PrintLabelsDialog = ({ items = [], isLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [statusFilterValue, setStatusFilterValue] = useState(false);
  const [storageFilterValue, setStorageFilterValue] = useState(false);
  const [loadingLabels, setLoadingLabels] = useState(false);
  const [checkedInstances, setCheckedInstances] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const { locations } = useContext(LocationContext);

  const filteredItems = useMemo(() => {
    let newFilteredItems = rows;
    setCheckedInstances((prevState) => {
      const newState = { ...prevState };

      Object.keys(newState).forEach((inputName) => {
        newState[inputName] = false;
      });

      return newState;
    });
    if (statusFilterValue) {
      newFilteredItems = rows.filter(
        (item) => item.warehouseStatus === statusFilterValue,
      );
    }
    if (storageFilterValue) {
      newFilteredItems = newFilteredItems.filter(
        (item) => item.locationId === Number(storageFilterValue),
      );
    }
    return newFilteredItems;
  }, [rows, statusFilterValue, storageFilterValue]);

  const [showDialog, setShowDialog] = useState(false);

  const handleLoadLabels = () => {
    setLoadingLabels(true);
    const ids = Object.keys(checkedInstances).filter(
      (key) => checkedInstances[key],
    );
    belecoApi.inv
      .getInstanceLabels({ ids })
      .then((blob) => {
        downloadImageFromBlob(blob, 'InstanceLabel.pdf');
      })
      .finally(() => {
        setLoadingLabels(false);
      })
      .catch((e) => console.log(e));
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setCheckedAll(event.target.checked);
    setCheckedInstances((prevState) => {
      const newState = { ...prevState };

      Object.keys(newState).forEach((inputName) => {
        newState[inputName] = isChecked;
      });

      return newState;
    });
  };

  const handleCheck = (publicId) => {
    setCheckedInstances((prevState) => {
      const newState = { ...prevState };
      newState[publicId] = !prevState[publicId];
      return newState;
    });
  };

  function getTotalSelectedItems() {
    return Object.keys(checkedInstances).filter(
      (item) => checkedInstances[item] === true,
    ).length;
  }

  useEffect(() => {
    let allChecked = true;

    Object.keys(checkedInstances).forEach((inputName) => {
      if (checkedInstances[inputName] === false) {
        allChecked = false;
      }
    });

    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checkedInstances]);

  useEffect(() => {
    setRows(
      items.map((item) => ({
        ...item,
        state: locations[item.locationId]?.state || `${t('Loading')}...`,
      })),
    );
    setCheckedInstances(
      items.reduce((a, c) => ({ ...a, [c.publicId]: false }), {}),
    );
  }, [items, locations]);

  return (
    <>
      <Button
        className={classes.printButton}
        variant='contained'
        onClick={() => {
          setShowDialog(true);
        }}
        disabled={isEmpty(items)}
      >
        {t('Print labels')}
      </Button>
      <Dialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
      >
        <DialogContent>
          <div className={classes.container}>
            <Box className={classes.headerRow}>
              <Typography variant='body2' className={classes.dialogTitle}>
                {t('Select items to print labels')}
              </Typography>
              <Button
                className={classes.printButton}
                variant='contained'
                onClick={handleLoadLabels}
                disabled={!getTotalSelectedItems() || loadingLabels}
              >
                {loadingLabels ? (
                  <CircularProgress size={24} />
                ) : (
                  t('Print labels')
                )}
              </Button>
            </Box>
            <div className={classes.filtersBox}>
              <FilterGroup label={t('Status')}>
                <Select
                  size='small'
                  variant='outlined'
                  onChange={(event) => setStatusFilterValue(event.target.value)}
                  style={{ width: 150 }}
                  value={statusFilterValue}
                  defaultValue={false}
                >
                  <MenuItem key='status-not-selected' value={false}>
                    <Typography variant='body2'>{t('Not selected')}</Typography>
                  </MenuItem>
                  {Object.values(WAREHOUSE_STATUS).map((option) => (
                    <MenuItem
                      key={`status-${option.value}`}
                      value={option.value}
                    >
                      <Typography
                        variant='body2'
                        style={{ textTransform: 'uppercase' }}
                      >
                        {t(option.label)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FilterGroup>
              <FilterGroup label={t('Storage')}>
                <Select
                  size='small'
                  variant='outlined'
                  onChange={(event) =>
                    setStorageFilterValue(event.target.value)
                  }
                  style={{ width: 150 }}
                  value={storageFilterValue}
                  defaultValue={false}
                >
                  <MenuItem key='status-not-selected' value={false}>
                    <Typography variant='body2'>{t('Not selected')}</Typography>
                  </MenuItem>
                  {Object.keys(locations).map((id) => (
                    <MenuItem key={`location-filter-${id}`} value={id}>
                      <Typography variant='body2'>
                        {locations[id]?.state}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FilterGroup>
            </div>
            <Box style={{ overflowX: 'scroll' }}>
              {(isLoading || loadingLabels) && <LoadingOverlay />}
              {isEmpty(filteredItems) && !isLoading ? (
                <WarningMessage allItems={rows} filteredItems={filteredItems} />
              ) : (
                <div style={{ minWidth: 500 }}>
                  <HeaderRow
                    selectAllHandler={handleSelectAll}
                    isSelectedAll={checkedAll}
                  />
                  <div
                    style={{
                      maxHeight: 500,
                      overflow: 'scroll',
                    }}
                  >
                    {filteredItems.map((item) => (
                      <DataTableRow
                        key={item.id}
                        item={item}
                        checkedInstances={checkedInstances}
                        handleCheck={handleCheck}
                      />
                    ))}
                  </div>
                </div>
              )}
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

PrintLabelsDialog.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default PrintLabelsDialog;
