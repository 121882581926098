import WordpressAPI from './WordpressAPI';
import InventoryAPI from './InventoryAPI';
import PayoutsAPI from './PayoutsAPI';

const wpBaseUrl = process.env.REACT_APP_WP_API_URL;
const svcBaseUrl = process.env.REACT_APP_SVC_API_URL;
const payoutsBaseUrl = process.env.REACT_APP_BPS_API_URL;

class BelecoAPI {
  constructor() {
    this.wp = new WordpressAPI({ baseURL: wpBaseUrl });
    this.inv = new InventoryAPI({ baseURL: svcBaseUrl });
    this.payouts = new PayoutsAPI({ baseURL: payoutsBaseUrl });
  }

  setupToken(token) {
    this.wp.setToken(token);
    this.inv.setToken(token);
    this.payouts.setToken(token);
  }
}

const belecoApi = new BelecoAPI();

export default belecoApi;
