import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { STATUS_COLOR_MAP, ORDER_STATUS } from '../../utils/constants';

const StatusCell = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        paddingLeft: '0px',
      }}
    >
      <div
        style={{
          width: '140px',
          height: '19px',
          backgroundColor: STATUS_COLOR_MAP[status || t('N/A')],
          padding: '3px 8px',
          borderRadius: '20px',
          textAlign: 'center',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          textTransform: 'uppercase',
        }}
      >
        <Typography
          variant='body2'
          style={{
            lineHeight: '19px',
          }}
        >
          {status ? t(ORDER_STATUS[status]?.label) : t('N/A')}
        </Typography>
      </div>
    </div>
  );
};

StatusCell.propTypes = {
  status: PropTypes.string,
};

export default StatusCell;
