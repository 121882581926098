import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ORDER_STATUS } from '../../../../utils/constants';

const styles = {
  statusContainer: {
    background: '#ffffff',
    border: '1px solid #cccccc',
    marginBottom: '16px',
    borderRadius: '4px',
    padding: '24px',

    '.statusItemContainer': {
      display: 'flex',
      alignItems: 'center',
      height: '29px',

      '.statusIndicator': {
        width: '29px',
        height: '29px',
        border: '1px solid #cccccc',
        borderRadius: '50%',
        marginRight: '16px',

        '&.successIndicator': {
          borderColor: '#3aab83',
          backgroundColor: '#3aab83',
        },

        '&.failIndicator': {
          borderColor: '#da4453',
          backgroundColor: '#da4453',
        },
      },

      '.statusTitle': {
        textTransform: 'uppercase',
        lineHeight: '14.5px',
      },
    },

    '.statusConnector': {
      height: '14.5px',
      width: '1px',
      backgroundColor: '#cccccc',
      marginLeft: '14px',

      '&.successConnector': {
        backgroundColor: '#3aab83',
      },

      '&.failConnector': {
        backgroundColor: '#da4453',
      },
    },
  },
  statusContainerHeader: {
    borderBottom: '1px solid #cccccc',
    marginBottom: '32px',
    display: 'flex',
    flexDirection: { xs: 'row', md: 'column', xl: 'row' },
    alignItems: { xs: 'center', md: 'flex-start', xl: 'center' },
    justifyContent: 'space-between',
    padding: '8px 0',

    'p.MuiTypography-root': {
      fontFamily: 'Helvetica Neue, sans-serif',
    },

    '> p:first-of-type': {
      fontSize: '18px',
      textTransform: 'uppercase',
    },
  },
  updatedInfo: {
    marginTop: { xs: '0', md: '10px', xl: '0' },

    'p.MuiTypography-root': {
      lineHeight: '14.5px',
      fontSize: '10px',

      '&:first-of-type': {
        span: { marginLeft: '5px', fontWeight: 500 },
      },

      '&:nth-of-type(2)': {
        fontStyle: 'italic',
        textAlign: 'right',
      },
    },
  },
};

const WarehouseDeliveryDetailsStatus = ({ warehouseOrderData }) => {
  const { updatedBy, updatedAt, orderStatus } = warehouseOrderData;

  const getIndicatorCondition = (status) => {
    const statusValues = Object.keys(ORDER_STATUS);
    const statusIndex = statusValues.indexOf(status);
    return statusValues.slice(statusIndex).includes(orderStatus);
  };

  return (
    <Box sx={styles.statusContainer}>
      <Box sx={styles.statusContainerHeader}>
        <Typography>{t('Status')}</Typography>
        <Box sx={styles.updatedInfo}>
          <Typography>
            {t('Updated by')}:{updatedBy ? <span>{updatedBy}</span> : '-'}
          </Typography>
          <Typography>
            {moment(updatedAt).format('YYYY-MM-DD HH:mm')}
          </Typography>
        </Box>
      </Box>
      {orderStatus !== ORDER_STATUS.CANCELLED.value ? (
        <Box className='statusItemContainer'>
          <Box className='statusIndicator successIndicator' />
          <Typography variant='body2' className='statusTitle'>
            {t(ORDER_STATUS.CREATED.label)}
          </Typography>
        </Box>
      ) : (
        <Box className='statusItemContainer'>
          <Box className='statusIndicator failIndicator' />
          <Typography variant='body2' className='statusTitle'>
            {t(ORDER_STATUS.CANCELLED.label)}
          </Typography>
        </Box>
      )}
      <Box
        className={`statusConnector ${
          getIndicatorCondition(ORDER_STATUS.IN_TRANSIT.value)
            ? 'successConnector'
            : ''
        }`}
      />
      <Box className='statusItemContainer'>
        <Box
          className={`statusIndicator ${
            getIndicatorCondition(ORDER_STATUS.IN_TRANSIT.value)
              ? 'successIndicator'
              : ''
          }`}
        />
        <Typography variant='body2' className='statusTitle'>
          {t(ORDER_STATUS.IN_TRANSIT.label)}
        </Typography>
      </Box>
      <Box
        className={`statusConnector ${
          getIndicatorCondition(ORDER_STATUS.PROCESSING.value)
            ? 'successConnector'
            : ''
        }`}
      />
      <Box className='statusItemContainer'>
        <Box
          className={`statusIndicator ${
            getIndicatorCondition(ORDER_STATUS.PROCESSING.value)
              ? 'successIndicator'
              : ''
          }`}
        />
        <Typography variant='body2' className='statusTitle'>
          {t(ORDER_STATUS.PROCESSING.label)}
        </Typography>
      </Box>
      <Box
        className={`statusConnector ${
          orderStatus === ORDER_STATUS.COMPLETED.value ? 'successConnector' : ''
        }`}
      />
      <Box className='statusItemContainer'>
        <Box
          className={`statusIndicator ${
            orderStatus === ORDER_STATUS.COMPLETED.value
              ? 'successIndicator'
              : ''
          }`}
        />
        <Typography variant='body2' className='statusTitle'>
          {t(ORDER_STATUS.COMPLETED.label)}
        </Typography>
      </Box>
    </Box>
  );
};

WarehouseDeliveryDetailsStatus.propTypes = {
  warehouseOrderData: PropTypes.object,
};

export default WarehouseDeliveryDetailsStatus;
