import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoadingOverlay from '../LoadingOverlay';

const useStyles = makeStyles({
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  placeholder: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#f8f8f8',
    backgroundSize: '200%',
    background: 'linear-gradient(45deg, #f8f8f8, #d4d4d4)',
    animation: 'gradient 10s linear infinite',
  },
});

const GradientLoadingOverlay = ({ height = 400, style = {}, ...restProps }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.placeholder}
      style={{ height, ...style }}
      {...restProps}
    >
      <LoadingOverlay size={height / 2 > 40 ? 40 : height / 2} />
    </Box>
  );
};

GradientLoadingOverlay.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
};

export default GradientLoadingOverlay;
