import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ForwardIcon from '@mui/icons-material/Forward';
import shippingOODIcon from '../../../../assets/icons/shipping-ood.png';
import shippingIcon from '../../../../assets/icons/shipping.png';
import StatusCell from '../../../../components/StatusCell';
import TitledDataCell from '../../../../components/TitledDataCell';

const styles = {
  deliveryRecordCard: {
    padding: '15px',
    borderTop: '10px solid #EBEBEB',
  },
  deliveryTypeAndStatus: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },
  dataRow: {
    paddingTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  subTitleText: {
    color: '#8B8B8B',
    fontSize: 10,
    letterSpacing: '0.5px',
  },
};

const WarehouseDeliveriesListItemMob = ({ item }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const {
    orderId,
    estimatedArrival,
    name,
    location,
    instances,
    arrival,
    orderStatus,
    publicId,
    publicOrderId,
  } = item;

  return (
    <Box
      sx={styles.deliveryRecordCard}
      onClick={() => push(`warehouse-orders/${publicId}`)}
    >
      <Box sx={styles.deliveryTypeAndStatus}>
        <img
          style={{ height: 30 }}
          src={orderId ? shippingOODIcon : shippingIcon}
          alt=''
        />
        <StatusCell status={orderStatus} />
      </Box>
      <Typography variant='body2' sx={styles.subTitleText}>
        {t('Created')}: {moment(item.createdAt).format('YYYY-MM-DD')}
      </Typography>
      <Typography
        variant='body2'
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {name}
        <ForwardIcon sx={{ margin: 'auto 5px', fontSize: '18px' }} />
        {t('{{city}} (Storage)', { city: location?.city })}
      </Typography>
      <Box sx={styles.dataRow}>
        <TitledDataCell cellName={t('Estimated')} data={estimatedArrival} />
        <TitledDataCell cellName={t('Order')} data={publicOrderId || '-'} />
        <TitledDataCell cellName={t('Items')} data={instances.length} />
        <TitledDataCell cellName={t('Arrival')} data={arrival || t('N/A')} />
      </Box>
    </Box>
  );
};

WarehouseDeliveriesListItemMob.propTypes = {
  item: PropTypes.object,
};

export default WarehouseDeliveriesListItemMob;
