import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  newBtn: {
    marginLeft: 10,
    height: 37,
    borderRadius: 4,
    background: '#DA4453',
    '&:hover': {
      background: '#DA4453',
      color: 'white',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: 25,
    },
  },
  newBtnLabel: {
    textTransform: 'capitalize',
    fontFamily: 'GT-America-Standard-Thin, sans-serif',
    fontSize: 14,
  },
}));

const AddNewButton = ({ onNew = null }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      size='small'
      classes={{ root: classes.newBtn, label: classes.newBtnLabel }}
      color='primary'
      variant='contained'
      onClick={onNew}
    >
      {t('Create')}
    </Button>
  );
};
AddNewButton.propTypes = {
  onNew: PropTypes.func,
};
export default AddNewButton;
