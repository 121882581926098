import { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import belecoApi from '../api/index';

const useAvailabilityHelper = (id = []) => {
  const currentIds = useRef([]);

  const [availabilityMap, setAvailabilityMap] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getAvailabilityForList = useCallback(async (updatedArray) => {
    setIsLoading(true);

    let freshAvailabilityMap = {};
    const startDate = moment().format('YYYY-MM-DD');
    const endDate = moment(startDate).add(1, 'y').format('YYYY-MM-DD');

    const params = {
      id: updatedArray,
      delivery: startDate,
      pickup: endDate,
    };

    try {
      const response = await belecoApi.inv.getInstancesAvailability(params);

      freshAvailabilityMap = response.reduce((acc, curr) => {
        return { ...acc, [curr.publicId]: curr };
      }, {});

      setAvailabilityMap(freshAvailabilityMap);
    } catch (error) {
      setAvailabilityMap({});
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && id.length) {
      const didArrayChange = id.some(
        (item, index) => item !== currentIds.current[index],
      );

      if (didArrayChange) {
        currentIds.current = id;

        getAvailabilityForList(id);
      }
    }
  }, [getAvailabilityForList, id, isLoading]);

  return { availabilityMap, isLoading };
};

export default useAvailabilityHelper;
