import { useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import belecoApi from '../../../../../api';
import useNotificator from '../../../../../utils/useNotificator';

const useStyles = makeStyles((theme) => ({
  root: {
    visibility: ({ disabled }) => (disabled ? 'hidden' : 'unset'),
    position: 'relative',
    border: 'solid 1px #d0d0d0',
    borderRadius: '5px',
    width: 150,
    height: 150,

    [theme.breakpoints.down('md')]: {
      width: 240,
      height: 240,
    },
  },
  previewImageContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: theme.zIndex.tooltip,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
  },
  previewCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,

    '& svg': {
      fontSize: 40,
    },
  },
  previewImage: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  drop: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  disabledDrop: {
    opacity: 0.15,
    cursor: 'not-allowed',
  },
  thumbnailContainer: {
    height: 'inherit',
    width: 'inherit',
  },
  thumbnail: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    maxHeight: 300,
  },
  uploadProgress: {
    color: '#000',
    position: 'absolute',
    alignSelf: 'center',
  },
  removeImageButton: {
    position: 'absolute',
    marginTop: '3%',
    marginRight: '3%',
    zIndex: 10,
    right: 0,
    cursor: 'pointer',

    '& svg': {
      fill: theme.palette.title.highlighted,
    },
  },
  addIcon: {
    fontSize: 30,
    margin: 'auto',
  },
}));
const ImagePicker = ({ index, reportId, setValue, images }) => {
  const { notifyError } = useNotificator();

  const disabled = index > images.length;

  const classes = useStyles({ disabled });
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const onDrop = async (acceptedFiles) => {
    setUploadInProgress(true);

    try {
      const acceptedFile = acceptedFiles[0];
      const imgFormData = new FormData();
      imgFormData.append('image', acceptedFile);

      const data = await belecoApi.inv.createDamageReportImage({
        id: reportId,
        data: imgFormData,
      });
      setValue('imageUrls', data.imageUrls, {
        shouldDirty: false,
      });
    } catch (error) {
      notifyError(error, 'Failed to upload image');
    } finally {
      setUploadInProgress(false);
    }
  };

  const removeImage = async () => {
    setUploadInProgress(true);

    try {
      const data = await belecoApi.inv.deleteDamageReportImage({
        id: reportId,
        data: {
          url: images[index],
        },
      });

      setValue('imageUrls', data.imageUrls || [], {
        shouldDirty: false,
      });
    } catch (error) {
      notifyError(error, 'Failed to delete image');
    } finally {
      setUploadInProgress(false);
    }
  };

  if (uploadInProgress) {
    return (
      <div className={classes.root}>
        <div className={classes.drop}>
          <CircularProgress size={24} className={classes.uploadProgress} />
        </div>
      </div>
    );
  }

  if (images[index] === undefined) {
    return (
      <div className={classes.root}>
        <Dropzone
          onDrop={onDrop}
          accept='image/jpeg,image/png'
          disabled={disabled}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={`${classes.drop} ${
                disabled ? classes.disabledDrop : ''
              }`}
            >
              <input {...getInputProps()} />
              <AddIcon className={classes.addIcon} />
            </div>
          )}
        </Dropzone>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {previewImage ? (
        <div className={classes.previewImageContainer}>
          <IconButton
            className={classes.previewCloseButton}
            onClick={() => setPreviewImage(null)}
            size='large'
          >
            <CloseIcon />
          </IconButton>
          <img
            src={previewImage}
            alt='Bild saknas'
            className={classes.previewImage}
          />
        </div>
      ) : null}
      <div
        className={classes.removeImageButton}
        onClick={removeImage}
        role='button'
        tabIndex={0}
        onKeyPress={removeImage}
      >
        <DeleteIcon />
      </div>
      <Dropzone
        // className={classes.disabledDropzone}
        onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
        accept='image/jpeg,image/png'
        disabled
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes.drop}>
            <input {...getInputProps()} disabled={disabled} />
            {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */}
            <div
              className={classes.thumbnailContainer}
              key={images[index]}
              role='button'
              onClick={() => setPreviewImage(images[index])}
            >
              <img
                src={images[index]}
                className={classes.thumbnail}
                alt='Bild saknas'
              />
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

ImagePicker.propTypes = {
  index: PropTypes.number.isRequired,
  reportId: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  images: PropTypes.array,
};

export default ImagePicker;
