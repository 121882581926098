export const BachOrderTableWrapper = () => ({
  detailsRowWrapper: {
    padding: 20,
    width: '100%',
    border: '1px solid #CCC',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '30px',
  },

  validationErrorMessage: {
    fontSize: 14,
    padding: 12,
    backgroundColor: '#F3ECC6',
    fontWeight: '700',
  },

  columnDataRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 30,
  },
});
