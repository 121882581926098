import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

const LoadingOverlayDialog = ({ text, show }) => {
  return (
    <Dialog open={show}>
      <DialogContent>
        <DialogContentText style={{ display: 'flex', padding: 15 }}>
          <CircularProgress size={30} />
          <Typography variant={'h5'} style={{ paddingLeft: 20, fontSize: 14 }}>
            {text}
            <br />
            Please wait until it's finished...
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingOverlayDialog;
