import { useRef } from 'react';
import { MenuItem, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: (props) => (props.width ? props.width : 160),
    },
  },
  inputBase: {
    width: '100%',
    height: 37,
    backgroundColor: 'white',
  },
  field: {
    fontSize: 14,
    fontFamily: 'GT-America-Standard-Thin, sans-serif',
    color: 'currentcolor',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'block!important',
  },
  menuPaper: {
    maxHeight: 310,
  },
}));
const OutlinedSelector = ({
  filterValue = null,
  onFilterChange,
  label,
  options = [],
  additionalClass = null,
  ...props
}) => {
  const classes = useStyles(props);
  const customRef = useRef();
  const onClose = () => {
    setTimeout(() => {
      document.activeElement.blur();
    }, 0);
  };
  return (
    <TextField
      select
      label={label}
      variant='outlined'
      className={`${classes.formControl} ${additionalClass}`}
      inputRef={customRef}
      SelectProps={{
        onClose,
        classes: {
          select: classes.field,
        },
        MenuProps: {
          classes: {
            paper: classes.menuPaper,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
      }}
      InputProps={{
        classes: {
          root: classes.inputBase,
        },
      }}
      size='small'
      onChange={(event) => onFilterChange(event.target.value)}
      value={filterValue || ''}
      {...props}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

OutlinedSelector.propTypes = {
  additionalClass: PropTypes.string,
  options: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
};

export default OutlinedSelector;
