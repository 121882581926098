import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import authReducer, { checkSession } from './reducers/auth';
import { history } from '../utils';
import locations from './reducers/locations';

const store = configureStore({
  reducer: {
    router: connectRouter(history),
    auth: authReducer,
    locations,
  },
  middleware: [routerMiddleware(history), ...getDefaultMiddleware()],
});

export default store;

store.dispatch(checkSession());
