import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Grid, Hidden } from '@mui/material';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '../../components/LoadingOverlay';
import TransportDetailsMainDesktop from './components/TransportDetailsMainDesktop';
import TransportDetailsMainMobile from './components/TransportDetailsMainMobile';
import TransportDetailsStatus from './components/TransportDetailsStatus';
import InstancesList from './components/InstancesList';
import Changelog from '../Changelog/Changelog';
import belecoApi from '../../api';
import CHANGELOG_TYPES from '../Changelog/changelogTypes';
import StatusChangeWarningDialog from './components/StatusChangeWarningDialog';
import { TRANSPORTING_STATUS_OPTIONS } from '../../utils/constants';
import TransportDetailsProvider, {
  TransportDetailsContext,
} from '../../context/TransportDetails/TransportDetailsContext';
import ChangelogProvider, {
  ChangelogContext,
} from '../../context/Changelog/ChangelogContext';
import useNotificator from '../../utils/useNotificator';

const TransportDetailsContent = () => {
  const { t } = useTranslation();
  const { publicId } = useParams();
  const { getChangelog } = useContext(ChangelogContext);

  const [isShownPopupToTransit, setIsShownPopupToTransit] = useState(false);
  const [isShownPopupToComplete, setIsShownPopupComplete] = useState(false);
  const context = useContext(TransportDetailsContext);
  const {
    updateTransport,
    getTransportDetails,
    clearTransportDetails,
    transport,
    isLoading,
  } = context;
  const { notifySuccess } = useNotificator();

  const onUpdateTms = (tmsId) => {
    updateTransport({
      data: { tmsId, isNoShow: false },
    }).then(() => {
      getChangelog(() => belecoApi.inv.getTransportHistory({ id: publicId }));
      notifySuccess('Transport was updated.');
    });
  };
  const updateTransportStatus = (newStatus) => {
    updateTransport({
      data: { status: newStatus },
    }).then(() => {
      getChangelog(() => belecoApi.inv.getTransportHistory({ id: publicId }));
    });
  };

  useEffect(() => {
    getTransportDetails();
    return () => {
      clearTransportDetails();
    };
  }, [publicId]);

  useEffect(() => {
    if (
      transport.status === TRANSPORTING_STATUS_OPTIONS.PROCESSING.value &&
      transport.instances.every((item) => item.transportInstances.isPacked)
    ) {
      setIsShownPopupToTransit(true);
    }
    if (
      transport.status === TRANSPORTING_STATUS_OPTIONS.IN_TRANSIT.value &&
      transport.instances.every((item) => item.transportInstances.isCompleted)
    ) {
      setIsShownPopupComplete(true);
    }
  }, [transport?.instances]);

  return isLoading || isEmpty(transport) ? (
    <LoadingOverlay />
  ) : (
    <>
      <Helmet>
        <title>{`${t('Transport')} ${transport.reference}`}</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item lg={9} xs={12}>
          <Hidden smDown>
            <TransportDetailsMainDesktop
              transport={transport}
              onUpdateTms={onUpdateTms}
            />
          </Hidden>
          <Hidden mdUp>
            <TransportDetailsMainMobile
              transport={transport}
              onUpdateTms={onUpdateTms}
            />
          </Hidden>
          <InstancesList
            instances={transport.instances}
            location={transport.location}
          />
          <StatusChangeWarningDialog
            isOpened={isShownPopupToComplete}
            closeDialog={() => setIsShownPopupComplete(false)}
            handleAction={() =>
              updateTransportStatus(TRANSPORTING_STATUS_OPTIONS.COMPLETED.value)
            }
            actionButtonText={t('Completed')}
            dialogTitle={t('All items delivered!')}
            messageText={t('Mark transport as completed?')}
          />
          <StatusChangeWarningDialog
            isOpened={isShownPopupToTransit}
            closeDialog={() => setIsShownPopupToTransit(false)}
            handleAction={() =>
              updateTransportStatus(
                TRANSPORTING_STATUS_OPTIONS.IN_TRANSIT.value,
              )
            }
            actionButtonText={t('Ready')}
            dialogTitle={t('All items packed!')}
            messageText={t('Is Transport ready for delivery?')}
          />
        </Grid>
        <Grid item lg={3} xs={12}>
          <TransportDetailsStatus transport={transport} />
          <Changelog
            changelogType={CHANGELOG_TYPES.TRANSPORT}
            getData={() => belecoApi.inv.getTransportHistory({ id: publicId })}
          />
        </Grid>
      </Grid>
    </>
  );
};

const TransportDetails = () => {
  return (
    <ChangelogProvider>
      <TransportDetailsProvider>
        <TransportDetailsContent />
      </TransportDetailsProvider>
    </ChangelogProvider>
  );
};

export default TransportDetails;
