import { useEffect, useMemo, useState, useContext, useRef } from 'react';
import { Typography, TextareaAutosize, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GradientLoadingOverlay from '../../components/GradientLoadingOverlay';
import ChangelogBubble from './components/ChangelogBubble';
import getTransportChangelogItem from './getTransportChangelogItem';
import getInstanceChangelogItem from './getInstanceChangelogItem';
import getWarehouseOrderChangelogItem from './getWarehouseOrderChangelogItem';
import CHANGELOG_TYPES from './changelogTypes';
import { ChangelogContext } from '../../context/Changelog/ChangelogContext';
import { TransportDetailsContext } from '../../context/TransportDetails/TransportDetailsContext';
import { InstanceDetailsContext } from '../../context/InstanceDetails/InstanceDetailsContext';
import useNotificator from '../../utils/useNotificator';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    background: '#ffffff',
    border: '1px solid #cccccc',
    marginBottom: 16,
    borderRadius: 4,
    padding: 24,

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 16,
    },
  },
  headingContainer: {
    borderBottom: '1px solid #cccccc',
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
  },
  heading: {
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 18,
    textTransform: 'uppercase',
  },
  bottomContainer: {
    borderTop: '1px solid #cccccc',
    marginTop: 24,
    paddingTop: 24,
  },
  noteText: {
    color: '#555555',
  },
  noteArea: {
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 12,
    width: '100%',
    resize: 'none',
    margin: '8px 0',
    padding: 8,
    outline: 'none',
    borderColor: '#707070',
    borderRadius: 4,
  },
  addButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const Changelog = ({ changelogType, getData, isHiddenNoteField = false }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { publicId } = useParams();
  const { updateTransport } = useContext(TransportDetailsContext);
  const { updateInstance } = useContext(InstanceDetailsContext);
  const { notifyError } = useNotificator();
  const {
    isLoading,
    isLoadingUsers,
    changelog,
    users,
    getChangelog,
    getUsersByChangelog,
  } = useContext(ChangelogContext);

  const methodsRef = useRef({
    getChangelogItem: () => {},
    parentUpdateMethod: () => {},
    isSupportingMultipleUpdate: false,
  });
  const {
    current: {
      getChangelogItem,
      parentUpdateMethod,
      isSupportingMultipleUpdate,
    },
  } = methodsRef;

  const [note, setNote] = useState('');
  const [isNoteSubmitting, setNoteSubmittingState] = useState(false);

  useEffect(() => {
    if (isLoading) return;
    if (isEmpty(changelog)) {
      getChangelog(getData);
    }
  }, []);

  useEffect(() => {
    switch (changelogType) {
      case CHANGELOG_TYPES.INSTANCE:
        methodsRef.current = {
          getChangelogItem: getInstanceChangelogItem,
          parentUpdateMethod: updateInstance,
          isSupportingMultipleUpdate: true,
        };
        break;
      case CHANGELOG_TYPES.TRANSPORT:
        methodsRef.current = {
          getChangelogItem: getTransportChangelogItem,
          parentUpdateMethod: updateTransport,
          isSupportingMultipleUpdate: false,
        };
        break;
      case CHANGELOG_TYPES.WAREHOUSE_ORDER:
        methodsRef.current = {
          getChangelogItem: getWarehouseOrderChangelogItem,
          parentUpdateMethod: () => {},
          isSupportingMultipleUpdate: true,
        };
        break;
      default:
        break;
    }
  }, [i18n.language]);

  const completeChangelog = useMemo(() => {
    if (isEmpty(changelog)) return [];

    return Object.values(changelog)
      .reduce((acc, item) => {
        if (Array.isArray(item)) {
          return [...acc, ...item];
        }

        return acc;
      }, [])
      .sort((a, b) => moment(b.event_time).diff(a.event_time));
  }, [changelog]);

  useEffect(() => {
    if (isEmpty(completeChangelog)) return;

    getUsersByChangelog(completeChangelog);
  }, [completeChangelog]);

  const renderChangeLogItem = (oldValue, newValue, updatedById, eventTime) => {
    const changeLogRecord = getChangelogItem(oldValue, newValue);
    if (!changeLogRecord || isEmpty(changeLogRecord)) return null;

    const changeLogMessage = !isSupportingMultipleUpdate
      ? [changeLogRecord.getTemplate(oldValue, newValue)]
      : changeLogRecord.map((log) => log.getTemplate(oldValue, newValue));
    const updatedByText = isLoadingUsers
      ? `${t('Loading')}...`
      : users[updatedById]?.display_name || t('Unknown');

    return (
      <ChangelogBubble
        key={newValue?.updated_at}
        changeLogMessage={changeLogMessage}
        updatedAt={newValue?.updated_at || eventTime}
        updatedBy={updatedByText}
        bubbleColor={changeLogRecord?.bubbleColor}
      />
    );
  };

  const onNoteSubmit = async () => {
    setNoteSubmittingState(true);

    try {
      await parentUpdateMethod({
        publicId,
        data: { note },
      });

      setNote('');
      setNoteSubmittingState(false);
      getChangelog(getData);
    } catch (error) {
      notifyError(error, 'Failed to update note');
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <Typography className={classes.heading}>{t('Updates')}</Typography>
      </div>
      {isEmpty(changelog) && isLoading ? (
        <GradientLoadingOverlay height={350} />
      ) : (
        completeChangelog.map(
          ({ origin_value, new_value, updated_by, event_time }) =>
            renderChangeLogItem(
              origin_value,
              new_value,
              updated_by,
              event_time,
            ),
        )
      )}
      {!isHiddenNoteField ? (
        <div className={classes.bottomContainer}>
          <Typography variant='body2' className={classes.noteText}>
            {t('Add note')}:
          </Typography>
          <TextareaAutosize
            minRows={3}
            maxRows={7}
            className={classes.noteArea}
            placeholder={t('Add a note')}
            value={note}
            onChange={(event) => setNote(event.target.value)}
            disabled={isNoteSubmitting}
          />
          <div className={classes.addButtonContainer}>
            <Button
              variant='contained'
              onClick={onNoteSubmit}
              disabled={isNoteSubmitting || note.length === 0}
            >
              {t('Add note')}
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

Changelog.propTypes = {
  changelogType: PropTypes.string,
  getData: PropTypes.func.isRequired,
  isHiddenNoteField: PropTypes.bool,
};

export default Changelog;
