import { useState } from 'react';
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';

const Navigation = () => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpened(!isDrawerOpened);
  };
  const onDrawerOpen = () => {
    setIsDrawerOpened(true);
  };
  const onDrawerClose = () => {
    setIsDrawerOpened(false);
  };

  return (
    <>
      <TopBar toggleDrawer={toggleDrawer} />
      <SideBar
        isDrawerOpened={isDrawerOpened}
        onDrawerOpen={onDrawerOpen}
        onDrawerClose={onDrawerClose}
      />
    </>
  );
};

export default Navigation;
