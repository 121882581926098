import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  dialogRoot: {
    display: 'flex',
    alignItems: 'center',
    background: 'rgb(0,0,0,0.6)',
  },
  commentDialog: {
    width: '300px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  commentDialogHeader: {
    textTransform: 'uppercase',
    color: '#ffffff',
    alignSelf: 'flex-start',
    fontSize: 16,
  },
  commentInput: {
    backgroundColor: '#FFF',
    padding: 15,
    width: 300,
    marginBottom: 20,
  },
});

const TransportDialog = ({ isOpen = false, setOpenState, onSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [comment, setComment] = useState('');

  const onDialogClose = (event) => {
    event.stopPropagation();
    setComment('');
    setOpenState(false);
  };

  return (
    <Dialog
      fullScreen
      classes={{
        paperFullScreen: classes.dialogRoot,
      }}
      open={isOpen}
      onClick={onDialogClose}
    >
      <div className={classes.commentDialog}>
        <Typography variant='body2' className={classes.commentDialogHeader}>
          {t('Failed to deliver')}
        </Typography>
        <TextField
          id='comment-related-to-failure'
          multiline
          minRows={25}
          defaultValue=''
          placeholder={t('Leave comment with reason of failure')}
          variant='outlined'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          InputProps={{
            className: classes.commentInput,
          }}
        />
        <Button
          variant='contained'
          className={classes.statusButton}
          style={{ alignSelf: 'flex-end' }}
          onClick={() => onSubmit(comment)}
        >
          <Typography className={classes.buttonText} variant='body2'>
            {t('Complete')}
          </Typography>
        </Button>
      </div>
    </Dialog>
  );
};

TransportDialog.propTypes = {
  isOpen: PropTypes.bool,
  setOpenState: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default TransportDialog;
