import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import store from './store';
import { GlobalSnackbarUtil } from './utils/GlobalSnackbarUtil';
import { history } from './utils';
import './index.css';
import 'moment/locale/sv';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/fi';
import 'moment/locale/nb';

import App from './App';
import mainTheme from './mainTheme';
import './localization';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              autoHideDuration={4000}
            >
              <GlobalSnackbarUtil />
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
