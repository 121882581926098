import { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import FilterBox from '../components/FilterBox';
import WarehouseDeliveriesTable from '../components/WarehouseDeliveriesTable';
import { WarehouseDeliveriesPageStyles } from '../styles';
import SectionContainer from '../../../components/CustomComponents/SectionContainer';
import TitleContainer from '../../../components/TitleContainer';

const useStyles = makeStyles(WarehouseDeliveriesPageStyles);

const WDDeskTopView = ({
  location,
  loading,
  assetsProvidersList,
  ownerIfFilterValue,
  setOwnerIdFilterValue,
  formattedStatuses,
  orderStatusFilterValue,
  setOrderStatusFilterValue,
  startDateFilter,
  endDateFilter,
  handleDateFilter,
  handleSearch,
  searchValue,
  resetFiltersToDefaults,
  resetSearchValue,
  handleChangePage,
  rows,
  total,
  pageSize,
  page,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const createNewWO = () => {
    history.push(`/warehouse-orders/create-new-order`);
  };

  const sortedRows = useMemo(() =>
    rows
      .map((item) => {
        return {
          ...item,
          createdAt: moment(item.createdAt).format('YYYY-MM-DD'),
          estimatedArrival: moment(item.estimatedArrival).format('YYYY-MM-DD'),
          arrival: item.arrival
            ? moment(item.arrival).format('DD MMM')
            : t('N/A'),
        };
      })
      .sort(
        (a, b) => a.estimatedArrival.localeCompare(b.estimatedArrival),
        [rows],
      ),
  );

  return (
    <>
      <TitleContainer title={t('Warehouse orders')} onNew={createNewWO}>
        <FilterBox
          {...{
            location,
            loading,
            assetsProvidersList,
            ownerIfFilterValue,
            setOwnerIdFilterValue,
            formattedStatuses,
            orderStatusFilterValue,
            setOrderStatusFilterValue,
            startDateFilter,
            endDateFilter,
            handleDateFilter,
            handleSearch,
            searchValue,
            resetFiltersToDefaults,
            resetSearchValue,
          }}
          onNew={createNewWO}
        />
      </TitleContainer>
      <SectionContainer>
        <WarehouseDeliveriesTable
          className={classes.root}
          onChangePage={handleChangePage}
          {...{ items: sortedRows, loading, total, pageSize, page }}
        />
      </SectionContainer>
    </>
  );
};
WDDeskTopView.propTypes = {
  location: PropTypes.object,
  // eslint-disable-next-line react/boolean-prop-naming
  loading: PropTypes.bool,
  assetsProvidersList: PropTypes.object,
  ownerIfFilterValue: PropTypes.array,
  setOwnerIdFilterValue: PropTypes.func,
  formattedStatuses: PropTypes.array,
  orderStatusFilterValue: PropTypes.array,
  setOrderStatusFilterValue: PropTypes.func,
  startDateFilter: PropTypes.object,
  endDateFilter: PropTypes.object,
  handleDateFilter: PropTypes.func,
  handleSearch: PropTypes.func,
  searchValue: PropTypes.string,
  resetFiltersToDefaults: PropTypes.func,
  handleChangePage: PropTypes.func,
  rows: PropTypes.array,
  total: PropTypes.number,
  page: PropTypes.number,
  resetSearchValue: PropTypes.func,
  pageSize: PropTypes.number,
};

export default WDDeskTopView;
