import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import PropTypes from 'prop-types';

const AxisTickLongPeriod = ({ x, y, payload }) => {
  const values = payload.value.split('-');
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-5}
        textAnchor='end'
        fill='#666'
        transform='rotate(-60)'
        style={{ fontSize: 12 }}
      >
        {values[0]} -
      </text>
      <text
        x={0}
        y={0}
        dx={-5}
        dy={10}
        textAnchor='end'
        fill='#666'
        transform='rotate(-60)'
        style={{ fontSize: 12 }}
      >
        {values[1]}
      </text>
    </g>
  );
};

AxisTickLongPeriod.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

const LongPeriodChart = ({ data, tooltipFormatter }) => {
  return (
    <ResponsiveContainer width='100%' aspect={2.5}>
      <BarChart
        data={data}
        margin={{ top: 15, left: -25, bottom: 25, right: 20 }}
        barCategoryGap='5%'
        barGap={0}
      >
        <CartesianGrid strokeDasharray='1 3' />
        <XAxis dataKey='date' interval={0} tick={AxisTickLongPeriod} />
        <YAxis allowDecimals={false} />
        <Tooltip
          formatter={tooltipFormatter}
          cursor={{ fill: 'rgba(0,0,0,0.04)' }}
        />
        <Bar dataKey='qtyFull' fill='#b8b8b8' fillOpacity='0.5' />
        <Bar dataKey='qtyInvisible' fill='#FD5D5D' fillOpacity='0.6' />
        <Bar dataKey='qtyAvailable' fill='#85CB85' fillOpacity='0.9' />
      </BarChart>
    </ResponsiveContainer>
  );
};

LongPeriodChart.propTypes = {
  data: PropTypes.array,
  tooltipFormatter: PropTypes.func,
};

export default LongPeriodChart;
