import {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  useMemo,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { Hidden, useMediaQuery } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import TransportsForMobile from './TransportsMobile';
import TransportsForDeskTop from './TransportsDesktop';
import useNotificator from '../../utils/useNotificator';
import { TMSContext } from '../../context/TMS/TMSContext';
import LocationContext from '../../context/Location/LocationContext';
import useDebouncedValue from '../../utils/useDebouncedValue';
import belecoApi from '../../api';

const Transports = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { notifyError } = useNotificator();
  const { userLocation } = useContext(LocationContext);
  const notInitialRender = useRef(false);

  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));
  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));

  const initUrlParams = new URLSearchParams(history.location.search);
  const initFilteringOptions = {
    from: initUrlParams.get('from')
      ? moment(initUrlParams.get('from'))
      : moment(),
    to: initUrlParams.get('to') ? moment(initUrlParams.get('to')) : moment(),
    isNoShow: initUrlParams.get('isNoShow')
      ? initUrlParams.get('isNoShow')
      : null,
    type: initUrlParams.get('type') ? initUrlParams.get('type') : '',
    tmsId: initUrlParams.get('tmsId') ? initUrlParams.get('tmsId') : '',
    offset: initUrlParams.get('offset') ? initUrlParams.get('offset') : 0,
    status: initUrlParams.get('status')
      ? initUrlParams.get('status').split(',')
      : [],
  };

  const pageSize = 15;
  const [transports, setTransports] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(initFilteringOptions.offset);
  const [startDateFilter, setStartDateFilter] = useState(
    initFilteringOptions.from,
  );
  const [endDateFilter, setEndDateFilter] = useState(initFilteringOptions.to);
  const [showHideFailedItems, setShowHideFailedItems] = useState(
    initFilteringOptions.isNoShow,
  );
  const [typeFilter, setTypeFilter] = useState(initFilteringOptions.type);
  const [tmsId, setTmsId] = useState(initFilteringOptions.tmsId);
  const [status, setStatus] = useState(initFilteringOptions.status);

  const updateUrlFilteringParams = () => {
    const urlSearchParams = {};

    if (startDateFilter)
      Object.assign(urlSearchParams, {
        from: moment(startDateFilter).format('YYYY-MM-DD'),
      });
    if (endDateFilter)
      Object.assign(urlSearchParams, {
        to: moment(endDateFilter).format('YYYY-MM-DD'),
      });
    if (showHideFailedItems !== null)
      Object.assign(urlSearchParams, { isNoShow: showHideFailedItems });
    if (typeFilter) Object.assign(urlSearchParams, { type: typeFilter });
    if (tmsId) Object.assign(urlSearchParams, { tmsId });
    if (offset > 0) Object.assign(urlSearchParams, { offset });
    if (status.length) Object.assign(urlSearchParams, { status });
    if (userLocation?.id)
      Object.assign(urlSearchParams, { locationId: userLocation.id });

    const urlParams = new URLSearchParams(urlSearchParams);

    history.push(`${location.pathname}?${urlParams.toString()}`);
  };

  const getTransportsData = async () => {
    setLoading(true);

    try {
      const dateFrom = startDateFilter
        ? moment(startDateFilter).format('YYYY-MM-DD')
        : '';
      const dateTo = endDateFilter
        ? moment(endDateFilter).format('YYYY-MM-DD')
        : '';

      const params = {
        limit: pageSize,
        offset,
        from: dateFrom,
        to: dateTo,
        locationId: userLocation?.id || '',
        orderBy: 'from',
        sort: 'ASC',
        isNoShow: showHideFailedItems,
        type: typeFilter,
        tmsId,
        status,
      };

      const data = await belecoApi.inv.getTransports(params);

      const { rows, count } = data;

      setTransports(rows);
      setTotal(count);
      setPage(offset / pageSize);
    } catch (err) {
      notifyError(err, t('Failed to load transports'));
    } finally {
      setLoading(false);
    }

    updateUrlFilteringParams();
  };

  const handleLoadMore = async () => {
    if (total > transports.length) {
      try {
        setLoading(true);
        const dateFrom = startDateFilter
          ? moment(startDateFilter).format('YYYY-MM-DD')
          : '';
        const dateTo = endDateFilter
          ? moment(endDateFilter).format('YYYY-MM-DD')
          : '';

        const params = {
          limit: 10,
          offset: transports.length,
          from: dateFrom,
          to: dateTo,
          locationId: userLocation?.id || '',
          orderBy: 'from',
        };

        const data = await belecoApi.inv.getTransports(params);
        const { rows } = data;

        setTransports((prev) => [...prev, ...rows]);
      } catch (err) {
        notifyError(err, t('Failed to load transports'));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getTransportsData();
  }, [offset]);

  const startEndDateMemoized = useMemo(() => {
    return { startDateFilter, endDateFilter };
  }, [startDateFilter, endDateFilter]);

  // Debounced two values as one
  const debouncedStartEndDate = useDebouncedValue(startEndDateMemoized, 500);

  useEffect(() => {
    // Skip first render
    if (notInitialRender.current) {
      if (offset === 0) {
        getTransportsData();
      } else {
        setOffset(0);
      }
    } else {
      notInitialRender.current = true;
    }
  }, [
    userLocation?.id,
    status,
    tmsId,
    typeFilter,
    showHideFailedItems,
    debouncedStartEndDate,
  ]);

  useEffect(() => {
    // Reset pagination when we change view from desktop to mob. Mob view has fetch more functionality
    if (isMobile && offset > 0) {
      setOffset(0);
    }

    // Reset rows data and do request when we change view from mob to desktop. Mob view has fetch more functionality
    if (isDesktop && transports.length > pageSize) {
      getTransportsData();
    }
  }, [isMobile, isDesktop]);

  const { TMSList, getTMS, loading: isLoadingTMSList } = useContext(TMSContext);

  const resetFiltersToDefaults = () => {
    setStartDateFilter(moment());
    setEndDateFilter(moment());
    setShowHideFailedItems(null);
    setTypeFilter('');
    setTmsId('');
    setStatus([]);
  };

  const handleDateFilter = ({ startDate, endDate }) => {
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };

  useEffect(() => {
    if (isEmpty(TMSList) && !isLoadingTMSList) {
      getTMS();
    }
  }, [isLoadingTMSList, TMSList]);

  const handleChangePage = useCallback((selectedPage) => {
    setOffset(selectedPage * pageSize);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('Transports')}</title>
      </Helmet>
      <Hidden mdDown>
        <TransportsForDeskTop
          {...{
            showHideFailedItems,
            setShowHideFailedItems,
            startDateFilter,
            endDateFilter,
            handleDateFilter,
            handleChangePage,
            page,
            pageSize,
            typeFilter,
            setTypeFilter,
            tmsId,
            setTmsId,
            status,
            setStatus,
            transports,
            total,
            isLoadingTransports: loading,
            TMSList,
            isLoadingTMSList,
          }}
        />
      </Hidden>
      <Hidden mdUp>
        <TransportsForMobile
          startDateFilter={startDateFilter}
          endDateFilter={endDateFilter}
          handleDateFilter={handleDateFilter}
          resetFiltersToDefaults={resetFiltersToDefaults}
          showHideFailedItems={showHideFailedItems}
          setShowHideFailedItems={setShowHideFailedItems}
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          tmsId={tmsId}
          setTmsId={setTmsId}
          status={status}
          setStatus={setStatus}
          transports={transports}
          total={total}
          isLoadingTransports={loading}
          TMSList={TMSList}
          isLoadingTMSList={isLoadingTMSList}
          handleLoadMore={handleLoadMore}
        />
      </Hidden>
    </>
  );
};

export default Transports;
