import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { formatXAxis, formatYAxis } from '../../helpers/graphHelper';

const useStyles = makeStyles(() => ({
  legendRow: {
    display: 'flex',
  },
  legendRowEl: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    },
  },
  color: {
    width: 12,
    height: 5,
    marginRight: 9,
  },
  customTooltip: {
    width: 150,
    height: 'auto',
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: '0px 0px 3px #00000086',
    padding: '5px 10px',
  },
  tooltipLabel: {
    fontWeight: 500,
  },
}));

const TransportsChart = ({ data = [] }) => {
  const classes = useStyles();

  const renderTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const formattedData = [payload[1], payload[2], payload[0]];
      return (
        <div className={classes.customTooltip}>
          <p className={classes.tooltipLabel}>{label}</p>
          {formattedData.map((entry) => (
            <p key={entry.name} className='row'>
              {`${entry.name}: ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };
  const renderLegend = ({ payload }) => {
    const formattedData = [payload[1], payload[2], payload[0]];
    return (
      <div className={classes.legendRow}>
        {formattedData.map((entry) => (
          <div key={entry.value} className={classes.legendRowEl}>
            <span
              className={classes.color}
              style={{ backgroundColor: entry.color }}
            />
            {entry.value}
          </div>
        ))}
      </div>
    );
  };

  return (
    <ResponsiveContainer width='100%' height={240}>
      <ComposedChart
        margin={{ top: 0, left: -35, right: 0, bottom: 25 }} // 20px => for legend
        height={240}
        data={data}
      >
        <CartesianGrid stroke='#f5f5f5' vertical={false} />
        <XAxis
          dataKey='name'
          padding={{ left: 0, right: 0 }}
          angle={-35}
          tickLine={false}
          tickSize={3}
          tick={{ fontSize: 10 }}
          tickMargin={10}
          axisLine={false}
          interval='preserveStart' // 'preserveStartEnd' to auto interval, 0 => show all
          tickFormatter={formatXAxis}
        />
        <YAxis
          yAxisId='left-axis'
          type='number'
          domain={[0, 'dataMax + 5']}
          allowDecimals={false}
          axisLine={false}
          tickLine={false}
          tickCount={6}
          tickFormatter={formatYAxis}
          width={70}
        />
        <YAxis
          yAxisId='right-axis'
          orientation='right'
          type='number'
          domain={[0, 'dataMax + 10']}
          allowDecimals={false}
          axisLine={false}
          tickLine={false}
          width={40}
          tickCount={5}
          tickFormatter={formatYAxis}
        />
        <Tooltip content={renderTooltip} cursor={false} />
        <Legend
          wrapperStyle={{ position: 'relative', left: 10, width: 'auto' }}
          content={renderLegend}
        />
        <Area
          connectNulls // doesnt work
          name='Volume in m³'
          type='monotone'
          dataKey='storageVolume'
          fill='#eaeaea'
          stroke='#eaeaea'
          yAxisId='right-axis'
          activeDot={false}
        />
        <Bar
          name='Warehouse orders'
          dataKey='orders'
          barSize={6}
          fill='#da4453'
          yAxisId='left-axis'
        />
        <Bar
          name='Products'
          dataKey='products'
          barSize={6}
          fill='#f0b4ba'
          yAxisId='left-axis'
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

TransportsChart.propTypes = {
  data: PropTypes.array,
};

export default TransportsChart;
