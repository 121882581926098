import API from './API';

class WordpressAPI extends API {
  login(username, password) {
    const data = { username, password };
    return this.api.post('/jwt-auth/v1/token', data);
  }

  getUser(userId) {
    return this.api.get(`/beleco/v1/user/${userId}`);
  }

  getProductsMeta(ids = []) {
    if (!ids.length) return {};
    const params = { ids: ids.join() };
    return this.api.get('/beleco/v1/custom/products', { params });
  }

  getProductsList({ search = null, offset = 0, limit = 10 }) {
    const params = { search, offset, limit };
    return this.api.get('/beleco/v1/products', { params });
  }

  getOwnerData(ownerId) {
    if (!ownerId) return null;
    return this.api.get(`/beleco/v1/user/${ownerId}`);
  }

  getAllAssetProviders() {
    const path = '/beleco/v1/asset_providers';
    return this.api.get(path);
  }

  getProductsForAssetProvider(ownerId) {
    const path = '/beleco/v1/asset_provider/products';
    const params = { owner_id: ownerId };
    return this.api.get(path, { params });
  }

  getProductsByOrganisationId(organisationId, params) {
    return this.api.get(`/beleco/v1/organisations/${organisationId}/products`, {
      params,
    });
  }

  validateBatchOrders(file) {
    return this.api.post('/beleco/v1/orders/batch/validate', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  createBatchOrderWithUploadedFile(file) {
    return this.api.post('/beleco/v1/orders/batch', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  createImageWithAuth(images) {
    return this.api.post('/beleco/v1/images', images);
  }
}

export default WordpressAPI;
