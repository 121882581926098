import { useCallback, useContext, useState, useEffect, useRef } from 'react';
import { Hidden } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import ProductsDeskTop from './ProductsDesktop';
import LocationContext from '../../context/Location/LocationContext';
import ProductsMobile from './ProductsMobile';
import useNotificator from '../../utils/useNotificator';
import belecoApi from '../../api';

const Products = () => {
  const history = useHistory();
  const location = useLocation();
  const { notifyError } = useNotificator();
  const { t } = useTranslation();
  const { userLocation } = useContext(LocationContext);
  const notInitialRender = useRef(false);

  const initUrlParams = new URLSearchParams(history.location.search);
  const initFilteringOptions = {
    search: initUrlParams.get('search') ? initUrlParams.get('search') : '',
    offset: initUrlParams.get('offset') ? initUrlParams.get('offset') : 0,
  };

  const pageSize = 15;
  const [offset, setOffset] = useState(initFilteringOptions.offset);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(initFilteringOptions.search);

  const updateUrlFilteringParams = () => {
    const urlSearchParams = {};

    if (searchValue) Object.assign(urlSearchParams, { search: searchValue });
    if (offset > 0) Object.assign(urlSearchParams, { offset });
    if (userLocation?.id)
      Object.assign(urlSearchParams, { locationId: userLocation.id });

    const urlParams = new URLSearchParams(urlSearchParams);

    history.push(`${location.pathname}?${urlParams.toString()}`);
  };

  const getProductsData = async () => {
    try {
      setLoading(true);
      const params = {
        limit: pageSize,
        offset,
        search: searchValue,
      };
      const data = await belecoApi.wp.getProductsList(params);

      const { rows, count } = data;
      setProducts(rows);
      setTotal(count);
      setLoading(false);
      setPage(offset / pageSize);
      updateUrlFilteringParams();
    } catch (err) {
      notifyError(err, t('Failed to load products'));
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductsData();
  }, [offset]);

  useEffect(() => {
    // Skip first render
    if (notInitialRender.current) {
      if (offset === 0) {
        getProductsData();
      } else {
        setOffset(0);
      }
    } else {
      notInitialRender.current = true;
    }
  }, [searchValue]);

  const resetSearch = () => {
    setSearchValue('');
  };

  const handleSearch = (name, debouncedValue) => {
    setSearchValue(debouncedValue);
  };

  const handleChangePage = useCallback((selectedPage) => {
    setOffset(selectedPage * pageSize);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('Products')}</title>
      </Helmet>
      <Hidden mdDown>
        <ProductsDeskTop
          onChangePage={handleChangePage}
          items={products}
          total={total}
          loading={loading}
          pageSize={pageSize}
          handleSearch={handleSearch}
          searchValue={searchValue}
          resetSearch={resetSearch}
          page={page}
        />
      </Hidden>
      <Hidden mdUp>
        <ProductsMobile
          onChangePage={handleChangePage}
          items={products}
          total={total}
          loading={loading}
          pageSize={pageSize}
          handleSearch={handleSearch}
          searchValue={searchValue}
          resetSearch={resetSearch}
          page={page}
        />
      </Hidden>
    </>
  );
};

export default Products;
