import { useState, useContext, useEffect, useRef } from 'react';
import { Typography, Box, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isNil } from 'lodash';
import useNotificator from '../../../../utils/useNotificator';
import CustomDataGrid from '../../../../components/CustomDataGrid';
import LocationContext from '../../../../context/Location/LocationContext';
import BuildingTypeIcon from '../../../../components/CustomComponents/BuildingTypeIcon';
import belecoApi from '../../../../api';

const styles = {
  blockStyleClass: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '288px',
  },
  baseTypoStyle: {
    fontSize: '12px',
  },
  link: {
    color: '#2a2929',
    display: 'flex',
    minWidth: '0px',
  },
};

const wpAdminURL = process.env.REACT_APP_WP_ADMIN_URL;

const MissingProducts = () => {
  const { t } = useTranslation();
  const { userLocation } = useContext(LocationContext);

  const [offset, setOffset] = useState(0);
  const [transports, setTransports] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [loading, setLoading] = useState(false);

  const { notifyError } = useNotificator();
  const notInitialRender = useRef(false);

  const pageSize = 5;

  const getMissingProducts = async () => {
    setLoading(true);

    try {
      const dateFrom = moment().subtract(6, 'd').format('YYYY-MM-DD'); // last 7 days
      const dateTo = moment().format('YYYY-MM-DD');

      const params = {
        offset: 0,
        limit: pageSize,
        from: dateFrom,
        to: dateTo,
        locationId: userLocation?.id || '',
        sort: order,
        orderBy: 'from',
        status: 'COMPLETED',
        isFullyDelivered: false,
        isNoShow: false,
      };

      const { rows, count } = await belecoApi.inv.getTransports(params);

      setPage(offset / pageSize);
      setTotal(count);
      setTransports(rows);
    } catch (err) {
      notifyError(err, 'Failed to load damage report');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMissingProducts();
  }, [offset]);

  useEffect(() => {
    // Skip first render
    if (notInitialRender.current) {
      if (offset === 0) {
        getMissingProducts();
      } else {
        setOffset(0);
      }
    } else {
      notInitialRender.current = true;
    }
  }, [userLocation?.id, order]);

  const onChangePage = (selectedPage) => {
    setOffset(selectedPage * pageSize);
  };

  const onSortChange = ([{ sort }]) => {
    setOrder(sort);
  };

  const initColumns = [
    {
      field: 'from',
      headerName: t('Date'),
      sortingOrder: ['desc', 'asc'],
      sortable: Boolean(transports?.length),
      minWidth: 140,
      flex: 1,
      renderCell: ({ row: { from, to } }) => (
        <Typography variant='body2' sx={styles.text}>
          {`${moment(from).format('D MMM')} (${moment(from).format(
            'HH:mm',
          )}-${moment(to).format('HH:mm')})`}
        </Typography>
      ),
    },
    {
      field: 'reference',
      headerName: t('Order'),
      sortable: false,
      minWidth: 80,
      flex: 1,
      renderCell: ({ row: { reference, orderId } }) => (
        <MuiLink
          href={`${wpAdminURL}/post.php?post=${orderId}&action=edit`}
          variant='body2'
          underline='always'
          target='_blank'
          rel='noopener noreferrer'
          style={styles.link}
        >
          <Typography
            variant='body2'
            component='span'
            noWrap
            sx={styles.baseTypoStyle}
          >
            {reference ? reference.split('_')[0] : '-'}
          </Typography>
        </MuiLink>
      ),
    },
    {
      headerName: t('Customer'),
      field: 'name',
      sortable: false,
      minWidth: 220,
      flex: 2,
      renderCell: ({ row: { name, publicId, companyName, buildingType } }) => (
        <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <BuildingTypeIcon buildingType={buildingType} />
          <MuiLink
            component={Link}
            sx={{
              ...styles.link,
              width: 'calc(100% - 30px)',
            }}
            variant='body2'
            to={`/transports/${publicId}`}
          >
            <Typography variant='body2' noWrap>
              {`${name}${isNil(companyName) ? '' : ` - ${companyName}`}`}
            </Typography>
          </MuiLink>
        </Box>
      ),
    },
  ];

  return (
    <CustomDataGrid
      rows={transports}
      columns={initColumns}
      rowCount={total}
      pageSize={pageSize}
      loading={loading}
      hideSpaceRow
      onPageChange={onChangePage}
      headerHeight={40}
      rowHeight={40}
      pagination
      paginationMode='server'
      hideFooterPagination={false}
      page={page}
      blockStyleSx={styles.blockStyleClass}
      sortingMode='server'
      onSortModelChange={onSortChange}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'from',
              sort: 'desc',
            },
          ],
        },
      }}
    />
  );
};

export default MissingProducts;
