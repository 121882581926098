import { useEffect, useState, useContext, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { RelatedInstancesListStyles } from '../../styles';
import InstanceTable from './components/InstanceTable';
import PrintLabelsDialog from './components/PrintLabelsDialog';
import MultiSelect from '../../../../components/MultiSelect';
import { WAREHOUSE_STATUS } from '../../../../utils/constants';
import LocationContext from '../../../../context/Location/LocationContext';
import { ProductDetailsContext } from '../../../../context/ProductDetails/ProductDetailsContext';

const useStyles = makeStyles(RelatedInstancesListStyles);

const RelatedInstances = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { locations, userLocation } = useContext(LocationContext);
  const [pageIndex, setPageIndex] = useState(0);

  const [locationFilterValue, setLocationFilterValue] = useState(
    userLocation?.id ? [userLocation.id] : [],
  );

  const [warehouseStatusFilterValue, setWarehouseStatusFilterValue] = useState(
    [],
  );
  const pageSize = 100;

  const {
    productId,
    productData: { existingInstances },
    relatedInstances,
    totalRelatedInstances,
    isRelatedInstancesLoading,
    loadProductInstances,
  } = useContext(ProductDetailsContext);

  const formattedStatuses = useMemo(
    () =>
      Object.values(WAREHOUSE_STATUS).map((option) => ({
        title: t(option.label),
        value: option.value,
      })),
    [i18n.language],
  );

  const formattedLocations = Object.values(locations)
    .map((item) => ({
      title: item?.title || null,
      value: item?.id || null,
    }))
    .filter((item) => item.title && item.value);

  const fetchRelatedItems = (page) => {
    const params = {
      offset: page * pageSize,
      limit: pageSize,
    };
    if (warehouseStatusFilterValue.length) {
      Object.assign(params, { warehouseStatus: warehouseStatusFilterValue });
    }
    if (locationFilterValue.length) {
      Object.assign(params, { locationId: locationFilterValue });
    }
    loadProductInstances(params);
  };

  const handlePageChange = (newPageIndex) => {
    fetchRelatedItems(newPageIndex);
    setPageIndex(newPageIndex);
  };

  useEffect(() => {
    if (productId) {
      setPageIndex(0);
      fetchRelatedItems(0);
    }
  }, [warehouseStatusFilterValue, locationFilterValue]);

  useEffect(() => {
    if (userLocation?.id) {
      setLocationFilterValue([userLocation.id]);
    } else {
      setLocationFilterValue([]);
    }
  }, [userLocation]);

  return (
    <div className={classes.container}>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Typography className={classes.tableName}>{t('Instances')}</Typography>
        <PrintLabelsDialog items={existingInstances} />
      </Box>
      <div className={classes.filtersBox}>
        <div className={classes.filerField}>
          <MultiSelect
            label={t('Warehouse Status')}
            minWidth={175}
            options={formattedStatuses}
            onChange={setWarehouseStatusFilterValue}
            value={warehouseStatusFilterValue}
          />
        </div>
        <div className={classes.filerField}>
          <MultiSelect
            label={t('Warehouse Location')}
            minWidth={175}
            options={formattedLocations}
            onChange={setLocationFilterValue}
            value={locationFilterValue}
          />
        </div>
      </div>

      {!relatedInstances.length ? (
        <Box style={{ padding: 10, margin: '10px 0px' }}>
          <Typography variant='h5' style={{ color: '#E3757F' }}>
            {t('No items to display')}
          </Typography>
        </Box>
      ) : (
        <InstanceTable
          items={relatedInstances}
          handlePageChange={handlePageChange}
          pageIndex={pageIndex}
          total={totalRelatedInstances}
          loading={isRelatedInstancesLoading}
          pageSize={pageSize}
        />
      )}
    </div>
  );
};

export default RelatedInstances;
