import { useContext, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  TRANSPORTING_STATUS_OPTIONS,
  TRANSPORTING_STATUS_OPTIONS as TSO,
} from '../../../utils/constants';
import TransportFailDeliveryDialog from '../../Transports/components/TransportFailDeliveryDialog';
import belecoApi from '../../../api';
import TransportsUpdateDialog from '../../Transports/components/TransportsUpdateDialog';
import GenericPopup from '../../../components/GenericPopup/GenericPopup';
import { TransportDetailsContext } from '../../../context/TransportDetails/TransportDetailsContext';
import { ChangelogContext } from '../../../context/Changelog/ChangelogContext';
import useNotificator from '../../../utils/useNotificator';

const useStyles = makeStyles((theme) => ({
  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 24px',
    marginTop: 32,
    marginRight: -8,

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap-reverse',
    },
  },
  controlButton: {
    margin: '4px 8px',
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 12,
  },
}));
const RenderButton = ({ title, isMobile, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.controlButton}
      variant='contained'
      size={isMobile ? 'small' : 'medium'}
      {...rest}
    >
      {title}
    </Button>
  );
};

RenderButton.propTypes = {
  title: PropTypes.string,
  isMobile: PropTypes.bool,
};

const TransportControlButtons = ({ transport = {}, isMobile = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status } = transport;
  const { publicId } = useParams();
  const { notifyError } = useNotificator();
  const { updateTransport } = useContext(TransportDetailsContext);
  const { getChangelog } = useContext(ChangelogContext);

  const [isDialogOpen, setDialogOpenState] = useState(false);
  const [setIsFailToComplete, isFailToCompleteDialogOpen] = useState(false);

  const onUpdateStatus = (newStatus, additionalData) => {
    updateTransport({
      data: { status: newStatus, isNoShow: false, ...additionalData },
    }).then(() => {
      getChangelog(() => belecoApi.inv.getTransportHistory({ id: publicId }));
    });
  };

  const handleCompleteTransport = () => {
    if (
      !transport.instances.some(
        ({ transportInstances }) => transportInstances.isCompleted,
      )
    ) {
      isFailToCompleteDialogOpen(true);
    } else {
      onUpdateStatus(TRANSPORTING_STATUS_OPTIONS.COMPLETED.value);
    }
  };

  const onFailedToDeliver = (comment) => {
    updateTransport({
      data: {
        isNoShow: true,
        status: TSO.COMPLETED.value,
        comment,
      },
    }).then(() => {
      getChangelog(() => belecoApi.inv.getTransportHistory({ id: publicId }));
    });
  };

  const renderConditionalButton = () => {
    switch (true) {
      case status === TSO.CREATED.value:
        return (
          <RenderButton
            isMobile={isMobile}
            title={t('In progress')}
            onClick={() => onUpdateStatus(TSO.PROCESSING.value)}
          />
        );
      case status === TSO.PROCESSING.value:
        return (
          <RenderButton
            isMobile={isMobile}
            title={t('Ready')}
            onClick={() => onUpdateStatus(TSO.READY.value)}
          />
        );
      case status === TSO.READY.value:
        return (
          <RenderButton
            isMobile={isMobile}
            onClick={() => onUpdateStatus(TSO.IN_TRANSIT.value)}
            title={t('In transit')}
          />
        );
      case status === TSO.IN_TRANSIT.value:
        return (
          <>
            <RenderButton
              isMobile={isMobile}
              onClick={() => setDialogOpenState(true)}
              title={t('Failed to deliver')}
              style={{
                backgroundColor: '#DA4453',
              }}
            />
            <RenderButton
              isMobile={isMobile}
              onClick={handleCompleteTransport}
              title={t('Delivered')}
            />
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (status && !Object.keys(TSO).includes(status)) {
      notifyError(null, t('Transport has incorrect status'));
    }
  }, [status]);

  return (
    <div className={classes.controlsContainer}>
      {!(status === TSO.CANCELLED.value) ? (
        <TransportsUpdateDialog
          transportRecord={transport}
          handleCompleteTransport={handleCompleteTransport}
          buttonTitle={`${t('More')}...`}
        />
      ) : null}
      <div>{renderConditionalButton()}</div>
      <GenericPopup
        setIsOpen={isFailToCompleteDialogOpen}
        isOpened={setIsFailToComplete}
        title={t('No items delivered')}
        text={t('Please select item that was delivered to complete transport')}
        action={null}
      />
      <TransportFailDeliveryDialog
        isOpen={isDialogOpen}
        setOpenState={setDialogOpenState}
        onSubmit={onFailedToDeliver}
      />
    </div>
  );
};

TransportControlButtons.propTypes = {
  transport: PropTypes.object,
  isMobile: PropTypes.bool,
};
export default TransportControlButtons;
