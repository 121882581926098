import { Popper, ClickAwayListener, Grow, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  paper: {
    minWidth: 230,
  },
});

const CustomPopper = ({ anchorRef, isOpen, handleClose, children }) => {
  const classes = useStyles();

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <div>
            {/* eslint-disable-next-line react/jsx-no-bind */}
            <ClickAwayListener onClickAway={handleClose}>
              <Paper className={classes.paper}>{children}</Paper>
            </ClickAwayListener>
          </div>
        </Grow>
      )}
    </Popper>
  );
};
CustomPopper.propTypes = {
  anchorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
};
export default CustomPopper;
