import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import LocationContext from './LocationContext';
import LOCATION_IDS from '../../utils/locationIds';
import belecoApi from '../../api';
import useNotificator from '../../utils/useNotificator';
import validateLocations from '../../utils/validateLocations';

const LOCATION_ID_FIELD_NAME = 'locationId';
const getLocationParamFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return parseInt(urlParams.get(LOCATION_ID_FIELD_NAME), 10);
};

const getLocationFromLocalStorage = () => {
  return parseInt(localStorage.getItem(LOCATION_ID_FIELD_NAME), 10);
};

const setLocationToLocalStorage = (newLocationId) => {
  localStorage.setItem(LOCATION_ID_FIELD_NAME, newLocationId);
};

const updateUrlParams = (newLocationId) => {
  const params = new URLSearchParams(window.location.search);
  params.set(LOCATION_ID_FIELD_NAME, newLocationId);
  window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
};

const LocationProvider = ({ children }) => {
  const { notifyError } = useNotificator();
  const userLocationId =
    getLocationParamFromURL() || getLocationFromLocalStorage();

  const [isLocationsListLoading, setLocationsListLoading] = useState(false);
  const [locations, setLocations] = useState({});
  const [userLocation, setUserLocation] = useState(
    userLocationId ? { id: userLocationId } : null,
  );

  const [shouldUpdateData, setUpdateState] = useState(false);

  const getLocations = async () => {
    setLocationsListLoading(true);
    try {
      const { rows } = await belecoApi.inv.getLocations({});
      const validLocations = validateLocations(rows);

      const reformatLocation = (arr) =>
        arr.reduce(
          (acc, curr) =>
            Object.assign(acc, {
              [curr.id]: curr,
            }),
          {},
        );

      const allLocationsFromServer = reformatLocation(validLocations);
      setLocations(allLocationsFromServer);

      if (userLocationId) {
        setUserLocation(allLocationsFromServer[userLocationId]);
        updateUrlParams(userLocationId);
      }

      setLocationsListLoading(false);
    } catch (error) {
      const defaultLocation = {
        [LOCATION_IDS.STHLM]: {
          state: 'Stockholm',
          id: LOCATION_IDS.STHLM,
        },
      };

      notifyError(error, 'Unable to get locations');
      setLocations(defaultLocation);
      setLocationsListLoading(false);
    }
  };

  const updateUserLocation = (newLocationId) => {
    if (newLocationId) setUserLocation(locations[newLocationId]);
    else {
      setUserLocation('');
    }
  };

  useEffect(() => {
    const userLocationIdLocal = getLocationFromLocalStorage();

    if (
      userLocation &&
      Number(userLocationIdLocal) !== Number(userLocation.id)
    ) {
      setLocationToLocalStorage(userLocation.id);
      updateUrlParams(userLocation.id);
      setUpdateState(true);
    }
    if (!userLocation) localStorage.removeItem(LOCATION_ID_FIELD_NAME);
  }, [userLocation]);

  useEffect(() => {
    getLocations();
  }, []);

  const successfulCallback = () => setUpdateState(false);

  const returnValues = useMemo(
    () => ({
      isLocationsListLoading,
      locations,
      getLocations,
      userLocation,
      updateUserLocation,
      shouldUpdateData,
      successfulCallback,
    }),
    [isLocationsListLoading, locations, userLocation, shouldUpdateData],
  );

  return (
    <LocationContext.Provider value={returnValues}>
      {children}
    </LocationContext.Provider>
  );
};

LocationProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default LocationProvider;
