import { useContext, useEffect, useState } from 'react';
import {
  Select,
  Typography,
  Button,
  MenuItem,
  Divider,
  Box,
  TextField,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { Edit, Cancel, ErrorOutlineSharp } from '@mui/icons-material';
import { isNil, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StorageInfoStyles from './styles';
import FormFieldRow from '../../../WarehouseDeliveryDetails/components/DeliveryDetails/components/FormFieldRow';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import LocationContext from '../../../../context/Location/LocationContext';
import { InstanceDetailsContext } from '../../../../context/InstanceDetails/InstanceDetailsContext';
import { WAREHOUSE_STATUS } from '../../../../utils/constants';

const useStyles = makeStyles(StorageInfoStyles);

const StorageInfo = () => {
  const { t } = useTranslation();
  const { isLocationsListLoading, locations } = useContext(LocationContext);
  const {
    instanceData: {
      product,
      locationId,
      warehouseStatus,
      updatedAt,
      aisle,
      shelf,
      warehouseOrders = [],
    },
    isInstanceLoading,
    bookings: { rows: instanceBookings },
    updateInstance,
  } = useContext(InstanceDetailsContext);

  const [storage, setStorage] = useState('');
  const [isEditingPlacement, setIsEditingPlacement] = useState(false);
  const [instancePlacement, setInstancePlacement] = useState({});
  const [hasUniquePlacement, setHasUniquePlacement] = useState(false);
  const [showFutureBookingsWarning, setShowFutureBookingsWarning] =
    useState(false);

  const classes = useStyles();

  // eslint-disable-next-line no-shadow
  const resetPlacementFromParent = (locationId) => {
    let productPlacement = { aisle: '', shelf: '' };
    if (
      !isEmpty(product.warehousePlacements) &&
      product.warehousePlacements.find((item) => item.locationId === locationId)
    ) {
      productPlacement = product.warehousePlacements.find(
        (item) => item.locationId === locationId,
      );
    }
    setInstancePlacement({
      aisle: productPlacement.aisle,
      shelf: productPlacement.shelf,
    });
    setIsEditingPlacement(false);
    setHasUniquePlacement(false);
  };

  useEffect(() => {
    if (instanceBookings) {
      // Show warning message if instance has bookings in future
      setShowFutureBookingsWarning(
        !isEmpty(
          instanceBookings.filter((el) => moment(el.deliveryDate).isAfter()),
        ),
      );
    }
  }, [instanceBookings]);

  useEffect(() => {
    if (!isEmpty(locations) && locationId) {
      setStorage(locations[locationId]?.id);
    }
  }, [locations, locationId]);

  useEffect(() => {
    if (aisle || shelf) {
      setInstancePlacement({
        aisle,
        shelf,
      });
      setHasUniquePlacement(true);
    } else if (product && product.warehousePlacements.length) {
      resetPlacementFromParent(locationId);
      setHasUniquePlacement(false);
    }
  }, [aisle, shelf, locationId]);
  const onUpdateStorageData = () => {
    // Remove unique placements if product level placement was selected
    let params = {
      locationId: storage,
      aisle: null,
      shelf: null,
    };

    let productPlacement = { aisle: '', shelf: '' };
    const foundItem = product.warehousePlacements.find(
      (item) => item.locationId === storage,
    );
    if (foundItem) {
      productPlacement = foundItem;
    }
    if (
      productPlacement.aisle !== instancePlacement.aisle ||
      productPlacement.shelf !== instancePlacement.shelf
    ) {
      // Set unique placement for this instance if it was manually updated.
      params = {
        locationId: storage,
        aisle: instancePlacement.aisle,
        shelf: instancePlacement.shelf,
      };
    }

    updateInstance({
      data: params,
    });
    setIsEditingPlacement(false);
  };

  const handleStorageSelect = (e) => {
    setStorage(e.target.value);
    resetPlacementFromParent(e.target.value);
  };

  const handleEdit = (e) => {
    e.persist();
    setInstancePlacement((prev) => ({
      ...prev,
      [e.target.name]: isEmpty(e.target.value) ? null : e.target.value,
    }));
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const Dimensions = () => {
    if (product) {
      return (
        <Box
          style={{
            margin: '10px 0px',
          }}
        >
          <Divider />
          <FormFieldRow>
            <Typography variant='body2'>{t('Width')}:</Typography>
            <Typography variant='body2'>
              {product.width
                ? t('{{value}} cm', { value: product.width })
                : t('N/A')}
            </Typography>
          </FormFieldRow>
          <FormFieldRow>
            <Typography variant='body2'>{t('Height')}:</Typography>
            <Typography variant='body2'>
              {product.height
                ? t('{{value}} cm', { value: product.height })
                : t('N/A')}
            </Typography>
          </FormFieldRow>
          <FormFieldRow>
            <Typography variant='body2'>{t('Depth')}:</Typography>
            <Typography variant='body2'>
              {product.depth
                ? t('{{value}} cm', { value: product.depth })
                : t('N/A')}
            </Typography>
          </FormFieldRow>
        </Box>
      );
    }
    return <LoadingOverlay />;
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const DescriptionMessage = () => {
    return hasUniquePlacement ? (
      <Typography
        variant='body2'
        style={{
          fontStyle: 'italic',
          padding: 10,
          margin: '5px 0px',
          backgroundColor: '#F4ECC6',
        }}
      >
        {t(
          'This instance is located in a different locations than ordinary product.',
        )}
      </Typography>
    ) : (
      <Typography
        variant='body2'
        style={{
          fontStyle: 'italic',
          padding: '10px 0px',
          margin: '5px 0px',
        }}
      >
        {t(
          'Instance location is only used if this particular instance is stored in a unique location.',
        )}
      </Typography>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const BookingWarningMessage = () => {
    return (
      <Typography
        variant='body2'
        style={{
          color: '#DA4453',
          padding: '10px',
          border: '1px solid #DA4453',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ErrorOutlineSharp style={{ fontSize: 30, marginRight: 10 }} />
        {t(
          'Warning. This product exists in future bookings. Changing warehouse placement might affect delivery.',
        )}
      </Typography>
    );
  };
  return (
    <div className={classes.container}>
      {(isLocationsListLoading || isInstanceLoading) && <LoadingOverlay />}
      <Typography variant='body1' className={classes.header}>
        {t('Storage')}
      </Typography>
      <div className={classes.listItem}>
        <Typography variant='body2'>{t('Storage')}:</Typography>
        <Select
          name='locationId'
          variant='outlined'
          onChange={handleStorageSelect}
          disabled={isEditingPlacement}
          style={{ width: 170 }}
          value={storage}
        >
          {Object.values(locations).map((item) => (
            <MenuItem key={item.id} value={item.id} disabled={!item.isActive}>
              <Typography variant='body2'>{`${item.title} (${item.wms.title})`}</Typography>
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={classes.listItem}>
        <Typography variant='body2'>
          {hasUniquePlacement
            ? t('Instance location:')
            : t('Product location:')}
        </Typography>
        <div className={classes.placementControlsBox}>
          <TextField
            name='aisle'
            classes={{ root: classes.storageInput }}
            variant='outlined'
            value={instancePlacement.aisle}
            disabled={!isEditingPlacement}
            onChange={handleEdit}
          />
          <TextField
            name='shelf'
            classes={{ root: classes.storageInput }}
            variant='outlined'
            disabled={!isEditingPlacement}
            value={instancePlacement.shelf}
            onChange={handleEdit}
          />
          <div className={classes.buttonsColumn}>
            {isEditingPlacement ? (
              <IconButton
                size='small'
                onClick={() => {
                  setIsEditingPlacement(false);
                  if (isNil(aisle) && isNil(shelf)) {
                    // reset inherited placement from product if the unique placement is not specified
                    resetPlacementFromParent(storage);
                  } else {
                    // reset placement if the unique placement is specified
                    setInstancePlacement({ aisle, shelf });
                  }
                }}
              >
                <Cancel />
              </IconButton>
            ) : (
              <IconButton
                size='small'
                onClick={() => {
                  setIsEditingPlacement(true);
                }}
              >
                <Edit />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      {showFutureBookingsWarning && <BookingWarningMessage />}
      <DescriptionMessage />
      <div className={classes.listItem}>
        <Typography variant='body2'>{t('Status')}:</Typography>
        <Typography variant='body2' style={{ textTransform: 'uppercase' }}>
          {t(WAREHOUSE_STATUS[warehouseStatus]?.label) || t('N/A')}
        </Typography>
      </div>
      <div className={classes.listItem}>
        <Typography variant='body2'>{t('Latest sync:')}</Typography>
        <Typography variant='body2'>
          {updatedAt
            ? moment(updatedAt).format('YYYY-MM-DD HH:MM:SS')
            : t('N/A')}
        </Typography>
      </div>
      {true && (
        <div className={classes.listItem}>
          <Typography variant='body2'>
            {t('Instance was created in warehouse order')}:
          </Typography>
          <Typography variant='body2'>
            {warehouseOrders.map(({ publicId: warehouseOrderId }) => (
              <Link
                key={warehouseOrderId}
                variant='body2'
                to={`/warehouse-orders/${warehouseOrderId}`}
                style={{
                  color: 'inherit',
                  display: 'block',
                }}
              >
                {warehouseOrderId.split('-')[0]}
              </Link>
            ))}
          </Typography>
        </div>
      )}
      <Dimensions />
      <Button
        disabled={locationId === storage && !isEditingPlacement}
        className={classes.updateButton}
        onClick={onUpdateStorageData}
        variant='contained'
      >
        {t('Update')}
      </Button>
    </div>
  );
};

export default StorageInfo;
