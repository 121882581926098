import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Hidden from '@mui/material/Hidden';
import { useTranslation } from 'react-i18next';
import MultiSelect from '../../../../components/MultiSelect';
import RangeDatePicker from '../../../../components/RangeDatePicker';
import { isMobileDevice } from '../../../../utils';
import SearchField from '../../../../components/SearchField';
import { WarehouseDeliveriesPageStyles } from '../../styles';
import AddNewButton from '../../../../components/AddNewButton';
import sortAssetProvidersForSelect from '../../../../utils/sortAssetProvidersForSelect';

const useStyles = makeStyles(WarehouseDeliveriesPageStyles);

const FilterBox = ({
  loading,
  assetsProvidersList,
  ownerIfFilterValue,
  setOwnerIdFilterValue,
  formattedStatuses,
  orderStatusFilterValue,
  setOrderStatusFilterValue,
  startDateFilter,
  endDateFilter,
  handleDateFilter,
  handleSearch,
  searchValue,
  isLoadingAssesProviders,
  resetSearchValue,
  onNew,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.filteringControls}>
      {isLoadingAssesProviders ? (
        <CircularProgress
          size='36px'
          style={{ alignSelf: 'center', marginRight: 10 }}
        />
      ) : (
        <MultiSelect
          label={t('Owner')}
          minWidth={200}
          disabled={loading}
          options={sortAssetProvidersForSelect(
            Object.values(assetsProvidersList),
          )}
          onChange={setOwnerIdFilterValue}
          value={ownerIfFilterValue}
          limitTags={1}
        />
      )}

      <MultiSelect
        label={t('Order Status')}
        minWidth={130}
        disabled={loading}
        options={formattedStatuses}
        onChange={setOrderStatusFilterValue}
        value={orderStatusFilterValue}
        limitTags={1}
      />
      <RangeDatePicker
        startDate={startDateFilter}
        endDate={endDateFilter}
        onChange={handleDateFilter}
        numberOfMonths={isMobileDevice() ? 1 : 2}
      />
      <Hidden mdDown>
        <div className={classes.searchBlock}>
          <SearchField
            additionalClass={classes.searchInput}
            disabled={loading}
            onSearch={handleSearch}
            initVal={searchValue}
            resetSearchValue={resetSearchValue}
          />
        </div>
      </Hidden>
      <Hidden mdUp>
        <SearchField
          disabled={loading}
          onSearch={handleSearch}
          initVal={searchValue}
          resetSearchValue={resetSearchValue}
        />
      </Hidden>
      <Hidden lgDown>
        <AddNewButton onNew={onNew} />
      </Hidden>
    </div>
  );
};

FilterBox.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  loading: PropTypes.bool,
  assetsProvidersList: PropTypes.object,
  ownerIfFilterValue: PropTypes.array,
  setOwnerIdFilterValue: PropTypes.func,
  formattedStatuses: PropTypes.array,
  orderStatusFilterValue: PropTypes.array,
  setOrderStatusFilterValue: PropTypes.func,
  startDateFilter: PropTypes.object,
  endDateFilter: PropTypes.object,
  handleDateFilter: PropTypes.func,
  handleSearch: PropTypes.func,
  searchValue: PropTypes.string,
  isLoadingAssesProviders: PropTypes.bool,
  resetSearchValue: PropTypes.func,
  onNew: PropTypes.func,
};

export default FilterBox;
