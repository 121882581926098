import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { t } from 'i18next';
import {
  HEADER_HEIGHT,
  ORDER_STATUS,
  STATUS_COLOR_MAP,
} from '../../../utils/constants';
import GenericPopup from '../../../components/GenericPopup/GenericPopup';

const styles = {
  statusButtonStyle: {
    width: '240px',
    backgroundColor: '#FFF',
    marginBottom: '30px',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#FFF',
    },
    '&:disabled': {
      backgroundColor: '#555',
    },
    '&.cancelButton': {
      color: '#FFF',
      backgroundColor: '#DA4453',
      '&:hover': {
        backgroundColor: '#DA4453',
      },
    },
  },
  moreButton: {
    '&.MuiButton-outlined': {
      padding: '2px 15px',
      maxWidth: { xs: '300px', sm: 'unset' },
      width: { xs: '100%', sm: 'unset' },
      alignSelf: 'center',
    },
  },
  dialog: {
    '.MuiDialog-paperFullScreen': {
      display: 'flex',
      alignItems: 'center',
      background: 'rgb(0,0,0,0.6)',
    },
  },
  buttonsContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: `${HEADER_HEIGHT + 20}px`,
    paddingRight: '40px',

    '> div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
};

const WarehouseStatusUpdateDialog = ({
  currentOrderStatus,
  handleStatusUpdate,
  cancelOrder,
  handleCompleteWarehouseOrder,
}) => {
  const [showStatusChange, setShowStatusChange] = useState(false);
  const [openDeletionDialog, setOpenDeletionDialog] = useState(false);

  const returnBtn = (value, label, color) => {
    return (
      <Button
        disabled={currentOrderStatus === value}
        key={value}
        variant='contained'
        sx={styles.statusButtonStyle}
        onClick={() => handleStatusUpdate(value)}
        style={{ backgroundColor: color }}
      >
        <Typography sx={{ fontSize: '14px', color: '#000' }} variant='body2'>
          {t(label)}
        </Typography>
      </Button>
    );
  };

  return (
    <>
      <Button
        variant='outlined'
        sx={styles.moreButton}
        onClick={() => {
          setShowStatusChange(true);
        }}
      >
        {t('More...')}
      </Button>
      <Dialog
        sx={styles.dialog}
        open={showStatusChange}
        onClick={(event) => {
          event.stopPropagation();
          setShowStatusChange(false);
        }}
        fullScreen
      >
        <Box sx={styles.buttonsContainer}>
          <Box>
            {currentOrderStatus !== ORDER_STATUS.COMPLETED.value && (
              <Button
                disabled={currentOrderStatus === ORDER_STATUS.CANCELLED.value}
                variant='contained'
                className='cancelButton'
                sx={styles.statusButtonStyle}
                onClick={() => {
                  setOpenDeletionDialog(true);
                }}
              >
                <Typography sx={{ fontSize: '16px' }} variant='body2'>
                  {t(ORDER_STATUS.CANCELLED.label)}
                </Typography>
              </Button>
            )}
          </Box>
          <Box>
            {Object.values(ORDER_STATUS).map(({ value, label }) => {
              const color = STATUS_COLOR_MAP[value] || '#ececec';
              return value !== ORDER_STATUS.CANCELLED.value &&
                value !== ORDER_STATUS.COMPLETED.value
                ? returnBtn(value, label, color)
                : null;
            })}
            <Button
              variant='contained'
              sx={styles.statusButtonStyle}
              onClick={handleCompleteWarehouseOrder}
              disabled={currentOrderStatus === ORDER_STATUS.COMPLETED.value}
              style={{
                backgroundColor: STATUS_COLOR_MAP.COMPLETED,
              }}
            >
              <Typography
                sx={{ fontSize: '14px', color: '#000' }}
                variant='body2'
              >
                {t(ORDER_STATUS.COMPLETED.label)}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Dialog>
      <GenericPopup
        setIsOpen={setOpenDeletionDialog}
        isOpened={openDeletionDialog}
        title={t('Confirm order cancellation')}
        text={t('Are you sure you want to delete this order?')}
        action={cancelOrder}
        actionText={t('Yes, cancel order')}
      />
    </>
  );
};

WarehouseStatusUpdateDialog.propTypes = {
  currentOrderStatus: PropTypes.string.isRequired,
  handleStatusUpdate: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  handleCompleteWarehouseOrder: PropTypes.func.isRequired,
};

export default WarehouseStatusUpdateDialog;
