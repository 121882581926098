import { useRef, useState } from 'react';
import {
  MenuList,
  MenuItem,
  Typography,
  useMediaQuery,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTranslation } from 'react-i18next';
import { logout } from '../../store/reducers/auth';
import CustomPopper from '../CustomPopper';
import CustomListItem from '../CustomListItem';
import ROLES, { hasRole, ROLES_TITLES } from '../../utils/roles';
import ButtonSelector from '../ButtonSelector';

const styles = {
  container: {
    height: '100%',
    minWidth: { xs: '85px', md: '230px' },
    borderLeft: '1px solid #DDDDDD',
  },
  menuRoot: {
    padding: '0px',
  },
  exitIcon: {
    width: '25px',
    height: '25px',
    padding: '3px',
    color: '#555555',
  },
  logOutText: {
    fontWeight: 500,
    fontFamily: 'Helvetica Neue, sans-serif',
    color: '#555555',
  },
};

const UserMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const [isOpen, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const menuHandler = () => {
    setOpen(!isOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAction = () => {
    dispatch(logout());
    handleClose();
  };

  const returnUserRole = (userRoles = []) => {
    if (hasRole(userRoles, ROLES.ADMIN)) {
      return t(ROLES_TITLES.ADMIN);
    }
    if (hasRole(userRoles, ROLES.WO_WORKER)) {
      return t(ROLES_TITLES.WO_WORKER);
    }
    return t(ROLES_TITLES.TRANSPORT_CARRIER);
  };

  return (
    <Box sx={styles.container}>
      <ButtonSelector menuHandler={menuHandler} anchorRef={anchorRef}>
        <CustomListItem
          primary={userData.displayname}
          secondary={returnUserRole(userData.roles)}
          shouldHideText={isMobile}
          isMenuitem={false}
        />
      </ButtonSelector>

      <CustomPopper
        anchorRef={anchorRef}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <div style={{ paddingTop: 6 }}>
          <CustomListItem
            primary={userData.displayname}
            secondary={returnUserRole(userData.roles)}
            hasDivider
          />
          <MenuList id='menu-list-grow' sx={styles.menuRoot}>
            <MenuItem onClick={handleAction}>
              <ExitToAppIcon sx={styles.exitIcon} />
              <Typography variant='body1' sx={styles.logOutText}>
                {t('Logout')}
              </Typography>
            </MenuItem>
          </MenuList>
        </div>
      </CustomPopper>
    </Box>
  );
};

export default UserMenu;
