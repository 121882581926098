import { Typography, Paper, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import WarehouseChartBlock from './WarehouseChartBlock';
import DelayedDeliveriesTable from './DelayedDeliveriesTable';

const styles = {
  warehouseBlock: {
    marginTop: '20px',
  },
  title: (theme) => ({
    fontSize: '25px',
    color: '#555555',
    lineHeight: '92px',

    [theme.breakpoints.down('md')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
      fontSize: '16px',
      textTransform: 'uppercase',
      letterSpacing: '0.9px',
      fontWeight: 200,
    },
  }),
  transportsGrid: (theme) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '28px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gap: '16px',
    },
  }),
  gridElem: (theme) => ({
    width: '100%',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '12px 24px',

    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
    },
  }),
  gridElemTitleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '5px',
  },
  gridElemTitle: {
    fontSize: '18px',
    lineHeight: '38px',
    color: 'black',
    fontWeight: 500,
  },
  btnLabel: {
    textTransform: 'none',
    color: '#da4453',
  },
  periodText: {
    paddingBottom: '4px',
    fontSize: '10px',
    lineHeight: '10px',
    color: 'gray',
  },
};

const WarehouseBlock = () => {
  return (
    <Box sx={styles.warehouseBlock}>
      <Typography sx={styles.title}>Warehouse deliveries</Typography>
      <Box sx={styles.transportsGrid}>
        <Paper sx={styles.gridElem}>
          <Box sx={styles.gridElemTitleBlock}>
            <Typography sx={styles.gridElemTitle}>
              Warehouse deliveries
            </Typography>
            <Link
              variant='body2'
              style={{
                textDecoration: 'none',
                color: '#da4453',
              }}
              to='/warehouse-orders'
            >
              View all
            </Link>
          </Box>
          <Typography sx={[styles.periodText, { paddingBottom: '20px' }]}>
            Last 20 days
          </Typography>
          <WarehouseChartBlock />
        </Paper>
        <Paper sx={styles.gridElem}>
          <Box sx={styles.gridElemTitleBlock}>
            <Typography sx={[styles.gridElemTitle, { marginBottom: '14px' }]}>
              Delayed deliveries
            </Typography>
          </Box>
          <DelayedDeliveriesTable />
        </Paper>
      </Box>
    </Box>
  );
};
export default WarehouseBlock;
