import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import GradientLoadingOverlay from '../../../components/GradientLoadingOverlay';
import TransportInstanceExpandedTable from '../../../components/InstanceExpandedTable/TransportInstanceExpandedTable';
import PrintLabelsDialog from '../../ProductDetails/components/RelatedInstacesList/components/PrintLabelsDialog';
import { TRANSPORTING_STATUS_OPTIONS } from '../../../utils/constants';
import { TransportDetailsContext } from '../../../context/TransportDetails/TransportDetailsContext';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    flex: 1,
    background: '#ffffff',
    border: '1px solid #cccccc',
    borderRadius: 4,
    padding: 24,

    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
      borderRadius: 0,
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
  printingToolWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,

    [theme.breakpoints.down('md')]: {
      width: '100%',
      backgroundColor: '#FFF',
      padding: '10px',
    },
  },
}));

const InstancesList = ({ instances = [], location }) => {
  const classes = useStyles();
  const { getTransportProductsMeta, products, transport, isProductsLoading } =
    useContext(TransportDetailsContext);

  const [combinedData, setCombinedData] = useState([]);

  useEffect(() => {
    if (instances.length) {
      const productIds = new Set(instances.map(({ productId }) => productId));

      getTransportProductsMeta([...productIds]);
    }
  }, [instances]);

  useEffect(() => {
    if (Object.keys(products).length !== 0) {
      const combined = Object.entries(products).map(([key, value]) => {
        const productInstances = instances.filter(
          ({ productId }) => productId === +key,
        );

        return {
          productId: key,
          ...value,
          isPacked: productInstances.length
            ? productInstances[0].transportInstances.isPacked
            : false,
          instances: productInstances,
          warehousePlacement: productInstances.length
            ? productInstances[0].product.warehousePlacements.find(
                ({ locationId }) => locationId === location.id,
              )
            : {},
        };
      });

      setCombinedData(combined);
    }
  }, [products]);

  const isEditable =
    transport.status !== TRANSPORTING_STATUS_OPTIONS.CANCELLED.value;

  return (
    <div className={classes.container} id='instances-list-container'>
      {isProductsLoading && !combinedData.length && (
        <GradientLoadingOverlay height={138} />
      )}
      <TransportInstanceExpandedTable
        data={combinedData}
        total={instances.length}
        isLoading={false}
        isEditable={isEditable}
        page='transport'
        type={transport.type}
      />
      <div className={classes.printingToolWrapper}>
        <PrintLabelsDialog items={instances} />
      </div>
    </div>
  );
};

InstancesList.propTypes = {
  instances: PropTypes.array,
  location: PropTypes.object,
};

export default InstancesList;
