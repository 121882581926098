import {
  Typography,
  Box,
  TextField,
  Button,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormFieldRow from '../../../../WarehouseDeliveryDetails/components/DeliveryDetails/components/FormFieldRow';
import LoadingOverlay from '../../../../../components/LoadingOverlay';

const useStyles = makeStyles(() => ({
  rootContainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  inputField: {
    width: 85,

    '& input': {
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: 'end',
    },
  },
  warningText: {
    color: '#FD5D5D',
    fontStyle: 'italic',
    padding: '5px',
  },
  updateButton: {
    marginLeft: 'auto',
    marginTop: 15,
  },
  formHelperText: {
    direction: 'rtl',
    whiteSpace: 'nowrap',
    marginLeft: 0,
    marginRight: 0,
  },
}));

const validationSchema = yup.object().shape({
  height: yup.number().required('Height is a required field'),
  width: yup.number().required('Width is a required field'),
  depth: yup.number().required('Depth is a required field'),
});

const DimensionTextField = ({ field, form }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const hasError = !!form.errors?.[field.name];

  return (
    <TextField
      variant='outlined'
      placeholder={t('N/A')}
      classes={{
        root: classes.inputField,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Typography variant='body2'>{t('cm')}</Typography>
          </InputAdornment>
        ),
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.formHelperText,
        },
      }}
      error={hasError}
      helperText={hasError ? t(form.errors[field.name]) : ''}
      {...field}
    />
  );
};

const DimensionsBox = ({
  values = { height: '', width: '', depth: '' },
  handleUpdate,
  isLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.rootContainer}>
      {isLoading && <LoadingOverlay />}
      <Formik
        initialValues={values}
        enableReinitialize
        onSubmit={handleUpdate}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, dirty: isDirty, isValid, errors }) => (
          <form onSubmit={handleSubmit}>
            <FormFieldRow>
              <Typography variant='body2'>{t('Width')}:</Typography>
              <Field name='width' component={DimensionTextField} />
            </FormFieldRow>
            {errors.width?.type === 'typeError' && (
              <Typography variant='body2' className={classes.warningText}>
                {t('Width is required and should be a number.')}
              </Typography>
            )}

            <FormFieldRow>
              <Typography variant='body2'>{t('Height')}:</Typography>
              <Field name='height' component={DimensionTextField} />
            </FormFieldRow>
            {errors.height?.type === 'typeError' && (
              <Typography variant='body2' className={classes.warningText}>
                {t('Height is required and should be a number.')}
              </Typography>
            )}
            <FormFieldRow>
              <Typography variant='body2'>{t('Depth')}:</Typography>
              <Field name='depth' component={DimensionTextField} />
            </FormFieldRow>
            {errors.depth?.type === 'typeError' && (
              <Typography variant='body2' className={classes.warningText}>
                {t('Depth is required and should be a number.')}
              </Typography>
            )}

            <Button
              type='submit'
              variant='contained'
              disabled={!isDirty || !isValid}
              className={classes.updateButton}
            >
              {t('Update Dimensions')}
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
};

DimensionTextField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

DimensionsBox.propTypes = {
  values: PropTypes.object,
  handleUpdate: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DimensionsBox;
