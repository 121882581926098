import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import CustomDataGrid from '../../../components/CustomDataGrid';
import Thumb from '../../Instances/Thumb';
import SearchField from '../../../components/SearchField';
import MobileHeaderBox from '../../../components/CustomComponents/MobileHeaderBox';
import FiltersBlock from '../../../components/CustomComponents/FiltersBlock';
import SectionContainer from '../../../components/CustomComponents/SectionContainer';

const useStyles = makeStyles(() => ({
  tableContainer: {
    paddingTop: 15,
    borderTop: '10px solid #EBEBEB',
  },
  dataGridClass: {
    '& .MuiDataGrid-row': {
      boxShadow: 'inset 0 0 0 1px #cccccc',
      width: '100%',

      '& .MuiDataGrid-cell': {
        border: 'none',
      },
    },
  },
}));

const ProductsMobile = ({
  onChangePage,
  items,
  total,
  loading,
  pageSize,
  handleSearch,
  searchValue,
  resetSearch,
  page,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);

  const initColumns = useMemo(
    () => [
      {
        headerName: t('Product'),
        field: 'coverImage.attachmentUrl',
        minWidth: 210,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          const { coverImage, title } = row;
          const data = {
            image: coverImage.attachmentUrl,
            thumbnail_image: '',
            title,
          };
          return <Thumb data={data} />;
        },
      },
      {
        headerName: t('Product ID'),
        field: 'post_id',
        minWidth: 90,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <NavLink to={`/products/${row.post_id}`} style={{ color: '#000' }}>
            <Typography variant='body2'>{row.post_id}</Typography>
          </NavLink>
        ),
      },
      {
        headerName: t('SKU'),
        field: 'sku',
        minWidth: 90,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <Typography variant='body2'>{row.sku || ''}</Typography>
        ),
      },
    ],
    [i18n.language],
  );

  return (
    <SectionContainer>
      <MobileHeaderBox title={t('Products')} showFilters={setShowFilters} />
      <FiltersBlock
        showFilters={showFilters}
        resetFiltersToDefaults={resetSearch}
      >
        <SearchField
          disabled={loading}
          onSearch={handleSearch}
          initVal={searchValue}
        />
      </FiltersBlock>

      <div className={classes.tableContainer}>
        <CustomDataGrid
          columns={initColumns}
          rows={items}
          rowCount={total}
          pageSize={pageSize}
          loading={loading}
          onPageChange={onChangePage}
          headerHeight={40}
          rowHeight={40}
          rowSpacing={5}
          pagination
          paginationMode='server'
          hideFooterPagination={false}
          dataGridClass={classes.dataGridClass}
          page={page}
        />
      </div>
    </SectionContainer>
  );
};

ProductsMobile.propTypes = {
  onChangePage: PropTypes.func,
  total: PropTypes.number,
  // eslint-disable-next-line react/boolean-prop-naming
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  handleSearch: PropTypes.func,
  items: PropTypes.array,
  searchValue: PropTypes.string,
  resetSearch: PropTypes.func,
  page: PropTypes.number,
};

export default ProductsMobile;
