import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

const LoadingOverlay = ({ size }) => (
  <div
    style={{
      position: 'absolute',
      backgroundColor: `rgba(255, 255, 255, 0.7)`,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      left: 0,
      zIndex: 100,
    }}
  >
    <CircularProgress size={size} />
  </div>
);

LoadingOverlay.defaultProps = {
  size: 40,
};

LoadingOverlay.propTypes = {
  size: PropTypes.number,
};

export default LoadingOverlay;
