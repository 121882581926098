import {
  createAction,
  createReducer,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { Userpilot } from 'userpilot';
import {
  currentSession,
  saveSession,
  removeSession,
} from '../../utils/session';
import belecoApi from '../../api';

export const checkSession = createAction('auth/checkSession', () => {
  const sessionData = currentSession();
  if (sessionData) {
    belecoApi.setupToken(sessionData.token);
  }
  return {
    payload: { sessionData },
  };
});

export const logout = createAction('auth/logout', () => {
  removeSession();
  return { payload: null };
});

export const login = createAsyncThunk(
  'auth/login',
  async ({ username, password }, { rejectWithValue }) => {
    try {
      return await belecoApi.wp.login(username, password).then((userData) => {
        Userpilot.identify(userData.user_nicename, {
          name: userData.user_name,
          email: userData.user_email,
        });
        return saveSession(userData.token);
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const initialState = {
  authenticated: false,
  loading: false,
  error: null,
  user: null,
  token: null,
};

const auth = createReducer(initialState, {
  [checkSession]: (state, action) => {
    const { sessionData } = action.payload;
    if (sessionData) {
      return {
        ...sessionData,
        authenticated: true,
        error: null,
        loading: false,
      };
    }

    return {
      loading: false,
      authenticated: false,
    };
  },
  [login.pending]: (state) => {
    state.loading = true;
  },
  [login.rejected]: (state, action) => {
    state.loading = false;
    state.authenticated = false;
    state.error = action.payload || action.error;
  },
  [logout]: () => initialState,
});

export default auth;
