import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import ImageLoader from '../ImageLoader';
import { volumeUnitConverter } from '../../utils';
import CompleteAllInstancesButton from '../../containers/WarehouseDeliveryDetails/components/CompleteAllInstancesButton';
import WarehouseOrderInstancesTable from '../../containers/WarehouseDeliveryDetails/components/WarehouseOrderInstancesTable';
import { WarehouseDetailsContext } from '../../containers/WarehouseDeliveryDetails/WarehouseDetailsContext';

const styles = {
  accordion: {
    '&.MuiAccordion-root': {
      border: 'none',
      boxShadow: 'none',
      '&:before': {
        opacity: '1!important',
      },
    },
  },
  accordionSummary: {
    '&.MuiAccordionSummary-root': {
      padding: '12px 0',

      '.MuiAccordionSummary-expandIconWrapper': {
        position: 'absolute',
        left: 0,
      },
    },
  },
  instancesTableHeaderContainer: {
    alignItems: 'center',

    '.MuiGrid-item, .MuiGrid-item p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  instancesTableTitle: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '24px',
  },
  accordionDetails: {
    '&.MuiAccordionDetails-root': {
      padding: 0,
      transition: 'all .25s linear',

      '.MuiDataGrid-columnHeaders': {
        color: 'rgb(85, 85, 85)',

        '.MuiDataGrid-columnHeader:first-of-type': {
          paddingLeft: '22px',
        },
      },
    },

    '.MuiDataGrid-row:nth-of-type(2n+1)': {
      backgroundColor: '#F8F9FA',
      '&:hover': { backgroundColor: '#F8F9FA!important' },
    },
  },
  hideOnMobileBlock: (theme) => ({
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  }),
};

const InstanceExpandedTable = ({ product, isAllInstancesCompleted }) => {
  const {
    instances,
    productId,
    thumbnail_image: thumbnailImage,
    title,
    sku,
  } = product;

  const { warehouseOrderData } = useContext(WarehouseDetailsContext);

  const allInstancesOfOneProductCompleted = instances.every(
    (instance) => instance.warehouseOrderInstances.isCompleted,
  );

  const [productToComplete, setProductToComplete] = useState(null);

  return (
    <Accordion
      square
      TransitionComponent={Collapse}
      disableGutters
      defaultExpanded
      sx={styles.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={styles.accordionSummary}
      >
        <Grid container columnGap={1} sx={styles.instancesTableHeaderContainer}>
          <Grid
            item
            xs
            sm={3.9}
            md={3.5}
            lg={4}
            sx={styles.instancesTableTitle}
          >
            <ImageLoader
              src={thumbnailImage}
              alt='instance'
              sx={{ width: '50px', marginRight: '10px' }}
            />
            <Typography variant='body2' sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Grid>
          <Grid item sm={2} sx={styles.hideOnMobileBlock} textAlign='center'>
            <Link
              component={RouterLink}
              variant='body2'
              to={`/products/${productId}`}
              target='_blank'
              sx={{ textDecoration: 'none' }}
            >
              {sku}
            </Link>
          </Grid>
          <Grid item sm={2} sx={styles.hideOnMobileBlock} textAlign='center'>
            <Typography variant='body2'>
              {`${volumeUnitConverter(
                instances.reduce((acc, item) => acc + item.volume, 0),
              )} m`}
              <sup>3</sup>
            </Typography>
          </Grid>
          <Grid
            item
            sm={isAllInstancesCompleted ? true : 1}
            sx={styles.hideOnMobileBlock}
            textAlign='right'
          >
            <Typography variant='body2'>{instances.length}</Typography>
          </Grid>
          {!allInstancesOfOneProductCompleted &&
            warehouseOrderData.orderStatus === 'PROCESSING' && (
              <Grid item xs textAlign='right'>
                <CompleteAllInstancesButton
                  productId={productId}
                  productToComplete={productToComplete}
                  setProductToComplete={setProductToComplete}
                />
              </Grid>
            )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>
        <WarehouseOrderInstancesTable
          instances={instances}
          productToComplete={productToComplete}
        />
      </AccordionDetails>
    </Accordion>
  );
};

InstanceExpandedTable.propTypes = {
  product: PropTypes.shape({
    instances: PropTypes.array.isRequired,
    productId: PropTypes.number.isRequired,
    thumbnail_image: PropTypes.string,
    title: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
  }),
  isAllInstancesCompleted: PropTypes.bool.isRequired,
};

export default InstanceExpandedTable;
