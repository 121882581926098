import { Box, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { isEmpty } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import decode from '../../../../utils/htmlDecode';
import { AssetProviderProductListStyles } from '../../styles';
import { WarehouseDetailsContext } from '../../WarehouseDetailsContext';

const useStyles = makeStyles(AssetProviderProductListStyles);

const AlertMessageBox = ({ text }) => {
  return (
    <Box style={{ padding: 10, margin: '10px 0px' }}>
      <Typography variant='h5' style={{ color: '#E3757F' }}>
        {text}
      </Typography>
    </Box>
  );
};

AlertMessageBox.propTypes = {
  text: PropTypes.string,
};

const SelectProductsToOrder = ({ addProductToOrder }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const {
    productListToOrder: productList,
    isLoadingProductList: loadingProductList,
    ownerIdForNewWO: ownerId,
  } = useContext(WarehouseDetailsContext);

  const showAlertNotification =
    loadingProductList || isEmpty(productList) || !ownerId;

  const [searchValue, setSearchValue] = useState('');

  const productsFromSearch = useMemo(() => {
    if (productList && ownerId) {
      return productList.filter(
        ({ id, title }) =>
          id.includes(String(searchValue).toUpperCase()) ||
          title.toLowerCase().includes(String(searchValue).toLowerCase()),
      );
    }
    return [];
  }, [searchValue, productList]);

  const handleFiltering = (event) => {
    setSearchValue(event.target.value);
  };

  const ErrorMessage = useMemo(() => {
    if (loadingProductList) {
      return (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: 50,
            alignItems: 'center',
          }}
        >
          {' '}
          <CircularProgress size={30} />{' '}
        </Box>
      );
    }

    if (!ownerId) {
      return (
        <AlertMessageBox
          text={t('Please select asset provider to be able to add products')}
        />
      );
    }

    if (isEmpty(productList)) {
      return (
        <AlertMessageBox
          text={t('Selected asset provider has no available products to order')}
        />
      );
    }
    return null;
  }, [productList, ownerId, loadingProductList, i18n.language]);

  return (
    <div>
      <div>
        <Typography className={classes.listHeader} variant='body2'>
          {t('Select products')}
        </Typography>
        <Divider style={{ height: 2 }} />
      </div>
      {showAlertNotification ? (
        ErrorMessage
      ) : (
        <div className={classes.listContainer}>
          <div className={classes.searchBox}>
            <TextField
              type='text'
              placeholder={`${t('Search by SKU')}...`}
              variant='outlined'
              onChange={handleFiltering}
              className={classes.filteringField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon fontSize='small' />
                  </InputAdornment>
                ),
              }}
              style={{
                width: '100%',
                transformText: 'uppercase',
              }}
            />
          </div>
          {productsFromSearch.map(
            ({ coverImage, title, id, post_id: productId }) => (
              <div className={classes.listItem} key={`product-list-item-${id}`}>
                <div className={classes.imageColumn}>
                  <img src={coverImage.attachmentUrl || ''} alt='' />
                </div>
                <div className={classes.titleColumn}>
                  <Typography variant='body2' className={classes.productTitle}>
                    {decode(title)}
                  </Typography>
                  <Link variant='body2' to={`/products/${productId}`}>
                    {id}
                  </Link>
                </div>

                <div className={classes.actionButtonColumn}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      addProductToOrder({
                        sku: id,
                        title,
                        productId,
                        image: coverImage.attachmentUrl,
                      });
                    }}
                  >
                    <ArrowForwardIcon style={{ color: '#FFF', fontSize: 20 }} />
                  </Button>
                </div>
              </div>
            ),
          )}
        </div>
      )}
    </div>
  );
};

SelectProductsToOrder.propTypes = {
  addProductToOrder: PropTypes.func.isRequired,
};

export default SelectProductsToOrder;
