import { useContext, useEffect, useState } from 'react';
import { Paper, Button, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import qrCodeErrorImage from '../../../../assets/images/placeholder.png';
import belecoApi from '../../../../api';
import downloadImageFromBlob from '../../../../utils/downloadImageFromBlob';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { WarehouseDetailsContext } from '../../WarehouseDetailsContext';

const useStyles = makeStyles(() => ({
  cardContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: 16,
  },
  cardHeader: {
    textTransform: 'uppercase',
    borderBottom: '2px solid #E0E0E0',
    width: '100%',
    fontSize: 20,
    lineHeight: 1.5,
  },
  qrCodeLabel: {
    width: '100%',
    margin: '10px 0',
  },
  descriptionText: {
    lineHeight: '1.1em',
    margin: '15px 0px',
  },
  loadLabelButton: {
    height: 30,
    alignSelf: 'flex-end',
    '& a': {
      textDecoration: 'none',
      color: '#FFF',
      fontSize: 10,
      textTransform: 'uppercase',
    },
  },
  disabledButton: {
    '& a': {
      color: '#B2B2B2',
    },
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const ShippingLabel = ({ isNewOrder }) => {
  const { t } = useTranslation();
  const [allowDownloadLabel, setAllowDownloadLabel] = useState(false);
  const [relevantImageSrc, setRelevantImageSrc] = useState('');
  const [creatingLabel, setCreatingLabel] = useState(false);
  const { warehouseOrderData } = useContext(WarehouseDetailsContext);
  const {
    logisticsProviderQrCode: vindenQRCode,
    qrCodeUrl: belecoQRCode,
    publicId: orderId,
  } = warehouseOrderData;

  const classes = useStyles();

  const getImgSrc = () => {
    if (belecoQRCode) {
      setAllowDownloadLabel(true);
      return belecoQRCode;
    }
    if (vindenQRCode) {
      setAllowDownloadLabel(true);
      return `data:image/png;base64, ${vindenQRCode}`;
    }
    setAllowDownloadLabel(false);
    return null;
  };

  const handleDownloadLabel = () => {
    // Disable fetching QRCode label from Beleco Storage when WO managed by vinden.
    if (vindenQRCode || isNewOrder) return;
    setAllowDownloadLabel(true);
    belecoApi.inv
      .getWarehouseDeliveryLabel(orderId)
      .then((blob) => {
        downloadImageFromBlob(blob, 'WO-label.pdf');
      })
      .catch((e) => console.log(e));
  };

  const generateQRCodeLabel = () => {
    setCreatingLabel(true);
    belecoApi.inv
      .createWarehouseDeliveryLabel(orderId)
      .then(({ qrCodeUrl }) => {
        setRelevantImageSrc(qrCodeUrl);
        setAllowDownloadLabel(true);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setCreatingLabel(false);
      });
  };

  useEffect(() => {
    setRelevantImageSrc(getImgSrc());
  }, []);
  return (
    <Paper className={classes.cardContainer}>
      <Typography className={classes.cardHeader}>
        {t('Transport label')}
      </Typography>
      {creatingLabel && <LoadingOverlay />}
      <img
        src={relevantImageSrc || qrCodeErrorImage}
        alt=''
        className={classes.qrCodeLabel}
      />
      <Typography variant='body2' className={classes.descriptionText}>
        {t(
          'Please put identification badge on a location where it is not likely to be touched by user.',
        )}
      </Typography>

      <Box className={classes.buttonsBox}>
        <Button
          disabled={isNewOrder || !isNil(relevantImageSrc)}
          variant='contained'
          onClick={generateQRCodeLabel}
          classes={{
            root: classes.loadLabelButton,
            disabled: classes.disabledButton,
          }}
        >
          {t('Create new')}
        </Button>
        <Button
          disabled={isNewOrder || !allowDownloadLabel}
          variant='contained'
          onClick={handleDownloadLabel}
          classes={{
            root: classes.loadLabelButton,
            disabled: classes.disabledButton,
          }}
        >
          {!isNil(vindenQRCode) ? (
            <a href={vindenQRCode} download>
              {t('Print')}
            </a>
          ) : (
            t('Print')
          )}
        </Button>
      </Box>
    </Paper>
  );
};

ShippingLabel.propTypes = {
  isNewOrder: PropTypes.bool,
};

export default ShippingLabel;
