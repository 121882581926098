import { useSnackbar } from 'notistack';

const useNotificator = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notifyError = (
    error,
    customMessage = 'Error!',
    useCustomMessage = false,
    options = null,
  ) => {
    const message = useCustomMessage
      ? customMessage
      : error?.response?.data?.message || error?.message || customMessage;

    if (navigator.onLine) {
      enqueueSnackbar(message, {
        variant: 'error',
        ...options,
      });
    } else {
      enqueueSnackbar('Error, lost internet connection', {
        variant: 'error',
        ...options,
      });
    }
  };

  const notifySuccess = (customMessage = 'Success!', options = null) => {
    enqueueSnackbar(customMessage, {
      variant: 'success',
      ...options,
    });
  };

  const notifyWarning = (customMessage = 'Warning!', options = null) => {
    enqueueSnackbar(customMessage, {
      variant: 'warning',
      ...options,
    });
  };

  const notify = (customMessage, options) => {
    enqueueSnackbar(customMessage, {
      ...options,
    });
  };

  return {
    notifyError,
    notifySuccess,
    notifyWarning,
    notify,
  };
};

export default useNotificator;
