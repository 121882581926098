import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Info as InfoIcon } from '@mui/icons-material';
import { TRANSPORTS_TOOLTIPS } from '../../../utils/constants';
import CustomTooltip from '../../../components/CustomComponents/CustomTooltip';

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    marginLeft: 4,
    color: ({ isPhoneBoothInTransport }) =>
      isPhoneBoothInTransport ? '#DA4453' : '#E6CF5F',
    fontSize: ({ iconSize }) => iconSize || 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
}));

const TransportsInfoIcon = ({
  iconSize,
  iconStyle,
  isPhoneBoothInTransport,
}) => {
  const classes = useStyles({ iconSize, isPhoneBoothInTransport });
  const { t } = useTranslation();

  const title = isPhoneBoothInTransport
    ? TRANSPORTS_TOOLTIPS.PHONE_BOOTH
    : TRANSPORTS_TOOLTIPS.NEEDS_ATTENTION;

  return (
    <CustomTooltip title={t(title)}>
      <InfoIcon
        {...(iconStyle ? { style: iconStyle } : {})}
        className={classes.infoIcon}
      />
    </CustomTooltip>
  );
};

TransportsInfoIcon.propTypes = {
  iconSize: PropTypes.number,
  iconStyle: PropTypes.object,
  isPhoneBoothInTransport: PropTypes.bool,
};

export default TransportsInfoIcon;
