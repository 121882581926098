import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import useDebouncedValue from '../../utils/useDebouncedValue';

const useStyles = makeStyles((theme) => ({
  field: {
    width: '100%',
    height: 37,
    backgroundColor: '#DA4453',
    borderRadius: 5,
    minWidth: 110,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: 10,
    },
    '& input': {
      backgroundColor: '#FFF',
      paddingRight: 28,
      paddingTop: 8.5,
      paddingBottom: 8.5,
    },
    '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
      color: '#FFF',
    },
  },
  clearBtn: {
    position: 'absolute',
    right: 40,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  clearBtnLabel: { color: 'rgba(0, 0, 0, 0.54)' },
  clearBtnSmall: { padding: 4 },
}));

const SearchField = ({
  disabled,
  onSearch,
  initVal,
  additionalClass,
  resetSearchValue,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [text, setText] = useState(initVal || '');

  const debouncedText = useDebouncedValue(text, 1000);

  useEffect(() => {
    setText(initVal);
  }, [initVal]);

  useEffect(() => {
    if (initVal !== debouncedText) {
      onSearch('search', debouncedText);
    }
  }, [debouncedText]);

  const handleInputChange = (e) => {
    const val = e.target.value;
    setText(val.toUpperCase());
  };

  return (
    <TextField
      size='small'
      autoComplete='off'
      disabled={disabled}
      onChange={handleInputChange}
      className={`${classes.field} ${additionalClass}`}
      value={text}
      id='search-textfield'
      placeholder={`${t('Search')}...`}
      variant='outlined'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {initVal ? (
              <IconButton
                size='small'
                classes={{
                  root: classes.clearBtn,
                  label: classes.clearBtnLabel,
                  sizeSmall: classes.clearBtnSmall,
                }}
                onClick={resetSearchValue}
              >
                <ClearIcon fontSize='small' />
              </IconButton>
            ) : null}
            <SearchIcon fontSize='small' />
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchField.defaultProps = {
  disabled: false,
  onSearch: () => {},
  initVal: '',
  additionalClass: '',
};

SearchField.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: PropTypes.bool,
  onSearch: PropTypes.func,
  initVal: PropTypes.string,
  additionalClass: PropTypes.string,
  resetSearchValue: PropTypes.func,
};

export default SearchField;
