import { useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import LoadingOverlay from '../LoadingOverlay';
import CustomPagination from './CustomPagination';

const useStyles = makeStyles((theme) => ({
  block: {
    width: '100%',
    height: ({ height }) => height,
    maxHeight: ({ maxHeight }) => maxHeight,
    overflowY: ({ maxHeight }) => (maxHeight === 'none' ? 'visible' : 'auto'),
  },
  root: {
    ...theme.typography.body1,
    border: 'none',

    '&.MuiDataGrid-root': {
      minHeight: '100%',
    },

    '&.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
      {
        outline: 'none',
      },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within':
      {
        outline: 'none',
      },
    '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
    },
    '& .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: '#ffffff!important',
      },
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: '#ffffff!important',
    },
    '& .MuiDataGrid-columnHeaders': {
      fontWeight: 500,
      fontFamily: 'Helvetica Neue, sans-serif',
      fontSize: 12,
      color: '#DA4453',
      textTransform: 'uppercase',
      borderBottom: '1px solid #cccccc',
    },
    '& .MuiDataGrid-overlay': {
      padding: '15px 0',
    },
    '& .MuiDataGrid-footerContainer': {
      minHeight: '30px',
      border: 'none',
    },
  },
}));

const CustomLoadingOverlay = () => (
  <GridOverlay>
    <LoadingOverlay />
  </GridOverlay>
);

const NoRowsOverlay = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {t('No items found')}
    </Box>
  );
};

const CustomDataGrid = ({
  columns = [],
  rows = [],
  pagination = false,
  hideHeader = false,
  height = 'auto',
  maxHeight = '100%',
  blockStyleClass = null,
  blockStyleSx = null,
  dataGridClass = null,
  components = {},
  rowSpacing = 0,
  headerHeight = 70,
  pageSize = 50,
  ...other
}) => {
  const classes = useStyles({
    height,
    maxHeight,
  });

  const getRowSpacing = useCallback(
    () => ({
      top: rowSpacing,
      bottom: 0,
    }),
    [],
  );

  return (
    <Grid
      className={`${classes.block} ${blockStyleClass || ''}`}
      sx={blockStyleSx || {}}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        headerHeight={hideHeader ? 0 : headerHeight}
        autoHeight
        className={`${classes.root} ${dataGridClass}`}
        pageSize={pageSize}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        hideFooterPagination={!pagination}
        hideFooterSelectedRowCount
        getRowSpacing={getRowSpacing}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          Pagination: pagination ? CustomPagination : null,
          NoRowsOverlay,
          ...components,
        }}
        {...other}
      />
    </Grid>
  );
};

CustomDataGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  pagination: PropTypes.bool,
  // eslint-disable-next-line react/boolean-prop-naming
  hideHeader: PropTypes.bool,
  height: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  blockStyleClass: PropTypes.object,
  blockStyleSx: PropTypes.object,
  dataGridClass: PropTypes.string,
  components: PropTypes.object,
  rowSpacing: PropTypes.number,
  pageSize: PropTypes.number,
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
};

export default CustomDataGrid;
