import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { WarehouseDetailsContext } from '../WarehouseDetailsContext';
import CustomDataGrid from '../../../components/CustomDataGrid';
import RequiredMountingIndicator from '../../../components/InstanceExpandedTable/RequiredMountingIndicator';
import { getConditionIcon } from '../../TransportDetails/helpers';
import WarehouseOrderInstanceActions from './WarehouseOrderInstanceActions';

const getActionColumns = (status, instances, productToComplete) => {
  if (status === 'CREATED' || status === 'IN_TRANSIT') return [];

  if (status === 'PROCESSING') {
    return [
      {
        headerName: instances.every(
          (instance) => instance.warehouseOrderInstances.isCompleted,
        )
          ? t('Completed')
          : t('Actions'),
        field: 'warehouseOrderInstances.isCompleted',
        sortable: false,
        flex: 0.4,
        minWidth: 120,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ row }) => (
          <WarehouseOrderInstanceActions
            {...row}
            productToComplete={productToComplete}
          />
        ),
      },
    ];
  }

  return [
    {
      headerName: t('Completed'),
      field: 'warehouseOrderInstances.isCompleted',
      sortable: false,
      flex: 0.4,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ row }) =>
        getConditionIcon(row.warehouseOrderInstances.isCompleted),
    },
  ];
};

const WarehouseOrderInstancesTable = ({ instances, productToComplete }) => {
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { warehouseOrderData } = useContext(WarehouseDetailsContext);

  const columns = useMemo(
    () => [
      {
        headerName: i18n.t('ID'),
        field: 'publicId',
        sortable: false,
        flex: 0.7,
        renderCell: ({ row }) => (
          <Link
            component={RouterLink}
            variant='body2'
            to={`/instances/${row.publicId}`}
            target='_blank'
            sx={{ textDecoration: 'none', paddingLeft: '12px' }}
          >
            {isMobile
              ? `${row.publicId.slice(0, 3)}...${row.publicId.slice(-4)}`
              : row.publicId}
          </Link>
        ),
      },
      {
        headerName: i18n.t('Info'),
        field: 'isMountingRequired',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) =>
          row.isMountingRequired && <RequiredMountingIndicator />,
      },
      ...getActionColumns(
        warehouseOrderData.orderStatus,
        instances,
        productToComplete,
      ),
    ],
    [
      warehouseOrderData.orderStatus,
      instances,
      i18n.language,
      isMobile,
      productToComplete,
    ],
  );

  return (
    <CustomDataGrid
      columns={columns}
      rows={instances}
      pagination={false}
      hideFooterPagination
      rowHeight={37}
      headerHeight={37}
      disableSelectionOnClick
      pageSize={100}
    />
  );
};

WarehouseOrderInstancesTable.propTypes = {
  instances: PropTypes.array.isRequired,
  productToComplete: PropTypes.number,
};

export default WarehouseOrderInstancesTable;
