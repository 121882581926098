import { Typography, TextField, Chip } from '@mui/material';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  root: (props) => ({
    minWidth: props.minWidth || 110,
    [breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
    '& .MuiInputBase-root': {
      minHeight: 37,
      backgroundColor: 'white',
      '& .MuiAutocomplete-tag': {
        backgroundColor: '#DA4453',
        color: palette.common.white,
        maxWidth: 110,
        margin: '0 1px',
      },
      ' &.MuiAutocomplete-tag': {
        overFlow: 'hidden',
        textOverFlow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '& .MuiAutocomplete-input': {
        minWidth: 'unset',
      },
    },
    '& .MuiAutocomplete-option': {
      padding: 10,
      fontSize: '2em',
    },
    '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
      {
        paddingRight: 65,
      },
  }),
}));

const MultiSelect = ({
  label,
  options,
  onChange,
  value,
  limitTags = 2,
  minWidth,
  ...rest
}) => {
  const classes = useStyles({ minWidth });
  return (
    <Autocomplete
      multiple
      size='small'
      classes={{
        root: classes.root,
      }}
      getOptionLabel={(option) => option.title}
      renderOption={(props, option) => (
        <li {...props}>
          <Typography>{option.title}</Typography>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant='outlined'
          size='small'
          style={{
            fontSize: 12,
          }}
        />
      )}
      /* eslint-disable-next-line no-shadow */
      renderTags={(value, getTagProps) => {
        const numTags = value.length;

        return (
          <>
            {value.slice(0, limitTags).map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                label={option.title}
                size='small'
              />
            ))}

            {numTags > limitTags && ` +${numTags - limitTags}`}
          </>
        );
      }}
      options={options}
      onChange={(event, newValue) => {
        const val = newValue.map((el) => el.value);
        onChange(val);
      }}
      value={options.filter((item) => value.includes(item.value))}
      {...rest}
    />
  );
};

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  className: PropTypes.string,
  renderOption: PropTypes.func,
  renderGroup: PropTypes.func,
  groupBy: PropTypes.func,
  limitTags: PropTypes.number,
  minWidth: PropTypes.number,
};

export default MultiSelect;
