import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Info as InfoIcon } from '@mui/icons-material';
import { useContext } from 'react';
import RequiredMountingIndicator from '../../../components/InstanceExpandedTable/RequiredMountingIndicator';
import CustomTooltip from '../../../components/CustomComponents/CustomTooltip';
import {
  TRANSPORTS_TOOLTIPS,
  WAREHOUSE_STATUS,
} from '../../../utils/constants';
import { hasStatusIssue } from '../helpers';
import { TransportDetailsContext } from '../../../context/TransportDetails/TransportDetailsContext';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  newInstance: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 2,
    backgroundColor: '#ECECEC',
    color: '#2A2929',
    paddingLeft: 8,
    paddingRight: 8,
    height: 22,
    borderRadius: 25,
    textTransform: 'uppercase',
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 12,
  },
  infoIcon: {
    marginLeft: 2,
    color: '#E6CF5F',
    fontSize: 22,
  },
  noInfo: {
    display: 'none',

    '&:only-child': {
      display: 'block',
    },
  },
}));

const TransportInstanceInfoCell = ({
  isMountingRequired,
  warehouseStatus,
  transports,
  aisle,
  shelf,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { transport } = useContext(TransportDetailsContext);

  return (
    <div className={classes.container}>
      {isMountingRequired && <RequiredMountingIndicator />}

      {/* if instance NOT IN STORAGE (only for transport) */}
      {hasStatusIssue(transport, warehouseStatus) && (
        <CustomTooltip
          title={
            <>
              {t(TRANSPORTS_TOOLTIPS.NOT_IN_STORAGE)}
              <br />
              {t('Current status is:')}{' '}
              <span style={{ fontWeight: 500, textTransform: 'uppercase' }}>
                {t(WAREHOUSE_STATUS[warehouseStatus]?.label)}
              </span>
            </>
          }
        >
          <InfoIcon className={classes.infoIcon} />
        </CustomTooltip>
      )}

      {/* if NEW instance ( each instance has at least 1 transport) */}
      {transports && transports.length === 1 && (
        <CustomTooltip title={t(TRANSPORTS_TOOLTIPS.NEW)}>
          <div className={classes.newInstance}>{t('New')}</div>
        </CustomTooltip>
      )}

      {(aisle || shelf) && (
        <div className={classes.newInstance}>
          <Typography variant='body2'>
            {`${aisle || ''} ${shelf || ''}`}
          </Typography>
        </div>
      )}
    </div>
  );
};

TransportInstanceInfoCell.propTypes = {
  isMountingRequired: PropTypes.bool,
  warehouseStatus: PropTypes.string,
  transports: PropTypes.array,
  aisle: PropTypes.string,
  shelf: PropTypes.string,
};

export default TransportInstanceInfoCell;
