import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TRANSPORTING_STATUS_OPTIONS } from '../../../utils/constants';

const STATUS_ITEM_SIZE = 29;

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    background: '#ffffff',
    border: '1px solid #cccccc',
    marginBottom: 16,
    borderRadius: 4,
    padding: 24,

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 16,
    },
  },
  headingContainer: {
    borderBottom: '1px solid #cccccc',
    marginBottom: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 0',
    [theme.breakpoints.between('md', 'xl')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  heading: {
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 18,
    textTransform: 'uppercase',
  },
  statusItemContainer: {
    display: 'flex',
    alignItems: 'center',
    height: STATUS_ITEM_SIZE,
  },
  statusIndicator: {
    width: STATUS_ITEM_SIZE,
    height: STATUS_ITEM_SIZE,
    border: '1px solid #cccccc',
    borderRadius: '50%',
    marginRight: 16,
    flexShrink: 0,
  },
  successIndicator: {
    borderColor: '#3aab83',
    backgroundColor: '#3aab83',
  },
  failIndicator: {
    borderColor: '#da4453',
    backgroundColor: '#da4453',
  },
  statusInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    height: STATUS_ITEM_SIZE,
  },
  statusUpdatedContainer: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginTop: '10px',
    },
  },
  updatedByName: {
    marginLeft: '5px',
    fontWeight: 500,
  },
  statusTitle: {
    textTransform: 'uppercase',
    height: STATUS_ITEM_SIZE / 2,
    width: '50%',
    whiteSpace: 'nowrap',
  },
  statusSubtitle: {
    height: STATUS_ITEM_SIZE / 2,
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 10,
  },
  statusDate: {
    height: STATUS_ITEM_SIZE / 2,
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 10,
    fontStyle: 'italic',
    textAlign: 'right',
  },
  statusConnector: {
    height: STATUS_ITEM_SIZE / 2,
    width: 1,
    backgroundColor: '#cccccc',
    marginLeft: 14,
  },
  successConnector: {
    backgroundColor: '#3aab83',
  },
  failConnector: {
    backgroundColor: '#da4453',
  },
}));

const TransportDetailsStatus = ({ transport = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status, updatedBy, updatedAt, isNoShow, carrierNote } = transport;

  const statusValues = Object.keys(TRANSPORTING_STATUS_OPTIONS);

  const secondIndicatorCondition =
    statusValues.slice(-6).includes(status) || isNoShow;
  const thirdIndicatorCondition =
    statusValues.slice(-5).includes(status) || isNoShow;
  const forthIndicatorCondition =
    statusValues.slice(-4).includes(status) || isNoShow;

  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <Typography className={classes.heading}>{t('Status')}</Typography>
        <div className={classes.statusUpdatedContainer}>
          <Typography className={classes.statusSubtitle}>
            {t('Updated by')}:
            {updatedBy ? (
              <span className={classes.updatedByName}>{updatedBy}</span>
            ) : (
              '-'
            )}
          </Typography>
          <Typography className={classes.statusDate}>
            {moment(updatedAt).format('YYYY-MM-DD HH:mm')}
          </Typography>
        </div>
      </div>
      {status !== TRANSPORTING_STATUS_OPTIONS.CANCELLED.value || isNoShow ? (
        <div className={classes.statusItemContainer}>
          <div
            className={`${classes.statusIndicator} ${classes.successIndicator}`}
          />
          <div className={classes.statusInfoContainer}>
            <Typography variant='body2' className={classes.statusTitle}>
              {t(TRANSPORTING_STATUS_OPTIONS.CREATED.label)}
            </Typography>
          </div>
        </div>
      ) : (
        <div className={classes.statusItemContainer}>
          <div
            className={`${classes.statusIndicator} ${classes.failIndicator}`}
          />
          <div className={classes.statusInfoContainer}>
            <Typography variant='body2' className={classes.statusTitle}>
              {t(TRANSPORTING_STATUS_OPTIONS.CANCELLED.label)}
            </Typography>
          </div>
        </div>
      )}
      <div
        className={`${classes.statusConnector} ${
          secondIndicatorCondition ? classes.successConnector : ''
        }`}
      />
      <div className={classes.statusItemContainer}>
        <div
          className={`${classes.statusIndicator} ${
            secondIndicatorCondition ? classes.successIndicator : ''
          }`}
        />
        <div className={classes.statusInfoContainer}>
          <Typography variant='body2' className={classes.statusTitle}>
            {t(TRANSPORTING_STATUS_OPTIONS.PROCESSING.label)}
          </Typography>
        </div>
      </div>
      <div
        className={`${classes.statusConnector} ${
          thirdIndicatorCondition ? classes.successConnector : ''
        }`}
      />
      <div className={classes.statusItemContainer}>
        <div
          className={`${classes.statusIndicator} ${
            thirdIndicatorCondition ? classes.successIndicator : ''
          }`}
        />
        <div className={classes.statusInfoContainer}>
          <Typography variant='body2' className={classes.statusTitle}>
            {t(TRANSPORTING_STATUS_OPTIONS.READY.label)}
          </Typography>
        </div>
      </div>
      <div
        className={`${classes.statusConnector} ${
          forthIndicatorCondition ? classes.successConnector : ''
        }`}
      />
      <div className={classes.statusItemContainer}>
        <div
          className={`${classes.statusIndicator} ${
            forthIndicatorCondition ? classes.successIndicator : ''
          }`}
        />
        <div className={classes.statusInfoContainer}>
          <Typography variant='body2' className={classes.statusTitle}>
            {t(TRANSPORTING_STATUS_OPTIONS.IN_TRANSIT.label)}
          </Typography>
        </div>
      </div>
      <div
        className={`${classes.statusConnector} ${
          status === TRANSPORTING_STATUS_OPTIONS.COMPLETED.value
            ? classes.successConnector
            : ''
        } ${isNoShow ? classes.failConnector : ''}`}
      />
      <div className={classes.statusItemContainer}>
        <div
          className={`${classes.statusIndicator} ${
            status === TRANSPORTING_STATUS_OPTIONS.COMPLETED.value
              ? classes.successIndicator
              : ''
          } ${isNoShow ? classes.failIndicator : ''}`}
        />
        <div className={classes.statusInfoContainer}>
          {!isNoShow ? (
            <Typography variant='body2' className={classes.statusTitle}>
              {t(TRANSPORTING_STATUS_OPTIONS.COMPLETED.label)}
            </Typography>
          ) : (
            <>
              <Typography variant='body2' className={classes.statusTitle}>
                Failed to deliver
              </Typography>
              {carrierNote ? (
                <Typography className={classes.statusSubtitle}>
                  {carrierNote}
                </Typography>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

TransportDetailsStatus.propTypes = {
  transport: PropTypes.object,
};

export default TransportDetailsStatus;
