import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: 20,
    border: '1px solid #ccc',
    borderRadius: 7,
    backgroundColor: '#ffffff',
    minHeight: 'calc(100vh - 160px)',
    display: 'flex',

    '& a:visited ,& a:active, & a:link': {
      color: '#000',
    },
    [theme.breakpoints.down('md')]: {
      padding: 0,
      borderRadius: 0,
      border: 'none',
      display: 'flex',
      flexFlow: 'column',
    },
  },
}));

const SectionContainer = ({ children }) => {
  const classes = useStyles();
  return <Paper className={classes.rootContainer}>{children}</Paper>;
};

SectionContainer.propTypes = {
  children: PropTypes.node,
};

export default SectionContainer;
