import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import LinkIcon from '@mui/icons-material/Link';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
import TransportControlButtons from './TransportControlButtons';
import { mainBlockMobileStyles } from '../styles';
import BuildingTypeIcon from '../../../components/CustomComponents/BuildingTypeIcon';

const useStyles = makeStyles(mainBlockMobileStyles);

const TransportDetailsMainMobile = ({ transport = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const fromDirectionData =
    transport.type === 'DELIVERY' ? transport.location : transport;
  const toDirectionData =
    transport.type === 'DELIVERY' ? transport : transport.location;
  const wpAdminURL = process.env.REACT_APP_WP_ADMIN_URL;

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <div className={classes.headingInfoContainer}>
          <BuildingTypeIcon
            buildingType={transport.buildingType}
            additionalIconStyle={{ width: 45 }}
          />
          <div>
            <Typography className={classes.headingTitle}>
              {transport.reference}
            </Typography>
            <Typography className={classes.headingDates}>
              {`${moment(transport.from).format(
                'DD MMM YYYY HH:MM',
              )} - ${moment(transport.to).format('HH:MM')}`}
            </Typography>
            <Typography className={classes.headingDates}>
              {`Managed by ${transport.tms.title}`}
            </Typography>
          </div>
        </div>
        <div>
          <a className={classes.headingButton} href={`tel:${transport.phone}`}>
            <PhoneIcon className={classes.headingButtonIcon} />
          </a>
          <a
            className={classes.headingButton}
            href={encodeURI(
              `https://www.google.com/maps/search/?api=1&query=${transport.addressLine1},${transport.addressLine2}`,
            )}
            target='_blank'
            rel='noopener noreferrer'
          >
            <LocationOnIcon className={classes.headingButtonIcon} />
          </a>
        </div>
      </div>
      <Grid container>
        <Grid item xs={6} className={classes.directionColumn}>
          <Typography variant='body2' className={classes.directionText}>
            {t('From')}
          </Typography>

          <Typography variant='body2'>
            {fromDirectionData.companyName}
          </Typography>
          <Typography variant='body2'>{fromDirectionData.name}</Typography>
          <Typography variant='body2'>{`${fromDirectionData.addressLine1} ${
            fromDirectionData.addressLine2 ? fromDirectionData.addressLine2 : ''
          }`}</Typography>
          <Typography variant='body2'>{`${fromDirectionData.zipCode}, ${fromDirectionData.state}`}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.directionColumn}
          style={{ borderLeft: '1px solid #cccccc' }}
        >
          <Typography variant='body2' className={classes.directionText}>
            {t('To')}
          </Typography>
          <Typography variant='body2'>{toDirectionData.companyName}</Typography>
          <Typography variant='body2'>{toDirectionData.name}</Typography>
          <Typography variant='body2'>{`${toDirectionData.addressLine1} ${
            toDirectionData.addressLine2 ? toDirectionData.addressLine2 : ''
          }`}</Typography>
          <Typography variant='body2'>{`${toDirectionData.zipCode}${
            toDirectionData.state ? `, ${toDirectionData.state}` : ''
          }`}</Typography>
        </Grid>
      </Grid>
      <a
        style={{ color: '#000' }}
        href={`${wpAdminURL}/post.php?post=${transport.orderId}&action=edit`}
      >
        <Typography
          variant='body2'
          style={{
            padding: '0px 16px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {t('View details in WP admin')}
          <LinkIcon
            style={{
              marginLeft: 5,
              transform: 'rotate(-45deg)',
              fontSize: 17,
            }}
          />
        </Typography>
      </a>
      <Typography variant='body2' style={{ padding: '0 16px' }}>
        {transport.transportInstructions}
      </Typography>
      <TransportControlButtons transport={transport} isMobile />
    </div>
  );
};

TransportDetailsMainMobile.propTypes = {
  transport: PropTypes.object.isRequired,
};

export default TransportDetailsMainMobile;
