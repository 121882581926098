import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GeneralTab from '../GeneralTab';
import AvailabilityTab from '../AvailabilityTab';

const styles = {
  container: {
    position: 'relative',
    backgroundColor: '#ffffff',
    minHeight: '320px',
    padding: '20px',
    border: '1px solid #cecece',
    borderRadius: '7px',
  },
  tabs: {
    marginBottom: '20px',
  },
  tab: {
    textTransform: 'capitalize',
  },
};

const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const ProductView = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={styles.container}>
      <Tabs
        sx={styles.tabs}
        aria-label='product details tabs'
        value={value}
        onChange={handleChange}
      >
        <Tab sx={styles.tab} label={t('General')} value={0} />
        <Tab sx={styles.tab} label={t('Availability')} value={1} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <GeneralTab />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <AvailabilityTab />
      </TabPanel>
    </Box>
  );
};

export default ProductView;
