import { useContext, useState, useEffect } from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import ShortPeriodChart from './ShortPeriodChart';
import LongPeriodChart from './LongPeriodChart';
import { ProductDetailsContext } from '../../../../context/ProductDetails/ProductDetailsContext';
import LocationContext from '../../../../context/Location/LocationContext';

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    paddingBottom: '20px',
    borderBottom: '2px solid #cecece',
    '& p': {
      fontSize: '32px',
    },
  },
  noData: {
    paddingTop: '30px',
    paddingBottom: '30px',
    textAlign: 'center',
  },
};

const tooltipFormatter = (value, name) => {
  if (name === 'qtyFull') return [value, 'All'];
  if (name === 'qtyAvailable') return [value, 'Available'];
  if (name === 'qtyInvisible') return [value, 'Invisible'];
  return [value, name];
};

const formatLabel = (tickItem) => {
  return moment(tickItem).format('D MMM');
};

const AvailabilityGraph = () => {
  const { t } = useTranslation();
  const { userLocation } = useContext(LocationContext);
  const [period, setPeriod] = useState(6);
  const onPeriodChange = (e) => {
    setPeriod(e.target.value);
  };

  const {
    productData,
    isProductLoading,
    availabilityPeriods,
    isAvailabilityPeriodsLoading,
    loadAvailabilityPeriods,
  } = useContext(ProductDetailsContext);

  const shouldHide =
    (!isAvailabilityPeriodsLoading && availabilityPeriods.length === 0) ||
    (!isProductLoading && productData.is_bundle);

  const formattedData = availabilityPeriods.map((obj) => {
    const { qtyTotal = 0, qtyInvisible = 0, start, end, date } = obj;
    const qtyFull = qtyTotal + qtyInvisible;
    const formedDate =
      start === end
        ? formatLabel(date)
        : `${formatLabel(start)} - ${formatLabel(end)}`;
    return { ...obj, qtyFull, date: formedDate };
  });

  const renderChart = () => {
    if (isAvailabilityPeriodsLoading) return null;
    if (period === 1) {
      return (
        <ShortPeriodChart
          data={formattedData}
          formatLabel={formatLabel}
          tooltipFormatter={tooltipFormatter}
        />
      );
    }
    return (
      <LongPeriodChart
        data={formattedData}
        tooltipFormatter={tooltipFormatter}
      />
    );
  };

  useEffect(() => {
    const locationId = userLocation ? userLocation.id : null;
    loadAvailabilityPeriods({ period, locationId });
  }, [period, userLocation]);

  return (
    <Box>
      {isAvailabilityPeriodsLoading && <LoadingOverlay />}
      <Box sx={styles.header}>
        <Typography variant='body1'> {t('Availability')} </Typography>

        <Select
          onChange={onPeriodChange}
          variant='outlined'
          size='small'
          value={period}
          sx={styles.inputSelect}
        >
          {productData?.existingInstances?.length < 100 && (
            <MenuItem value={1}> {t('Month')} </MenuItem>
          )}
          <MenuItem value={6}> {t('Half Year')} </MenuItem>
          <MenuItem value={12}> {t('Year')} </MenuItem>
        </Select>
      </Box>

      {shouldHide ? (
        <Box sx={styles.noData}>
          <Typography>{t('No available data')}</Typography>
        </Box>
      ) : (
        renderChart()
      )}
    </Box>
  );
};

export default AvailabilityGraph;
