import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import makeStyles from '@mui/styles/makeStyles';
import { mainBlockStyles } from '../styles';
import { GOOGLE_MAPS_URI } from '../../../utils/constants';

const useStyles = makeStyles(mainBlockStyles);

const ContactBlock = ({ transport }) => {
  const classes = useStyles();

  const returnAddressLine = () => {
    const { addressLine1, addressLine2, zipCode, city } = transport;
    let addressLine = `${GOOGLE_MAPS_URI}${addressLine1}`;
    if (addressLine2) addressLine = addressLine.concat(`,${addressLine2}`);
    if (zipCode) addressLine = addressLine.concat(`,${zipCode}`);
    if (city) addressLine = addressLine.concat(`,${city}`);
    return addressLine;
  };

  return (
    <div>
      {transport.phone && (
        <a className={classes.headingButton} href={`tel:${transport.phone}`}>
          <PhoneIcon className={classes.headingButtonIcon} />
        </a>
      )}
      {transport.addressLine1 && (
        <a
          className={classes.headingButton}
          href={encodeURI(returnAddressLine())}
          target='_blank'
          rel='noopener noreferrer'
        >
          <LocationOnIcon className={classes.headingButtonIcon} />
        </a>
      )}
    </div>
  );
};
export default ContactBlock;
