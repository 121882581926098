import { useContext } from 'react';
import { Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { InstanceDetailsContext } from '../../../../context/InstanceDetails/InstanceDetailsContext';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    minHeight: 200,
    padding: 20,
    border: '1px solid #cecece',
    borderRadius: 7,
    marginBottom: 16,
  },
  header: {
    borderBottom: '2px solid #cecece',
    fontSize: 20,
    height: 28,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
  labelWarningText: {
    fontSize: 14,
    margin: '8px 0px 16px 0',
  },
  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  qrCodeContainer: {
    border: '1px solid #000000',
  },
  qrCodeImage: {
    width: '100%',
  },
});

const QRCode = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    refreshQRCode,
    getQRCodeLabelPdf,
    instanceData: { publicId, qrCodeUrl: instanceQRCodeUrl },
    isInstanceDataLoading,
  } = useContext(InstanceDetailsContext);

  const onRefreshQRClick = () => {
    if (!isInstanceDataLoading && publicId) {
      refreshQRCode(publicId);
    }
  };

  const onPrintClick = () => {
    getQRCodeLabelPdf({
      ids: [publicId],
      filename: `${publicId} label`,
    });
  };

  return (
    <div className={classes.container}>
      {isInstanceDataLoading && <LoadingOverlay />}
      <Typography variant='body1' className={classes.header}>
        {t('Identification')}
      </Typography>
      {instanceQRCodeUrl ? (
        <div className={classes.qrCodeContainer}>
          <img
            className={classes.qrCodeImage}
            src={instanceQRCodeUrl}
            alt={t('QR code')}
          />
        </div>
      ) : null}
      <Typography variant='body2' className={classes.labelWarningText}>
        {t(
          'Please put identification badge on a location where it is not likely to be touched by user.',
        )}
      </Typography>
      <div className={classes.controlsContainer}>
        <Button
          onClick={onRefreshQRClick}
          variant='contained'
          disabled={instanceQRCodeUrl}
        >
          {t('Create new')}
        </Button>
        <Button
          onClick={onPrintClick}
          variant='contained'
          disabled={!instanceQRCodeUrl}
        >
          {t('Print')}
        </Button>
      </div>
    </div>
  );
};

export default QRCode;
