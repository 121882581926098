import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, AppBar, Toolbar, TextField, MenuItem } from '@mui/material';

import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TopBarStyles from './styles';
import UserMenu from '../UserMenu';
import QRScanner from '../../containers/QRScanner';
import LocationSelector from '../LocationSelector';

const LANGUAGES = [
  { label: 'en', value: 'en' },
  { label: 'sv', value: 'sv' },
  // Hide unused languages
  // { label: 'da', value: 'da' },
  // { label: 'de', value: 'de' },
  // { label: 'fi', value: 'fi' },
  // { label: 'no', value: 'no' },
];

const showLanguageSelect =
  process.env.REACT_APP_ENV === 'dev' ||
  process.env.REACT_APP_ENV === 'localhost' ||
  process.env.REACT_APP_ENV === 'stage' ||
  process.env.REACT_APP_ENV === 'feature';

const TopBar = ({ toggleDrawer }) => {
  const { i18n } = useTranslation();
  const { push } = useHistory();

  const onQRScan = (data) => {
    const { instanceId, warehouseOrderId } = JSON.parse(data);

    if (instanceId) {
      push(`/instances/${instanceId}`);
    } else if (warehouseOrderId) {
      push(`/warehouse-orders/${warehouseOrderId}`);
    } else {
      // Throw a generic error in case data is incorrect to show 'Invalid QR Code' message and continue scanning.
      throw Error;
    }
  };

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  const [language, setLanguage] = useState(i18n.resolvedLanguage || 'en');

  const handleChange = (event) => {
    setLanguage(event.target.value);
    changeLanguageHandler(event.target.value);
  };

  return (
    <AppBar color='inherit' position='fixed' sx={TopBarStyles.appBar}>
      <Toolbar sx={TopBarStyles.toolbar}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={TopBarStyles.container}
        >
          <Button type='text' sx={TopBarStyles.logo} onClick={toggleDrawer}>
            B
          </Button>
          <LocationSelector />
          <Grid container item sx={TopBarStyles.controlsContainer}>
            <Grid item sx={TopBarStyles.qrScanBlock}>
              <QRScanner onQRScan={onQRScan} />
            </Grid>
            {showLanguageSelect && (
              <Grid item>
                <TextField
                  select
                  label='Language'
                  variant='outlined'
                  size='small'
                  sx={TopBarStyles.languageSelectorBlock}
                  value={language}
                  onChange={handleChange}
                  SelectProps={{ MenuProps: { style: { zIndex: 1500 } } }}
                >
                  {LANGUAGES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>

          <UserMenu />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
};

export default TopBar;
