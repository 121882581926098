const sortAssetProvidersForSelect = (array) => {
  return array
    .sort((a, b) => {
      return (a.meta.company_name || a.display_name).localeCompare(
        b.meta.company_name || b.display_name,
      );
    })
    .map((row) => ({
      value: row.ID,
      title: row.meta.company_name || row.display_name,
      name: row.meta.company_name || row.display_name,
    }));
};

export default sortAssetProvidersForSelect;
