import PropTypes from 'prop-types';
import { Typography, Link, Tooltip, Box } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '../LoadingOverlay';

const { REACT_APP_WP_ADMIN_URL } = process.env;

const styles = {
  ownerInfoContainer: {
    position: 'relative',
    backgroundColor: '#ffffff',
    minHeight: '200px',
    padding: '20px',
    border: '1px solid #cecece',
    borderRadius: '7px',

    '.ownerInfoItem': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '40px',

      '& .MuiTypography-root:first-of-type': {
        paddingRight: '8px',
        lineHeight: '14px',
      },
      '& .MuiTypography-root:last-of-type': {
        lineHeight: '14px',
      },
    },
  },
  ownerInfoTitle: {
    borderBottom: '2px solid #cecece',
    marginBottom: '10px',
    fontSize: '20px',
    height: '28px',
    textTransform: 'uppercase',
  },
  tooltipPopper: {
    '&.MuiTooltip-popper': {
      '& .MuiTooltip-tooltip': {
        maxWidth: { xs: '300px', sm: '500px' },
      },
    },
  },
  ownerIdLink: {
    display: 'flex',
    cursor: 'pointer',
    color: '#2a2929',
    alignItems: 'center',
    paddingRight: '0!important',
    '& p': {
      paddingRight: '0!important',
      marginRight: '8px',
    },
  },
};

const OwnerInfo = ({ loading, data: { user = {} } }) => {
  const { t } = useTranslation();
  const meta = user.meta || {};
  return (
    <Box sx={styles.ownerInfoContainer}>
      {loading && <LoadingOverlay />}

      <Typography variant='body1' sx={styles.ownerInfoTitle}>
        {t('Owner')}
      </Typography>

      <div className='ownerInfoItem'>
        <Typography variant='body2'>{t('Company')}:</Typography>
        <Typography variant='body1'>{meta.company_name}</Typography>
      </div>

      <div className='ownerInfoItem'>
        <Typography variant='body2'>{t('Phone')}:</Typography>
        <Typography variant='body1'>{meta.billing_phone}</Typography>
      </div>

      <div className='ownerInfoItem'>
        <Typography variant='body2'>{t('Email')}:</Typography>
        {user.user_email && (
          <Tooltip
            title={user.user_email}
            enterDelay={300}
            sx={styles.tooltipPopper}
          >
            <Typography variant='body1' noWrap>
              {user.user_email}
            </Typography>
          </Tooltip>
        )}
      </div>

      <div className='ownerInfoItem'>
        <Typography variant='body2'>{t('Owner ID')}:</Typography>
        {user.ID ? (
          <Link
            target='_blank'
            rel='noopener noreferrer'
            underline='none'
            href={`${REACT_APP_WP_ADMIN_URL}/user-edit.php?user_id=${user.ID}`}
            sx={styles.ownerIdLink}
          >
            <Typography variant='body1'>{user.ID}</Typography>
            <VisibilityOutlinedIcon fontSize='small' />
          </Link>
        ) : null}
      </div>
    </Box>
  );
};

OwnerInfo.defaultProps = {
  data: {
    user: {
      ID: null,
      user_email: '',
      meta: {
        company_name: '',
        billing_address_1: '',
        billing_phone: '',
      },
    },
  },
};

OwnerInfo.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    user: PropTypes.shape({
      ID: PropTypes.string,
      user_email: PropTypes.string,
      meta: PropTypes.shape({
        company_name: PropTypes.string,
        billing_address_1: PropTypes.string,
        billing_phone: PropTypes.string,
      }),
    }),
  }),
};

export default OwnerInfo;
