import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Drawer,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HEADER_HEIGHT } from '../../utils/constants';
import {
  ADMIN_MENU,
  TRANSPORT_CARRIER_MENU,
  WO_WORKER_MENU,
} from './menuLinks';
import ROLES, { hasRole } from '../../utils/roles';

const FULL_WIDTH_DRAWER = 240;
const FULL_WIDTH_DRAWER_MOBILE = 300;
const SMALL_DRAWER = 65;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: SMALL_DRAWER,
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      width: FULL_WIDTH_DRAWER_MOBILE,
    },
  },
  drawerPaper: {
    paddingTop: HEADER_HEIGHT,
    borderRight: 'none',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.dark,
  },
  fullWidthDrawer: {
    width: FULL_WIDTH_DRAWER,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      width: FULL_WIDTH_DRAWER_MOBILE,
    },
  },
  smallDrawer: {
    width: SMALL_DRAWER,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      width: 0,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
    },
  },
  nav: { marginTop: 16 },
  navLink: {
    width: FULL_WIDTH_DRAWER,
    display: 'grid',
    gridTemplateColumns: `${SMALL_DRAWER}px auto`,
    alignItems: 'center',
    textTransform: 'uppercase',
    padding: '17px 0px',
    '& img': {
      width: 35,
    },
    '&:hover span': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up('md')]: {
      '&:first-child': {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      width: FULL_WIDTH_DRAWER_MOBILE,
      padding: '24px 0px',
    },
  },
  iconContainer: {
    justifyContent: 'center',
  },
  activeNavLink: {},
  listText: {
    fontSize: 14,
    fontFamily: 'Helvetica Neue, sans-serif',
    textDecoration: 'none',
    color: 'white',
    textTransform: 'none',
  },
  backdrop: {
    backgroundColor: 'transparent',
  },
}));

const SideBar = ({ isDrawerOpened, onDrawerOpen, onDrawerClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const userData = useSelector((state) => state.auth.user);

  const handleClick = (path) => {
    onDrawerClose();
    history.push(path);
  };

  const returnMenuItems = (userRoles = []) => {
    if (hasRole(userRoles, ROLES.ADMIN)) {
      return ADMIN_MENU;
    }
    if (hasRole(userRoles, ROLES.WO_WORKER)) {
      return WO_WORKER_MENU;
    }
    return TRANSPORT_CARRIER_MENU;
  };

  const returnMenu = (userRoles = []) => {
    const MENU_ITEMS = returnMenuItems(userRoles);
    return MENU_ITEMS.map(({ title, path, icon, activeIcon }) => {
      return (
        <ListItem
          key={title}
          button
          selected={pathname.includes(path)}
          onClick={() => handleClick(path)}
          classes={{
            root: classes.navLink,
            selected: classes.activeNavLink,
          }}
        >
          <ListItemIcon className={classes.iconContainer}>
            {pathname.includes(path) ? activeIcon : icon}
          </ListItemIcon>
          <ListItemText
            primary={t(title)}
            classes={{ primary: classes.listText }}
          />
        </ListItem>
      );
    });
  };

  return (
    <Drawer
      className={classes.drawer}
      open={isMobile ? isDrawerOpened : true}
      onClose={onDrawerClose}
      variant={isMobile ? 'temporary' : 'permanent'}
      classes={{
        paper: `${classes.drawerPaper} ${
          isDrawerOpened ? classes.fullWidthDrawer : classes.smallDrawer
        }`,
      }}
      PaperProps={{
        onMouseOver: onDrawerOpen,
        onMouseLeave: onDrawerClose,
      }}
      ModalProps={{
        BackdropProps: {
          classes: {
            root: classes.backdrop,
          },
        },
      }}
    >
      <List className={classes.nav}>
        {!!userData?.roles && returnMenu(userData.roles)}
      </List>
    </Drawer>
  );
};

SideBar.propTypes = {
  isDrawerOpened: PropTypes.bool.isRequired,
  onDrawerOpen: PropTypes.func.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
};

export default SideBar;
