import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import DamageReportsTableRow from './DamageReportsTableRow';
import onProductImageError from '../../../../../utils/onProductImageError';

const useStyles = makeStyles((theme) => ({
  tableHeadCell: {
    paddingTop: 8,
    paddingBottom: 8,
    color: '#8b8b8b',
    fontSize: 14,

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  tableCell: {
    fontSize: 14,

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  cellContentContainer: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',

      '& p': {
        whiteSpace: 'nowrap',
        textAlign: 'center',
        fontSize: 11,
      },
    },
  },
  reportImagePreview: {
    width: 36,
    height: 36,
    marginRight: 28,
    objectFit: 'contain',

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: 4,
    },
  },
  noReportsCell: {
    fontSize: 14,
    borderBottom: 'none',
  },
  deleteIcon: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const DamageReportsTable = ({
  reports,
  updateDamageReport,
  users,
  isLoadingUsers,
  onDeleteIconClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Table style={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeadCell}>
            {t('Reports')}
          </TableCell>
          <TableCell className={classes.tableHeadCell}>{t('By')}</TableCell>
          <TableCell className={classes.tableHeadCell}>{t('Reason')}</TableCell>
          <TableCell className={classes.tableHeadCell}>{t('Status')}</TableCell>
          <TableCell padding='checkbox' colSpan={0} />
        </TableRow>
      </TableHead>
      {reports.length > 0 ? (
        <TableBody>
          {reports.map((row) => (
            <DamageReportsTableRow
              key={row.id}
              report={row}
              users={users}
              updateDamageReport={updateDamageReport}
              onDeleteIconClick={onDeleteIconClick}
            >
              <TableCell className={classes.tableCell}>
                <div className={classes.cellContentContainer}>
                  <img
                    src={row.imageUrls?.[0] || ''}
                    className={classes.reportImagePreview}
                    alt={t('Damage reported')}
                    onError={onProductImageError}
                  />
                  <Typography
                    variant='body2'
                    style={{ textDecoration: 'bold' }}
                  >
                    {moment(row.updatedAt).format('DD MMM YYYY')}
                  </Typography>
                </div>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {isLoadingUsers ? (
                  <CircularProgress size={14} />
                ) : (
                  users[row.createdBy]?.display_name || t('N/A')
                )}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {t(row.cause) || t('N/A')}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{
                  color: row.isCompleted ? 'inherit' : '#DA4453',
                }}
              >
                {row.isCompleted ? t('Resolved') : t('Open')}
              </TableCell>
              <TableCell padding='checkbox' colSpan={0}>
                <IconButton
                  className={classes.deleteIcon}
                  onClick={(event) => onDeleteIconClick(event, row.id)}
                  size='large'
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </DamageReportsTableRow>
          ))}
        </TableBody>
      ) : (
        <TableBody>
          <TableRow>
            <TableCell colSpan={5} className={classes.noReportsCell}>
              {t('No damages reported')}
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </Table>
  );
};

DamageReportsTable.propTypes = {
  reports: PropTypes.array,
  updateDamageReport: PropTypes.func.isRequired,
  users: PropTypes.object,
  isLoadingUsers: PropTypes.bool,
  onDeleteIconClick: PropTypes.func.isRequired,
};

export default DamageReportsTable;
