import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GradientLoadingOverlay from '../../../../components/GradientLoadingOverlay';
import DamageReportsTable from './components/DamageReportsTable';
import DeleteReportDialog from './components/DeleteReportDialog';
import belecoApi from '../../../../api';
import useNotificator from '../../../../utils/useNotificator';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#ffffff',
    minHeight: 172,
    padding: '20px 0',
    marginTop: 20,
    border: '1px solid #c5c5c5',
    borderRadius: 7,
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
  },
  title: {
    fontSize: '34px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    textTransform: 'uppercase',
    lineHeight: 1,
    marginBottom: 20,
  },
  newButton: {
    borderRadius: 4,
    height: 37,
  },
}));

const DamageReports = ({ publicId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { notifyError } = useNotificator();
  const userData = useSelector(({ auth }) => auth.user);

  const [isLoadingReports, setReportsLoadingState] = useState(true);
  const [reports, setReports] = useState([]);
  const [isLoadingUsers, setUsersLoadingState] = useState(true);
  const [users, setUsers] = useState({});
  const [isDeleteDialogOpen, setDeleteDialogOpenState] = useState(false);
  const [reportIdToDelete, setReportIdToDelete] = useState(null);

  const getReports = async (shouldExpandLatest = false) => {
    try {
      let data = await belecoApi.inv.getInstanceDamageReports({ publicId });

      if (shouldExpandLatest) {
        data = [
          ...data.slice(0, -1),
          {
            ...data.slice(-1)[0],
            isDefaultExpanded: true,
          },
        ];
      }

      setReports(data);
      setReportsLoadingState(false);
    } catch (error) {
      notifyError(error, t('Failed to get reports'));
    }
  };

  const getUsersByReports = async () => {
    setUsersLoadingState(true);

    let userIds = new Set(
      reports
        .map(({ assigneeId, createdBy, updatedBy }) => [
          assigneeId,
          createdBy,
          updatedBy,
        ])
        .flat(),
    );
    userIds = [...userIds].filter(
      (id) => !Object.keys(users).includes(id) && id !== '',
    );

    if (!Array.isArray(userIds) || userIds.length === 0) return;

    try {
      const data = await Promise.all(
        userIds.map((id) => belecoApi.wp.getUser(id)),
      );

      setUsers(
        data.reduce(
          (acc, { user }) => ({
            ...acc,
            [user.ID]: user,
          }),
          {},
        ),
      );

      setUsersLoadingState(false);
    } catch (error) {
      notifyError(error, t('Failed to fetch users'));
    }
  };

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    if (reports.length) {
      getUsersByReports();
    }
  }, [reports]);

  const createNewReport = async () => {
    try {
      await belecoApi.inv.createDamageReport({
        params: {
          instanceId: publicId,
          assigneeId: userData.id,
        },
      });

      getReports(true);
    } catch (error) {
      notifyError(error, t('Failed to create Damage Report'));
    }
  };

  const updateDamageReport = async (id, data) => {
    try {
      await belecoApi.inv.updateDamageReport({
        id,
        data,
      });

      await getReports();
    } catch (error) {
      notifyError(error, t('Failed to update damage report'));
    }
  };

  const onDeleteIconClick = (event, id) => {
    event.stopPropagation();

    setDeleteDialogOpenState(true);
    setReportIdToDelete(id);
  };

  const onDeleteDamageReport = async () => {
    try {
      await belecoApi.inv.deleteDamageReport({ id: reportIdToDelete });
      setReportIdToDelete(null);

      await getReports();
      setDeleteDialogOpenState(false);
    } catch (error) {
      notifyError(error, t('Failed to delete damage report'));
    }
  };

  if (isLoadingReports) return <GradientLoadingOverlay />;

  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <Typography className={classes.title}>{t('Damage reports')}</Typography>
        <Button
          variant='contained'
          className={classes.newButton}
          onClick={createNewReport}
        >
          {t('New')}
        </Button>
      </div>
      <DamageReportsTable
        reports={reports}
        getReports={getReports}
        updateDamageReport={updateDamageReport}
        users={users}
        isLoadingUsers={isLoadingUsers}
        onDeleteIconClick={onDeleteIconClick}
      />
      <DeleteReportDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setDeleteDialogOpenState(false)}
        onDeleteConfirm={onDeleteDamageReport}
      />
    </div>
  );
};

DamageReports.propTypes = {
  publicId: PropTypes.string.isRequired,
};

export default DamageReports;
