import { useEffect, useContext, useState } from 'react';
import { CardContent, Typography, Card, Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import StoragePlacementDetails from '../StoragePlacementDetails';
import { ProductDetailsContext } from '../../../../context/ProductDetails/ProductDetailsContext';

const styles = {
  container: {
    minHeight: '176px',
    marginBottom: '20px',
    position: 'relative',
  },
  titleBox: {
    borderBottom: '2px solid #CCC',
    display: 'grid',
    gridTemplateColumns: '35% 25% 40%',
  },
  title: {
    textTransform: 'uppercase',
  },
  availabilityTitle: ({ breakpoints }) => ({
    textAlign: 'center',
    textTransform: 'uppercase',
    [breakpoints.down(950)]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
};

const StorageCard = () => {
  const { t } = useTranslation();

  const {
    woPlacements,
    updateWoPlacements,
    isProductLoading,
    isLocationsAvailabilityLoading,
    locationsAvailability,
    isWoPlacementsLoading,
    setIsWoPlacementsLoading,
  } = useContext(ProductDetailsContext);

  const updateProductHandler = async (values) => {
    updateWoPlacements({ params: values });
  };

  const [placementDetails, setPlacementsDetails] = useState([]);

  useEffect(() => {
    setPlacementsDetails(
      woPlacements.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.locationId]: { aisle: curr.aisle, shelf: curr.shelf },
        }),
        {},
      ),
    );
  }, [woPlacements]);

  useEffect(() => {
    if (!isProductLoading) {
      setIsWoPlacementsLoading(false);
    }
  }, [isProductLoading]);

  return (
    <Card sx={styles.container}>
      <CardContent>
        <Box sx={styles.titleBox}>
          <Typography variant='body2' sx={styles.title}>
            {t('Storage')}
          </Typography>
          <Typography variant='subtitle2' sx={styles.availabilityTitle}>
            {t('Availability')}
          </Typography>
          <Typography
            variant='subtitle2'
            sx={{ ...styles.title, textAlign: 'end' }}
          >
            {t('Placement')}
          </Typography>
        </Box>
        {isWoPlacementsLoading && <LoadingOverlay />}
        {!isLocationsAvailabilityLoading && !isEmpty(locationsAvailability) && (
          <StoragePlacementDetails
            availabilityData={locationsAvailability}
            warehousePlacements={placementDetails}
            handleUpdate={updateProductHandler}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default StorageCard;
