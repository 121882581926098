import moment from 'moment';
import { ORDER_STATUS } from '../../utils/constants';
import i18n from '../../localization';

const warehouseOrderChangelogMap = {
  ORDER_CRATED: {
    getTemplate: (oldValue, { location_id: newLocationId }) =>
      `${i18n.t('Order created. Location')} = <b>${newLocationId}</b>`,
  },
  STATUS: {
    getTemplate: ({ order_status: oldStatus }, { order_status: newStatus }) =>
      `${i18n.t('Warehouse order status')}: </br>
      <b>${i18n.t(ORDER_STATUS[oldStatus]?.label)}</b> → <b>${i18n.t(
        ORDER_STATUS[newStatus]?.label,
      )}</b>`,
  },
  TRACKING_ID: {
    getTemplate: (
      { shipment_tracking_code: oldTrackingCode },
      { shipment_tracking_code: newTrackingCode },
    ) => {
      const oldState = oldTrackingCode
        ? ` <b>${oldTrackingCode}</b>`
        : ` <i>Empty</i>`;
      const newState = newTrackingCode
        ? `<b>${newTrackingCode}</b>`
        : `<i>Empty</i>`;
      return `${i18n.t('Tracking ID to 3PL')} : ${oldState} → ${newState}`;
    },
  },
  COMMENT: {
    getTemplate: ({ comment: oldComment }, { comment: newComment }) => {
      const oldState = oldComment ? ` <b>${oldComment}</b>` : ` <i>Empty</i>`;
      const newState = newComment ? `<b>${newComment}</b>` : `<i>Empty</i>`;
      return `${i18n.t(
        'Instructions to receiving party',
      )} : ${oldState} → ${newState}`;
    },
  },
  INSTANCE_REMOVED: {
    getTemplate: ({ instance_id: oldInstanceId }) =>
      `<b>${oldInstanceId}</b> ${i18n.t('was removed')}`,
  },
  ARRIVAL_DATE: {
    getTemplate: (
      { estimated_arrival: oldETA },
      { estimated_arrival: newETA },
    ) =>
      `${i18n.t('Estimated arrival date')}: </br><b>${moment(oldETA).format(
        'YYYY-MM-DD',
      )}</b> → <b>${moment(newETA).format('YYYY-MM-DD')}</b>`,
  },
  SHIP_LOCATION: {
    getTemplate: ({ location_id: oldLocation }, { location_id: newLocation }) =>
      `${i18n.t(
        'Ship to',
      )}: </br><b>${oldLocation}</b> → <b>${newLocation}</b>`,
  },
  SHIPPING_PROVIDER: {
    getTemplate: (
      { shipment_provider: oldProvider },
      { shipment_provider: newProvider },
    ) =>
      `${i18n.t(
        'Shipping provider',
      )}: </br><b>${oldProvider}</b> → <b>${newProvider}</b> `,
  },
  CANCELLED: {
    getTemplate: (oldValue, { order_status_updated_at: newStatusUpdatedAt }) =>
      `${i18n.t('Order cancelled on')} <b>${moment(newStatusUpdatedAt).format(
        'YYYY-MM-DD',
      )}</b>`,
  },
};

const getWarehouseOrderChangelogItem = (oldValue, newValue) => {
  if (newValue === null) return;
  const {
    updated_at,
    // eslint-disable-next-line no-unused-vars
    updated_by: updatedBy = null,
    qr_code_url,
    ...updatedPropertiesObject
  } = newValue;
  const changedProperties = [];

  if (oldValue === null) {
    // when the order is created
    if (updatedPropertiesObject.created_at) {
      changedProperties.push(warehouseOrderChangelogMap.ORDER_CRATED);
    }
  } else {
    Object.keys(updatedPropertiesObject).forEach(function (property) {
      if (oldValue[property] !== updatedPropertiesObject[property]) {
        if (property === 'location_id') {
          // when WO location changed
          changedProperties.push(warehouseOrderChangelogMap.SHIP_LOCATION);
        } else if (property === 'order_status') {
          if (
            updatedPropertiesObject.order_status ===
            ORDER_STATUS.CANCELLED.value
          ) {
            // when WO was canceled
            changedProperties.push(warehouseOrderChangelogMap.CANCELLED);
          } else {
            // when WO status was changed
            changedProperties.push(warehouseOrderChangelogMap.STATUS);
          }
        } else if (property === 'instance_id' && !newValue) {
          // when items in WO was swapped / removed
          changedProperties.push(warehouseOrderChangelogMap.INSTANCE_REMOVED);
        } else if (property === 'shipment_provider') {
          // when shipping provider was changed
          changedProperties.push(warehouseOrderChangelogMap.SHIPPING_PROVIDER);
        } else if (property === 'estimated_arrival') {
          // when shipping date was changed
          changedProperties.push(warehouseOrderChangelogMap.ARRIVAL_DATE);
        } else if (property === 'comment') {
          // when comment was changed
          changedProperties.push(warehouseOrderChangelogMap.COMMENT);
        } else if (property === 'shipment_tracking_code') {
          // when shipping tracking url was updated
          changedProperties.push(warehouseOrderChangelogMap.TRACKING_ID);
        }
      }
    });
  }
  // eslint-disable-next-line consistent-return
  return changedProperties;
};

export default getWarehouseOrderChangelogItem;
