const commonStyles = {
  wpReferenceText: {
    color: '#000',
    display: 'flex',
    alignItems: 'center',
  },
  wpReferenceIcon: {
    marginLeft: 5,
    transform: 'rotate(-45deg)',
    fontSize: 17,
  },
};

export const mainBlockStyles = () => ({
  ...commonStyles,
  container: {
    flex: 1,
    background: '#ffffff',
    border: '1px solid #cccccc',
    marginBottom: 16,
    borderRadius: 4,
    padding: '24px 0',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #cccccc',
    padding: '16px 24px',
    marginBottom: 32,
  },
  headingTitle: {
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 24,
  },
  headingButton: {
    width: 32,
    height: 32,
    minWidth: 0,
    borderRadius: '50%',
    padding: 0,
    backgroundColor: '#cccccc',
    marginLeft: 8,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit',
  },
  headingButtonIcon: {
    fontSize: 22,
  },
  column: {
    padding: '0 24px',
  },
  formItemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,

    '& .MuiTextField-root': {
      width: 200,
    },
  },
  formDateItem: {
    display: 'flex',

    '& .MuiTextField-root': {
      width: 75,
      marginLeft: 5,
    },

    '& > div': {
      width: 120,

      '& .SingleDatePicker': {
        width: '100%',

        '& .SingleDatePickerInput': {
          height: 38,
          width: '100%',

          '& .DateInput': {
            height: 38,
            width: '100%',

            '& .DateInput_input': {
              height: 38,
              width: '100%',
              fontFamily: 'Helvetica Neue, sans-serif',
              fontSize: 12,
              fontStyle: 'normal',
            },
          },
        },
      },
    },

    '& .MuiSvgIcon-root': {
      top: 9,
    },
  },
  formItemName: {
    fontFamily: 'Helvetica Neue, sans-serif',
    fontWeight: 700,
  },
  updateWarningContainer: {
    padding: 16,
    width: '100%',
    backgroundColor: '#f4ecc6',
  },
  addressContainer: {
    width: 200,
  },
  addressLine: {
    marginBottom: 4,
  },
  selectFieldWrapper: {
    width: 200,
  },
  selectFieldTypo: {
    fontFamily: 'Helvetica Neue, sans-serif',
  },
});

export const mainBlockMobileStyles = () => ({
  ...commonStyles,
  container: {
    background: '#ffffff',
    border: '1px solid #cccccc',
    padding: '16px 0',
    marginBottom: 8,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    marginBottom: 24,
  },
  headingButton: {
    width: 32,
    height: 32,
    minWidth: 0,
    borderRadius: '50%',
    padding: 0,
    backgroundColor: '#ececec',
    marginLeft: 8,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit',
  },
  headingButtonIcon: {
    fontSize: 22,
  },
  headingInfoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  headingTitle: {
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 18,
    height: 20,
  },
  headingDates: {
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 10,
    color: '#8b8b8b',
    height: 12,
    marginLeft: 2,
  },
  directionColumn: {
    padding: '0 16px',
    marginBottom: 16,
  },
  directionText: {
    textTransform: 'uppercase',
    marginBottom: 4,
  },
});
