import { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography } from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useNotificator from '../../utils/useNotificator';
import belecoApi from '../../api';
import TitleContainer from '../../components/TitleContainer';
import SectionContainer from '../../components/CustomComponents/SectionContainer';
import CustomDataGrid from '../../components/CustomDataGrid';
import { getConditionIcon } from '../TransportDetails/helpers';

const wpAdminURL = process.env.REACT_APP_WP_ADMIN_URL;

const PayoutDetails = () => {
  const { t, i18n } = useTranslation();
  const { payoutBasisId = '' } = useParams();
  const { notifyError } = useNotificator();

  const [isLoading, setIsLoading] = useState(true);
  const [payout, setPayout] = useState({});

  const getPayout = async () => {
    setIsLoading(true);

    try {
      const data = await belecoApi.payouts.getPayoutDetails(payoutBasisId);

      setPayout(data);
      setIsLoading(false);
    } catch (error) {
      notifyError(error);
    }
  };

  useEffect(() => {
    getPayout();
  }, []);

  const initColumns = useMemo(
    () => [
      {
        headerName: t('Instance ID'),
        field: 'instanceId',
        minWidth: 180,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row.instanceId ? (
            <Link to={`/instances/${row.instanceId}`}>
              <Typography variant='body2'>{row.instanceId}</Typography>
            </Link>
          ) : (
            <Typography variant='body2'>-</Typography>
          ),
      },
      {
        headerName: t('Order id'),
        field: 'orderId',
        minWidth: 100,
        flex: 0.55,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <a
            style={{ color: '#000' }}
            href={`${wpAdminURL}/post.php?post=${row.orderId}&action=edit`}
          >
            <Typography variant='body2'>{row.orderId}</Typography>
          </a>
        ),
      },
      {
        headerName: t('Sum'),
        field: 'sum',
        minWidth: 100,
        flex: 0.55,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <Typography variant='body2'>{`${Math.floor(
            row.sum / 100,
          )} kr`}</Typography>
        ),
      },
      {
        headerName: t('Booking start date'),
        field: 'bookingStartDate',
        minWidth: 160,
        flex: 0.9,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <Typography variant='body2'>{row.bookingStartDate || '-'}</Typography>
        ),
      },
      {
        headerName: t('Booking end date'),
        field: 'bookingEndDate',
        minWidth: 140,
        flex: 0.8,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <Typography variant='body2'>{row.bookingEndDate || '-'}</Typography>
        ),
      },
      {
        headerName: t('ID'),
        field: 'id',
        minWidth: 80,
        flex: 0.45,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <Typography variant='body2'>{row.id}</Typography>
        ),
      },
      {
        headerName: `${t('Subscription')}?`,
        field: 'isSubscriptionPayment',
        minWidth: 120,
        flex: 0.65,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ row }) => getConditionIcon(row.isSubscriptionPayment),
      },
    ],
    [i18n.language],
  );

  return (
    <>
      <Helmet>
        <title>{t('Payouts')}</title>
      </Helmet>
      <TitleContainer
        title={t('Payout {{payoutId}}', {
          payoutId: payoutBasisId,
        })}
      />
      <SectionContainer>
        <CustomDataGrid
          rows={payout.payoutItems}
          columns={initColumns}
          loading={isLoading}
          rowHeight={40}
          headerHeight={40}
          pagination
          paginationMode='client'
          rowCount={payout.payoutItems?.length || 0}
          pageSize={50}
          hideFooterPagination={false}
        />
      </SectionContainer>
    </>
  );
};

export default PayoutDetails;
