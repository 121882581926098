import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  MenuItem,
  CircularProgress,
  Paper,
  Typography,
  Box,
  Select,
  Button,
  Divider,
  Link,
} from '@mui/material';
import { Formik, Field } from 'formik';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  SHIPPING_PROVIDER_OPTIONS,
  ORDER_STATUS,
} from '../../../../utils/constants';
import Datepicker from '../../../InstanceDetails/components/Datepicker';
import FormFieldLabel from './components/FormFieldLabel';
import FormFieldRow from './components/FormFieldRow';
import LocationContext from '../../../../context/Location/LocationContext';
import QuickActionButtons from '../QuickActionButtons';
import AssetProviderSelectField from './components/AssetProviderSelectField';
import { WarehouseDetailsContext } from '../../WarehouseDetailsContext';
import WarehouseStatusUpdateDialog from '../WarehouseStatusUpdateDialog';
import GenericPopup from '../../../../components/GenericPopup/GenericPopup';
import StatusChangeWarningDialog from '../../../TransportDetails/components/StatusChangeWarningDialog';

const validationSchema = Yup.object().shape({
  shipmentProvider: Yup.string().nullable(),
  estimatedDelivery: Yup.string().required('Estimated Delivery is required'),
  shipTo: Yup.string()
    .test(
      'is-not-default-state',
      'Please select Ship To',
      (value) => value !== 'false',
    )
    .required('Ship To is required'),
  ownerId: Yup.string()
    .test(
      'is-not-default-state',
      'Please select Ship From',

      (value) => value !== 'false',
    )
    .required('Ship From is required'),
  comment: Yup.string().nullable(),
  trackingId: Yup.string().nullable(),
});

const responsiveInputStyle = {
  xs: '200px',
  md: '160px',
  lg: '200px',
  xl: '250px',
};

const styles = {
  deliveryDetailsContainer: {
    padding: { xs: '20px 10px', lg: '30px' },
    '& .MuiSelect-selectMenu': {
      padding: '3px 30px 3px 9px',
      fontFamily: 'GT-America-Standard-Thin, sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.87)',
      lineHeight: '1.8',
    },
  },
  deliveryDetailsHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderBottom: '2px solid #ccc',
    marginBottom: '20px',

    '& p': {
      alignSelf: 'flex-end',
      paddingBottom: '10px',

      '&:first-of-type': (theme) => ({
        fontSize: '32px',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
        },
      }),

      '&.orderId': {
        lineHeight: 1,
        paddingLeft: '5px',
        color: '#b8b8b8',
        fontSize: '20px',
      },

      '&:last-of-type': {
        textTransform: 'uppercase',
      },
    },

    button: {
      marginLeft: 'auto',
      '.MuiSvgIcon-root': {
        color: '#000',
        fontSize: '24px',
      },
    },
  },
  formContainer: (theme) => ({
    position: 'relative',
    display: 'grid',
    gridAutoFlow: 'dense',
    columnGap: { xs: '30px', xl: '80px' },
    rowGap: '15px',
    gridTemplateColumns: { xs: '100%', md: 'repeat(2, 1fr)' },

    '.MuiBox-root:not(:first-child, .infoContainer)': {
      width: responsiveInputStyle,
      '.SingleDatePicker': {
        '.DateInput': { width: responsiveInputStyle },
      },
    },

    '.MuiInputBase-root:not(.MuiInputBase-multiline)': {
      width: responsiveInputStyle,
    },

    '> div:nth-of-type(4)': {
      '.MuiTypography-root': {
        maxWidth: 'max-content',
        [theme.breakpoints.between('md', 1000)]: {
          maxWidth: 'min-content',
        },
      },
    },
  }),
  dividerContainer: (theme) => ({
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  warehouseInfoContainer: {
    gridColumn: { xs: 'unset', md: 2 },

    '.storageText': {
      width: responsiveInputStyle,
      paddingLeft: '4px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '&.underlined': {
        textDecoration: 'underline',
      },
    },
  },
  textareasContainer: {
    '> div': {
      display: 'flex',
      flexDirection: 'column',
      margin: '15px 0px',
    },
  },
  orderIdLink: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    color: '#000',
    zIndex: 1,

    svg: {
      fontSize: '17px',
      transform: 'rotate(-45deg)',
      marginLeft: '5px',
    },
  },
  errorMessage: {
    color: '#DA4453',
    margin: '10px 0px',
    fontSize: '15px',
  },
  updatingButtonsContainer: {
    display: 'flex',
    flexDirection: { xs: 'column-reverse', sm: 'row' },
    justifyContent: 'space-between',
    paddingTop: '20px',
  },
  updatingButton: {
    maxWidth: { xs: '300px', sm: 'unset' },
    width: { xs: '100%', sm: 'unset' },
    margin: { xs: 'auto', sm: 'unset' },
    marginBottom: '16px',
  },
};

const DeliveryDetails = ({
  isNewOrder,
  sendNewOrderHandler,
  cancelOrder,
  processingOrder,
  discardChangesToOrderProductList,
  editMode,
  setEditMode,
  updateOrderStatus,
  clearListOfSelectedProducts,
}) => {
  const { t } = useTranslation();
  const { warehouseOrderData } = useContext(WarehouseDetailsContext);
  const { orderStatus, location, publicOrderId, orderId, instances } =
    warehouseOrderData;
  const { locations } = useContext(LocationContext);

  const [currentOrderStatus, setCurrentOrderStatus] = useState(orderStatus);
  const [isShownPopupToComplete, setIsShownPopupComplete] = useState(false);
  const [isFailToCompleteDialogOpen, setIsFailToCompleteDialogOpen] =
    useState(false);

  const woIsEditable =
    !editMode &&
    !isNewOrder &&
    currentOrderStatus !== ORDER_STATUS.CANCELLED.value;
  const wpAdminURL = process.env.REACT_APP_WP_ADMIN_URL;

  useEffect(() => {
    if (
      currentOrderStatus === ORDER_STATUS.PROCESSING.value &&
      warehouseOrderData.instances.every(
        (item) => item.warehouseOrderInstances.isCompleted,
      )
    ) {
      setIsShownPopupComplete(true);
    }
  }, [warehouseOrderData?.instances, currentOrderStatus]);

  const handleStatusUpdate = (newStatus) => {
    updateOrderStatus(newStatus).then((newOrderStatus) => {
      if (newOrderStatus) setCurrentOrderStatus(newOrderStatus);
    });
  };

  const handleCompleteWarehouseOrder = () => {
    if (
      !instances.some(
        ({ warehouseOrderInstances }) => warehouseOrderInstances.isCompleted,
      )
    ) {
      setIsFailToCompleteDialogOpen(true);
    } else {
      handleStatusUpdate(ORDER_STATUS.COMPLETED.value);
    }
  };

  const renderBtnText = () => {
    if (processingOrder) {
      return <CircularProgress size={30} />;
    }
    if (editMode) {
      return t('Update Order');
    }
    return t('Send Order');
  };

  return (
    <Grid item>
      <Formik
        initialValues={warehouseOrderData}
        onSubmit={sendNewOrderHandler}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          values: { shipTo: selectedLocation },
          errors,
          resetForm,
        }) => (
          <Paper sx={styles.deliveryDetailsContainer}>
            <Box sx={styles.deliveryDetailsHeader}>
              <Typography>
                {isNewOrder ? t('New warehouse order') : t('Warehouse order')}
              </Typography>
              {publicOrderId ? (
                <Typography variant='body2' className='orderId'>
                  {publicOrderId}
                </Typography>
              ) : null}
              {woIsEditable && (
                <IconButton
                  onClick={() => {
                    setEditMode(true);
                  }}
                  title={t('Edit Order')}
                >
                  <EditIcon />
                </IconButton>
              )}
              {editMode && (
                <IconButton
                  onClick={() => {
                    resetForm();
                    discardChangesToOrderProductList();
                    setEditMode(false);
                  }}
                  title={t('Discard Changes')}
                >
                  <CancelIcon />
                </IconButton>
              )}
              <Typography variant='body2'>
                {currentOrderStatus
                  ? t(ORDER_STATUS[currentOrderStatus]?.label)
                  : t('N/A')}
              </Typography>
            </Box>
            <Box>
              <form onSubmit={handleSubmit}>
                <Box sx={styles.formContainer}>
                  <Box sx={styles.dividerContainer}>
                    <Divider orientation='vertical' />
                  </Box>
                  <FormFieldRow>
                    <FormFieldLabel label={`${t('Estimated delivery')}:`} />
                    <Field name='estimatedDelivery'>
                      {({ field, form }) => (
                        <Datepicker
                          name={field.name}
                          value={field.value}
                          onChange={(value) =>
                            form.setFieldValue(field.name, value)
                          }
                          datePickerProps={{
                            isOutsideRange: (day) => day.isBefore(moment()),
                            disabled: !(isNewOrder || editMode),
                            placeholder: t('Not selected'),
                          }}
                        />
                      )}
                    </Field>
                  </FormFieldRow>
                  <FormFieldRow>
                    <FormFieldLabel label={`${t('Ship from')}:`} />
                    <Field name='ownerId'>
                      {({ field }) => (
                        <AssetProviderSelectField
                          formikProps={field}
                          isNewOrder={isNewOrder}
                          clearListOfSelectedProducts={
                            clearListOfSelectedProducts
                          }
                        />
                      )}
                    </Field>
                  </FormFieldRow>
                  <FormFieldRow>
                    <FormFieldLabel label={`${t('Shipping provider')}:`} />
                    <Field
                      as={(formikProps) => (
                        <Select
                          size='small'
                          variant='outlined'
                          disabled={!isNewOrder && !editMode}
                          displayEmpty
                          {...formikProps}
                          value={formikProps.value ? formikProps.value : ''}
                        >
                          <MenuItem key='default-provider' disabled value=''>
                            <em>{t('Not selected')}</em>
                          </MenuItem>
                          {SHIPPING_PROVIDER_OPTIONS.map((item) => (
                            <MenuItem
                              key={`sp-${item}`}
                              value={item.toString()}
                            >
                              <Typography variant='body2'> {item}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      name='shipmentProvider'
                    />
                  </FormFieldRow>
                  <FormFieldRow>
                    <FormFieldLabel label={`${t('Ship to')}:`} />
                    <Field
                      name='shipTo'
                      as={(formikProps) => (
                        <Select
                          size='small'
                          variant='outlined'
                          disabled={!isNewOrder && !editMode}
                          displayEmpty
                          {...formikProps}
                        >
                          <MenuItem
                            key='default-ship-to'
                            disabled
                            selected
                            hidden
                            value=''
                          >
                            <em>{t('Not selected')}</em>
                          </MenuItem>
                          {Object.keys(locations).map((locationId) => (
                            <MenuItem
                              disabled={!locations[locationId].isActive}
                              key={locations[locationId].title}
                              value={locationId}
                            >
                              <Typography variant='body2'>
                                {`${locations[locationId].title} (${locations[locationId].wms.title})`}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormFieldRow>
                  {locations[selectedLocation] && (
                    <Box
                      className='infoContainer'
                      sx={styles.warehouseInfoContainer}
                    >
                      <FormFieldRow align='flex-start'>
                        <FormFieldLabel label={`${t('Address')}:`} />
                        <div>
                          <Typography
                            variant='body2'
                            className='storageText'
                            gutterBottom
                          >
                            {locations[selectedLocation].wms.title || ''}{' '}
                            {locations[selectedLocation].provider
                              ? ` / ${locations[selectedLocation].provider}`
                              : ''}
                          </Typography>
                          {locations[selectedLocation].addressLine1 && (
                            <Typography
                              variant='body2'
                              className='storageText'
                              gutterBottom
                            >{`${locations[selectedLocation].addressLine1},`}</Typography>
                          )}
                          {locations[selectedLocation].addressLine2 && (
                            <Typography
                              variant='body2'
                              className='storageText'
                              gutterBottom
                            >{`${locations[selectedLocation].addressLine2},`}</Typography>
                          )}
                          <Typography variant='body2' className='storageText'>
                            {locations[selectedLocation].zipCode},{' '}
                            {locations[selectedLocation].title}
                          </Typography>
                        </div>
                      </FormFieldRow>
                      <FormFieldRow paddingTop='25px'>
                        <FormFieldLabel label={`${t('Opening hours')}:`} />
                        {locations[selectedLocation].openingHours ? (
                          <Typography variant='body2' className='storageText'>
                            {locations[selectedLocation].openingHours}
                          </Typography>
                        ) : (
                          <Typography
                            align='center'
                            variant='body2'
                            className='storageText'
                          >
                            -
                          </Typography>
                        )}
                      </FormFieldRow>
                      <FormFieldRow paddingTop='25px'>
                        <FormFieldLabel label={`${t('Phone')}:`} />
                        {locations[selectedLocation].phone ? (
                          <Link
                            href={`tel: ${locations[selectedLocation].phone}`}
                            variant='body2'
                            className='storageText underlined'
                          >
                            {locations[selectedLocation].phone}
                          </Link>
                        ) : (
                          <Typography
                            align='center'
                            variant='body2'
                            className='storageText'
                          >
                            -
                          </Typography>
                        )}
                      </FormFieldRow>
                      <FormFieldRow paddingTop='25px'>
                        <FormFieldLabel label={`${t('Email')}:`} />
                        {locations[selectedLocation].email ? (
                          <Link
                            href={`mailto: ${locations[selectedLocation].email}`}
                            variant='body2'
                            className='storageText underlined'
                          >
                            {locations[selectedLocation].email}
                          </Link>
                        ) : (
                          <Typography
                            align='center'
                            variant='body2'
                            className='storageText'
                          >
                            -
                          </Typography>
                        )}
                      </FormFieldRow>
                    </Box>
                  )}
                  <Box className='infoContainer' sx={styles.textareasContainer}>
                    <div>
                      <FormFieldLabel label={`${t('Tracking ID to 3PL')}:`} />
                      <Field name='trackingId'>
                        {({ field }) => (
                          <TextField
                            disabled={!isNewOrder && !editMode}
                            type='text'
                            multiline
                            maxRows={4}
                            minRows={2}
                            variant='outlined'
                            InputProps={{
                              readOnly: !isNewOrder && !editMode,
                            }}
                            {...field}
                          />
                        )}
                      </Field>
                    </div>
                    <div>
                      <FormFieldLabel
                        label={`${t('Instructions to receiving party')}:`}
                      />
                      <Field name='comment'>
                        {({ field }) => (
                          <TextField
                            type='text'
                            disabled={!isNewOrder && !editMode}
                            multiline
                            maxRows={4}
                            minRows={2}
                            variant='outlined'
                            InputProps={{
                              readOnly: !isNewOrder && !editMode,
                            }}
                            {...field}
                          />
                        )}
                      </Field>
                    </div>
                  </Box>
                  {orderId ? (
                    <Link
                      href={`${wpAdminURL}/post.php?post=${orderId}&action=edit`}
                      variant='body2'
                      underline='always'
                      sx={styles.orderIdLink}
                    >
                      {t('View details in WP admin')}
                      <LinkIcon />
                    </Link>
                  ) : (
                    ''
                  )}
                </Box>
                <div>
                  {Object.entries(errors).map(([fieldName, fieldValue]) => (
                    <Typography
                      sx={styles.errorMessage}
                      variant='body2'
                      key={fieldName}
                    >
                      {t(fieldValue)}
                    </Typography>
                  ))}
                </div>
                <Box sx={styles.updatingButtonsContainer}>
                  {!isNewOrder &&
                    currentOrderStatus !== ORDER_STATUS.CANCELLED.value && (
                      <WarehouseStatusUpdateDialog
                        currentOrderStatus={orderStatus}
                        handleStatusUpdate={handleStatusUpdate}
                        cancelOrder={cancelOrder}
                        handleCompleteWarehouseOrder={
                          handleCompleteWarehouseOrder
                        }
                      />
                    )}

                  {(isNewOrder || editMode) && (
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={processingOrder}
                      sx={styles.updatingButton}
                    >
                      {renderBtnText()}
                    </Button>
                  )}

                  {!isNewOrder && !editMode && location && (
                    <QuickActionButtons
                      location={location}
                      currentOrderStatus={currentOrderStatus}
                      handleStatusUpdate={handleStatusUpdate}
                      handleCompleteWarehouseOrder={
                        handleCompleteWarehouseOrder
                      }
                    />
                  )}
                  <GenericPopup
                    setIsOpen={setIsFailToCompleteDialogOpen}
                    isOpened={isFailToCompleteDialogOpen}
                    title={t('No items delivered')}
                    text={t(
                      'Please select item that was delivered to complete transport',
                    )}
                    action={null}
                  />
                  <StatusChangeWarningDialog
                    isOpened={isShownPopupToComplete}
                    closeDialog={() => setIsShownPopupComplete(false)}
                    handleAction={() =>
                      handleStatusUpdate(ORDER_STATUS.COMPLETED.value)
                    }
                    actionButtonText={t('Completed')}
                    dialogTitle={t('All items delivered!')}
                    messageText={t('Mark transport as completed?')}
                  />
                </Box>
              </form>
            </Box>
          </Paper>
        )}
      </Formik>
    </Grid>
  );
};

DeliveryDetails.propTypes = {
  isNewOrder: PropTypes.bool.isRequired,
  sendNewOrderHandler: PropTypes.func.isRequired,
  clearListOfSelectedProducts: PropTypes.func,
  cancelOrder: PropTypes.func.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  processingOrder: PropTypes.bool,
  // eslint-disable-next-line react/boolean-prop-naming
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  discardChangesToOrderProductList: PropTypes.func.isRequired,
  updateOrderStatus: PropTypes.func.isRequired,
};

export default DeliveryDetails;
