import { useContext, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, Paper, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { isEmpty, isNil } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import DeliveryDetails from './components/DeliveryDetails';
import { getLocations } from '../../store/reducers/locations';
import belecoApi from '../../api';
import SelectProductsToOrder from './components/InventoryDetails/SelectProductsToOrder';
import ProductsToAdd from './components/InventoryDetails/ProductsToAdd';
import LoadingOverlay from '../../components/LoadingOverlay';
import ShippingLabel from './components/ShippingLabel';
import LoadingOverlayDialog from '../../components/LoadingOverlayDialog/LoadingOverlayDialog';
import PrintLabelsDialog from '../ProductDetails/components/RelatedInstacesList/components/PrintLabelsDialog';
import InstancesTable from '../../components/InstanceExpandedTable';
import Changelog from '../Changelog/Changelog';
import CHANGELOG_TYPES from '../Changelog/changelogTypes';
import WarehouseDetailsProvider, {
  WarehouseDetailsContext,
} from './WarehouseDetailsContext';
import ChangelogProvider, {
  ChangelogContext,
} from '../../context/Changelog/ChangelogContext';
import useNotificator from '../../utils/useNotificator';
import LocationContext from '../../context/Location/LocationContext';
import WarehouseDeliveryDetailsStatus from './components/DeliveryStatus/DeliveryStatus';

const productsContainer = {
  marginTop: '20px',
  display: 'flex',
  flexDirection: { xs: 'column', lg: 'row' },

  '> div': {
    width: { xs: '100%', lg: '50%' },
  },
};

const WarehouseDeliveryDetailsPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [instancesToOrder, setInstancesToOrder] = useState({});
  const [processingOrder, setProcessingOrder] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { locations, isLocationsListLoading: locationLoading } =
    useContext(LocationContext);

  const {
    warehouseOrderData,
    isLoadingWOData,
    listOfAssetProviders: assetsProvidersList,
    instanceMap: instancesMap,
    updateOrder,
    resetOrderData,
  } = useContext(WarehouseDetailsContext);
  const { getChangelog } = useContext(ChangelogContext);

  const { instances } = warehouseOrderData;

  const isNewOrder = params.publicId === 'create-new-order';

  const { notifyError } = useNotificator();

  const getAssetProviderName = (id) => {
    const assetProviderData = assetsProvidersList[id];
    const {
      display_name: displayName,
      meta: {
        company_name: companyName,
        first_name: firstName,
        last_name: lastName,
      },
    } = assetProviderData;
    if (companyName === '') {
      return firstName && lastName ? `${firstName} ${lastName}` : displayName;
    }
    return companyName;
  };

  const sendNewOrder = async (orderDetails, inventories) => {
    setProcessingOrder(true);
    const {
      estimatedDelivery: estimatedArrival,
      comment,
      shipmentProvider,
      shipTo,
      ownerId,
      trackingId,
    } = orderDetails;

    const products = Object.keys(inventories).map((productId) => ({
      id: productId,
      quantity: instancesToOrder[productId].qty,
      condition: 5,
    }));

    const newWarehouseOrderData = {
      ownerId,
      estimatedArrival,
      comment,
      locationId: Number(shipTo),
      products,
      shipmentProvider,
      name: getAssetProviderName(ownerId),
      shipmentTrackingCode: trackingId,
    };
    try {
      const { publicId } = await belecoApi.inv.createWarehouseDeliveryOrder(
        newWarehouseOrderData,
      );
      if (publicId) {
        history.push(`/warehouse-orders/${publicId}`);
      }
      setProcessingOrder(false);
    } catch (e) {
      setProcessingOrder(false);
      notifyError(e, t('Server error. Creating new delivery order failed'));
    }
  };

  const updateOrderStatus = async (newStatus) => {
    setProcessingOrder(true);
    let newOrderStatus;
    await updateOrder(newStatus).then((data) => {
      getChangelog(() =>
        belecoApi.inv.getWarehouseOrderHistory({ id: params.publicId }),
      );
      newOrderStatus = data;
    });
    setProcessingOrder(false);
    return newOrderStatus;
  };

  const editOrder = async (orderDetails, inventories) => {
    setProcessingOrder(true);
    const {
      estimatedDelivery: estimatedArrival,
      comment,
      shipmentProvider,
      shipTo,
      ownerId,
      trackingId,
    } = orderDetails;

    const products = Object.keys(inventories).map((productId) => ({
      id: Number(productId),
      quantity: instancesToOrder[productId].qty,
      condition: 5,
    }));

    const requestParams = {
      ownerId,
      estimatedArrival,
      comment,
      locationId: Number(shipTo),
      products,
      shipmentProvider,
      name: getAssetProviderName(ownerId),
      shipmentTrackingCode: trackingId,
    };
    try {
      const { data } = await belecoApi.inv.editWarehouseDeliveryOrder(
        params.publicId,
        requestParams,
      );
      if (data) {
        history.push(`/warehouse-orders/${data.publicId}`);
      }
      getChangelog(() =>
        belecoApi.inv.getWarehouseOrderHistory({ id: params.publicId }),
      );
      setProcessingOrder(false);
      setEditMode(false);
    } catch (e) {
      setProcessingOrder(false);
      notifyError(e, t('Server error. Editing order failed'));
    }
  };

  const sendNewOrderHandler = (formValues) => {
    if (Object.keys(instancesToOrder).length === 0) {
      notifyError(
        null,
        t(
          'Order without products can not be created. Please select products first.',
        ),
      );
      return;
    }

    if (editMode) {
      editOrder(formValues, instancesToOrder);
    } else {
      sendNewOrder(formValues, instancesToOrder);
    }
  };

  const cancelOrder = async () => {
    setProcessingOrder(true);
    try {
      await belecoApi.inv.cancelWarehouseDeliveryOrder({
        publicId: params.publicId,
      });
      history.push(`/warehouse-orders`);
    } catch (e) {
      notifyError(e, t('Server error. The cancellation process failed.'));
    } finally {
      setProcessingOrder(false);
    }
  };

  const clearListOfSelectedProducts = () => {
    setInstancesToOrder({});
  };

  useEffect(() => {
    if (!locationLoading && isEmpty(locations)) {
      dispatch(getLocations());
    }
  }, [dispatch, locationLoading, locations]);

  useEffect(() => {
    if (!isLoadingWOData) {
      setInstancesToOrder(instancesMap);
    }
    if (isNewOrder) {
      clearListOfSelectedProducts();
    }
  }, [isNewOrder, instancesMap, isLoadingWOData]);

  useEffect(() => {
    return () => {
      resetOrderData();
    };
  }, [params.publicId]);

  const discardChangesToOrderProductList = () => {
    setInstancesToOrder(instancesMap);
  };

  const addProduct = ({ productId, sku, title, image, number }) => {
    const { [productId]: itemToEdit, ...rest } = instancesToOrder;
    if (isNil(itemToEdit)) {
      setInstancesToOrder({
        ...rest,
        [productId]: { qty: 1, id: productId, sku, title, image },
      });
      return;
    }
    const qty = isNil(number) ? itemToEdit.qty + 1 : number;
    setInstancesToOrder((prev) => ({
      ...prev,
      [productId]: { ...itemToEdit, qty },
    }));
  };

  const removeProduct = (productId, removeItemFromList = false) => {
    const { [productId]: itemToEdit } = instancesToOrder;
    if (itemToEdit.qty === 1 || removeItemFromList) {
      const orderWithoutItem = { ...instancesToOrder };
      delete orderWithoutItem[productId];
      setInstancesToOrder(orderWithoutItem);
    } else {
      const qty = itemToEdit.qty - 1;
      setInstancesToOrder((prev) => ({
        ...prev,
        [productId]: { ...itemToEdit, qty },
      }));
    }
  };

  const getInstanceTableData = useMemo(() => {
    return Object.keys(instancesMap).map((key) => {
      const productInstances = instances.filter(
        ({ productId }) => productId === +key,
      );

      return {
        ...instancesMap[key],
        instances: productInstances.sort(
          (a, b) =>
            a.publicId.split('-').slice(-1)[0] -
            b.publicId.split('-').slice(-1)[0],
        ),
        warehousePlacement: [],
      };
    });
  }, [isLoadingWOData, instancesMap]);

  if (isLoadingWOData) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <Helmet>
        <title>
          {isNewOrder
            ? t('New Warehouse Order')
            : `${t('Warehouse Order')} ${params.publicId}`}
        </title>
      </Helmet>

      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <DeliveryDetails
            isNewOrder={isNewOrder}
            discardChangesToOrderProductList={discardChangesToOrderProductList}
            clearListOfSelectedProducts={clearListOfSelectedProducts}
            sendNewOrderHandler={sendNewOrderHandler}
            cancelOrder={cancelOrder}
            processingOrder={processingOrder}
            editMode={editMode}
            setEditMode={setEditMode}
            updateOrderStatus={updateOrderStatus}
          />
          <Grid item>
            <Paper style={{ marginTop: 20, padding: 20 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingBottom: '15px',
                }}
              >
                <Typography style={{ fontSize: 32 }}>
                  {isNewOrder
                    ? t('Select products to send in')
                    : t('Instances')}
                </Typography>
                <PrintLabelsDialog items={instances} />
              </div>

              {isNewOrder ? (
                <Box sx={productsContainer}>
                  <div>
                    <SelectProductsToOrder addProductToOrder={addProduct} />
                  </div>
                  <div>
                    <ProductsToAdd
                      editMode={editMode}
                      isNewOrder={isNewOrder}
                      items={instancesToOrder}
                      addProductToOrder={addProduct}
                      removeProductFromOrder={removeProduct}
                    />
                  </div>
                </Box>
              ) : (
                <InstancesTable
                  data={getInstanceTableData}
                  isLoading={isLoadingWOData}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
        <Grid item md={4} xs={12}>
          <WarehouseDeliveryDetailsStatus
            warehouseOrderData={warehouseOrderData}
          />
          <ShippingLabel orderId={params.publicId} isNewOrder={isNewOrder} />
          {!isNewOrder ? (
            <Changelog
              changelogType={CHANGELOG_TYPES.WAREHOUSE_ORDER}
              getData={() =>
                belecoApi.inv.getWarehouseOrderHistory({
                  id: params.publicId,
                })
              }
              isHiddenNoteField
            />
          ) : null}
        </Grid>
        <LoadingOverlayDialog
          text={t('To process delivery order may take some time.')}
          show={processingOrder}
        />
      </Grid>
    </>
  );
};

const WarehouseDeliveryDetails = () => (
  <WarehouseDetailsProvider>
    <ChangelogProvider>
      <WarehouseDeliveryDetailsPage />
    </ChangelogProvider>
  </WarehouseDetailsProvider>
);

export default WarehouseDeliveryDetails;
