export const AssetProviderProductListStyles = (theme) => ({
  listHeader: {
    textTransform: 'uppercase',
    color: '#E3727D',
    padding: '5px 0px',
  },
  listContainer: {
    paddingTop: 10,
    paddingRight: 20,
    borderRight: '1px solid #ccc',
    [theme.breakpoints.down('lg')]: {
      paddingRight: '0px',
      border: 'none',
    },
  },
  searchBox: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '7px 14px',
    },
  },
  listItem: {
    marginTop: 10,
    border: '1px solid #ccc',
    display: 'grid',
    gridTemplateColumns: '20% 65% 15% 1fr',
    height: 70,
  },
  imageColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    '& img': {
      maxHeight: '60px',
      maxWidth: '100%',
      objectFit: 'contain',
    },
  },
  titleColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  productTitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '50vw',
  },
  actionButtonColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 15,
    '& .MuiButtonBase-root': {
      minWidth: 30,
      padding: 5,
    },
  },
  filteringField: {
    backgroundColor: '#DA4453',
    '&  .MuiOutlinedInput-adornedEnd': {
      paddingRight: '10px',
    },
    '& input': {
      backgroundColor: '#FFF',
    },
    '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
      color: '#FFF',
    },
  },
});
export const ProductListAddedToOrder = (theme) => ({
  listHeader: {
    textTransform: 'uppercase',
    color: '#E3727D',
    padding: '5px 0px',
  },
  imageColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
    width: '100%',
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
      padding: 3,
    },
  },
  titleColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  actionButtonColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      minWidth: 30,
      padding: 5,
    },
  },
  tableColumnsHeaderRow: {
    display: 'grid',
    gridTemplateColumns: '70% 30% 1fr',
    marginLeft: 20,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
    },
  },
  tableColumnsHeaderRowExpanded: {
    gridTemplateColumns: '80% 20% 1fr',
    marginLeft: 0,
  },
  productListItem: {
    marginTop: 10,
    border: '1px solid #ccc',
    display: 'grid',
    gridTemplateColumns: '20% 50% 15% 15% 1fr',
    height: 70,
    marginLeft: 20,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
      padding: 0,
    },
  },
  listItemExpanded: {
    gridTemplateColumns: '10% 70% 15% 15% 1fr',
    marginLeft: 0,
  },

  amountControls: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-input': {
      padding: 4,
      width: 35,
      textAlign: 'center',
    },
  },
});
