// eslint-disable-next-line import/prefer-default-export
export const WarehouseDeliveriesPageStyles = (theme) => ({
  container: {
    padding: 20,
    paddingTop: 10,
    backgroundColor: '#ffffff',
    border: '1px solid #ccc',
    borderRadius: '7px',
    '& a:visited ,& a:active, & a:link': {
      color: '#000',
    },
    [theme.breakpoints.down('lg')]: {
      borderRadius: 0,
      padding: 0,
      border: 'none',
      display: 'flex',
      flexFlow: 'column',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    overflowX: 'scroll',
  },
  headerSection: {
    flex: '0 1 auto',
    padding: 10,
    borderBottom: '1px solid #CCC',
  },

  tableName: {
    fontSize: '32px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    textTransform: 'uppercase',
    alignSelf: 'start',
    lineHeight: 1,
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      minWidth: 240,
      alignSelf: 'center',
    },
  },
  filterButton: {
    backgroundColor: '#ECECEC',
    padding: 7,
    marginTop: 10,
    width: 40,
    marginLeft: 'auto',
  },
  titleRow: {
    display: 'flex',
    justify: 'space-between',
    alignItems: 'center',
  },
  secondaryRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonsBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      width: '100%',
      marginTop: '10px',
      marginLeft: 'auto',
    },
  },
  filteringControls: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginLeft: 'auto',
    margin: '-4px',
    '& > div': {
      margin: 4,
    },
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'flex-start',
      [theme.breakpoints.down('md')]: {
        marginLeft: 'initial',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  },

  searchBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchInput: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 180,
    },
  },
  searchField: {
    backgroundColor: '#DA4453',
    borderRadius: '5px',
    marginLeft: '8px',
    minWidth: 150,
    height: 38,
    '&  .MuiOutlinedInput-adornedEnd': {
      paddingRight: '10px',
    },
    '& input': {
      backgroundColor: '#FFF',
    },
    '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
      color: '#FFF',
    },
  },
  filterByOwnerSelect: {
    margin: 'auto 10px',
    '& .MuiInputBase-root': {
      minWidth: 230,
      height: 38,
      padding: '0px 12px',
      paddingRight: '25px !important',
    },
  },
  filterByOrderStatusSelect: {
    margin: 'auto 10px',
    '& .MuiInputBase-root': {
      minWidth: 150,
      height: 38,
      padding: '0px 12px',
      paddingRight: '25px !important',
    },
  },
  createNewOrderButton: {
    marginLeft: 'auto',
    backgroundColor: '#DA4453',
    '&:hover': {
      color: 'white',
      backgroundColor: '#DA4453',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 15,
    },
  },
});
