import { useMemo, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useNotificator from '../../utils/useNotificator';
import belecoApi from '../../api';

export const TMSContext = createContext({});

const TMSProvider = ({ children }) => {
  const { t } = useTranslation();
  const { notifyError } = useNotificator();
  const [TMSList, setTMSList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTMS = async () => {
    setLoading(true);

    try {
      const { rows } = await belecoApi.inv.getTMSList();
      setTMSList(rows);
    } catch (err) {
      notifyError(err, t('Failed to load TMS'));
    } finally {
      setLoading(false);
    }
  };

  const returnValues = useMemo(
    () => ({ getTMS, TMSList, loading }),
    [getTMS, TMSList, loading],
  );

  return (
    <TMSContext.Provider value={returnValues}>{children}</TMSContext.Provider>
  );
};

TMSProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default TMSProvider;
