import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import LocationContext from '../../../../context/Location/LocationContext';
import belecoApi from '../../../../api';
import useNotificator from '../../../../utils/useNotificator';
import CustomDataGrid from '../../../../components/CustomDataGrid';
import { TRANSPORTATION_TYPE } from '../../../../utils/constants';
import DeliveryIconEnabled from '../../../../assets/icons/Icon-delivery-enabled.svg';
import PickupIconEnabled from '../../../../assets/icons/Icon-pickup-enabled.svg';

const styles = {
  blockStyleClass: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '288px',
  },
  text: {
    fontSize: '12px',
  },
  link: {
    color: '#2a2929',
    display: 'flex',
    minWidth: '0px',
  },
};

const FailedTransportsTable = () => {
  const { t } = useTranslation();
  const { userLocation } = useContext(LocationContext);

  const [transports, setTransports] = useState([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState('desc');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const { notifyError } = useNotificator();
  const notInitialRender = useRef(false);
  const pageSize = 5;

  const getTransportsData = async () => {
    setLoading(true);

    try {
      const dateFrom = moment().subtract(6, 'd').format('YYYY-MM-DD'); // last 7 days
      const dateTo = moment().format('YYYY-MM-DD');

      const params = {
        offset: 0,
        from: dateFrom,
        to: dateTo,
        locationId: userLocation?.id || '',
        sort: order,
        orderBy: 'from',
        isNoShow: true,
      };

      const { rows, count } = await belecoApi.inv.getTransports(params);

      setPage(offset / pageSize);
      setTransports(rows);
      setTotal(count);
    } catch (err) {
      notifyError(err, t('Failed to load transports'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransportsData();
  }, [offset]);

  useEffect(() => {
    // Skip first render
    if (notInitialRender.current) {
      if (offset === 0) {
        getTransportsData();
      } else {
        setOffset(0);
      }
    } else {
      notInitialRender.current = true;
    }
  }, [userLocation?.id, order]);

  const handleChangePage = useCallback((selectedPage) => {
    setOffset(selectedPage * pageSize);
  }, []);

  const onSortChange = ([{ sort }]) => {
    setOrder(sort);
  };

  const returnTransportTypeIcon = (type) => {
    let iconSrc = '';
    let iconAlt = '';

    if (type === TRANSPORTATION_TYPE.DELIVERY) {
      iconAlt = 'Delivery';
      iconSrc = DeliveryIconEnabled;
    } else if (type === TRANSPORTATION_TYPE.PICKUP) {
      iconAlt = 'Pickup';
      iconSrc = PickupIconEnabled;
    }
    return (
      <img
        style={{ marginLeft: 5, marginRight: 5, width: '20px' }}
        src={iconSrc}
        alt={iconAlt}
      />
    );
  };

  const initColumns = [
    {
      field: 'from',
      headerName: t('Date'),
      sortingOrder: ['desc', 'asc'],
      sortable: Boolean(transports?.length),
      minWidth: 180,
      flex: 1,
      renderCell: ({ row: { from, to } }) => (
        <Typography variant='body2' sx={styles.text}>
          {`${moment(from).format('D MMM')} (${moment(from).format(
            'HH:mm',
          )}-${moment(to).format('HH:mm')})`}
        </Typography>
      ),
    },
    {
      field: 'city',
      headerName: t('To / From'),
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: ({ row: { type, status, city, location, publicId } }) => (
        <Link
          component={RouterLink}
          sx={styles.link}
          to={`/transports/${publicId}`}
        >
          <Typography variant='body2' component='span' noWrap sx={styles.text}>
            {location?.city}
          </Typography>
          {returnTransportTypeIcon(type, status)}
          <Typography variant='body2' component='span' noWrap sx={styles.text}>
            {city}
          </Typography>
        </Link>
      ),
    },
  ];

  return (
    <CustomDataGrid
      rows={transports}
      columns={initColumns}
      rowCount={total}
      pageSize={pageSize}
      loading={loading}
      hideSpaceRow
      onPageChange={handleChangePage}
      headerHeight={40}
      rowHeight={40}
      pagination
      hideFooterPagination={false}
      page={page}
      blockStyleSx={styles.blockStyleClass}
      sortingMode='server'
      onSortModelChange={onSortChange}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'from',
              sort: 'desc',
            },
          ],
        },
      }}
    />
  );
};

export default FailedTransportsTable;
