import { Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  accountBtn: {
    width: '100%',
    height: '100%',
    borderRadius: 0,
    paddingLeft: 0,
    '& .MuiButton-endIcon': {
      margin: '0!important',
    },
  },
  arrowIcon: {
    color: '#999999',
    '& > *:first-child': {
      fontSize: 25,
    },
  },
});

const ButtonSelector = ({ menuHandler, anchorRef, children }) => {
  const classes = useStyles();
  return (
    <Button
      ref={anchorRef}
      onClick={menuHandler}
      className={classes.accountBtn}
      classes={{ label: classes.btnLabel, iconSizeMedium: classes.arrowIcon }}
      endIcon={<ArrowDropDownIcon />}
    >
      {children}
    </Button>
  );
};

ButtonSelector.propTypes = {
  menuHandler: PropTypes.func.isRequired,
  anchorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  children: PropTypes.node,
};

export default ButtonSelector;
