import { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, CircularProgress, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import LockIcon from '@mui/icons-material/Lock';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TransportFailDeliveryDialog from '../TransportFailDeliveryDialog';
import {
  TRANSPORTING_STATUS_OPTIONS,
  STATUS_COLOR_MAP,
  HEADER_HEIGHT,
} from '../../../../utils/constants';

import GenericPopup from '../../../../components/GenericPopup/GenericPopup';
import useNotificator from '../../../../utils/useNotificator';
import { TransportDetailsContext } from '../../../../context/TransportDetails/TransportDetailsContext';
import { ChangelogContext } from '../../../../context/Changelog/ChangelogContext';
import belecoApi from '../../../../api';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    display: 'flex',
    alignItems: 'center',
    background: 'rgb(0,0,0,0.6)',
  },
  failedToDeliver: {
    backgroundColor: '#DA4453!important',

    '& p': {
      color: '#ffffff!important',
    },
  },
  statusMainButton: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      minWidth: 160,
    },
    height: 25,
    backgroundColor: '#ECECEC',
    borderRadius: '20px',
    whiteSpace: 'nowrap',
  },
  mainButtonCancelled: {
    border: '1px solid #ececec',
  },
  statusMainButtonTypo: {
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
    textTransform: 'initial',
  },
  statusMainButtonTypoCancelled: {
    color: '#8b8b8b',
  },
  statusList: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: HEADER_HEIGHT + 20,
    paddingRight: 40,
  },
  statusButton: {
    width: '240px',
    backgroundColor: '#FFF',
    marginBottom: 30,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#FFF',
    },
    '&:disabled': {
      backgroundColor: '#555',
    },
  },
  buttonText: {
    fontSize: 14,
    color: '#000',
  },
  cancelButton: {
    color: '#FFF',
    backgroundColor: '#DA4453',
    '&:hover': {
      backgroundColor: '#DA4453',
    },
  },
  cancelButtonText: {
    fontSize: 16,
    color: '#FFF',
  },
  buttonsSetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  disabledStatusChange: {
    pointerEvents: 'none',
  },
  outlinedButtonStyles: {
    '&.MuiButton-outlined': {
      padding: '2px 15px',
    },
  },
}));

const TransportsUpdateDialog = ({
  transportRecord,
  handleCompleteTransport = null,
  buttonStyles = {},
  isDisabledStatusUpdate = false,
  buttonTitle = '',
}) => {
  const { t } = useTranslation();
  const { notifyError, notifySuccess } = useNotificator();

  const { cancelTransport, updateTransport } = useContext(
    TransportDetailsContext,
  );
  const { getChangelog } = useContext(ChangelogContext);

  const [showStatusChange, setShowStatusChange] = useState(false);
  const [showCommentDialog, setShowCommentDialog] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const { tms } = transportRecord;
  const isManagedByBeleco = !!tms?.isManagedByBeleco;
  const transportIsCanceled =
    transportRecord.status === TRANSPORTING_STATUS_OPTIONS.CANCELLED.value;
  const classes = useStyles();

  const isPartialDelivery =
    transportRecord.status === TRANSPORTING_STATUS_OPTIONS.COMPLETED.value &&
    transportRecord.instances.some(
      ({ transportInstances }) => !transportInstances?.isCompleted,
    );

  const getLabel = (transport) => {
    // Failed status
    if (transport.isNoShow) {
      return t(TRANSPORTING_STATUS_OPTIONS.FAILED.label);
    }

    // Partial delivery status
    if (isPartialDelivery) {
      return t(TRANSPORTING_STATUS_OPTIONS.PARTIAL.label);
    }

    // Regular status
    return (
      t(TRANSPORTING_STATUS_OPTIONS[transport.status].label) || t('Unknown')
    );
  };

  const handleStatusChange = (status) => {
    if (
      transportRecord.status === TRANSPORTING_STATUS_OPTIONS.COMPLETED.value &&
      status === TRANSPORTING_STATUS_OPTIONS.CREATED.value
    )
      return;

    setShowStatusChange(false);
    const { publicId } = transportRecord;

    updateTransport({
      data: { status, isNoShow: false },
    })
      .then(() => {
        notifySuccess(
          t('Status for transport {{publicId}} was updated to {{status}}', {
            publicId,
            status,
          }),
        );
        getChangelog(() => belecoApi.inv.getTransportHistory({ id: publicId }));
      })
      .catch((error) => {
        notifyError(
          error,
          t("Error. Status for transport {{publicId}} wasn't updated", {
            publicId,
          }),
        );
      });
  };

  const handleCancelTransport = () => {
    const { publicId } = transportRecord;

    try {
      cancelTransport()
        .then(() => {
          notifySuccess(t('Transport {{publicId}} was canceled', { publicId }));
          getChangelog(() =>
            belecoApi.inv.getTransportHistory({ id: publicId }),
          );
        })
        .catch((err) => {
          notifyError(
            err,
            t("Error. Transport {{publicId}} wasn't updated", { publicId }),
          );
        });
    } catch (err) {
      notifyError(
        err,
        t("Error. Transport {{publicId}} wasn't updated", { publicId }),
      );
    }
  };

  const handleSubmitFail = (comment) => {
    const { publicId } = transportRecord;

    updateTransport({
      data: {
        isNoShow: true,
        status: TRANSPORTING_STATUS_OPTIONS.COMPLETED.value,
        comment,
      },
    })
      .then(() => {
        notifySuccess(
          t('Transport {{publicId}} was marked as failed delivery', {
            publicId,
          }),
        );
        getChangelog(() => belecoApi.inv.getTransportHistory({ id: publicId }));
      })
      .catch((err) => {
        notifyError(
          err,
          t("Error. Transport {{publicId}} wasn't updated", { publicId }),
        );
      });
  };

  if (!transportRecord) {
    return <CircularProgress />;
  }

  const returnBtn = (value, label, color) => {
    return (
      <Button
        disabled={transportRecord.status === value}
        key={value}
        variant='contained'
        className={classes.statusButton}
        onClick={() => handleStatusChange(value)}
        style={{ backgroundColor: color }}
      >
        <Typography className={classes.buttonText} variant='body2'>
          {t(label)}
        </Typography>
      </Button>
    );
  };

  const buttonClassName = `${classes.statusMainButton}
      ${transportRecord.isNoShow ? classes.failedToDeliver : ''}
      ${transportIsCanceled ? classes.mainButtonCancelled : ''}
      ${isDisabledStatusUpdate ? classes.disabledStatusChange : ''}
    `;
  return (
    <>
      {buttonTitle.length ? (
        <Button
          variant='outlined'
          className={classes.outlinedButtonStyles}
          onClick={(event) => {
            event.stopPropagation();
            setShowStatusChange(true);
          }}
        >
          {buttonTitle}
        </Button>
      ) : (
        <Button
          disabled={!isManagedByBeleco || transportIsCanceled}
          className={buttonClassName}
          style={{
            ...buttonStyles,
            backgroundColor:
              STATUS_COLOR_MAP[
                (isPartialDelivery && 'PARTIAL') ||
                  transportRecord.status ||
                  'N/A'
              ],
          }}
          onClick={(event) => {
            event.stopPropagation();
            setShowStatusChange(true);
          }}
        >
          <Typography
            variant='body2'
            className={`${classes.statusMainButtonTypo} ${
              transportIsCanceled ? classes.statusMainButtonTypoCancelled : ''
            }`}
          >
            {!isManagedByBeleco && (
              <LockIcon style={{ fontSize: 12, marginRight: 3 }} />
            )}
            {transportRecord && getLabel(transportRecord)}
          </Typography>
        </Button>
      )}
      <Dialog
        classes={{
          paperFullScreen: classes.dialogRoot,
        }}
        open={showStatusChange}
        onClick={(event) => {
          event.stopPropagation();
          setShowStatusChange(false);
        }}
        fullScreen
      >
        <div className={classes.statusList}>
          <div className={classes.buttonsSetWrapper}>
            <Button
              disabled={
                transportRecord.status ===
                TRANSPORTING_STATUS_OPTIONS.CANCELLED.value
              }
              variant='contained'
              className={`${classes.statusButton} ${classes.cancelButton}`}
              onClick={() => {
                setIsCancelDialogOpen(true);
              }}
            >
              <Typography className={classes.cancelButtonText} variant='body2'>
                {t(TRANSPORTING_STATUS_OPTIONS.CANCELLED.label)}
              </Typography>
            </Button>
            <Button
              variant='contained'
              className={classes.statusButton}
              onClick={() => {
                setShowCommentDialog(true);
              }}
            >
              <Typography className={classes.buttonText} variant='body2'>
                {t('Failed to deliver')}
              </Typography>
            </Button>
          </div>
          <div className={classes.buttonsSetWrapper}>
            {Object.values(TRANSPORTING_STATUS_OPTIONS).map(
              ({ value, label, color = '#ececec' }) => {
                return value !== TRANSPORTING_STATUS_OPTIONS.CANCELLED.value &&
                  value !== TRANSPORTING_STATUS_OPTIONS.COMPLETED.value &&
                  // Thess statuses handling only on frontend side
                  value !== TRANSPORTING_STATUS_OPTIONS.FAILED.value &&
                  value !== TRANSPORTING_STATUS_OPTIONS.PARTIAL.value
                  ? returnBtn(value, label, color)
                  : null;
              },
            )}
            <Button
              variant='contained'
              className={classes.statusButton}
              onClick={handleCompleteTransport}
              disabled={
                transportRecord.status ===
                TRANSPORTING_STATUS_OPTIONS.COMPLETED.value
              }
              style={{
                backgroundColor: TRANSPORTING_STATUS_OPTIONS.COMPLETED.color,
              }}
            >
              <Typography className={classes.buttonText} variant='body2'>
                {t(TRANSPORTING_STATUS_OPTIONS.COMPLETED.label)}
              </Typography>
            </Button>
          </div>
        </div>
      </Dialog>
      <GenericPopup
        setIsOpen={setIsCancelDialogOpen}
        isOpened={isCancelDialogOpen}
        title={t('Cancel transport order')}
        text={t('Are you sure you want to cancel this order?')}
        action={handleCancelTransport}
      />
      <TransportFailDeliveryDialog
        isOpen={showCommentDialog}
        setOpenState={setShowCommentDialog}
        onSubmit={handleSubmitFail}
      />
    </>
  );
};

TransportsUpdateDialog.propTypes = {
  transportRecord: PropTypes.object,
  handleCompleteTransport: PropTypes.func,
  buttonStyles: PropTypes.object,
  isDisabledStatusUpdate: PropTypes.bool,
  buttonTitle: PropTypes.string,
};

export default TransportsUpdateDialog;
