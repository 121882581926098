import { Helmet } from 'react-helmet-async';
import { Box, Typography } from '@mui/material';
import TransportsBlock from './components/transports/TransportsBlock';
import WarehouseBlock from './components/warehouses/WarehouseBlock';
import ProductsBlock from './components/products/ProductsBlock';

const styles = {
  dashboard: (theme) => ({
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
  }),
  title: (theme) => ({
    fontSize: '35px',
    color: '#555555',
    fontWeight: 300,

    [theme.breakpoints.down('md')]: {
      paddingRight: '10px',
      paddingLeft: '10px',
      fontSize: '18px',
      textTransform: 'uppercase',
      letterSpacing: '0.9px',
      fontWeight: 200,
    },
  }),
};
// test comment
const Dashboard = () => {
  return (
    <Box sx={styles.dashboard}>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Typography component='h1' variant='body2' sx={styles.title}>
        Dashboard
      </Typography>
      <TransportsBlock />
      <WarehouseBlock />
      <ProductsBlock />
    </Box>
  );
};
export default Dashboard;
