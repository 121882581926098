import PropTypes from 'prop-types';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ palette }) => ({
  statusMark: {
    color: '#269B62',
  },
  reasonText: {
    color: palette.title.highlighted,
    textTransform: 'uppercase',
  },
}));

const BookingStatusComponent = ({ isAvailable, isDoubleBooked }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (isDoubleBooked) {
    return (
      <Typography variant='subtitle2' className={classes.reasonText}>
        {t('Double booked')}
      </Typography>
    );
  }

  if (isAvailable) {
    return <CheckOutlinedIcon className={classes.statusMark} />;
  }

  return (
    <Typography variant='subtitle2' className={classes.reasonText}>
      {t('N/A')}
    </Typography>
  );
};

BookingStatusComponent.propTypes = {
  isAvailable: PropTypes.bool,
  isDoubleBooked: PropTypes.bool,
};

export default BookingStatusComponent;
