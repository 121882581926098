import React from 'react';

const FormFieldRow = ({ children, align = 'center', paddingTop = '10px' }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: align,
        paddingTop: paddingTop,
      }}
    >
      {children}
    </div>
  );
};
export default FormFieldRow;
