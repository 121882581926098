import { useContext } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { WarehouseDetailsContext } from '../WarehouseDetailsContext';
import GradientLoadingOverlay from '../../../components/GradientLoadingOverlay';

const CompleteAllInstancesButton = ({
  productId,
  productToComplete,
  setProductToComplete,
}) => {
  const { t } = useTranslation();

  const { warehouseOrderData, updateProductCompletedStatus } = useContext(
    WarehouseDetailsContext,
  );

  const completeAllInstances = async () => {
    setProductToComplete(productId);
    await updateProductCompletedStatus({
      publicId: warehouseOrderData.publicId,
      productId,
      isCompleted: true,
    });
    setProductToComplete(null);
  };

  if (productToComplete) return <GradientLoadingOverlay height={22} />;

  return (
    <Button
      variant='contained'
      sx={{ height: '22px', textTransform: 'none' }}
      onClick={(e) => {
        e.stopPropagation();
        completeAllInstances();
      }}
    >
      {t('Complete all')}
    </Button>
  );
};

CompleteAllInstancesButton.propTypes = {
  productId: PropTypes.number.isRequired,
  productToComplete: PropTypes.number,
  setProductToComplete: PropTypes.func.isRequired,
};

export default CompleteAllInstancesButton;
