import { useState, useContext, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useNotificator from '../../../../utils/useNotificator';
import CustomDataGrid from '../../../../components/CustomDataGrid';
import LocationContext from '../../../../context/Location/LocationContext';
import belecoApi from '../../../../api';

const styles = {
  blockStyleClass: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '288px',
  },
  baseTypoStyle: {
    fontSize: '12px',
  },
  link: {
    color: '#2a2929',
    display: 'flex',
    minWidth: '0px',
  },
};

const DamageReportTable = () => {
  const { t } = useTranslation();
  const { userLocation } = useContext(LocationContext);

  const [offset, setOffset] = useState(0);
  const [damageReports, setDamageReports] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const { notifyError } = useNotificator();
  const notInitialRender = useRef(false);

  const pageSize = 5;

  const getDamageReportData = async () => {
    setLoading(true);

    try {
      const params = {
        offset,
        limit: pageSize,
        locationId: userLocation?.id || '',
        isCompleted: false,
      };

      const { rows, count } = await belecoApi.inv.getAllDamageReports(params);

      setPage(offset / pageSize);
      setTotal(count);
      setDamageReports(rows);
    } catch (err) {
      notifyError(err, 'Failed to load damage report');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDamageReportData();
  }, [offset]);

  useEffect(() => {
    // Skip first render
    if (notInitialRender.current) {
      if (offset === 0) {
        getDamageReportData();
      } else {
        setOffset(0);
      }
    } else {
      notInitialRender.current = true;
    }
  }, [userLocation?.id]);

  const onChangePage = (selectedPage) => {
    setOffset(selectedPage * pageSize);
  };

  const initColumns = [
    {
      field: 'createdAt',
      headerName: t('Date'),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: ({ row: { createdAt } }) => (
        <Typography variant='body2' sx={styles.baseTypoStyle}>
          {moment(createdAt).format('D MMM YYYY')}
        </Typography>
      ),
    },
    {
      field: 'publicId',
      headerName: 'public id',
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: ({
        row: {
          instance: { publicId },
        },
      }) => (
        <Link style={styles.link} to={`/instances/${publicId}`}>
          <Typography
            variant='body2'
            component='span'
            noWrap
            sx={styles.baseTypoStyle}
          >
            {publicId}
          </Typography>
        </Link>
      ),
    },
  ];

  return (
    <CustomDataGrid
      rows={damageReports}
      columns={initColumns}
      rowCount={total}
      pageSize={pageSize}
      loading={loading}
      hideSpaceRow
      onPageChange={onChangePage}
      headerHeight={40}
      rowHeight={40}
      pagination
      paginationMode='server'
      hideFooterPagination={false}
      page={page}
      blockStyleSx={styles.blockStyleClass}
    />
  );
};

export default DamageReportTable;
