import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { useSnackbarRef } from '../utils/GlobalSnackbarUtil';

class API {
  constructor({ baseURL }) {
    this.cache = setupCache({
      maxAge: 1000 * 60 * 10,
      limit: 10,
      invalidate: async (cfg, req) => {
        const method = req.method.toLowerCase();
        if (method !== 'get') {
          await cfg.store.clear(); // simply totally clear the cache if user made changes
        }
      },
    });

    this.api = axios.create({
      adapter: this.cache.adapter,
      baseURL,
      responseType: 'json',
      timeout: 70000,
    });

    this.api.interceptors.response.use(
      (response) => response.data,
      (error) => {
        if (error.response?.status === 408) {
          useSnackbarRef.enqueueSnackbar('Server timed out', {
            variant: 'error',
          });
        }
        if (error.code === 'ECONNABORTED') {
          useSnackbarRef.enqueueSnackbar(
            'Call timeout exceeded. Please try again',
            { variant: 'error' },
          );
        }
        if (error.response) {
          const { data, headers, status, config } = error.response;
          const { url, method, baseURL } = config;
          return Promise.reject({
            data,
            headers,
            status,
            url,
            method,
            baseURL,
          });
        } else {
          return Promise.reject({
            message: error.message,
            name: error.name,
            unknown: true,
          });
        }
      },
    );
  }

  setToken(token) {
    this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
}

export default API;
