export default {
  billing_email: {
    label: 'Billing email',
    columns: ['F E-postadress'],
  },
  products: {
    label: 'Products',
    columns: ['Produkt(er)', 'Hyrespris(er)'],
  },
  order_delivery_date: {
    label: 'Delivery date',
    columns: ['Leveransdatum'],
  },
  billing_first_name: {
    label: 'Billing first name',
    columns: ['F Förnamn'],
  },
  billing_last_name: {
    label: 'Billing last name',
    columns: ['F Efternamn'],
  },
  billing_company: {
    label: 'Billing company name',
    columns: ['Företag'],
  },
  billing_address_1: {
    label: 'Billing address 1',
    columns: ['F Adress 1'],
  },
  billing_address_2: {
    label: 'Billing address 2',
    columns: ['F Adress 2'],
  },
  billing_postcode: {
    label: 'Billing postcode',
    columns: ['F Postnummer'],
  },
  billing_city: {
    label: 'Billing city',
    columns: ['F Postort'],
  },
  billing_phone: 'Billing phone',
  shipping_first_name: {
    label: 'Shipping first name',
    columns: ['Förnamn'],
  },
  shipping_last_name: {
    label: 'Shipping last name',
    columns: ['Efternamn'],
  },
  shipping_address_1: {
    label: 'Shipping address 1',
    columns: ['Adress 1'],
  },
  shipping_address_2: {
    label: 'Shipping address 2',
    columns: ['Adress 2'],
  },
  shipping_postcode: {
    label: 'Shipping postcode',
    columns: ['Postnummer'],
  },
  shipping_city: {
    label: 'Shipping city',
    columns: ['Postort'],
  },
  billing_customer_invoice_reference: {
    label: 'Reference',
    columns: ['Fakturareferens'],
  },
  billing_organisation_number: {
    label: 'Organisation number',
    columns: ['Organisationsnummer'],
  },
  shipping_cost: {
    label: 'Shipping cost',
    columns: ['Fraktkostnad exkl. moms'],
  },
  cost_center: {
    label: 'Cost center',
    columns: ['Kostnadsställe'],
  },
  shipping_email: {
    label: 'Shipping email',
    columns: ['E-postadress'],
  },
  shipping_phone: 'Billing phone',
  payment_method: {
    label: 'Payment method',
    columns: ['Betalmetod'],
  },
  commitment_period: {
    label: 'Commitment period',
    columns: ['Bindningstid'],
  },
};
