import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Typography,
  Hidden,
  Collapse,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@mui/icons-material';
import { volumeUnitConverter } from '../../utils';
import TransportProductInstancesTable from '../../containers/TransportDetails/components/TransportProductInstancesTable';
import { getLocationContainerColor } from '../../containers/TransportDetails/helpers';
import ImageLoader from '../ImageLoader';
import NoItemsFound from './noItemsPlaceholder';

const useStyles = makeStyles((theme) => ({
  listHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: 32,

    '& > p': {
      flex: 1,
    },

    '& > p:nth-child(1)': {
      minWidth: 160,
      flexBasis: '35%',
      marginLeft: '-32px',
    },
    '& > p:nth-child(2)': {
      minWidth: 100,
      flexBasis: '20%',
      textAlign: 'center',
    },
    '& > p:nth-child(3)': {
      minWidth: 100,
      flexBasis: '12.5%',
      textAlign: 'center',
    },
    '& > p:nth-child(4)': {
      minWidth: 100,
      flexBasis: '12.5%',
      textAlign: 'center',
    },
    '& > p:nth-child(5)': {
      minWidth: 100,
      flexBasis: '20%',
      textAlign: 'right',
    },
  },
  headerName: {
    fontWeight: 500,
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 12,
    color: '#DA4453',
    textTransform: 'uppercase',
    flex: 1,
  },
  instanceInfo: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  image: {
    width: 50,
    height: 50,
    marginRight: 10,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Helvetica Neue, sans-serif',
    fontWeight: 500,
    height: 50,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: 12,
      textAlign: 'start',
    },
  },
  accordionRoot: {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    margin: '0!important',

    '&:before': {
      opacity: '1!important',
    },
  },
  expandIcon: {
    margin: '0 0 0 -12px',
    position: 'absolute',
    left: 0,

    [theme.breakpoints.down('sm')]: {
      margin: '0 0 0 5px',
    },
  },
  summaryRoot: {
    flexDirection: 'row-reverse',
    padding: '12px 0',
    minHeight: 'unset!important',
  },
  summaryContent: {
    margin: '0!important',
    display: 'flex',
    paddingLeft: 8,

    '& > div': {
      flex: 1,
    },

    '& > div:nth-child(1)': {
      minWidth: 160,
      flexBasis: '35%',

      [theme.breakpoints.down('sm')]: {
        flexBasis: '75%',
      },
    },
    '& > div:nth-child(2)': {
      minWidth: 100,
      flexBasis: '20%',

      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset',
        textAlign: 'right',
        paddingRight: 16,
      },
    },
    '& > div:nth-child(3)': {
      minWidth: 100,
      flexBasis: '12.5%',
    },
    '& > div:nth-child(4)': {
      minWidth: 100,
      flexBasis: '12.5%',
    },
    '& > div:nth-child(5)': {
      minWidth: 100,
      flexBasis: '20%',
      textAlign: 'right',
    },

    [theme.breakpoints.down('sm')]: {
      margin: '0',
      paddingLeft: 40,
    },
  },
  detailsRoot: {
    padding: 0,
    transition: 'all .25s linear',
    width: '100%',

    '& > div': {
      boxShadow: 'none',
    },

    '& .MuiDataGrid-row:nth-of-type(2n+1)': {
      backgroundColor: '#F8F9FA',
    },

    '& .MuiDataGrid-columnHeaders': {
      color: 'rgb(85, 85, 85)',
    },
  },
  textItem: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  locationItem: {
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 12,
    height: 32,
    marginTop: 9,
    minWidth: 32,
    borderRadius: 16,
    padding: '0 16px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  },
}));

const TransportInstanceExpandedTable = ({ data, isLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (isEmpty(data) && !isLoading) return <NoItemsFound />;

  return (
    <>
      <Hidden smDown>
        <div className={classes.listHeader}>
          <Typography className={classes.headerName}>
            {t('Products')}
          </Typography>
          <Typography className={classes.headerName}>{t('SKU')}</Typography>
          <Typography className={classes.headerName}>{t('Volume')}</Typography>
          <Typography className={classes.headerName}>{t('Amount')}</Typography>
          <Typography className={classes.headerName}>
            {t('Location')}
          </Typography>
        </div>
      </Hidden>
      {data.map(
        ({
          title,
          thumbnail_image: thumbnailImage,
          instances,
          productId,
          sku,
          warehousePlacement,
        }) => (
          <Accordion
            key={title}
            square
            classes={{
              root: classes.accordionRoot,
            }}
            TransitionComponent={Collapse}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              classes={{
                root: classes.summaryRoot,
                content: classes.summaryContent,
                expandIconWrapper: classes.expandIcon,
              }}
            >
              <div className={classes.instanceInfo}>
                <Typography variant='body2' className={classes.title}>
                  {title}
                </Typography>
                <ImageLoader
                  src={thumbnailImage}
                  alt='instance'
                  className={classes.image}
                />
              </div>
              <Hidden smDown>
                <div>
                  <Link
                    variant='body2'
                    to={`/products/${productId}`}
                    className={classes.textItem}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      fontFamily: 'Helvetica Neue, sans-serif',
                      fontSize: 12,
                    }}
                  >
                    {sku}
                  </Link>
                </div>
                <div>
                  <Typography variant='body2' className={classes.textItem}>
                    {`${volumeUnitConverter(
                      instances.reduce((acc, item) => acc + item.volume, 0),
                    )} m`}
                    <sup style={{ verticalAlign: 'top' }}>3</sup>
                  </Typography>
                </div>
                <div>
                  <Typography variant='body2' className={classes.textItem}>
                    {instances.length}
                  </Typography>
                </div>
              </Hidden>
              <div>
                <div
                  className={classes.locationItem}
                  style={{
                    backgroundColor: getLocationContainerColor(instances),
                  }}
                >
                  {`${warehousePlacement?.aisle || ''}${
                    warehousePlacement?.aisle || warehousePlacement?.shelf
                      ? ' '
                      : '?'
                  }${warehousePlacement?.shelf || ''}`}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: classes.detailsRoot,
              }}
            >
              <TransportProductInstancesTable instances={instances} />
            </AccordionDetails>
          </Accordion>
        ),
      )}
    </>
  );
};

TransportInstanceExpandedTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default TransportInstanceExpandedTable;
