import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { Trans } from 'react-i18next';

const useStyles = makeStyles({
  logItemContainer: {
    marginBottom: 16,

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  logItemBubble: ({ bubbleColor }) => ({
    position: 'relative',
    width: '100%',
    padding: '8px 12px',
    borderRadius: 8,
    backgroundColor: bubbleColor,
    marginBottom: 24,

    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: -18,
      left: 18,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '18px 18px 0 0',
      borderColor: `${bubbleColor} transparent transparent transparent`,
    },
  }),
  logItemInfo: {
    color: '#555555',
  },
});

const ChangelogBubble = ({
  changeLogMessage = [],
  updatedAt,
  updatedBy,
  bubbleColor = '#ececec',
}) => {
  const classes = useStyles({ bubbleColor });
  return (
    <div className={classes.logItemContainer}>
      <div className={classes.logItemBubble}>
        {changeLogMessage.length > 0 &&
          changeLogMessage.map((message, index) => (
            <Fragment key={message}>
              <Typography
                variant='body2'
                dangerouslySetInnerHTML={{ __html: message }}
              />
              {index < changeLogMessage.length - 1 ? <hr /> : null}
            </Fragment>
          ))}
      </div>
      <Typography variant='body2' className={classes.logItemInfo}>
        <Trans
          i18nKey='{{date}} by {{author}}'
          values={{
            date: moment(updatedAt).format('DD MMM, YYYY [kl.] HH:mm'),
            author: '<i>{{updatedBy}}</i>',
            updatedBy,
          }}
        />
      </Typography>
    </div>
  );
};

ChangelogBubble.propTypes = {
  changeLogMessage: PropTypes.array,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  bubbleColor: PropTypes.string,
};

export default ChangelogBubble;
