import { useContext } from 'react';
import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ProductView from './components/ProductView';
import StorageCard from './components/Storage';
import DimensionsCard from './components/Dimensions';
import RelatedInstances from './components/RelatedInstacesList';
import decode from '../../utils/htmlDecode';
import ProductDetailsProvider, {
  ProductDetailsContext,
} from '../../context/ProductDetails/ProductDetailsContext';

const ProductDetailsContent = () => {
  const { t } = useTranslation();
  const { productData } = useContext(ProductDetailsContext);

  return (
    <>
      <Helmet>
        <title>{`${t('Product')} ${decode(productData?.title)}`}</title>
      </Helmet>
      <Grid container direction='row' alignItems='stretch' spacing={2}>
        <Grid item xl={9} md={8} xs={12}>
          <ProductView />
          <RelatedInstances />
        </Grid>
        <Grid item xl={3} md={4} xs={12}>
          <DimensionsCard />
          <StorageCard />
        </Grid>
      </Grid>
    </>
  );
};

const ProductDetails = () => {
  return (
    <ProductDetailsProvider>
      <ProductDetailsContent />
    </ProductDetailsProvider>
  );
};

export default ProductDetails;
