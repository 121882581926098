import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CustomDataGrid from '../../../../../components/CustomDataGrid';
import VisibilityComponent from '../../../../Instances/components/VisibilityComponent';
import AvailabilityComponent from '../../../../Instances/components/AvailabilityComponent';
import useAvailabilityHelper from '../../../../../utils/useAvailabilityHelper';
import { WAREHOUSE_STATUS } from '../../../../../utils/constants';

const InstanceTable = ({
  handlePageChange,
  loading,
  items,
  total,
  pageSize,
}) => {
  const { t, i18n } = useTranslation();
  const { availabilityMap } = useAvailabilityHelper(
    items.map(({ publicId }) => publicId),
  );

  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      items.map((item) => ({
        ...item,
        availability: availabilityMap[item.publicId],
      })),
    );
  }, [items, availabilityMap]);

  const initColumns = useMemo(
    () => [
      {
        headerName: t('Instance id'),
        field: 'publicId',
        minWidth: 170,
        flex: 2,
        sortable: false,
        renderCell: ({ row }) => (
          <Link
            variant='body2'
            style={{
              fontSize: 12,
              fontWeight: 600,
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            to={`/instances/${row.publicId}`}
          >
            {row.publicId}
          </Link>
        ),
      },
      {
        headerName: t('Storage'),
        field: 'state',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography variant='body2'>
            {row.location.title || t('N/A')}
          </Typography>
        ),
      },
      {
        headerName: t('Status'),
        field: 'warehouseStatus',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography variant='body2' style={{ textTransform: 'uppercase' }}>
            {t(WAREHOUSE_STATUS[row.warehouseStatus]?.label) || t('N/A')}
          </Typography>
        ),
      },
      {
        headerName: t('Visibility'),
        field: 'isVisible',
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <VisibilityComponent item={row} />,
      },
      {
        headerName: t('Availability'),
        field: 'invisibilityReason',
        align: 'center',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <AvailabilityComponent item={row} />,
      },
      {
        headerName: ' ',
        field: '',
        align: 'right',
        width: 50,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <Link to={`/instances/${row.publicId}`}>
              <IconButton size='small'>
                <CreateOutlinedIcon />
              </IconButton>
            </Link>
          );
        },
      },
    ],
    [i18n.language],
  );

  return (
    <CustomDataGrid
      columns={initColumns}
      rows={rows}
      loading={loading}
      onPageChange={handlePageChange}
      rowCount={total}
      pageSize={pageSize}
      headerHeight={40}
      rowHeight={40}
      pagination
      paginationMode='server'
      hideFooterPagination={false}
    />
  );
};

InstanceTable.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  loading: PropTypes.bool,
  items: PropTypes.array,
  total: PropTypes.number,
  pageSize: PropTypes.number,
};

export default InstanceTable;
