import { IconButton, Typography, Button } from '@mui/material';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  filterIcon: {
    backgroundColor: '#ECECEC!important',
    padding: 7,
  },
  subTitle: {
    textTransform: 'uppercase',
    paddingTop: 5,
    letterSpacing: '0.5px',
    color: '#8B8B8B',
    fontSize: 10,
  },
  titleText: {
    fontSize: 18,
    textTransform: 'uppercase',
    letterSpacing: '0.9px',
    fontWeight: 200,
  },
  mobileHeaderBox: {
    borderTop: '2px solid #EBEBEB',
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  createNewOrderButton: {
    marginLeft: 10,
    marginRight: 20,
    padding: '4px 15px',
    color: 'white',
    backgroundColor: '#DA4453',
    '&:hover': {
      backgroundColor: '#DA4453',
    },
  },
  actionsBlock: {
    display: 'flex',
  },
}));

const MobileHeaderBox = ({
  title = '',
  subtitle = '',
  addFuncText = '',
  addFunc = null,
  showFilters = null,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.mobileHeaderBox}>
      <div>
        <Typography variant={'body2'} className={classes.titleText}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant={'body2'} className={classes.subTitle}>
            {subtitle}
          </Typography>
        )}
      </div>
      <div className={classes.actionsBlock}>
        {addFuncText && (
          <Button
            variant='contained'
            size='small'
            className={classes.createNewOrderButton}
            onClick={() => {
              addFunc();
            }}
          >
            {addFuncText}
          </Button>
        )}
        <IconButton
          className={classes.filterIcon}
          onClick={() => {
            showFilters((prev) => !prev);
          }}
          size='large'
        >
          <FilterListIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default MobileHeaderBox;
