import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import decode from '../../utils/htmlDecode';
import ImageLoader from '../../components/ImageLoader';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
  },
  image: {
    width: 35,
    height: 35,
    marginRight: 8,
  },
  title: {
    width: '80%',
  },
});

const Thumb = ({ data }) => {
  const classes = useStyles();

  const { image, thumbnail_image, title } = data;

  return (
    <div className={classes.container}>
      <ImageLoader
        src={thumbnail_image || image}
        alt='blc'
        className={classes.image}
      />
      <Tooltip title={decode(title)} enterDelay={300}>
        <Typography variant='body2' noWrap className={classes.title}>
          {decode(title)}
        </Typography>
      </Tooltip>
    </div>
  );
};

export default Thumb;
