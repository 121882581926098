import StorageIcon from '@mui/icons-material/Storage';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ProductsIcon from '../../assets/icons/menu/Icon_white_products.png';
import ProductsIconActive from '../../assets/icons/menu/Icon_red_products.png';
import InstancesIcon from '../../assets/icons/menu/Icon_white_instances.png';
import InstancesIconActive from '../../assets/icons/menu/Icon_red_instances.png';
import WOIcon from '../../assets/icons/menu/Icon_white_wo.png';
import WOIconActive from '../../assets/icons/menu/Icon_red_wo.png';
import TransportsIcon from '../../assets/icons/menu/Icon_white_transports.png';
import TransportsIconActive from '../../assets/icons/menu/Icon_red_transports.png';
import OtherIcon from '../../assets/icons/menu/Icon_white_settings.png';
import OtherIconActive from '../../assets/icons/menu/Icon_red_settings.png';

export const MENU_ITEMS = {
  DASHBOARD: {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardIcon style={{ color: 'white', fontSize: 31 }} />,
    activeIcon: <DashboardIcon style={{ color: '#da4453', fontSize: 31 }} />,
  },
  PRODUCTS: {
    title: 'Products',
    path: '/products',
    icon: <img src={ProductsIcon} alt='Products' />,
    activeIcon: <img src={ProductsIconActive} alt='Products' />,
  },
  BOOKINGS: {
    title: 'Booking',
    path: '/bookings',
    icon: <img src={TransportsIcon} alt='Bookings list' />,
    activeIcon: <img src={TransportsIcon} alt='Bookings list' />,
  },
  STORAGE: {
    title: 'Storage',
    path: '/storage',
    icon: <StorageIcon />,
  },
  INSTANCES: {
    title: 'Instances',
    path: '/instances',
    icon: <img src={InstancesIcon} alt='Instances list' />,
    activeIcon: (
      <img
        src={InstancesIconActive}
        alt='Instances list'
        style={{ width: 40 }}
      />
    ),
  },
  WAREHOUSE_DELIVERIES: {
    title: 'Warehouse deliveries',
    path: '/warehouse-orders',
    icon: <img src={WOIcon} alt='Warehouse deliveries' />,
    activeIcon: <img src={WOIconActive} alt='Warehouse deliveries' />,
  },
  TRANSPORTS: {
    title: 'Transports',
    path: '/transports',
    icon: <img src={TransportsIcon} alt='Transports list' />,
    activeIcon: <img src={TransportsIconActive} alt='Transports list' />,
  },
  PAYOUTS: {
    title: 'Payouts',
    path: '/payouts',
    icon: <ReceiptIcon style={{ color: '#ffffff', fontSize: 28 }} />,
    activeIcon: <ReceiptIcon style={{ color: '#DA4453', fontSize: 28 }} />,
  },
  OTHER: {
    title: 'Other tools',
    path: '/other-tools',
    icon: <img src={OtherIcon} alt='Other tools' />,
    activeIcon: <img src={OtherIconActive} alt='Other tools' />,
  },
};

export const ADMIN_MENU = [
  MENU_ITEMS.DASHBOARD,
  MENU_ITEMS.PRODUCTS,
  MENU_ITEMS.INSTANCES,
  MENU_ITEMS.WAREHOUSE_DELIVERIES,
  MENU_ITEMS.TRANSPORTS,
  MENU_ITEMS.PAYOUTS,
  MENU_ITEMS.OTHER,
];

export const WO_WORKER_MENU = [
  MENU_ITEMS.DASHBOARD,
  MENU_ITEMS.PRODUCTS,
  MENU_ITEMS.INSTANCES,
  MENU_ITEMS.WAREHOUSE_DELIVERIES,
  MENU_ITEMS.TRANSPORTS,
];

export const TRANSPORT_CARRIER_MENU = [MENU_ITEMS.TRANSPORTS];
