import { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomDataGrid from '../../../components/CustomDataGrid';
import TransportInstanceSelectionCell from './TransportInstanceSelectionCell';
import TransportInstanceInfoCell from './TransportInstanceInfoCell';
import TransportInstanceActions from './TransportInstanceActions';
import { getActionsColumnTitle, getConditionIcon } from '../helpers';
import i18n from '../../../localization';
import { TransportDetailsContext } from '../../../context/TransportDetails/TransportDetailsContext';

const getActionColumns = (status, instances, isMobile) => {
  if (status === 'CREATED') return [];

  if (['PROCESSING', 'IN_TRANSIT'].includes(status)) {
    return [
      {
        headerName: i18n.t(getActionsColumnTitle(status, instances)),
        field: 'transportInstances',
        sortable: false,
        flex: isMobile ? 1 : 0.5,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ row }) => <TransportInstanceActions {...row} />,
      },
    ];
  }

  if (status === 'READY') {
    return [
      {
        headerName: i18n.t('Packed'),
        field: 'instance.transportInstances.isPacked',
        sortable: false,
        flex: isMobile ? 1 : 0.5,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ row }) =>
          getConditionIcon(row.transportInstances.isPacked),
      },
    ];
  }

  return [
    {
      headerName: i18n.t('Packed'),
      field: 'instance.transportInstances.isPacked',
      sortable: false,
      flex: isMobile ? 1 : 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) =>
        getConditionIcon(row.transportInstances.isPacked),
    },
    {
      headerName: isMobile ? i18n.t('Del.') : i18n.t('Delivered'),
      field: 'instance.transportInstances.isCompleted',
      sortable: false,
      flex: isMobile ? 1 : 0.5,
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ row }) =>
        getConditionIcon(row.transportInstances.isCompleted),
    },
  ];
};

const createColumns = (
  transportType,
  orderId,
  from,
  status,
  instances,
  isMobile,
) => [
  {
    headerName: i18n.t('ID'),
    field: 'publicId',
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <TransportInstanceSelectionCell
        transportType={transportType}
        orderId={orderId}
        from={from}
        {...row}
      />
    ),
  },
  {
    headerName: i18n.t('Info'),
    field: 'isMountingRequired',
    sortable: false,
    flex: isMobile ? 1 : 2,
    renderCell: ({ row }) => <TransportInstanceInfoCell {...row} />,
  },
  ...getActionColumns(status, instances, isMobile),
];

const TransportProductInstancesTable = ({ instances }) => {
  const { i18n: i18nLocal } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const { transport } = useContext(TransportDetailsContext);

  const columns = useMemo(() => {
    return createColumns(
      transport.type,
      transport.orderId,
      transport.from,
      transport.status,
      instances,
      isMobile,
    );
  }, [transport.status, instances, i18nLocal.language]);

  return (
    <CustomDataGrid
      columns={columns}
      rows={instances}
      pagination={false}
      hideFooterPagination
      rowHeight={37}
      headerHeight={37}
      pageSize={100}
    />
  );
};

TransportProductInstancesTable.propTypes = {
  instances: PropTypes.array,
};

export default TransportProductInstancesTable;
