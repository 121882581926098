import React from 'react';
import { useSnackbar } from 'notistack';

let useSnackbarRef;

const GlobalSnackbarUtil = () => {
  useSnackbarRef = useSnackbar();

  return null;
};

export { GlobalSnackbarUtil, useSnackbarRef };
