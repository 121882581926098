import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const initialValues = {
  username: '',
  password: '',
};

const validationSchema = yup.object().shape({
  username: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'grid',
    gridTemplateRows: '2fr 4fr',
    width: '330px',
  },
  logoBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'black',
    opacity: '0.7',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 40px 40px ',
    backgroundColor: '#F5F3ED',
    width: '100%',
  },
  inputField: {
    marginBottom: '10px',
    backgroundColor: 'transparent',
    borderRadius: '10px',

    '& input': {
      backgroundColor: 'white',
    },
  },
  logo: {
    fontFamily: 'sequel',
    textTransform: 'uppercase',
    color: 'white',
    textAlign: 'center',
  },

  title: {
    fontWeight: 'bold',
    fontSize: '36px',
    marginBottom: '5px',
  },

  loginButton: {
    maxWidth: '100%',
    textTransform: 'unset !important',
  },
  text: {
    margin: '10px auto',
    color: '#787878',
  },
  contentContainer: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundAnimation: {
    width: '100%',
    height: '100vh',
    position: 'absolute',
    objectFit: 'cover',
    zIndex: 0,
    right: 0,
    bottom: 0,
    left: 0,
    top: 0,
  },
});

const LoginForm = ({ isLoading, onSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.contentContainer}>
      <video autoPlay muted loop className={classes.backgroundAnimation}>
        <source
          src='https://storage.googleapis.com/beleco_assets/admin-background-animation.mov'
          type='video/mp4'
        />
      </video>

      <div className={classes.container}>
        <div className={classes.logoBlock}>
          <Typography className={`${classes.title} ${classes.logo}`}>
            beleco
          </Typography>
          <Typography className={classes.logo}>
            {t('Inventory service')}
          </Typography>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit }) => (
              <form className={classes.loginForm} onSubmit={handleSubmit}>
                <Field name='username'>
                  {({ field, meta }) => (
                    <TextField
                      size='small'
                      variant='outlined'
                      className={classes.inputField}
                      label={t('Username or email')}
                      type='email'
                      error={meta.error}
                      helperText={meta.error || ''}
                      inputProps={{
                        type: 'email',
                      }}
                      {...field}
                    />
                  )}
                </Field>
                <Field name='password'>
                  {({ field, meta }) => (
                    <TextField
                      size='small'
                      variant='outlined'
                      className={classes.inputField}
                      type='password'
                      label={t('Password')}
                      error={meta.error}
                      helperText={meta.error || ''}
                      {...field}
                    />
                  )}
                </Field>
                <Button
                  style={{ textTransform: 'none' }}
                  type='submit'
                  variant='contained'
                  disabled={isLoading}
                  className={classes.loginButton}
                >
                  {t('Login')}
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};
export default LoginForm;
