import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';

const ShortPeriodChart = ({ data, formatLabel, tooltipFormatter }) => {
  // TODO: figure out how to stack graphs depending on higher dataKey value
  const isMoreAvailable = data.length
    ? data[0].qtyAvailable > data[0].qtyInvisible
    : false;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <ResponsiveContainer width='100%' aspect={2.5}>
      <AreaChart
        data={data}
        margin={{ top: 15, left: -25, bottom: 25, right: 20 }}
      >
        <CartesianGrid strokeDasharray='1 3' />
        <XAxis
          dataKey='date'
          angle={isMobile ? -90 : -50}
          textAnchor='end'
          dx={-5}
          style={{ fontSize: isMobile ? 10 : 12 }}
          interval={0}
        />
        <YAxis allowDecimals={false} style={{ fontSize: isMobile ? 10 : 12 }} />
        <Tooltip labelFormatter={formatLabel} formatter={tooltipFormatter} />
        <Area
          type='monotone'
          dataKey='qtyFull'
          stroke='#b8b8b8'
          strokeWidth={0}
          fill='#dcdcdc'
          fillOpacity='0.6'
        />
        {isMoreAvailable
          ? [
              <Area
                type='monotone'
                dataKey='qtyAvailable'
                stroke='#85CB85'
                strokeWidth={0}
                fill='#85CB85'
                fillOpacity='0.9'
              />,
              <Area
                type='monotone'
                dataKey='qtyInvisible'
                stroke='#FD5D5D'
                strokeWidth={0}
                fill='#FD5D5D'
                fillOpacity='1'
              />,
            ]
          : [
              <Area
                type='monotone'
                dataKey='qtyInvisible'
                stroke='#FD5D5D'
                strokeWidth={0}
                fill='#FD5D5D'
                fillOpacity='0.9'
              />,
              <Area
                type='monotone'
                dataKey='qtyAvailable'
                stroke='#85CB85'
                strokeWidth={0}
                fill='#85CB85'
                fillOpacity='1'
              />,
            ]}
      </AreaChart>
    </ResponsiveContainer>
  );
};

ShortPeriodChart.propTypes = {
  data: PropTypes.array,
  formatLabel: PropTypes.func,
  tooltipFormatter: PropTypes.func,
};

export default ShortPeriodChart;
