import { useState, useEffect } from 'react';
import belecoApi from '../../../../../api';

const useInstances = (productId) => {
  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInstances = async () => {
      if (!productId) return;

      try {
        const data = await belecoApi.inv.getInstances({
          limit: 10000,
          search: productId,
        });
        setInstances(data?.rows);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchInstances();
  }, [productId]);

  return { instances, loading, error };
};

export default useInstances;
