import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography, Divider, Box, TextField, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Close from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { isEmpty, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import decode from '../../../../utils/htmlDecode';
import { ProductListAddedToOrder } from '../../styles';

const amountControlsStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 4,
  },
  controlIcon: {
    cursor: 'pointer',
    fontSize: 13,
    '&:hover': {
      backgroundColor: '#CCC',
    },
  },
};

const AlertMessageBox = ({ text }) => {
  return (
    <Box style={{ padding: 10, margin: '10px 0px' }}>
      <Typography variant='h5' style={{ color: '#E3757F' }}>
        {text}
      </Typography>
    </Box>
  );
};

AlertMessageBox.propTypes = {
  text: PropTypes.string.isRequired,
};

const AmountControls = withStyles(amountControlsStyles)(
  ({ classes, increaseCallback, decreaseCallback }) => {
    return (
      <div className={classes.container}>
        <KeyboardArrowUpIcon
          className={classes.controlIcon}
          onClick={increaseCallback}
        />
        <KeyboardArrowDownIcon
          className={classes.controlIcon}
          onClick={decreaseCallback}
        />
      </div>
    );
  },
);

const useStyles = makeStyles(ProductListAddedToOrder);

const ProductsToAdd = ({
  items,
  addProductToOrder,
  removeProductFromOrder,
  isNewOrder,
  editMode,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [allowEditing, setAllowEditing] = useState(false);

  useEffect(() => {
    setAllowEditing(isNewOrder || editMode);
  }, [isNewOrder, editMode]);

  const addProductHandler = (item, number = null) => {
    const { productId, sku, title, id, image } = item;
    const relevantId = isNil(productId) ? id : productId;
    addProductToOrder({ productId: relevantId, sku, title, image, number });
  };

  const handleDirectQtyChange = (e, item) => {
    const inputNumber = Number(e.target.value.replace(/\D/g, ''));
    if (inputNumber > 0 && inputNumber < 300) {
      addProductHandler(item, inputNumber);
    } else {
      e.preventDefault();
    }
  };

  const getImageUrlForListItem = (itemId) => {
    return items[itemId].image;
  };

  return (
    <div>
      <div>
        <div
          className={`${classes.tableColumnsHeaderRow} ${
            allowEditing ? '' : classes.tableColumnsHeaderRowExpanded
          } `}
        >
          <Typography className={classes.listHeader} variant='body2'>
            {t('Selected')}
          </Typography>
          <Typography className={classes.listHeader} variant='body2'>
            {t('Amount')}
          </Typography>
        </div>
        <Divider style={{ height: 2 }} />
      </div>
      <div>
        {isEmpty(items) && <AlertMessageBox text={t('No items added yet')} />}
        {Object.keys(items).map((itemId) => (
          <div
            key={`product-to-add-key-${itemId}`}
            className={`${classes.productListItem} ${
              allowEditing ? '' : classes.listItemExpanded
            }`}
          >
            <div className={classes.imageColumn}>
              <img src={getImageUrlForListItem(itemId)} alt='' />
            </div>

            <div className={classes.titleColumn}>
              <Typography
                variant='body2'
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {decode(items[itemId].title)}
              </Typography>
              <Link variant='body2' to={`/products/${itemId}`}>
                {items[itemId].sku}
              </Link>
            </div>

            <div className={classes.amountControls}>
              <TextField
                type='tel'
                variant='outlined'
                style={{
                  padding: '0px',
                }}
                disabled={!allowEditing}
                onChange={(e) => {
                  handleDirectQtyChange(e, items[itemId]);
                }}
                value={items[itemId].qty}
              />

              {allowEditing && (
                <AmountControls
                  increaseCallback={() => {
                    addProductHandler(items[itemId]);
                  }}
                  decreaseCallback={() => {
                    removeProductFromOrder(itemId);
                  }}
                />
              )}
            </div>

            {allowEditing && (
              <div className={classes.actionButtonColumn}>
                <Button
                  variant='contained'
                  onClick={() => {
                    removeProductFromOrder(itemId, true);
                  }}
                >
                  <Close
                    style={{
                      fontSize: 20,
                      cursor: 'pointer',
                      color: '#fff',
                    }}
                  />
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

ProductsToAdd.propTypes = {
  items: PropTypes.array,
  addProductToOrder: PropTypes.func.isRequired,
  removeProductFromOrder: PropTypes.func.isRequired,
  isNewOrder: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  editMode: PropTypes.bool.isRequired,
};

export default ProductsToAdd;
