import moment from 'moment';
import { capitalize, isNil } from 'lodash';
import i18n from '../../localization';
import { TRANSPORTING_STATUS_OPTIONS } from '../../utils/constants';

const transportChangelogMap = {
  NOTE: {
    getTemplate: (oldValues, { note }) =>
      `${i18n.t('Note added')}: </br><b>${note}</b>`,
  },
  STATUS: {
    getTemplate: ({ status: oldStatus }, { status }) =>
      `${i18n.t('Transport status')}: </br><b>${i18n.t(
        TRANSPORTING_STATUS_OPTIONS[oldStatus]?.label,
      )}</b> → <b>${i18n.t(TRANSPORTING_STATUS_OPTIONS[status]?.label)}</b>`,
  },
  TMS_ID: {
    getTemplate: ({ tms_id: oldTmsId }, { tms_id: newTmsId }) =>
      `${i18n.t(
        'Transport provider',
      )}: </br><b>${oldTmsId}</b> → <b>${newTmsId}</b>`,
  },
  ADDRESS: {
    getTemplate: (oldValues, newValues) => {
      let addressString = `${i18n.t('Address updated')}: `;

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(newValues)) {
        // eslint-disable-next-line no-continue
        if (key === 'updated_at') continue;

        addressString += `</br><b>${i18n.t(
          capitalize(key.replaceAll('_', ' ')),
        )}</b>: ${value} `;
      }

      return addressString;
    },
  },
  FAILED_DELIVERY: {
    getTemplate: () => i18n.t('Transport marked as failed delivery'),
    bubbleColor: '#f8d7da',
  },
  TIMEFRAME: {
    getTemplate: ({ from: oldFrom, to: oldTo }, { from, to }) => {
      const formatString = moment(oldTo).isSame(to, 'day')
        ? 'HH:mm'
        : 'YYYY-MM-DD HH:mm';

      return `${i18n.t('Timeframe updated')}: </br><b>${moment(oldFrom).format(
        formatString,
      )}-${moment(oldTo).format('HH:mm')}</b> → <b>${moment(from).format(
        formatString,
      )}-${moment(to).format('HH:mm')}</b>`;
    },
  },
  ID: {
    getTemplate: (
      { instance_id: oldInstanceId },
      { instance_id: newInstanceId },
    ) =>
      `${i18n.t(
        'Content of transport',
      )}: </br><b>${oldInstanceId}</b> → <b>${newInstanceId}</b>`,
  },
  INSTANCE_REMOVED_FROM_TRANSPORT: {
    getTemplate: ({ instance_id: oldInstanceId }) =>
      `${i18n.t('Instance')} <b>${oldInstanceId}</b> ${i18n.t(
        'removed from transport',
      )}`,
  },
};

const ADDRESS_CHANGE_FIELDS = [
  'name',
  'address_line_1',
  'address_line_2',
  'zip_code',
  'city',
  'phone',
  'company_name',
];

const getTransportChangelogItem = (oldValue, newValue) => {
  switch (true) {
    case isNil(newValue) && !isNil(oldValue.instance_id):
      return transportChangelogMap.INSTANCE_REMOVED_FROM_TRANSPORT;
    case newValue.is_no_show === 't':
      return transportChangelogMap.FAILED_DELIVERY;
    case !!oldValue?.status && !!newValue.status:
      return transportChangelogMap.STATUS;
    case !!oldValue?.tms_id && !!newValue.tms_id:
      return transportChangelogMap.TMS_ID;
    case Object.keys(newValue).some((key) =>
      ADDRESS_CHANGE_FIELDS.includes(key),
    ):
      return transportChangelogMap.ADDRESS;
    case !!newValue.from && !!newValue.to:
      return transportChangelogMap.TIMEFRAME;
    case !!oldValue?.instance_id && !!newValue.instance_id:
      return transportChangelogMap.ID;
    case !!newValue.note:
      return transportChangelogMap.NOTE;
    default:
      return null;
  }
};

export default getTransportChangelogItem;
