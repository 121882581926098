import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useNotificator from '../../utils/useNotificator';
import belecoApi from '../../api';

export const ChangelogContext = createContext({
  isLoading: false,
  isLoadingUsers: false,
  changelog: {},
});

const ChangelogProvider = ({ children }) => {
  const { notifyError } = useNotificator();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [changelog, setChangelog] = useState({});
  const [users, setUsers] = useState({});

  const getChangelog = async (apiCall, params = {}) => {
    setIsLoading(true);

    try {
      const data = await apiCall(params);

      setChangelog(data[0]);
    } catch (error) {
      notifyError(error, 'Failed to get changelog');
    } finally {
      setIsLoading(false);
    }
  };

  const getUsersByChangelog = async (logsArray) => {
    setIsLoadingUsers(true);

    let userIds = new Set(
      logsArray.map(({ updated_by: updatedBy }) =>
        updatedBy ? updatedBy.toString() : '',
      ),
    );
    userIds = [...userIds].filter(
      (id) => !Object.keys(users).includes(id) && id !== '',
    );

    if (userIds.length === 0) {
      setIsLoadingUsers(false);
      return;
    }

    try {
      const newUsers = await Promise.all(
        userIds.map((id) => belecoApi.wp.getUser(id)),
      );

      setUsers((prevUsers) => ({
        ...prevUsers,
        ...newUsers.reduce(
          (acc, { user }) => ({
            ...acc,
            [user.ID]: user,
          }),
          {},
        ),
      }));
    } catch (error) {
      notifyError(error, "Failed to get users' data");
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const returnValues = useMemo(
    () => ({
      isLoading,
      isLoadingUsers,
      changelog,
      users,
      getChangelog,
      getUsersByChangelog,
    }),
    [isLoading, isLoadingUsers, changelog, users],
  );

  return (
    <ChangelogContext.Provider value={returnValues}>
      {children}
    </ChangelogContext.Provider>
  );
};

ChangelogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChangelogProvider;
