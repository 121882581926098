import { isEmpty } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
  TRANSPORTATION_TYPE,
  TRANSPORTING_STATUS_OPTIONS,
  WAREHOUSE_STATUS,
} from '../../utils/constants';

export const getLocationContainerColor = (instances) => {
  if (!instances.transportInstances) return '#ececec';

  if (
    instances.every(({ transportInstances }) => transportInstances.isCompleted)
  ) {
    return '#a3d8a3';
  }

  if (
    instances.some(({ transportInstances }) => transportInstances.isCompleted)
  ) {
    return '#e6cf5f';
  }

  return '#ececec';
};

export const getProductWarehousePlacement = (
  { locationId, instances },
  productId,
) => {
  const { product } = instances.find(
    ({ productId: instanceProductId }) => instanceProductId === productId,
  );

  if (isEmpty(product.warehousePlacements)) {
    return {};
  }

  const productPlacement = product.warehousePlacements.find(
    ({ locationId: placementLocationId }) => placementLocationId === locationId,
  );

  return productPlacement
    ? { aisle: productPlacement.aisle, shelf: productPlacement.shelf }
    : {};
};

export const getConditionIcon = (condition) => {
  const containerStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 22,
    height: 22,
  };

  if (condition) {
    return (
      <div style={{ ...containerStyle, backgroundColor: '#A3D8A3' }}>
        <CheckIcon
          style={{
            color: '#ffffff',
            fontSize: 18,
          }}
        />
      </div>
    );
  }

  return (
    <div style={{ ...containerStyle, backgroundColor: '#E88C99' }}>
      <ClearIcon
        style={{
          color: '#ffffff',
          fontSize: 18,
        }}
      />
    </div>
  );
};

export const getActionsColumnTitle = (status, instances) => {
  if (status === 'PROCESSING') {
    if (
      instances.every(({ transportInstances }) => transportInstances.isPacked)
    ) {
      return 'Packed';
    }
  } else if (status === 'IN_TRANSIT') {
    if (
      instances.every(
        ({ transportInstances }) => transportInstances.isCompleted,
      )
    ) {
      return 'Completed';
    }
  }

  return 'Actions';
};

// return true if there is an issue with delivery or pickup transport statuses
// has special condition for completed transports
export const hasStatusIssue = ({ status, type }, warehouseStatus) => {
  const transportIsCompleted =
    status === TRANSPORTING_STATUS_OPTIONS.COMPLETED.value;

  const hasDeliveryIssue = () => {
    // If transport is completed and instances have different status than “AWAY“ then show warning
    if (transportIsCompleted) {
      return warehouseStatus !== WAREHOUSE_STATUS.AWAY.value;
    }
    return warehouseStatus !== WAREHOUSE_STATUS.IN_STORAGE.value;
  };

  const hasPickupIssue = () => {
    // If transport is completed and instances have different status than “IN_STORAGE“ then show warning
    if (transportIsCompleted) {
      return warehouseStatus !== WAREHOUSE_STATUS.IN_STORAGE.value;
    }
    return warehouseStatus !== WAREHOUSE_STATUS.AWAY.value;
  };

  if (type === TRANSPORTATION_TYPE.DELIVERY) return hasDeliveryIssue();
  return hasPickupIssue();
};
