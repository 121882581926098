import { createReducer, createAsyncThunk } from '@reduxjs/toolkit';
import belecoApi from '../../api';

export const getLocations = createAsyncThunk(
  'currentInstance/getLocations',
  async (payload, { rejectWithValue }) => {
    const requiredProperties = ['id', 'title', 'wms', 'zipCode'];
    const validateLocations = (locations) => {
      return locations.filter((row) =>
        requiredProperties.every((property) => row[property]),
      );
    };
    try {
      const { rows } = await belecoApi.inv.getLocations({});
      const locations = {};
      const activeLocations = {};
      const validLocations = validateLocations(rows);
      validLocations.forEach((item) => {
        locations[item.id] = item;
        if (item.isActive) {
          activeLocations[item.id] = item;
        }
      });
      return {
        locations,
        activeLocations,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const initialState = {
  loading: false,
  error: null,
  locationId: [],
  locations: {},
  activeLocations: {},
};

const locations = createReducer(initialState, {
  [getLocations.pending]: (state) => {
    state.loading = true;
  },
  [getLocations.fulfilled]: (state, action) => {
    const { locations, activeLocations } = action.payload;
    state.loading = false;
    state.locations = locations;
    state.activeLocations = activeLocations;
    state.error = null;
  },
  [getLocations.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload || action.error;
  },
});

export default locations;
