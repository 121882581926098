import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const FormInput = ({ children, ...formProps }) => {
  return (
    <TextField variant='outlined' disabled {...formProps}>
      {children}
    </TextField>
  );
};

FormInput.propTypes = {
  children: PropTypes.node,
};

export default FormInput;
