import { CircularProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';

const useStyles = makeStyles(({ palette }) => ({
  unavailableMark: {
    color: palette.title.highlighted,
    verticalAlign: 'middle',
  },
  availableMark: {
    color: '#269B62',
    verticalAlign: 'middle',
  },
}));

const AvailabilityComponent = ({ item: { availability }, isLoading }) => {
  const classes = useStyles();

  if (isLoading || !availability) {
    return <CircularProgress size={14} />;
  }

  const { nextAvailableDate, isAvailable } = availability;
  const returnUnavailable = () => {
    if (nextAvailableDate) {
      return <Typography variant='body2'>{nextAvailableDate}</Typography>;
    }
    return <ClearIcon className={classes.unavailableMark} />;
  };
  if (isAvailable) {
    return <CheckOutlinedIcon className={classes.availableMark} />;
  }
  return returnUnavailable();
};

AvailabilityComponent.propTypes = {
  item: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default AvailabilityComponent;
