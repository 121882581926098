import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TableCell,
  TableRow,
  Typography,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Field } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Datepicker from '../../Datepicker';
import ImagePicker from './ImagePicker';

const useStyles = makeStyles((theme) => ({
  heading: {
    color: '#555555',
    fontSize: 18,
    fontWeight: 500,
  },
  subtitle: {
    color: '#555555',
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 20,
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCell: {
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      border: 'none',
      width: '100vw',
      paddingBottom: 0,
    },
  },
  inputsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 30,

    '& > div': {
      flex: 1,

      '&:first-of-type': {
        marginRight: 18,
      },

      '&:last-of-type': {
        marginLeft: 18,
      },
    },

    '& .MuiSvgIcon-root': {
      top: 9,
    },

    '& .SingleDatePicker': {
      zIndex: theme.zIndex.snackbar,
      width: '100%',

      '& .SingleDatePickerInput': {
        width: '100%',

        '& .DateInput': {
          width: '100%',
        },
      },
    },
  },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > :not(:last-child)': {
      marginRight: 10,
    },
  },
  controlButton: {
    borderRadius: 4,
    height: 37,
  },
  imagePickersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 4,
    width: 150,

    '& > div': {
      width: 47,
      height: 47,
    },

    [theme.breakpoints.down('md')]: {
      width: 240,

      '& > div': {
        width: 77,
        height: 77,
      },
    },
  },
  defaultValue: {
    display: 'none',
  },
  noteTextArea: {
    marginBottom: 20,
    color: '#000000',
  },
}));

const getDefaultValues = (report) => ({
  updatedAt: report.updatedAt,
  cause: report.cause || false,
  note: report.note || '',
  isCompleted: report.isCompleted,
  imageUrls: report.imageUrls || [],
});

const validationSchema = Yup.object().shape({
  updatedAt: Yup.string().required(),
  cause: Yup.string(),
  note: Yup.string(),
  isCompleted: Yup.bool(),
  imageUrls: Yup.array().of(Yup.string()),
});

const DamageReportsTableRow = ({
  children,
  report,
  users,
  updateDamageReport,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [isExpanded, setIsExpanded] = useState(
    report.isDefaultExpanded || false,
  );

  const damageCauseOptions = useMemo(
    () => [
      { name: t('Damaged in transport'), value: 'Damaged in transport' },
      { name: t('Damaged by customer'), value: 'Damaged by customer' },
      { name: t('Damaged in warehouse'), value: 'Damaged in warehouse' },
    ],
    [i18n.language],
  );

  const onFormSubmit = async (values, { resetForm }) => {
    const data = {
      note: values.note,
      isCompleted: values.isCompleted,
      imageUrls: values.imageUrls,
    };
    if (values.updatedAt && values.updatedAt !== report.updatedAt)
      Object.assign(data, { updatedAt: values.updatedAt });
    if (damageCauseOptions.map(({ value }) => value).includes(values.cause))
      Object.assign(data, { cause: values.cause });

    await updateDamageReport(report.id, data);

    resetForm({
      ...getDefaultValues(report),
      ...data,
    });
  };

  return (
    <>
      <TableRow
        onClick={() => setIsExpanded(!isExpanded)}
        role='button'
        className={classes.tableRow}
      >
        {children}
      </TableRow>
      {isExpanded && (
        <Formik
          initialValues={getDefaultValues(report)}
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
          enableReinitialize
          component={({ handleSubmit, isSubmitting, dirty: isDirty }) => (
            <TableRow>
              <TableCell
                colSpan={isMobile ? 5 : 1}
                className={classes.tableCell}
              >
                <Field name='imageUrls'>
                  {({ field, form }) => (
                    <>
                      <ImagePicker
                        index={0}
                        reportId={report.id}
                        setValue={form.setFieldValue}
                        images={field.value}
                      />
                      <div className={classes.imagePickersContainer}>
                        {[1, 2, 3].map((index) => (
                          <ImagePicker
                            index={index}
                            reportId={report.id}
                            setValue={form.setFieldValue}
                            images={field.value}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </Field>
              </TableCell>
              <TableCell
                colSpan={isMobile ? 5 : 4}
                className={classes.tableCell}
              >
                <Typography variant='body2' className={classes.heading}>
                  {users[report.createdBy]?.display_name}
                </Typography>
                <Typography variant='body2' className={classes.subtitle}>
                  {`${t('Date')}: ${moment(report.updatedAt).format(
                    'DD MMM YYYY',
                  )}`}
                </Typography>
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                  <div className={classes.inputsContainer}>
                    <Field name='updatedAt'>
                      {({ field, form }) => (
                        <Datepicker
                          name={field.name}
                          value={field.value}
                          onChange={(value) =>
                            form.setFieldValue(field.name, value)
                          }
                          datePickerProps={{
                            displayFormat: 'DD MMM YYYY',
                          }}
                        />
                      )}
                    </Field>
                    <Field
                      name='cause'
                      as={(field) => (
                        <Select variant='outlined' {...field}>
                          <MenuItem
                            key='not-selected'
                            value={false}
                            disabled
                            className={classes.defaultValue}
                          >
                            <Typography variant='body2'>
                              {t('Select')}
                            </Typography>
                          </MenuItem>
                          {damageCauseOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              <Typography variant='body2'>
                                {item.name}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                  <Field name='note'>
                    {({ field }) => (
                      <TextField
                        multiline
                        maxRows={2}
                        fullWidth
                        variant='outlined'
                        label={t('Description')}
                        className={classes.noteTextArea}
                        {...field}
                      />
                    )}
                  </Field>
                  <div className={classes.controlsContainer}>
                    <Field
                      name='isCompleted'
                      component={({ field, form }) => (
                        <Button
                          variant='contained'
                          className={classes.controlButton}
                          onClick={() =>
                            form.setFieldValue(field.name, !field.value, true)
                          }
                        >
                          {field.value ? t('Reopen') : t('Mark as resolved')}
                        </Button>
                      )}
                    />
                    <Button
                      variant='contained'
                      className={classes.controlButton}
                      type='submit'
                      disabled={isSubmitting || !isDirty}
                    >
                      {t('Save')}
                      {isSubmitting ? (
                        <CircularProgress size={14} style={{ marginLeft: 4 }} />
                      ) : (
                        ''
                      )}
                    </Button>
                  </div>
                </form>
              </TableCell>
            </TableRow>
          )}
        />
      )}
    </>
  );
};

DamageReportsTableRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  report: PropTypes.object,
  users: PropTypes.object,
  updateDamageReport: PropTypes.func.isRequired,
};

export default DamageReportsTableRow;
