import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import House from '../../assets/icons/buildingTypes/icon-house.png';
import Apartment from '../../assets/icons/buildingTypes/icon-apartment.png';
import IndustryBuilding from '../../assets/icons/buildingTypes/icon-industry.png';
import UnknownBuilding from '../../assets/icons/buildingTypes/icon-unknown.png';

const useStyles = makeStyles({
  buildingTypeIcon: {
    width: 30,
    marginLeft: -5,
  },
});

const BuildingTypeIcon = ({ buildingType, additionalIconStyle = {} }) => {
  const classes = useStyles();
  const returnTypeOfBuildingImg = (type) => {
    switch (type) {
      case 'HOUSE': {
        return House;
      }
      case 'APARTMENT': {
        return Apartment;
      }
      case 'INDUSTRIAL': {
        return IndustryBuilding;
      }
      default: {
        return UnknownBuilding;
      }
    }
  };

  return (
    <img
      className={classes.buildingTypeIcon}
      style={{
        ...additionalIconStyle,
      }}
      src={returnTypeOfBuildingImg(buildingType)}
      alt='Type of building'
    />
  );
};

BuildingTypeIcon.propTypes = {
  buildingType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  additionalIconStyle: PropTypes.object,
};

export default BuildingTypeIcon;
