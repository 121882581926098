import { createBrowserHistory } from 'history';
import { isNil } from 'lodash';
export const history = createBrowserHistory();
export const isMobileDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

export const volumeUnitConverter = (volume) => {
  let volumeToDisplay = isNil(volume) || volume === 0 ? 0 : Number(volume);
  return String(volumeToDisplay.toFixed(1)).replace('.0', '');
};

export const getTMSListAsSelectOptions = (TMSList = []) => {
  return [
    { value: '', name: 'All' },
    ...TMSList.map(({ id, title }) => ({ value: id, name: title })),
  ];
};

export const moveItemInArray = (array, oldIndex, newIndex) => {
  if (newIndex >= array.length) {
    let k = newIndex - array.length + 1;
    while (k--) {
      array.push(undefined);
    }
  }

  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
};
