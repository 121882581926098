import { createContext, useEffect, useMemo, useState } from 'react';
import { groupBy } from 'lodash';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { t } from 'i18next';
import belecoApi from '../../api';
import useNotificator from '../../utils/useNotificator';

export const WarehouseDetailsContext = createContext({});

const initWOData = {
  estimatedDelivery: '',
  shipTo: '',
  shipmentProvider: '',
  trackingId: '',
  comment: '',
  updatedAt: '',
  orderStatus: '',
  name: '',
  ownerId: '',
  logisticsProviderQrCode: null,
  qrCodeUrl: null,
  publicOrderId: null,
  location: null,
  instances: [],
};
const WarehouseDetailsProvider = ({ children }) => {
  const [ownerIdForNewWO, setOwnerIdForNewWO] = useState('');
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isLoadingWOData, setIsLoadingWOData] = useState(false);
  const [warehouseOrderData, setWarehouseOrderData] = useState(initWOData);
  const [instanceMap, setInstanceMap] = useState({});
  const [productListToOrder, setProductListToOrder] = useState(null);
  const [isLoadingListOfAssetProviders, setIsLoadingListOfAssetProviders] =
    useState(false);
  const [listOfAssetProviders, setListOfAssetProviders] = useState(null);
  const [isLoadingProductList, setIsLoadingProductList] = useState(false);

  const { notifyError } = useNotificator();

  const { publicId = '' } = useParams();
  const isNewOrder = publicId === 'create-new-order';

  const getWarehouseDeliveryData = async () => {
    try {
      const data = await belecoApi.inv.getWarehouseDeliveryDetails(publicId);
      const instancesMap = groupBy(data.instances, 'productId');
      const productsMeta = await belecoApi.wp.getProductsMeta(
        Object.keys(instancesMap),
      );
      const extendedMap = {};
      Object.keys(instancesMap).forEach((productId) => {
        extendedMap[productId] = {
          ...productsMeta[productId],
          ...instancesMap[productId][0],
          qty: instancesMap[productId].length,
        };
      });
      setInstanceMap(extendedMap);
      setWarehouseOrderData({
        ...data,
        shipTo: data.locationId,
        trackingId: data.shipmentTrackingCode,
        estimatedDelivery: String(
          moment(data.estimatedArrival).format('YYYY-MM-DD'),
        ),
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  const updateOrder = async (newStatus) => {
    setIsUpdateLoading(true);
    try {
      const data = await belecoApi.inv.editWarehouseDeliveryOrder(publicId, {
        orderStatus: newStatus,
      });
      setWarehouseOrderData({ ...warehouseOrderData, orderStatus: newStatus });
      return data.orderStatus;
    } catch (e) {
      notifyError(e, t('Server error. Editing order failed'));
      return null;
    } finally {
      setIsUpdateLoading(false);
    }
  };

  const getOwnersProducts = async (ownerId) => {
    setOwnerIdForNewWO(ownerId);
    setIsLoadingProductList(true);
    try {
      const params = { limit: 1000 };
      const { rows: productList } =
        await belecoApi.wp.getProductsByOrganisationId(ownerId, params);
      setProductListToOrder(productList);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingProductList(false);
    }
  };

  const getOwnerData = async () => {
    setIsLoadingListOfAssetProviders(true);
    try {
      const { user } = await belecoApi.wp.getOwnerData(
        warehouseOrderData.ownerId,
      );
      setListOfAssetProviders({ [user.ID]: user });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingListOfAssetProviders(false);
    }
  };

  const getListOfAssetProviders = async () => {
    // Needed in case if we going to create new WO and select an AP from the list.
    setIsLoadingListOfAssetProviders(true);
    try {
      const assetsProviders = await belecoApi.wp.getAllAssetProviders();
      const formattedList = assetsProviders.reduce(
        (acc, curr) => ({ ...acc, [curr.ID]: curr }),
        {},
      );
      setListOfAssetProviders(formattedList);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingListOfAssetProviders(false);
    }
  };

  const resetOrderData = () => {
    setWarehouseOrderData({});
  };

  const updateInstanceCompletedStatus = async (params) => {
    try {
      await belecoApi.inv.updateWarehouseOrderInstanceIsCompleted(params);
      await getWarehouseDeliveryData();
    } catch (err) {
      notifyError(err, 'Failed to update instance completed status');
    }
  };

  const updateProductCompletedStatus = async (params) => {
    try {
      await belecoApi.inv.updateWarehouseOrderProductIsCompleted(params);
      await getWarehouseDeliveryData();
    } catch (err) {
      notifyError(err, 'Failed to update instances completed status');
    }
  };

  useEffect(async () => {
    if (!isLoadingWOData && !isNewOrder) {
      setIsLoadingWOData(true);
      await getWarehouseDeliveryData(publicId);
      setIsLoadingWOData(false);
    }
  }, [isNewOrder]);

  useEffect(() => {
    if (isLoadingListOfAssetProviders || isLoadingWOData) return;
    if (warehouseOrderData.ownerId.length !== 0 && !isNewOrder) {
      getOwnerData();
    } else if (isNewOrder) {
      getListOfAssetProviders();
    }
  }, [isLoadingWOData]);

  const values = useMemo(
    () => ({
      warehouseOrderData,
      instanceMap,
      productListToOrder,
      listOfAssetProviders,
      getWarehouseDeliveryData,
      getOwnersProducts,
      getListOfAssetProviders,
      getOwnerData,
      resetOrderData,
      isLoadingWOData,
      isLoadingProductList,
      isLoadingListOfAssetProviders,
      ownerIdForNewWO,
      updateOrder,
      isUpdateLoading,
      updateInstanceCompletedStatus,
      updateProductCompletedStatus,
    }),
    [
      isLoadingWOData,
      isLoadingProductList,
      isLoadingListOfAssetProviders,
      isUpdateLoading,
      warehouseOrderData,
    ],
  );

  return (
    <WarehouseDetailsContext.Provider value={values}>
      {children}
    </WarehouseDetailsContext.Provider>
  );
};
WarehouseDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default WarehouseDetailsProvider;
