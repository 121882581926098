import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  statusColumn: {
    borderRadius: '45%',
    lineHeight: '2em',
    display: 'inline-block',
    width: 25,
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 12,
    textAlign: 'center',

    '&.average': {
      backgroundColor: '#F7BBBB',
    },
    '&.satisfactory': {
      backgroundColor: '#EDE1A7',
    },
    '&.new': {
      backgroundColor: '#85CB84',
    },
    '&.notAvailable': {
      backgroundColor: '#CCC',
      color: '#FFF',
    },
  },
}));

const ConditionComponent = ({ item: { condition } }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let conditionClassName = '';

  switch (condition) {
    case 1:
    case 2:
      conditionClassName = 'average';
      break;
    case 3:
      conditionClassName = 'satisfactory';
      break;
    case 4:
    case 5:
      conditionClassName = 'new';
      break;
    default:
      conditionClassName = 'notAvailable';
  }
  return (
    <span className={`${classes.statusColumn} ${conditionClassName}`}>
      {condition || t('N/A')}
    </span>
  );
};

ConditionComponent.propTypes = {
  item: PropTypes.shape({
    condition: PropTypes.oneOfType([PropTypes.number, PropTypes.any]),
  }),
};

export default ConditionComponent;
