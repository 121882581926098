import { useContext, useRef, useState } from 'react';
import { Box, Button, MenuList, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomPopper from '../CustomPopper';
import CustomListItem from '../CustomListItem';
import ButtonSelector from '../ButtonSelector';
import LocationContext from '../../context/Location/LocationContext';
import Countries from '../../utils/countries';

const styles = {
  container: (theme) => ({
    minWidth: { md: '230px' },
    height: '100%',
    borderRight: '1px solid #DDDDDD',

    '.MuiButton-root': {
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '114px',
    },
  }),
  showAllTitle: (theme) => ({
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Helvetica Neue, sans-serif',
    textTransform: 'none',
    lineHeight: 1,
    padding: '0 4px 0 6px',

    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '25px',
    },
  }),
  menuRoot: {
    padding: '0px',
  },
  showAllBtn: {
    width: '100%',
    paddingLeft: '16px',
    paddingRight: '16px',
    justifyContent: 'flex-start',
    textTransform: 'none',
  },
};

const LocationSelector = () => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { locations, userLocation, updateUserLocation } =
    useContext(LocationContext);
  const menuHandler = () => {
    setOpen(!isOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAction = (selectedLocation) => {
    handleClose();
    updateUserLocation(selectedLocation);
  };

  const sortLocations = (locationsObj) => {
    return Object.values(locationsObj)
      .sort((a, b) => b.id - a.id)
      .sort((a, b) => {
        if (a.isActive && !b.isActive) {
          return -1;
        }
        if (!a.isActive && b.isActive) {
          return 1;
        }
        return 0;
      });
  };

  const SORTED_LOCATIONS = sortLocations(locations);
  return (
    <Box sx={styles.container}>
      <ButtonSelector menuHandler={menuHandler} anchorRef={anchorRef}>
        {userLocation?.id ? (
          <CustomListItem
            primary={userLocation?.title}
            secondary={t(Countries[userLocation?.country])}
            shouldHideText={isMobile}
            avatarTextChars={2}
          />
        ) : (
          <Box component='span' sx={styles.showAllTitle}>
            {t('Select warehouse')}
          </Box>
        )}
      </ButtonSelector>

      <CustomPopper
        anchorRef={anchorRef}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <div style={{ paddingTop: 6 }}>
          <MenuList id='menu-list-grow' sx={styles.menuRoot}>
            {Object.values(SORTED_LOCATIONS).map((location) => (
              <CustomListItem
                key={location.id}
                primary={location?.title}
                secondary={t(Countries[location?.country])}
                avatarTextChars={2}
                hasDivider
                isBtn
                id={location.id}
                onClickBtn={handleAction}
                isActive={location.isActive}
                hasStatus
              />
            ))}
            <Button
              type='text'
              sx={styles.showAllBtn}
              onClick={() => handleAction('')}
            >
              {t('Show all warehouses')}
            </Button>
          </MenuList>
        </div>
      </CustomPopper>
    </Box>
  );
};

export default LocationSelector;
