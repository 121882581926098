import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useNotificator from '../../../../utils/useNotificator';
import CustomDataGrid from '../../../../components/CustomDataGrid';
import belecoApi from '../../../../api';

const styles = {
  blockStyleClass: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '288px',
  },
  baseTypoStyle: {
    fontSize: '12px',
  },
  link: {
    color: '#2a2929',
    display: 'flex',
    minWidth: '0px',
  },
};

const ConditionInstancesTable = () => {
  const { t } = useTranslation();

  const [conditionInstances, setConditionInstances] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const { notifyError } = useNotificator();

  const pageSize = 5;

  const getConditionInstancesData = async () => {
    setLoading(true);
    const dateFrom = moment().subtract(30, 'd').format('YYYY-MM-DD'); // last 30 days

    try {
      const params = {
        from: dateFrom,
      };

      const data = await belecoApi.inv.getConditionChangelog(params);

      setTotal(data?.length || 0);
      setConditionInstances(data || []);
    } catch (err) {
      notifyError(err, 'Failed to load instances');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConditionInstancesData();
  }, []);

  const onChangePage = (selectedPage) => {
    setPage(selectedPage);
  };

  const initColumns = [
    {
      field: 'createdAt',
      headerName: t('Date'),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: ({ row: { timestamp } }) => (
        <Typography variant='body2' sx={styles.baseTypoStyle}>
          {moment(timestamp).format('D MMM YYYY')}
        </Typography>
      ),
    },
    {
      field: 'instanceId',
      headerName: t('Id'),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: ({ row: { instanceId } }) => (
        <Link style={styles.link} to={`/instances/${instanceId}`}>
          <Typography
            variant='body2'
            component='span'
            noWrap
            sx={styles.baseTypoStyle}
          >
            {instanceId}
          </Typography>
        </Link>
      ),
    },

    {
      field: 'old',
      headerName: t('Old condition'),
      sortable: false,
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row: { old: oldCondition } }) => (
        <Typography variant='body2' sx={styles.baseTypoStyle}>
          {oldCondition}
        </Typography>
      ),
    },
    {
      field: 'new',
      headerName: t('New condition'),
      sortable: false,
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row: { new: newCondition } }) => (
        <Typography variant='body2' sx={styles.baseTypoStyle}>
          {newCondition}
        </Typography>
      ),
    },
  ];

  return (
    <CustomDataGrid
      rows={conditionInstances}
      columns={initColumns}
      getRowId={({ timestamp }) => timestamp}
      rowCount={total}
      pageSize={pageSize}
      loading={loading}
      hideSpaceRow
      onPageChange={onChangePage}
      headerHeight={40}
      rowHeight={40}
      pagination
      hideFooterPagination={false}
      page={page}
      blockStyleSx={styles.blockStyleClass}
    />
  );
};

export default ConditionInstancesTable;
