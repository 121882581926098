import makeStyles from '@mui/styles/makeStyles';
import { Hidden, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import AddNewButton from '../AddNewButton';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 25,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > div': {
        width: '100%',
        marginTop: 20,
      },
    },
  },
  titleBlock: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginRight: 16,
  },
  title: {
    fontSize: 30,
    fontWeight: 300,
    [theme.breakpoints.up('md')]: {
      fontSize: 25,
    },
  },
}));

const TitleContainer = ({ title, onNew = null, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.titleContainer}>
      <div className={classes.titleBlock}>
        <Typography className={classes.title}>{title}</Typography>
        {!!onNew && (
          <Hidden lgUp>
            <AddNewButton onNew={onNew} />
          </Hidden>
        )}
      </div>

      {children}
    </div>
  );
};
TitleContainer.propTypes = {
  onNew: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};
export default TitleContainer;
