import { Typography, Link, Box, Button, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import _ from 'lodash';
import { useState } from 'react';
import Datepicker from '../Datepicker';
import useBooking from './hooks/useBooking';
import ChangeInstanceDialog from './ChangeInstanceDialog';
import useInstances from './hooks/useInstances';
import belecoApi from '../../../../api';

const useStyles = makeStyles((theme) => ({
  formField: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: 20,
    fontSize: 14,
  },
  container: {
    marginTop: 20,
    padding: 20,
    backgroundColor: '#ffffff',
    border: '1px solid #c5c5c5',
    borderRadius: '7px',
    '& .MuiTableCell-root.MuiTableCell-body': {
      padding: '2px 5px',
      maxWidth: 100,
      textAlign: 'center',
    },
    '& .MuiTableBody-root tr.MuiTableRow-root:not([index])': {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
    },
  },
  tableName: {
    fontSize: '34px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    textTransform: 'uppercase',
    lineHeight: 1,
    marginBottom: '20px',
  },
  dataGridClass: {
    '& .MuiDataGrid-row': {
      boxShadow: 'inset 0 0 0 1px #cccccc',
      width: '100%',

      '& .MuiDataGrid-cell': {
        border: 'none',
        fontWeight: 600,
        fontFamily: 'Helvetica Neue, sans-serif',

        '& p': {
          fontFamily: 'inherit',
        },
      },
    },
  },
}));

const wpAdminURL = process.env.REACT_APP_WP_ADMIN_URL; // Utility library to help with deep object comparison

const BookingDetailedView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { bookingId } = useParams();
  const { booking, updateBooking } = useBooking(bookingId);
  const [isChangeInstanceDialogOpen, setChangeInstanceDialogOpen] =
    useState(false);

  const { instances } = useInstances(booking?.instance?.product?.id);
  const [isLoadingChange, setLoadingChange] = useState(false);
  const history = useHistory();

  const getModifiedFields = (initialValues, values) => {
    const modifiedFields = {};
    Object.keys(values).forEach((key) => {
      if (!_.isEqual(initialValues[key], values[key])) {
        modifiedFields[key] = values[key];
      }
    });
    return modifiedFields;
  };

  const changeInstance = async (newInstancePublicId) => {
    setLoadingChange(true);

    const newBookingData = {
      instanceId: newInstancePublicId,
      orderId: booking.orderId,
      deliveryDate: booking.deliveryDate,
      pickupDate: booking.pickupDate,
    };

    try {
      const data = await belecoApi.inv.createBooking(newBookingData);
      await belecoApi.inv.removeBooking(bookingId);
      console.log('data', data);
      history.push(`/bookings/${data.id}`);
    } catch (err) {
      console.log('Error in changing booking', err);
    } finally {
      isLoadingChange(false);
    }
  };

  return (
    <div className={classes.container}>
      <Typography
        variant='body1b'
        component='span'
        style={{
          cursor: 'pointer',
          color: 'gray',
          weight: 'bold',
          textDecoration: 'underline',
        }}
        onClick={() =>
          history.push(`/instances/${booking?.instance?.publicId}`)
        }
      >
        {t('backToInstance')}
      </Typography>

      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        marginTop='20px'
      >
        <Typography className={classes.tableName}>
          {t('editBooking')}
        </Typography>
      </Box>

      <Divider sx={{ marginBottom: '20px' }} />

      <Typography variant='body3'>{t('editBookingWarning')}</Typography>

      <Box>
        <Button
          sx={{ marginTop: '20px', marginBottom: '5px' }}
          variant='contained'
          onClick={() => setChangeInstanceDialogOpen(true)}
        >
          {t('changeInstance')}
        </Button>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Formik
          initialValues={booking}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            const modifiedFields = getModifiedFields(booking, values);
            if (Object.keys(modifiedFields).length > 0) {
              updateBooking(modifiedFields); // Only submit modified fields
            } else {
              console.log('No changes detected'); // If no modified fields
            }
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, dirty }) => (
            <form className={classes.loginForm} onSubmit={handleSubmit}>
              <div className={classes.formField}>
                Order ID:
                <Link
                  href={`${wpAdminURL}/post.php?post=${booking?.orderId}&action=edit`}
                >
                  #{booking?.orderId}
                </Link>
              </div>

              <div className={classes.formField}>
                <Typography variant='body3'>
                  {t('deliveryDateBooking')}
                </Typography>
                <Field name='deliveryDate'>
                  {({ field, form }) => (
                    <Datepicker
                      name={field.name}
                      value={field.value}
                      onChange={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                      datePickerProps={{
                        placeholder: t('Not selected'),
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className={classes.formField}>
                <Typography variant='body3'>
                  {t('pickupDateBooking')}
                </Typography>
                <Field name='pickupDate'>
                  {({ field, form }) => (
                    <Datepicker
                      name={field.name}
                      value={field.value}
                      onChange={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                      datePickerProps={{
                        placeholder: t('Not selected'),
                      }}
                    />
                  )}
                </Field>
              </div>

              {/* Disable the button if no fields are modified */}
              <Button type='submit' variant='contained' disabled={!dirty}>
                {t('editBookingSave')}
              </Button>
            </form>
          )}
        </Formik>
      </Box>
      <ChangeInstanceDialog
        handleClose={() => setChangeInstanceDialogOpen(false)}
        isOpen={isChangeInstanceDialogOpen}
        currentInstance={booking?.instance}
        availableInstances={instances}
        changeInstance={changeInstance}
      />
    </div>
  );
};

export default BookingDetailedView;
