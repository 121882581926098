import { useMemo, useState, useContext } from 'react';
import { Typography, Select, MenuItem, Button, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { isEmpty, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { INVISIBILITY_REASONS } from '../../utils/constants';
import Datepicker from './components/Datepicker';
import VisibilityWarningPopUp from './components/VisibilityWarningPopup';
import i18n from '../../localization';
import { InstanceDetailsContext } from '../../context/InstanceDetails/InstanceDetailsContext';

const useStyles = makeStyles({
  formField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    fontSize: 14,
    '& .MuiInputBase-root': {
      width: 130,
      '& .MuiSelect-selectMenu': {
        width: 130,
        '&  > legend': {
          display: 'none',
        },
      },
    },
  },
  notesArea: {
    width: '100%',
    resize: 'none',
    margin: '10px 0',
    outline: 'none',
    fontFamily: 'GT-America-Standard-Thin, sans-serif',
  },
  notesTitle: {
    color: '#000000',
  },
  price: {
    '& ::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
});

const OPTIONS_CONDITION = [
  [1, 1],
  [2, 2],
  [3, 3],
  [4, 4],
  [5, 5],
];

const MOUNTING_CONDITIONS = () => [
  [true, i18n.t('Yes')],
  [false, i18n.t('No')],
];

const OPTIONS_VISIBILITY = () => [
  [true, i18n.t('Yes')],
  [false, i18n.t('No')],
];

const validationSchema = Yup.object().shape({
  condition: Yup.mixed()
    .oneOf(OPTIONS_CONDITION.map((o) => o[0]))
    .nullable(),
  isVisible: Yup.mixed().oneOf(OPTIONS_VISIBILITY().map((o) => o[0])),
  // invisibilityReason: Yup.mixed().oneOf(OPTIONS_REASON.map(o => o[0])),
  purchasePrice: Yup.number()
    .transform((val) => (val && val > 0 ? val : null))
    .nullable(),
  purchaseDate: Yup.string().nullable(),
  isMountingRequired: Yup.bool(),
});

const initialValues = {
  condition: null,
  invisibilityReason: '',
  isVisible: true,
  purchaseDate: null,
  purchasePrice: '',
  isMountingRequired: false,
};

const InstanceForm = () => {
  const classes = useStyles();
  const { t, i18n: i18nLocal } = useTranslation();

  const {
    bookings,
    instanceData = initialValues,
    updateInstance,
    isInstanceDataLoading,
  } = useContext(InstanceDetailsContext);
  const bookingsArray = bookings?.rows || [];

  const [showBookingsWarning, setShowBookingsWarning] = useState(false);

  const checkInstanceForBookingsInFuture = () => {
    const bookingsInFuture = bookingsArray.filter(({ deliveryDate }) =>
      moment(deliveryDate).isAfter(),
    );
    return !isEmpty(bookingsInFuture);
  };

  const handleBookingWarningCLose = () => {
    updateInstance({
      data: { isVisible: true },
    });
    setShowBookingsWarning(false);
  };

  const renderSelect = (options, formikProps) => (
    <Select variant='outlined' {...formikProps}>
      {options.map((opt) => (
        <MenuItem value={opt[0]} key={opt[0]}>
          <Typography variant='body2'>{opt[1]}</Typography>
        </MenuItem>
      ))}
    </Select>
  );

  const onSubmit = (values) => {
    const dataForSubmit = pick(values, [
      'condition',
      'invisibilityReason',
      'isVisible',
      'purchaseDate',
      'purchasePrice',
      'isMountingRequired',
    ]);

    if (dataForSubmit?.purchasePrice === '') {
      dataForSubmit.purchasePrice = null;
    }

    updateInstance({
      data: dataForSubmit,
    });
  };

  const checkAndSubmitHandler = (values) => {
    if (
      !values.isVisible &&
      bookingsArray.length &&
      checkInstanceForBookingsInFuture()
    ) {
      setShowBookingsWarning(true);
    } else {
      onSubmit(values);
    }
  };

  const OPTIONS_REASON = useMemo(
    () =>
      Object.entries(INVISIBILITY_REASONS).map(([key, value]) => [
        key,
        t(value),
      ]),
    [i18nLocal.language],
  );

  return (
    <div>
      <Formik
        initialValues={instanceData}
        onSubmit={checkAndSubmitHandler}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ handleSubmit, values, isSubmitting, dirty: isDirty }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.formField}>
              <Typography variant='body2'>{t('Purchase Price')}</Typography>
              <Field name='purchasePrice'>
                {({ field, meta }) => (
                  <TextField
                    className={classes.price}
                    variant='outlined'
                    type='number'
                    error={meta?.error}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className={classes.formField}>
              <Typography variant='body2'>{t('Date of purchase')}</Typography>
              <Field name='purchaseDate'>
                {({ field, form }) => (
                  <Datepicker
                    name={field.name}
                    value={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                    datePickerProps={{
                      placeholder: t('Not selected'),
                    }}
                  />
                )}
              </Field>
            </div>
            <div className={classes.formField}>
              <Typography variant='body2'>{t('Condition')}</Typography>
              <Field
                as={(formikProps) =>
                  renderSelect(OPTIONS_CONDITION, formikProps)
                }
                name='condition'
              />
            </div>
            <div className={classes.formField}>
              <Typography variant='body2'>{t('Needs mounting')}</Typography>
              <Field
                as={(formikProps) =>
                  renderSelect(MOUNTING_CONDITIONS(), formikProps)
                }
                name='isMountingRequired'
              />
            </div>
            <div className={classes.formField}>
              <Typography variant='body2'>{t('Visibility')}</Typography>
              <Field
                as={(formikProps) =>
                  renderSelect(OPTIONS_VISIBILITY(), formikProps)
                }
                name='isVisible'
              />
            </div>
            {!values.isVisible && (
              <div className={classes.formField}>
                <Typography variant='body2'>{t('Reason')}</Typography>
                <Field
                  as={(formikProps) =>
                    renderSelect(OPTIONS_REASON, formikProps)
                  }
                  name='invisibilityReason'
                />
              </div>
            )}

            <div style={{ textAlign: 'right' }}>
              <Button
                type='submit'
                variant='contained'
                disabled={isInstanceDataLoading || isSubmitting || !isDirty}
              >
                {t('Update')}
              </Button>
            </div>
          </form>
        )}
      </Formik>
      <VisibilityWarningPopUp
        isOpen={showBookingsWarning}
        handleClose={handleBookingWarningCLose}
        bookingsArray={bookingsArray}
      />
    </div>
  );
};

export default InstanceForm;
