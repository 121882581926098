import { useState, useContext, useEffect, useRef } from 'react';
import { Typography, Link } from '@mui/material';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useNotificator from '../../../../utils/useNotificator';
import CustomDataGrid from '../../../../components/CustomDataGrid';
import LocationContext from '../../../../context/Location/LocationContext';
import belecoApi from '../../../../api';

const styles = {
  blockStyleClass: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '288px',
  },
  baseTypoStyle: {
    fontSize: '12px',
  },
  link: {
    color: '#2a2929',
    display: 'flex',
    minWidth: '0px',
  },
};

const wpAdminURL = process.env.REACT_APP_WP_ADMIN_URL;

const DelayedDeliveriesTable = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { userLocation } = useContext(LocationContext);

  const [offset, setOffset] = useState(0);
  const [warehouseOrders, setWarehouseOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const { notifyError } = useNotificator();
  const notInitialRender = useRef(false);

  const pageSize = 5;

  // Handling locationId in url
  const updateUrlFilteringParams = () => {
    const urlSearchParams = {};

    if (userLocation?.id)
      Object.assign(urlSearchParams, { locationId: userLocation.id });

    const urlParams = new URLSearchParams(urlSearchParams);
    history.push(`${location.pathname}?${urlParams.toString()}`);
  };

  const getDelayedDeliveriesData = async () => {
    setLoading(true);

    try {
      // Excluding  'COMPLETED', 'CANCELLED',
      const params = {
        offset,
        limit: pageSize,
        locationId: userLocation?.id || '',
        orderStatus: ['CREATED', 'PROCESSING', 'IN_TRANSIT'],
        etaBefore: moment().format('YYYY-MM-DD'),
      };

      const { rows, count } = await belecoApi.inv.getWarehouseOrders(params);

      setPage(offset / pageSize);
      setTotal(count);
      setWarehouseOrders(rows);
    } catch (err) {
      notifyError(err, 'Failed to load delayed deliveries');
    } finally {
      setLoading(false);
      updateUrlFilteringParams();
    }
  };

  useEffect(() => {
    getDelayedDeliveriesData();
  }, [offset]);

  useEffect(() => {
    // Skip first render
    if (notInitialRender.current) {
      if (offset === 0) {
        getDelayedDeliveriesData();
      } else {
        setOffset(0);
      }
    } else {
      notInitialRender.current = true;
    }
  }, [userLocation?.id]);

  const onChangePage = (selectedPage) => {
    setOffset(selectedPage * pageSize);
  };

  const initColumns = [
    {
      headerName: t('EST. Delivery'),
      field: 'estimatedArrival',
      align: 'left',
      headerAlign: 'left',
      minWidth: 140,
      sortable: false,
      renderCell: ({ row }) => (
        <Typography variant='body2'>{row.estimatedArrival}</Typography>
      ),
    },
    {
      headerName: t('From'),
      field: 'name',
      align: 'left',
      headerAlign: 'left',
      minWidth: 180,
      flex: 2,
      sortable: false,
      renderCell: ({ row }) => (
        <Link
          sx={styles.link}
          component={RouterLink}
          to={`/warehouse-orders/${row.publicId}`}
          variant='body2'
          underline='always'
        >
          <Typography variant='body2'>{row.name}</Typography>
        </Link>
      ),
    },
    {
      headerName: t('Items'),
      field: 'instances.length',
      align: 'center',
      headerAlign: 'center',
      minWidth: 80,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <Typography variant='body2'>{row.instances.length}</Typography>
      ),
    },
    {
      headerName: t('Order'),
      field: 'publicOrderId',
      align: 'center',
      headerAlign: 'center',
      minWidth: 80,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) =>
        row.orderId ? (
          <Link
            sx={styles.link}
            href={`${wpAdminURL}/post.php?post=${row.orderId}&action=edit`}
            target='_blank'
            rel='noopener noreferrer'
            variant='body2'
            underline='always'
          >
            {row.publicOrderId}
          </Link>
        ) : (
          '-'
        ),
    },
  ];

  return (
    <CustomDataGrid
      rows={warehouseOrders}
      columns={initColumns}
      rowCount={total}
      pageSize={pageSize}
      loading={loading}
      hideSpaceRow
      onPageChange={onChangePage}
      headerHeight={40}
      rowHeight={40}
      pagination
      paginationMode='server'
      hideFooterPagination={false}
      page={page}
      blockStyleSx={styles.blockStyleClass}
    />
  );
};

export default DelayedDeliveriesTable;
