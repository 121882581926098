import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import TransportsTable from '../../../Transports/components/TransportsTable';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    padding: 20,
    backgroundColor: '#ffffff',
    border: '1px solid #c5c5c5',
    borderRadius: '7px',
    '& .MuiTableCell-root.MuiTableCell-body': {
      padding: '2px 5px',
      maxWidth: 100,
      textAlign: 'center',
    },
    '& .MuiTableBody-root tr.MuiTableRow-root:not([index])': {
      display: 'none',
    },
  },
  tableName: {
    fontSize: '34px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    textTransform: 'uppercase',
    lineHeight: 1,
  },
});

const TransportsList = ({ data, isLoading, onChangePage }) => {
  const classes = useStyles();
  const { rows = [], count } = data;
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Typography className={classes.tableName}>{t('Transports')}</Typography>
      </Box>
      <TransportsTable
        isRelatedTable
        items={rows}
        pageSize={50}
        rowCount={count}
        onPageChange={onChangePage}
        loading={isLoading}
        total={count}
        hasPagination={false}
      />
    </div>
  );
};

TransportsList.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChangePage: PropTypes.func.isRequired,
};

export default TransportsList;
