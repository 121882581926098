import PropTypes from 'prop-types';
import { Button, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

import { useContext } from 'react';
import GradientLoadingOverlay from '../../../components/GradientLoadingOverlay';
import { TransportDetailsContext } from '../../../context/TransportDetails/TransportDetailsContext';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  containedButton: {
    height: 22,
    textTransform: 'none',
  },
  iconButton: {
    minWidth: 'unset',
    width: 22,
    height: 22,
    backgroundColor: '#A3D8A3',
  },
  icon: {
    color: '#ffffff',
    fontSize: 18,
  },
}));

const TransportInstanceActions = ({ publicId, transportInstances }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    transport,
    isPackedLoading,
    updateInstancePackedStatus,
    updateInstanceCompletedStatus,
  } = useContext(TransportDetailsContext);

  const packInstance = (isPacked) => {
    updateInstancePackedStatus({
      publicId: transport.publicId,
      instanceId: publicId,
      isPacked,
    });
  };

  const completeInstance = (isCompleted) => {
    updateInstanceCompletedStatus({
      publicId: transport.publicId,
      instanceId: publicId,
      isCompleted,
    });
  };

  if (isPackedLoading === publicId)
    return <GradientLoadingOverlay height={22} />;

  return (
    <div className={classes.container}>
      {transport.status === 'PROCESSING' &&
        (!transportInstances.isPacked ? (
          <Button
            variant='contained'
            className={classes.containedButton}
            onClick={() => packInstance(true)}
          >
            {t('Pack')}
          </Button>
        ) : (
          <IconButton
            className={classes.iconButton}
            onClick={() => packInstance(false)}
            size='large'
          >
            <CheckIcon className={classes.icon} />
          </IconButton>
        ))}
      {transport.status === 'IN_TRANSIT' &&
        (!transportInstances.isCompleted ? (
          <Button
            variant='contained'
            className={classes.containedButton}
            onClick={() => completeInstance(true)}
          >
            {t('Delivered')}
          </Button>
        ) : (
          <IconButton
            className={classes.iconButton}
            onClick={() => completeInstance(false)}
            size='large'
          >
            <CheckIcon className={classes.icon} />
          </IconButton>
        ))}
    </div>
  );
};

TransportInstanceActions.propTypes = {
  publicId: PropTypes.string.isRequired,
  transportInstances: PropTypes.shape({
    isPacked: PropTypes.bool.isRequired,
    isCompleted: PropTypes.bool.isRequired,
  }).isRequired,
};

export default TransportInstanceActions;
