import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ChangeInstanceDialog = ({
  handleClose,
  isOpen,
  currentInstance,
  availableInstances,
  changeInstance,
}) => {
  const { t } = useTranslation();
  const selectList = (availableInstances || []).map((ins) => {
    return {
      value: ins.publicId,
      label: ins.publicId,
    };
  });

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: '50vw',
          minHeight: '40vh',
          padding: '10px',
        },
      }}
    >
      <DialogTitle sx={{ fontSize: '18px' }}>{t('changeInstance')}</DialogTitle>
      <Formik
        initialValues={{ instance: currentInstance?.publicId }}
        enableReinitialize
        onSubmit={({ instance }) => {
          handleClose();
          changeInstance(instance);
        }}
      >
        {({ handleSubmit, values, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              {t('editBookingWarning')}
              <Divider sx={{ marginTop: '20px' }} />
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                mt='20px'
                alignItems='center'
              >
                <Typography variant='body1b'>{t('currentInstance')}</Typography>
                <Typography>{currentInstance?.publicId}</Typography>
              </Box>
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                mt='20px'
                alignItems='center'
              >
                <Typography variant='body1b'>{t('newInstance')}</Typography>
                <FormControl>
                  <Select
                    labelId='select-Instance'
                    id='instance'
                    name='instance'
                    value={values.instance}
                    onChange={handleChange}
                  >
                    {selectList.map((opt) => (
                      <MenuItem value={opt.value} key={opt.value}>
                        <Typography variant='body2'>{opt.label}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t('cancelChangeInstance')}</Button>
              <Button
                variant='contained'
                type='submit'
                disabled={
                  values?.instance === currentInstance?.publicId ||
                  !values?.instance
                }
              >
                {t('updateChangeInstance')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

ChangeInstanceDialog.propTypes = {
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  currentInstance: PropTypes.object,
  availableInstances: PropTypes.array,
  changeInstance: PropTypes.func,
};

export default ChangeInstanceDialog;
