import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import LoadingOverlay from '../LoadingOverlay';
import blackLogo from '../../assets/images/beleco_logo_black.png';

const ImageLoader = ({ src, alt, className, sx }) => {
  const [state, setState] = useState({
    loading: true,
    src: '',
    error: false,
  });

  useEffect(() => {
    if (!src) {
      setState({
        src: '',
        loading: false,
        error: true,
      });
    } else {
      const img = new Image();
      img.src = src;

      if (img.complete) {
        setState({
          src,
          loading: false,
          error: false,
        });
      } else {
        img.onload = () => {
          setState({
            src,
            loading: false,
            error: false,
          });
        };

        img.onerror = () => {
          setState({
            src: '',
            loading: false,
            error: true,
          });
        };
      }
    }
  }, [src]);

  const getImage = () => {
    if (state.loading) return <LoadingOverlay size={20} />;
    if (state.src && !state.loading)
      return (
        <img
          src={state.src}
          alt={alt}
          width='100%'
          height='100%'
          style={{ objectFit: 'contain' }}
        />
      );
    if (!src || state.error)
      return <img src={blackLogo} alt='Beleco placeholder' width='100%' />;

    return null;
  };

  return (
    <Box
      className={className}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        ...sx,
      }}
    >
      {getImage()}
    </Box>
  );
};
ImageLoader.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  alt: PropTypes.string,
  className: PropTypes.string,
  sx: PropTypes.object,
};
export default ImageLoader;
