import { useMemo, useState, createContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import belecoApi from '../../api';
import useNotificator from '../../utils/useNotificator';
import validateLocations from '../../utils/validateLocations';

export const ProductDetailsContext = createContext({});

const ProductDetailsProvider = ({ children }) => {
  const { notifyError, notifyWarning } = useNotificator();
  const { productId } = useParams();
  const { push } = useHistory();

  const [productData, setProductData] = useState({});
  const [isProductLoading, setIsProductLoading] = useState(true);

  const [dimensions, setDimensions] = useState(null);
  const [isDimensionsLoading, setIsDimensionsLoading] = useState(true);

  const [woPlacements, setWoPlacements] = useState([]);
  const [isWoPlacementsLoading, setIsWoPlacementsLoading] = useState(true);

  const [availabilityPeriods, setAvailabilityPeriods] = useState([]);
  const [isAvailabilityPeriodsLoading, setIsAvailabilityPeriodsLoading] =
    useState(true);

  const [locationsAvailability, setLocationsAvaialability] = useState([]);
  const [isLocationsAvailabilityLoading, setIsLocationsAvailabilityLoading] =
    useState(false);

  const [relatedInstances, setRelatedInstances] = useState([]);
  const [totalRelatedInstances, setTotalRelatedInstances] = useState(0);
  const [isRelatedInstancesLoading, setIsRelatedInstancesLoading] =
    useState(false);

  const loadLocationsAvailability = async () => {
    setIsLocationsAvailabilityLoading(true);
    try {
      const response = await belecoApi.inv.getLocationsAvailability(productId);
      if (response) {
        const validLocations = validateLocations(response, true);
        setLocationsAvaialability(validLocations);
      }
    } catch (e) {
      notifyError(e, 'Unable to load locations availability');
    } finally {
      setIsLocationsAvailabilityLoading(false);
    }
  };

  const loadProduct = async () => {
    setIsProductLoading(true);
    try {
      const resp = await belecoApi.wp.getProductsMeta([productId]);
      const product = await belecoApi.inv.getProductData(productId);
      const { width, height, depth, warehousePlacements, instances } = product;

      const data = resp[productId];

      if (data) {
        if (data.is_bundle) {
          notifyWarning('Not all data is available for bundle');
        }
        setProductData({
          ...data,
          existingInstances: instances,
        });
        setDimensions({ width, height, depth });
        setWoPlacements(warehousePlacements);
      }
    } catch (e) {
      console.log('error', e);
      notifyError(e, 'Unable to load the product');
      push('/instances');
    } finally {
      setIsProductLoading(false);
    }
  };

  const loadAvailabilityPeriods = async ({ period = 1, locationId = null }) => {
    setIsAvailabilityPeriodsLoading(true);
    try {
      const startDate = moment().format('YYYY-MM-DD');
      const endDate = moment().add(period, 'month').format('YYYY-MM-DD');
      const response = await belecoApi.inv.getAvailabilityPeriods(
        productId,
        startDate,
        endDate,
        locationId,
      );
      if (response) {
        setAvailabilityPeriods(response);
      }
    } catch (e) {
      console.log('error', e);
      // no available data
      setAvailabilityPeriods([]);
    } finally {
      setIsAvailabilityPeriodsLoading(false);
    }
  };

  const loadProductInstances = async ({
    limit = null,
    offset = null,
    warehouseStatus = null,
    locationId = null,
  }) => {
    setIsRelatedInstancesLoading(true);
    try {
      const params = {};
      if (limit) {
        Object.assign(params, { limit });
      }
      if (warehouseStatus) {
        Object.assign(params, { warehouseStatus });
      }
      if (locationId) {
        Object.assign(params, { locationId });
      }
      if (offset) {
        Object.assign(params, { offset });
      }

      const response = await belecoApi.inv.getProductInstances(
        productId,
        params,
      );
      if (response) {
        const { rows = [], count = 0 } = response;
        setRelatedInstances(rows);
        setTotalRelatedInstances(count);
      }
    } catch (e) {
      notifyError(e, 'Unable to load related instances');
    } finally {
      setIsRelatedInstancesLoading(false);
    }
  };

  const updateDimensions = async ({ params }) => {
    setIsDimensionsLoading(true);
    try {
      const updatedProductData = await belecoApi.inv.updateProductData({
        productId,
        params,
      });
      const { width, height, depth } = updatedProductData;
      setDimensions({ width, height, depth });
    } catch (e) {
      notifyError(e, 'Unable to update the product');
    } finally {
      setIsDimensionsLoading(false);
    }
  };

  const updateWoPlacements = async ({ params }) => {
    setIsWoPlacementsLoading(true);
    const oldWarehousePlacement = [...woPlacements];
    try {
      const updatedProductData = await belecoApi.inv.updateProductData({
        productId,
        params,
      });
      const { warehousePlacements } = updatedProductData;
      setWoPlacements(
        warehousePlacements.length
          ? warehousePlacements
          : oldWarehousePlacement,
      );
    } catch (e) {
      notifyError(e, 'Unable to update the product');
    } finally {
      setIsWoPlacementsLoading(false);
    }
  };

  useEffect(() => {
    loadProduct();
    loadLocationsAvailability();
  }, []);

  const returnValues = useMemo(
    () => ({
      productId,
      productData,
      isProductLoading,
      availabilityPeriods,
      locationsAvailability,
      isAvailabilityPeriodsLoading,
      isLocationsAvailabilityLoading,
      relatedInstances,
      totalRelatedInstances,
      isRelatedInstancesLoading,
      dimensions,
      isDimensionsLoading,
      woPlacements,
      isWoPlacementsLoading,
      loadProduct,
      loadAvailabilityPeriods,
      loadLocationsAvailability,
      loadProductInstances,
      updateWoPlacements,
      updateDimensions,
      setIsWoPlacementsLoading,
      setIsDimensionsLoading,
    }),
    [
      productData,
      availabilityPeriods,
      locationsAvailability,
      relatedInstances,
      totalRelatedInstances,
      isProductLoading,
      isRelatedInstancesLoading,
      isAvailabilityPeriodsLoading,
      isLocationsAvailabilityLoading,
      dimensions,
      isDimensionsLoading,
      woPlacements,
      isWoPlacementsLoading,
    ],
  );

  return (
    <ProductDetailsContext.Provider value={returnValues}>
      {children}
    </ProductDetailsContext.Provider>
  );
};

ProductDetailsProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default ProductDetailsProvider;
