const ROLES = {
  ADMIN: 'administrator',
  WO_WORKER: 'bm_warehouse_worker',
  TRANSPORT_CARRIER: 'bm_transport_carrier',
};
export default ROLES;

export const ROLES_TITLES = {
  ADMIN: 'Administrator',
  WO_WORKER: 'Warehouse worker',
  TRANSPORT_CARRIER: 'Transport carrier',
};

export const ROLES_LINKS = {
  ADMIN: '/dashboard',
  WO_WORKER: '/warehouse-orders',
  TRANSPORT_CARRIER: '/transports',
};

export const hasRole = (userRoles, role) => {
  return Object.values(userRoles).includes(role);
};
