/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import { useContext, memo } from 'react';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { isNil, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import TransportStatus from '../TransportStatus';
import TransportsTypeIcon from '../TransportTypeIcon';
import { volumeUnitConverter } from '../../../../utils';
import TitledDataCell from '../../../../components/TitledDataCell';
import TransportsInfoIcon from '../TransportsInfoIcon';
import { hasStatusIssue } from '../../../TransportDetails/helpers';
import { TRANSPORTING_STATUS_OPTIONS } from '../../../../utils/constants';
import { TransportDetailsContext } from '../../../../context/TransportDetails/TransportDetailsContext';

const useStyles = makeStyles(() => ({
  rootContainer: {
    padding: 20,
    border: '1px solid #ccc',
    borderRadius: 7,
    backgroundColor: '#ffffff',
    minHeight: '80vh',
  },

  rowContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 15,
    borderTop: '10px solid #EBEBEB',
  },

  basicDataColumn: {
    width: '50%',
  },

  actionColumn: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 'max-content',
    alignItems: 'center',
  },

  transportDetails: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '15px',
  },
  subTitleText: {
    color: '#8B8B8B',
    fontSize: 10,
    letterSpacing: '0.5px',
  },
  transportsDirection: {
    display: 'flex',
    alignItems: 'center',
  },
  itemsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const TransportsRow = ({ item }) => {
  const {
    instances,
    reference,
    volume,
    from,
    to,
    name,
    companyName,
    addressLine1,
    addressLine2,
    publicId,
    location,
    city,
    type,
    status,
    tms,
  } = item;
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useHistory();

  let isPhoneBoothInTransport = false;
  const shouldShowInfoIcon = instances.some(
    ({ isMountingRequired, warehouseStatus, transports, product }) => {
      // for completed transports check only warehouse status
      if (status === TRANSPORTING_STATUS_OPTIONS.COMPLETED.value) {
        return hasStatusIssue({ status, type }, warehouseStatus);
      }

      const { isPhoneBooth } = product;
      if (isPhoneBooth) {
        isPhoneBoothInTransport = true;
      }

      return (
        isMountingRequired ||
        hasStatusIssue({ status, type }, warehouseStatus) ||
        (transports && transports.length === 1) ||
        product.isPhoneBooth
      );
    },
  );

  return (
    <div
      className={classes.rowContainer}
      onClick={() => push(`transports/${publicId}`)}
    >
      <div className={classes.basicDataColumn}>
        <Typography variant='body2'>
          {`${name}  ${isNil(companyName) ? '' : `, ${companyName}`}`}
          {!isNil(city) && ` (${city})`}
        </Typography>
        <Typography variant='body2' className={classes.subTitleText}>
          {addressLine1} <br />
          {addressLine2}
        </Typography>
        <Typography variant='body2' className={classes.subTitleText}>
          {moment(from).format('YYYY-MM-DD')}
        </Typography>
        <Typography variant='body2' className={classes.subTitleText}>
          {t('TMS')} - {tms?.title || t('N/A')}
        </Typography>
      </div>
      <div className={classes.actionColumn}>
        <TransportStatus
          statusStyles={{ width: '80%', minWidth: 140 }}
          transportRecord={item}
        />
        {shouldShowInfoIcon && (
          <TransportsInfoIcon
            isPhoneBoothInTransport={isPhoneBoothInTransport}
          />
        )}
      </div>
      <div className={classes.transportsDirection}>
        <Typography variant='body2'>{city}</Typography>
        <div style={{ transform: 'scale(0.7)', margin: '0px 5px' }}>
          <TransportsTypeIcon deliveryType={item.type} />
        </div>
        <Typography variant='body2'>{location.city} (storage) </Typography>
      </div>
      <div className={classes.transportDetails}>
        <TitledDataCell cellName={t('Ref')} data={reference.substr(0, 7)} />
        <TitledDataCell
          cellName={t('Items')}
          data={
            <Typography variant='body2'>{instances.length || ''}</Typography>
          }
        />
        <TitledDataCell
          cellName={t('Volume')}
          data={volumeUnitConverter(volume)}
        />
        <TitledDataCell
          cellName={t('Time')}
          data={`${moment(from).format('HH:mm')} - ${moment(to).format(
            'HH:mm',
          )}`}
        />
      </div>
    </div>
  );
};
TransportsRow.propTypes = {
  item: PropTypes.object.isRequired,
};

const TransportsListMobile = ({ items, isLoading }) => {
  const { t } = useTranslation();
  const { isUpdateLoading } = useContext(TransportDetailsContext);
  const NoItemsFound = () => {
    return (
      <div
        style={{
          height: '200px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant='body2'> {t('No items found')}</Typography>
      </div>
    );
  };

  return (
    <div>
      {items.map((item) => (
        <TransportsRow item={item} key={item.publicId} />
      ))}
      {(isLoading || isUpdateLoading) && <LoadingOverlay />}
      {isEmpty(items) && <NoItemsFound />}
    </div>
  );
};

TransportsListMobile.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool,
};
export default memo(TransportsListMobile);
