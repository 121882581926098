import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ORDER_STATUS } from '../../../../utils/constants';

const buttonsContainer = (theme) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '.quickActionButton': {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '300px',
      marginLeft: '0',
      marginBottom: '16px',
    },
  },
});

const QuickActionButtons = ({
  currentOrderStatus,
  location,
  handleStatusUpdate,
  handleCompleteWarehouseOrder,
}) => {
  const { t } = useTranslation();

  if (
    currentOrderStatus === ORDER_STATUS.CANCELLED.value ||
    !location.wms.isManagedByBeleco
  ) {
    return null;
  }

  return (
    <Box sx={buttonsContainer}>
      {currentOrderStatus === ORDER_STATUS.CREATED.value && (
        <Button
          className='quickActionButton'
          variant='contained'
          color='primary'
          onClick={() => {
            handleStatusUpdate(ORDER_STATUS.IN_TRANSIT.value);
          }}
        >
          {t('Transit')}
        </Button>
      )}
      {currentOrderStatus === ORDER_STATUS.IN_TRANSIT.value && (
        <Button
          className='quickActionButton'
          variant='contained'
          color='primary'
          onClick={() => {
            handleStatusUpdate(ORDER_STATUS.PROCESSING.value);
          }}
        >
          {t('Process')}
        </Button>
      )}
      {currentOrderStatus === ORDER_STATUS.PROCESSING.value && (
        <Button
          className='quickActionButton'
          variant='contained'
          color='primary'
          onClick={handleCompleteWarehouseOrder}
        >
          {t('Complete')}
        </Button>
      )}
    </Box>
  );
};

QuickActionButtons.propTypes = {
  currentOrderStatus: PropTypes.string.isRequired,
  location: PropTypes.object,
  handleStatusUpdate: PropTypes.func.isRequired,
  handleCompleteWarehouseOrder: PropTypes.func.isRequired,
};

export default QuickActionButtons;
