import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import LocationContext from '../../../../context/Location/LocationContext';
import useNotificator from '../../../../utils/useNotificator';
import belecoApi from '../../../../api';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import WarehouseChart from './WarehouseChart';

const WarehouseChartBlock = () => {
  const { t } = useTranslation();
  const { userLocation } = useContext(LocationContext);

  const [warehouses, setWarehouses] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { notifyError } = useNotificator();

  const dateFrom = moment().subtract(19, 'days'); // last 20 days
  const dateTo = moment(); // today

  const dateFromFormatted = dateFrom.format('YYYY-MM-DD');
  const dateToFormatted = dateTo.format('YYYY-MM-DD');

  const getData = async () => {
    setLoading(true);

    try {
      const params = {
        offset: 0,
        etaAfter: dateFromFormatted,
        etaBefore: dateToFormatted,
        locationId: userLocation?.id || '',
      };

      const { rows } = await belecoApi.inv.getWarehouseOrders(params);
      setWarehouses(rows);
    } catch (err) {
      notifyError(err, t('Failed to load transports'));
    } finally {
      setLoading(false);
    }
  };

  const returnListOfDates = () => {
    const nbDays = dateTo.diff(dateFrom, 'days') + 1;
    return [...Array(nbDays).keys()].map((i) =>
      dateFrom.clone().add(i, 'd').format('YYYY-MM-DD'),
    );
  };

  const formattedData = useMemo(() => {
    if (!warehouses.length) return [];

    const datesArr = returnListOfDates();
    return datesArr.map((date) => {
      const dataForDate = warehouses.filter((el) => {
        return moment(el.estimatedArrival).format('YYYY-MM-DD') === date;
      });
      const findTotalProductsForOrder = (arr) => {
        return arr.reduce((sum, { volume }) => sum + volume, 0);
      };
      return {
        name: moment(date).format('YYYY-MM-DD'),
        orders: dataForDate.length,
        products: Math.ceil(
          dataForDate.reduce((n, { instances }) => n + instances.length, 0),
        ),
        storageVolume: Math.ceil(
          dataForDate.reduce((n, { instances }) => {
            const sumOfDayInstances = findTotalProductsForOrder(instances);
            return n + sumOfDayInstances;
          }, 0),
        ),
      };
    });
  }, [warehouses]);

  useEffect(() => {
    getData();
  }, [userLocation?.id]);

  return (
    <Box
      sx={{
        height: '240px',
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLoading && <LoadingOverlay />}

      {!isLoading && !formattedData?.length ? (
        <Typography>No results found.</Typography>
      ) : (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            '& > div': {
              flex: 1,
              width: '0 !important',
            },
          }}
        >
          <WarehouseChart data={formattedData} />
        </Box>
      )}
    </Box>
  );
};

export default WarehouseChartBlock;
