const requiredProperties = ['id', 'title', 'wms', 'zipCode'];
const validateLocations = (locationsToValidate, isNested = false) => {
  return locationsToValidate.filter((row) =>
    requiredProperties.every((property) =>
      isNested ? row.location[property] : row[property],
    ),
  );
};

export default validateLocations;
