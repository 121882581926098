import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import LocationContext from '../../../../context/Location/LocationContext';
import useNotificator from '../../../../utils/useNotificator';
import belecoApi from '../../../../api';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import TransportsChart from './TransportsChart';

const TransportsChartBlock = () => {
  const { t } = useTranslation();
  const { userLocation } = useContext(LocationContext);

  const [transports, setTransports] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { notifyError } = useNotificator();

  const dateFrom = moment(); // today
  const dateTo = moment().add(19, 'days'); // 20 day

  const dateFromFormatted = dateFrom.format('YYYY-MM-DD');
  const dateToFormatted = dateTo.format('YYYY-MM-DD');

  const getTransportsData = async () => {
    setLoading(true);

    try {
      const params = {
        offset: 0,
        from: dateFromFormatted,
        to: dateToFormatted,
        locationId: userLocation?.id || '',
      };

      const { rows } = await belecoApi.inv.getTransports(params);

      setTransports(rows);
    } catch (err) {
      notifyError(err, t('Failed to load transports'));
    } finally {
      setLoading(false);
    }
  };

  const returnListOfDates = () => {
    const nbDays = dateTo.diff(dateFrom, 'days') + 1;
    return [...Array(nbDays).keys()].map((i) =>
      dateFrom.clone().add(i, 'd').format('YYYY-MM-DD'),
    );
  };

  const formattedTransportInfo = useMemo(() => {
    if (!transports.length) return [];

    const datesArr = returnListOfDates();
    return datesArr.map((date) => {
      const dataForDate = transports.filter((el) => {
        return moment(el.from).format('YYYY-MM-DD') === date;
      });
      return {
        name: moment(date).format('YYYY-MM-DD'),
        deliveries: dataForDate.filter((el) => el.type === 'DELIVERY').length,
        pickups: dataForDate.filter((el) => el.type === 'PICKUP').length,
        storageVolume: Math.ceil(
          dataForDate.reduce((n, { volume }) => n + volume, 0),
        ),
      };
    });
  }, [transports]);

  useEffect(() => {
    getTransportsData();
  }, [userLocation?.id]);

  return (
    <Box
      sx={{
        height: '240px',
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLoading && <LoadingOverlay />}

      {!isLoading && !formattedTransportInfo?.length ? (
        <Typography>No results found.</Typography>
      ) : (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            '& > div': {
              flex: 1,
              width: '0 !important',
            },
          }}
        >
          <TransportsChart data={formattedTransportInfo} />
        </Box>
      )}
    </Box>
  );
};

export default TransportsChartBlock;
