import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#000000',
    fontSize: 16,
    fontWeight: 500,
  },
  actionButton: {
    height: 37,
    borderRadius: 4,
  },
  dialog: {
    zIndex: theme.zIndex.tooltip,
  },
}));

const DeleteReportDialog = ({ isOpen, onClose, onDeleteConfirm }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      maxWidth='sm'
      fullWidth
      onClose={onClose}
      className={classes.dialog}
    >
      <DialogTitle>
        <Typography variant='body1' className={classes.title}>
          {t('Are you sure?')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Do you want to delete a report?')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={onClose}
          className={classes.actionButton}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant='contained'
          className={classes.actionButton}
          onClick={onDeleteConfirm}
        >
          {t('Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteReportDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteConfirm: PropTypes.func.isRequired,
};

export default DeleteReportDialog;
