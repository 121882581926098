import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { LOCALES } from '../utils/constants';
import en from './languages/en.json';
import sv from './languages/sv.json';
// import da from './languages/da.json';
// import de from './languages/de.json';
// import fi from './languages/fi.json';
// import no from './languages/no.json';

const resources = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
  // Hide unused languages
  // da: {
  //   translation: da,
  // },
  // de: {
  //   translation: de,
  // },
  // fi: {
  //   translation: fi,
  // },
  // no: {
  //   translation: no,
  // },
};

if (
  process.env.REACT_APP_ENV === 'dev' ||
  process.env.REACT_APP_ENV === 'localhost' ||
  process.env.REACT_APP_ENV === 'stage' ||
  process.env.REACT_APP_ENV === 'feature'
) {
  i18n.use(detector);
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false,
  },

  returnEmptyString: false,
});

// Update localization for html tag and other external library
i18n.on('languageChanged', (lng) => {
  if (lng) {
    document.documentElement.setAttribute('lang', lng);

    const momentLng = LOCALES[lng].localeCode;
    moment.locale(momentLng);
  }
});

// Init localization language for external library
document.documentElement.setAttribute('lang', i18n.resolvedLanguage);
moment.locale(LOCALES[i18n.resolvedLanguage].localeCode);

export default i18n;
