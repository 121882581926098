import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ROLES from '../../utils/roles';
import { logout } from '../../store/reducers/auth';
import useNotificator from '../../utils/useNotificator';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const { notifyError } = useNotificator();
  const { authenticated, user } = useSelector((state) => state.auth);

  const allowedRoles = [ROLES.ADMIN, ROLES.WO_WORKER, ROLES.TRANSPORT_CARRIER];

  const userHasPermissions =
    user &&
    Object.values(user.roles).some((role) => allowedRoles.includes(role));

  if (user && !userHasPermissions) {
    notifyError(
      null,
      'You are not allowed to use this app, please contact your administrator for more details.',
      true,
    );
  }

  if (!authenticated || !userHasPermissions) {
    dispatch(logout());
    return <Redirect to='/login' />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? <Component {...props} /> : <Redirect to='/login' />
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func,
};

export default ProtectedRoute;
