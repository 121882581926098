import PropTypes from 'prop-types';
import { Typography, Box, CircularProgress } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from 'react-i18next';
import {
  TRANSPORTING_STATUS_OPTIONS,
  STATUS_COLOR_MAP,
} from '../../../../utils/constants';

const styles = {
  mainStatus: (theme) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '25px',
    backgroundColor: '#ECECEC',
    borderRadius: '20px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('xl')]: {
      minWidth: '160px',
    },
  }),
  cancelledStatus: {
    border: '1px solid #ececec',
  },
  failedToDeliverStatus: {
    backgroundColor: '#DA4453!important',

    '& p': {
      color: '#ffffff!important',
    },
  },
  textStatus: {
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
    textTransform: 'initial',
  },
  textStatusCancelled: {
    color: '#8b8b8b',
  },
};

const TransportStatus = ({ transportRecord, statusStyles = {} }) => {
  const { t } = useTranslation();

  const { tms, status, instances = [] } = transportRecord;
  const isManagedByBeleco = !!tms?.isManagedByBeleco;
  const transportIsCanceled =
    transportRecord.status === TRANSPORTING_STATUS_OPTIONS.CANCELLED.value;

  const isPartialDelivery =
    status === TRANSPORTING_STATUS_OPTIONS.COMPLETED.value &&
    instances.some(
      ({ transportInstances }) => !transportInstances?.isCompleted,
    );

  const getLabel = (transport) => {
    // Failed status
    if (transport.isNoShow) {
      return t(TRANSPORTING_STATUS_OPTIONS.FAILED.label);
    }

    // Partial delivery status
    if (isPartialDelivery) {
      return t(TRANSPORTING_STATUS_OPTIONS.PARTIAL.label);
    }

    // Regular status
    return (
      t(TRANSPORTING_STATUS_OPTIONS[transport.status].label) || t('Unknown')
    );
  };

  if (!transportRecord) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={[
        styles.mainStatus,
        {
          backgroundColor:
            STATUS_COLOR_MAP[
              (isPartialDelivery && 'PARTIAL') ||
                transportRecord.status ||
                'N/A'
            ],
        },
        ...(transportIsCanceled ? [styles.cancelledStatus] : []),
        ...(transportRecord.isNoShow ? [styles.failedToDeliverStatus] : []),
        ...(statusStyles ? [statusStyles] : []),
      ]}
    >
      <Typography
        variant='body2'
        sx={{
          ...styles.textStatus,
          ...(transportIsCanceled ? styles.textStatusCancelled : {}),
        }}
      >
        {!isManagedByBeleco && (
          <LockIcon sx={{ fontSize: '12px', marginRight: '3px' }} />
        )}
        {transportRecord && getLabel(transportRecord)}
      </Typography>
    </Box>
  );
};

TransportStatus.propTypes = {
  transportRecord: PropTypes.object,
  statusStyles: PropTypes.object,
};

export default TransportStatus;
