import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Container, CssBaseline } from '@mui/material';
import { Userpilot } from 'userpilot';
import Login from './containers/Login';
import Main from './containers/Main';
import ProtectedRoute from './components/ProtectedRoute';

try {
  if (process.env.REACT_APP_USERPILOT_ENABLED === 'true') {
    Userpilot.initialize(process.env.REACT_APP_USERPILOT_TOKEN);
  }
} catch (e) {
  console.error('failed to initialize UserPilot');
}

function App() {
  return (
    <Container
      className='appContainer'
      style={{ height: '100%' }}
      maxWidth={false}
      disableGutters
    >
      <CssBaseline />

      <Switch>
        <Route path='/login' component={Login} exact />
        <ProtectedRoute path='/' component={Main} />
        <Redirect to='/' />
      </Switch>
    </Container>
  );
}

export default App;
