import { useContext } from 'react';
import { Grid, Typography, Fade, Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import ImageLoader from '../../../../components/ImageLoader';
import decode from '../../../../utils/htmlDecode';
import { ProductDetailsContext } from '../../../../context/ProductDetails/ProductDetailsContext';
import LocationContext from '../../../../context/Location/LocationContext';

const styles = {
  container: {
    position: 'relative',
    backgroundColor: '#ffffff',
    minHeight: '320px',
    padding: '20px',
    border: '1px solid #cecece',
    borderRadius: '7px',
  },
  header: {
    marginBottom: '15px',
    fontSize: '30px',
  },
  description: {
    minHeight: '80px',
    fontSize: '12px',
    lineHeight: 1.5,
  },
  image: {
    alignItems: 'center',
    minHeight: '300px',
    maxHeight: '400px',
    border: '1px solid #cecece',
    padding: '20px',
    '& img': {
      position: 'absolute',
      objectFit: 'contain',
    },
  },
  valueBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  valueItem: {
    fontSize: '12px',
    lineHeight: 1.5,
  },
};

const ProductView = () => {
  const { t } = useTranslation();

  const { productData, isProductLoading, locationsAvailability } = useContext(
    ProductDetailsContext,
  );
  const { userLocation } = useContext(LocationContext);

  const renderInstancesStats = () => {
    if (locationsAvailability.length === 0) return null;

    const neededLocationObj = userLocation
      ? locationsAvailability.filter((el) => el.location.id === userLocation.id)
      : locationsAvailability;

    const values = neededLocationObj.reduce(
      (acc, item) => {
        acc[0] += item.qtyAvailable;
        acc[1] += item.qtyTotal;
        acc[2] += item.qtyInvisible;
        return acc;
      },
      [0, 0, 0],
    );

    return (
      <>
        <Box sx={styles.valueBlock}>
          <Typography variant='body2' sx={styles.valueItem}>
            {t('In storage')}:
          </Typography>
          <Typography variant='body2'>
            {values[0]} / {values[1]}
          </Typography>
        </Box>
        <Box sx={styles.valueBlock}>
          <Typography variant='body2' sx={styles.valueItem}>
            {t('Available to book')}:
          </Typography>
          <Typography variant='body2'>{values[0]}</Typography>
        </Box>
        <Box sx={styles.valueBlock}>
          <Typography variant='body2' sx={styles.valueItem}>
            {t('Invisible')}:
          </Typography>
          <Typography variant='body2'>{values[2]}</Typography>
        </Box>
      </>
    );
  };

  if (isProductLoading && isEmpty(productData)) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <Fade in>
        <div>
          <Grid container direction='row' alignItems='stretch' spacing={2}>
            <Grid item xl={4} md={6} xs={12}>
              <ImageLoader
                src={productData.image}
                alt={t('Product')}
                sx={styles.image}
              />
            </Grid>
            <Grid item xl={8} md={6} xs={12}>
              <Typography sx={styles.header} variant='body2'>
                {decode(productData.title)}
              </Typography>
              <Typography sx={styles.description} variant='body2'>
                {decode(productData.description)}
              </Typography>
              <Box sx={styles.valueBlock}>
                <Typography variant='body2' sx={styles.valueItem}>
                  {t('SKU')}:
                </Typography>
                <Typography variant='body2'>{productData.sku}</Typography>
              </Box>
              {renderInstancesStats()}
            </Grid>
          </Grid>
        </div>
      </Fade>
      {isProductLoading && <LoadingOverlay />}
    </>
  );
};

export default ProductView;
