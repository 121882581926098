import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isNil } from 'lodash';
import { BachOrderTableWrapper } from './styles';

const useStyles = makeStyles(BachOrderTableWrapper);

const ColumnDataRow = ({ title, data, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.columnDataRow}>
      <Typography variant='body2'>{title}</Typography>
      {children || (
        <Typography>
          {isNil(data) || data.error ? <em>Not specified</em> : data}
        </Typography>
      )}
    </div>
  );
};

ColumnDataRow.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  children: PropTypes.node,
};

export default ColumnDataRow;
