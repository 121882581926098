import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MultiSelect from '../../../components/MultiSelect';
import TransportsListMobile from '../components/TransportsListMobile';
import RangeDatePicker from '../../../components/RangeDatePicker';
import OutlinedSelector from '../../../components/OutlinedSelector';
import {
  DIRECTION_FILTER_OPTIONS,
  FAILURE_FILTER_OPTIONS,
  TRANSPORTING_STATUS_OPTIONS,
} from '../../../utils/constants';
import MobileHeaderBox from '../../../components/CustomComponents/MobileHeaderBox';
import FiltersBlock from '../../../components/CustomComponents/FiltersBlock';
import { getTMSListAsSelectOptions } from '../../../utils';

const styles = {
  mobileContainer: {
    maxHeight: '100%',
    backgroundColor: 'white',
    overflow: 'auto',
  },
};

const TransportsForMobile = ({
  startDateFilter,
  endDateFilter,
  handleDateFilter,
  resetFiltersToDefaults,
  showHideFailedItems,
  setShowHideFailedItems,
  typeFilter,
  setTypeFilter,
  tmsId,
  setTmsId,
  status,
  setStatus,
  transports,
  total,
  isLoadingTransports,
  TMSList,
  isLoadingTMSList,
  handleLoadMore,
}) => {
  const { t } = useTranslation();

  const [showFilters, setShowFilters] = useState(false);

  const tmsOptions = getTMSListAsSelectOptions(TMSList);

  const subtitle = `${
    startDateFilter ? startDateFilter.format('YYYY-MM-DD') : ''
  } ... ${
    endDateFilter ? endDateFilter.format('YYYY-MM-DD') : ''
  } - ${total} ${t('Transports')}`;

  const statusOptions = Object.values(TRANSPORTING_STATUS_OPTIONS).map(
    ({ value, label }) => ({ title: label, value }),
  );

  const containerRef = useRef();

  const handleListScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (
        scrollTop + clientHeight === scrollHeight &&
        total > transports.length
      ) {
        handleLoadMore();
      }
    }
  };

  return (
    <Box
      sx={styles.mobileContainer}
      onScroll={handleListScroll}
      ref={containerRef}
    >
      <MobileHeaderBox
        title={t('Transports')}
        subtitle={subtitle}
        showFilters={setShowFilters}
      />
      <FiltersBlock
        showFilters={showFilters}
        resetFiltersToDefaults={resetFiltersToDefaults}
      >
        <MultiSelect
          label={t('Status')}
          width={170}
          options={statusOptions}
          onChange={setStatus}
          value={status}
        />
        <OutlinedSelector
          label={t('TMS')}
          options={tmsOptions}
          onFilterChange={setTmsId}
          filterValue={tmsId}
          disabled={isLoadingTMSList}
        />
        <OutlinedSelector
          label={t('Direction')}
          options={DIRECTION_FILTER_OPTIONS}
          onFilterChange={setTypeFilter}
          filterValue={typeFilter}
        />
        <OutlinedSelector
          label={t('Failure status')}
          options={FAILURE_FILTER_OPTIONS}
          onFilterChange={setShowHideFailedItems}
          filterValue={showHideFailedItems}
          width={180}
        />
        <RangeDatePicker
          startDate={startDateFilter}
          endDate={endDateFilter}
          onChange={handleDateFilter}
          numberOfMonths={1}
        />
      </FiltersBlock>

      <TransportsListMobile
        isLoading={isLoadingTransports}
        items={transports}
      />
    </Box>
  );
};

TransportsForMobile.propTypes = {
  startDateFilter: PropTypes.object,
  endDateFilter: PropTypes.object,
  handleDateFilter: PropTypes.func,
  showHideFailedItems: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setShowHideFailedItems: PropTypes.func,
  typeFilter: PropTypes.string,
  setTypeFilter: PropTypes.func,
  tmsId: PropTypes.string,
  setTmsId: PropTypes.func,
  status: PropTypes.array,
  setStatus: PropTypes.func,
  resetFiltersToDefaults: PropTypes.func,
  transports: PropTypes.array,
  total: PropTypes.number.isRequired,
  isLoadingTransports: PropTypes.bool.isRequired,
  TMSList: PropTypes.array,
  isLoadingTMSList: PropTypes.bool,
  handleLoadMore: PropTypes.func,
};

export default TransportsForMobile;
