import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import jsQR from 'jsqr';
import ClearIcon from '@mui/icons-material/Clear';
import { ReactComponent as QRCodeIcon } from '../../assets/icons/Icon-scan-qr.svg';
import useNotificator from '../../utils/useNotificator';

const useStyles = makeStyles((theme) => ({
  video: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    zIndex: -9999,
  },
  canvas: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
    zIndex: 1399, // Snackbar library has 1400, so it stays on top
  },
  openButton: {
    color: '#000000',
    minWidth: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 48,
      width: 48,
      padding: '6px 0',
    },
  },
  openIcon: {
    width: 26,
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 32,
    width: 32,
  },
  closeButton: {
    position: 'fixed',
    top: 8,
    right: 8,
    zIndex: 1400,
  },
  closeIcon: {
    color: '#ffffff',
    fontSize: 40,
  },
  btnLabel: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
  },
}));

let requestId = null;

const QRScanner = ({ onQRScan }) => {
  const classes = useStyles();
  const { notifyError } = useNotificator();

  const qrScannerVideoRef = useRef(null);
  const qrScannerCanvasRef = useRef(null);

  const [isScannerOpen, setScannerOpenState] = useState(false);

  const onScannerClose = () => {
    qrScannerVideoRef.current.srcObject.getTracks().forEach((track) => {
      track.stop();
    });
    qrScannerVideoRef.current.srcObject = null;

    const { current: canvasElement } = qrScannerCanvasRef;
    const canvas = canvasElement.getContext('2d');
    canvas.clearRect(0, 0, canvasElement.width, canvasElement.height);

    window.cancelAnimationFrame(requestId);

    setScannerOpenState(false);
  };

  const drawLine = (begin, end) => {
    const canvas = qrScannerCanvasRef.current.getContext('2d');

    canvas.beginPath();
    canvas.moveTo(begin.x, begin.y);
    canvas.lineTo(end.x, end.y);
    canvas.lineWidth = 4;
    canvas.strokeStyle = '#DA4453';
    canvas.stroke();
  };

  const tick = () => {
    const { current: video } = qrScannerVideoRef;

    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      const { current: canvasElement } = qrScannerCanvasRef;
      const canvas = canvasElement.getContext('2d');

      canvasElement.height = video.videoHeight;
      canvasElement.width = video.videoWidth;
      canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
      const imageData = canvas.getImageData(
        0,
        0,
        canvasElement.width,
        canvasElement.height,
      );

      try {
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
        });

        if (code) {
          const { location, data } = code;

          // Use lines and not rect to account for tilted and crumpled labels
          drawLine(location.topLeftCorner, location.topRightCorner);
          drawLine(location.topRightCorner, location.bottomRightCorner);
          drawLine(location.bottomRightCorner, location.bottomLeftCorner);
          drawLine(location.bottomLeftCorner, location.topLeftCorner);

          if (data && typeof data === 'string') {
            try {
              onQRScan(data);
              onScannerClose();
            } catch (error) {
              notifyError(error, 'Invalid QR Code', false, {
                preventDuplicate: true,
              });
              requestId = window.requestAnimationFrame(tick);
            }
          }
        } else {
          requestId = window.requestAnimationFrame(tick);
        }
      } catch (error) {
        window.cancelAnimationFrame(requestId);
        requestId = window.requestAnimationFrame(tick);
      }
    } else {
      requestId = window.requestAnimationFrame(tick);
    }
  };

  const getUserMedia = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          height: { ideal: 480, max: 720 },
          facingMode: 'environment',
          frameRate: { ideal: 20, max: 30 },
        },
      })
      .then((stream) => {
        qrScannerVideoRef.current.srcObject = stream;
        qrScannerVideoRef.current.play();
        requestId = window.requestAnimationFrame(tick);
        setScannerOpenState(true);
      })
      .catch((error) => {
        notifyError(error, 'Failed to get user media');
      });
  };
  return (
    <div>
      <Button
        variant='text'
        onClick={getUserMedia}
        className={classes.openButton}
        classes={{ label: classes.btnLabel }}
      >
        <div className={classes.imgContainer}>
          <QRCodeIcon />
        </div>
      </Button>
      <video
        ref={qrScannerVideoRef}
        className={classes.video}
        playsInline
        autoPlay
        muted
      />
      <canvas
        ref={qrScannerCanvasRef}
        className={classes.canvas}
        hidden={!isScannerOpen}
      />
      {isScannerOpen ? (
        <IconButton
          onClick={onScannerClose}
          className={classes.closeButton}
          size='large'
        >
          <ClearIcon className={classes.closeIcon} />
        </IconButton>
      ) : null}
    </div>
  );
};

QRScanner.propTypes = {
  onQRScan: PropTypes.func.isRequired,
};

export default QRScanner;
