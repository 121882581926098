import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const styles = {
  dialogPaper: {
    maxWidth: 400,
  },
  dialogTitle: {
    paddingTop: '24px',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  dialogContent: {
    padding: '10px 0px',
    textAlign: 'center',
  },

  actionArea: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '0  30px 20px',
  },
};
const GenericPopup = ({
  setIsOpen,
  isOpened,
  title,
  text,
  action,
  actionText,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpened}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: 400,
        },
      }}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <DialogTitle variant='h5' sx={styles.dialogTitle}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2' sx={styles.dialogContent}>
          {text}
        </Typography>
        <Divider />
      </DialogContent>
      <DialogActions sx={styles.actionArea}>
        <Button
          onClick={() => {
            setIsOpen(false);
          }}
          color='primary'
          variant={action ? 'text' : 'contained'}
        >
          {action ? 'no' : 'ok'}
        </Button>
        {action ? (
          <Button
            onClick={() => {
              action();
              setIsOpen(false);
            }}
            color='primary'
            autoFocus
            variant='contained'
          >
            {actionText || t('Yes')}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
GenericPopup.propTypes = {
  isOpened: PropTypes.bool,
  // eslint-disable-next-line react/boolean-prop-naming
  setIsOpen: PropTypes.func,
  action: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  actionText: PropTypes.string,
};
export default GenericPopup;
