import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { t } from 'i18next';
import NoItemsFound from './noItemsPlaceholder';
import InstanceExpandedTable from './InstancesExpandedTable';

const styles = {
  tableHeaderContainer: {
    padding: '20px 0',

    '.tableHeaderName': {
      fontWeight: 500,
      fontFamily: 'Helvetica Neue, sans-serif',
      fontSize: '12px',
      color: '#DA4453',
      textTransform: 'uppercase',
    },
  },
  hideOnMobileBlock: (theme) => ({
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  }),
};

const InstancesTable = ({ data, isLoading }) => {
  if (isEmpty(data) && !isLoading) return <NoItemsFound />;

  const isAllInstancesCompleted = data.every((product) =>
    product.instances.every(
      (instance) => instance.warehouseOrderInstances.isCompleted,
    ),
  );

  return (
    <>
      <Grid container columnGap={1} sx={styles.tableHeaderContainer}>
        <Grid item xs sm={3.9} md={3.5} lg={4}>
          <Typography className='tableHeaderName'>{t('Products')}</Typography>
        </Grid>
        <Grid item sm={2} sx={styles.hideOnMobileBlock} textAlign='center'>
          <Typography className='tableHeaderName'>{t('SKU')}</Typography>
        </Grid>
        <Grid item sm={2} sx={styles.hideOnMobileBlock} textAlign='center'>
          <Typography className='tableHeaderName'>{t('Volume')}</Typography>
        </Grid>
        <Grid
          item
          sm={isAllInstancesCompleted ? true : 1}
          sx={styles.hideOnMobileBlock}
          textAlign='right'
        >
          <Typography className='tableHeaderName'>{t('Amount')}</Typography>
        </Grid>
      </Grid>
      {data.map((product) => (
        <InstanceExpandedTable
          key={product.productId}
          product={product}
          isAllInstancesCompleted={isAllInstancesCompleted}
        />
      ))}
    </>
  );
};

InstancesTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

export default InstancesTable;
