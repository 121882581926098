import { useContext, useState } from 'react';
import { Box, IconButton, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { WarehouseDetailsContext } from '../WarehouseDetailsContext';
import GradientLoadingOverlay from '../../../components/GradientLoadingOverlay';

const styles = {
  instanceActionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  iconButton: {
    minWidth: 'unset',
    width: '22px',
    height: '22px',
    backgroundColor: '#A3D8A3',
  },
};

const WarehouseOrderInstanceActions = ({
  publicId,
  warehouseOrderInstances,
  productId,
  productToComplete,
}) => {
  const [isCompletedLoading, setIsCompletedLoading] = useState(false);
  const { t } = useTranslation();

  const { warehouseOrderData, updateInstanceCompletedStatus } = useContext(
    WarehouseDetailsContext,
  );

  const completeInstance = async (isCompleted) => {
    setIsCompletedLoading(true);
    await updateInstanceCompletedStatus({
      publicId: warehouseOrderData.publicId,
      instanceId: publicId,
      isCompleted,
    });
    setIsCompletedLoading(false);
  };

  if (
    isCompletedLoading ||
    (productToComplete === productId && !warehouseOrderInstances.isCompleted)
  )
    return <GradientLoadingOverlay height={22} />;

  return (
    <Box sx={styles.instanceActionContainer}>
      {warehouseOrderData.orderStatus === 'PROCESSING' &&
        (!warehouseOrderInstances.isCompleted ? (
          <Button
            variant='contained'
            sx={{ height: '22px', textTransform: 'none' }}
            onClick={() => completeInstance(true)}
          >
            {t('Completed')}
          </Button>
        ) : (
          <IconButton
            sx={styles.iconButton}
            onClick={() => completeInstance(false)}
            size='large'
          >
            <CheckIcon sx={{ color: '#ffffff', fontSize: '18px' }} />
          </IconButton>
        ))}
    </Box>
  );
};

WarehouseOrderInstanceActions.propTypes = {
  publicId: PropTypes.string.isRequired,
  warehouseOrderInstances: PropTypes.shape({
    isCompleted: PropTypes.bool.isRequired,
  }).isRequired,
  productId: PropTypes.number.isRequired,
  productToComplete: PropTypes.number,
};

export default WarehouseOrderInstanceActions;
