export const RelatedInstancesListStyles = (theme) => ({
  container: {
    minHeight: 350,
    marginTop: 20,
    padding: 20,
    backgroundColor: '#ffffff',
    border: '1px solid #ccc',
    borderRadius: '7px',
    '& .MuiTableCell-root.MuiTableCell-body': {
      padding: '2px 5px',
      maxWidth: 100,
      textAlign: 'center',
    },
    '& a:visited ,& a:active, & a:link': {
      color: '#000',
    },
    // hide empty rows
    '& .MuiTableBody-root tr.MuiTableRow-root:not([index])': {
      display: 'none',
    },
    '& span.MuiCheckbox-root': {
      color: '#000!important',
    },
  },
  tableName: {
    fontSize: 32,
    [theme.breakpoints.down('lg')]: {
      fontSize: 28,
    },
    fontStyle: 'normal',
    fontStretch: 'normal',
    textTransform: 'uppercase',
    lineHeight: 1,
  },
  printButton: {
    whiteSpace: 'nowrap',
  },
  filtersBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: '20px 0px',
    '& > div': {
      margin: 4,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      minHeight: 90,
    },
  },
  filerField: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
});

export const PrintLabelsDialogStyles = (theme) => ({
  container: {
    backgroundColor: '#ffffff',
    margin: 0,
    padding: '0px',
    border: 'none',
    '& .MuiTableCell-root.MuiTableCell-body': {
      padding: '2px 5px',
      maxWidth: 100,
      textAlign: 'center',
    },
    '& a:visited ,& a:active, & a:link': {
      color: '#000',
    },
    // hide empty rows
    '& .MuiTableBody-root tr.MuiTableRow-root:not([index])': {
      display: 'none',
    },
    '& span.MuiCheckbox-root': {
      color: '#000!important',
    },
  },
  printButton: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 'auto',
    },
  },
  filtersBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '20px 0px',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      minHeight: 70,
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  dialogTitle: {
    textTransform: 'uppercase',
    fontSize: 22,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 10,
      marginRight: 'auto',
      fontSize: 20,
    },
  },
  headerTitle: {
    textTransform: 'uppercase',
  },
});

export const DimensionsCardStyles = () => ({
  container: {
    minHeight: 80,
    marginBottom: 20,
    position: 'relative',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    borderBottom: '2px #CCC solid',
    padding: '10px 0px 5px',
    fontSize: 20,
  },
  storageItem: {
    display: 'grid',
    gridTemplateColumns: '40% 30% 30%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleBox: {
    borderBottom: '2px solid #CCC',
  },
  titleText: {
    textTransform: 'uppercase',
  },
  storageName: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  storageInput: {
    margin: 5,
    '& input': {
      padding: '5px',
      textAlign: 'center',
      fontFamily: 'Helvetica Neue, sans-serif',
    },
  },
  updateButton: {
    width: 150,
    marginLeft: 'auto',
    marginTop: 15,
  },
  content: {
    minHeight: 171,
  },
});
