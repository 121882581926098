import { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '../../components/LoadingOverlay';
import InstanceForm from './InstanceForm';
import ImageLoader from '../../components/ImageLoader';
import decode from '../../utils/htmlDecode';
import { InstanceDetailsContext } from '../../context/InstanceDetails/InstanceDetailsContext';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    backgroundColor: '#ffffff',
    minHeight: 400,
    padding: 20,
    border: '1px solid #c5c5c5',
    borderRadius: 7,

    [theme.breakpoints.down('md')]: {
      border: 'none',
      borderRadius: 0,
    },
  },
  header: {
    marginBottom: 20,
    '& p': {
      fontSize: 32,
      marginBottom: 10,
    },
    '& a': {
      textDecoration: 'none',
    },
    '& h5': {
      color: theme.palette.title.highlighted,
      textTransform: 'uppercase',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    maxHeight: 400,
    border: '1px solid #cecece',
  },
}));

const InstanceView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { instanceData, isInstanceDataLoading } = useContext(
    InstanceDetailsContext,
  );

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant='body1'> {instanceData.publicId} </Typography>
        <NavLink to={`/products/${instanceData.productId}`}>
          <Typography display='inline' variant='h5'>
            {decode(instanceData.title)}
          </Typography>
        </NavLink>
      </div>

      <Grid container direction='row' alignItems='stretch' spacing={2}>
        <Grid item md={7} xs={12}>
          <ImageLoader
            src={instanceData.image}
            alt={t('Instance')}
            className={classes.image}
          />
        </Grid>

        <Grid item md={5} xs={12}>
          <InstanceForm />
        </Grid>
      </Grid>

      {isInstanceDataLoading && <LoadingOverlay />}
    </div>
  );
};

export default InstanceView;
