import { useState, useEffect, useRef, useMemo } from 'react';
import { Box, Divider, IconButton, Paper, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import TitleContainer from '../../components/TitleContainer';
import SectionContainer from '../../components/CustomComponents/SectionContainer';
import useNotificator from '../../utils/useNotificator';
import belecoApi from '../../api';
import CustomDataGrid from '../../components/CustomDataGrid';
import RangeDatePicker from '../../components/RangeDatePicker';
import GradientLoadingOverlay from '../../components/GradientLoadingOverlay';
import sortAssetProvidersForSelect from '../../utils/sortAssetProvidersForSelect';
import OutlinedSelector from '../../components/OutlinedSelector';
import { isMobileDevice } from '../../utils';
import formatMoney from '../../utils/money';

const APInputSX = {
  mr: {
    xs: 0,
    sm: '20px',
  },
  width: {
    xs: '100%',
    sm: '200px',
  },
  mb: {
    xs: '8px',
    sm: 0,
  },
};

const Payouts = () => {
  const { notifyError } = useNotificator();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const initUrlParams = new URLSearchParams(history.location.search);

  const notInitialRender = useRef(false);

  const initFilteringOptions = {
    createdAfter: initUrlParams.get('createdAfter')
      ? moment(initUrlParams.get('createdAfter'))
      : moment().subtract(1, 'month').format('YYYY-MM-DD'), // Default to only show last month's payouts,
    createdBefore: initUrlParams.get('createdBefore')
      ? moment(initUrlParams.get('createdBefore'))
      : '',
    ownerId: initUrlParams.get('ownerId') ? initUrlParams.get('ownerId') : '',
  };

  const [isLoadingPayouts, setIsLoadingPayouts] = useState(true);
  const [isLoadingAssesProviders, setIsLoadingAssesProviders] = useState(true);
  const [payouts, setPayouts] = useState([]);
  const [assetProvidersList, setAssetProvidersList] = useState({});
  const [startDateFilter, setStartDateFilter] = useState(
    initFilteringOptions.createdAfter,
  );
  const [endDateFilter, setEndDateFilter] = useState(
    initFilteringOptions.createdBefore,
  );
  const [ownerIdFilterValue, setOwnerIdFilterValue] = useState(
    initFilteringOptions.ownerId,
  );

  const updateUrlFilteringParams = () => {
    const urlSearchParams = {};

    if (startDateFilter)
      Object.assign(urlSearchParams, {
        createdAfter: moment(startDateFilter).format('YYYY-MM-DD'),
      });
    if (endDateFilter)
      Object.assign(urlSearchParams, {
        createdBefore: moment(endDateFilter).format('YYYY-MM-DD'),
      });
    if (ownerIdFilterValue)
      Object.assign(urlSearchParams, { ownerId: ownerIdFilterValue });

    const urlParams = new URLSearchParams(urlSearchParams);

    history.push(`${location.pathname}?${urlParams.toString()}`);
  };

  const getPayouts = async () => {
    setIsLoadingPayouts(true);

    try {
      const items = await belecoApi.payouts.getPayoutsList({
        createdAfter: startDateFilter
          ? moment(startDateFilter).format('YYYY-MM-DD')
          : '',
        createdBefore: endDateFilter
          ? moment(endDateFilter).format('YYYY-MM-DD')
          : '',
        ownerId: ownerIdFilterValue,
      });

      setPayouts(items);
    } catch (error) {
      notifyError(error);
    } finally {
      setIsLoadingPayouts(false);
    }

    updateUrlFilteringParams();
  };

  const getAPFilteringOptions = async () => {
    setIsLoadingAssesProviders(true);

    try {
      const assetsProviders = await belecoApi.wp.getAllAssetProviders();

      setAssetProvidersList([
        {
          ID: null,
          title: '-',
          meta: {
            company_name: '-',
          },
        },
        ...assetsProviders,
      ]);
      setIsLoadingAssesProviders(false);
    } catch (err) {
      notifyError(err, t('Failed to load asset providers'));
    }
  };

  const handleDateFilter = ({ startDate, endDate }) => {
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };

  const handleOwnerSelect = (value) => {
    if (value === ownerIdFilterValue) {
      setOwnerIdFilterValue('');
    } else {
      setOwnerIdFilterValue(value);
    }
  };

  useEffect(() => {
    getPayouts();
    getAPFilteringOptions();
  }, []);

  useEffect(() => {
    if (notInitialRender.current) {
      getPayouts();
    } else {
      notInitialRender.current = true;
    }
  }, [ownerIdFilterValue, startDateFilter, endDateFilter]);

  const initColumns = useMemo(
    () => [
      {
        headerName: t('Period'),
        field: 'period',
        minWidth: 150,
        flex: 0.75,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography variant='body2'>
            {`${moment(row.periodStart).format('DD.MM.YYYY')}-${moment(
              row.periodEnd,
            ).format('DD.MM.YYYY')}`}
          </Typography>
        ),
      },
      {
        headerName: t('Name'),
        field: 'payoutRecipient.name',
        minWidth: 200,
        flex: 1,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <Typography
            variant='body2'
            sx={{
              whiteSpace: {
                xs: 'normal',
                sm: 'nowrap',
              },
              textAlign: 'center',
            }}
          >
            {row.payoutRecipient.name}
          </Typography>
        ),
      },
      {
        headerName: t('Sum (excl. vat)'),
        field: 'sum',
        minWidth: 150,
        flex: 0.75,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <Typography
            variant='body2'
            sx={{ whiteSpace: 'normal', textAlign: 'center' }}
          >
            {`${Math.floor(row.sum / 100)} kr`}
          </Typography>
        ),
      },
      {
        headerName: t('Created Date'),
        field: 'createdDate',
        minWidth: 110,
        flex: 0.55,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <Typography variant='body2'>{row.createdDate}</Typography>
        ),
      },
      {
        headerName: '',
        field: 'editIcon',
        align: 'right',
        minWidth: 55,
        flex: 0.275,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <IconButton
              to={`/payouts/${row.payoutBasisId}`}
              component={Link}
              style={{ height: 35, width: 35 }}
              size='large'
            >
              <CreateOutlinedIcon />
            </IconButton>
          );
        },
      },
    ],
    [i18n.language],
  );

  const totalSum = Math.floor(
    payouts.reduce((acc, payout) => acc + payout.sum, 0) / 100,
  );
  const totalVAT = Math.floor(
    payouts.reduce((acc, payout) => acc + payout.vat, 0) / 100,
  );

  return (
    <>
      <Helmet>
        <title>{t('Payouts')}</title>
      </Helmet>
      <TitleContainer title={t('Payouts')}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            alignItems: 'center',
            px: {
              xs: '20px',
              sm: 0,
            },
          }}
        >
          {isLoadingAssesProviders ? (
            <GradientLoadingOverlay height={37} sx={APInputSX} />
          ) : (
            <OutlinedSelector
              label={t('Owner')}
              disabled={isLoadingPayouts || isLoadingAssesProviders}
              options={sortAssetProvidersForSelect(assetProvidersList)}
              onFilterChange={handleOwnerSelect}
              filterValue={ownerIdFilterValue}
              sx={APInputSX}
            />
          )}
          <RangeDatePicker
            startDate={startDateFilter}
            endDate={endDateFilter}
            onChange={handleDateFilter}
            numberOfMonths={isMobileDevice() ? 1 : 2}
          />
        </Box>
      </TitleContainer>
      <Paper sx={{ padding: '20px', marginBottom: '20px' }}>
        <Box display='flex' flexDirection='column' gap='10px'>
          <Typography color='#DA4453' variant='h3'>
            {t('payoutsSummaryHeader')}
          </Typography>
          <Divider />
          <Box
            display='flex'
            flexDirection='row'
            gap='10px'
            alignItems='center'
          >
            <Typography variant='h5'>{t('payoutsSummaryTotal')}: </Typography>
            <Typography variant='h5'>{formatMoney(totalSum)}</Typography>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            gap='10px'
            alignItems='center'
          >
            <Typography variant='h5'>{t('payoutsSummaryVAT')}: </Typography>
            <Typography variant='h5'>{formatMoney(totalVAT)}</Typography>
          </Box>
        </Box>
      </Paper>
      <SectionContainer>
        <CustomDataGrid
          getRowId={({ payoutBasisId }) => payoutBasisId}
          rows={payouts}
          columns={initColumns}
          loading={isLoadingPayouts}
          rowHeight={40}
          headerHeight={40}
          pagination
          paginationMode='client'
          rowCount={payouts.length}
          pageSize={50}
          hideFooterPagination={false}
        />
      </SectionContainer>
    </>
  );
};

export default Payouts;
