import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Typography, Box } from '@mui/material';
import WarehouseDeliveriesListItemMob from '../WarehouseDeliveriesListItemMob';

const NoItemsFound = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: '200px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant='body2'>{t('No items found')}</Typography>
    </Box>
  );
};

const WarehouseDeliveriesListMob = ({ listItems }) => {
  return (
    <div>
      {listItems.map((item) => (
        <WarehouseDeliveriesListItemMob
          key={`${item.publicId}-wo`}
          item={item}
        />
      ))}
      {isEmpty(listItems) && <NoItemsFound />}
    </div>
  );
};

WarehouseDeliveriesListMob.propTypes = {
  listItems: PropTypes.array.isRequired,
};

export default memo(WarehouseDeliveriesListMob);
