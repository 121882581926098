import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const FieldLabel = ({ label }) => {
  return (
    <Typography
      variant='body2'
      style={{
        fontWeight: 600,
        textTransform: 'capitalize',
      }}
    >
      {label}
    </Typography>
  );
};
FieldLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export default FieldLabel;
