import moment from 'moment';

export const formatXAxis = (tickItem) => {
  return moment(tickItem).format('D MMM');
};

export const formatYAxis = (number) => {
  if (number > 1000000000) {
    return `${Math.ceil(number / 1000000000).toString()}B`;
  }
  if (number > 1000000) {
    return `${Math.ceil(number / 1000000).toString()}M`;
  }
  if (number > 1000) {
    return `${Math.ceil(number / 1000).toString()}K`;
  }
  return Math.ceil(number).toString();
};
