import moment from 'moment';
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  Hidden,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GradientLoadingOverlay from '../../../components/GradientLoadingOverlay';
import CustomTooltip from '../../../components/CustomComponents/CustomTooltip';
import { TRANSPORTS_TOOLTIPS } from '../../../utils/constants';
import belecoApi from '../../../api';
import { TransportDetailsContext } from '../../../context/TransportDetails/TransportDetailsContext';

const useStyles = makeStyles((theme) => ({
  instanceId: {
    color: '#2A2929',
    marginRight: 8,
    textDecoration: 'none',
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 12,
  },
  instanceUpdateButton: {
    minWidth: 0,
    padding: 4,
    borderRadius: '50%',

    '& *': {
      pointerEvents: 'none',
    },
  },
  instanceUpdateContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rootContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    paddingRight: 0,
    paddingLeft: 12,
    maxWidth: 240,

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      maxWidth: 'unset',
      width: '100%',
      flexDirection: 'row',
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: '100%',
    },
  },
}));

const TransportInstanceSelectionCell = ({
  transportType,
  orderId,
  from,
  publicId,
  productId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { publicId: transportPublicId } = useParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [replacingInstance, setReplacingInstance] = useState(''); // Fix for weird map inside map ids interaction
  const [isLoadingReplacements, setReplacementsLoadingState] = useState(false);
  const [instanceReplacements, setInstanceReplacements] = useState([]);

  const { replaceTransportInstance, instanceUpdateLoadingSKU } = useContext(
    TransportDetailsContext,
  );

  const onMenuOpen = async (event) => {
    setReplacementsLoadingState(true);
    setReplacingInstance(publicId);
    event.persist();

    try {
      const replacements =
        transportType === 'DELIVERY'
          ? await belecoApi.inv.getTransportDeliveryInstanceSiblings({
              publicId,
              delivery: moment(from).format('YYYY-MM-DD'),
            })
          : await belecoApi.inv.getTransportPickupInstanceSiblings({
              orderId,
              productId,
            });

      if (replacements.length) {
        // Siblings can return instances that are already in transport. Filter them out here
        setInstanceReplacements(
          replacements.reduce((acc, { publicId: replacementPublicId }) => {
            if (publicId === replacementPublicId) {
              return acc;
            }

            return [
              ...acc,
              {
                value: replacementPublicId,
                label: replacementPublicId,
              },
            ];
          }, []),
        );
      } else {
        setInstanceReplacements([
          {
            value: null,
            label: 'No instances found',
          },
        ]);
      }

      setAnchorEl(event.target);
    } catch (error) {
      console.log(error);
    } finally {
      setReplacementsLoadingState(false);
    }
  };

  const onMenuItemClick = (event) => {
    const newInstance = event.target.getAttribute('value');

    replaceTransportInstance({
      publicId: transportPublicId,
      instanceToReplace: replacingInstance,
      newInstance,
    });

    setAnchorEl(null);
  };

  return (
    <div className={classes.rootContainer}>
      <div>
        {instanceUpdateLoadingSKU === publicId ? (
          <GradientLoadingOverlay height={20} key={publicId} />
        ) : (
          <div className={classes.instanceUpdateContainer}>
            <Hidden smDown>
              <Link
                className={classes.instanceId}
                variant='body2'
                to={`/instances/${publicId}`}
              >
                {publicId}
              </Link>
            </Hidden>
            <Hidden smUp>
              <Link
                className={classes.instanceId}
                variant='body2'
                to={`/instances/${publicId}`}
              >
                {`${publicId.length > 5 ? '..' : ''}${publicId.slice(-5)}`}
              </Link>
            </Hidden>

            <CustomTooltip title={t(TRANSPORTS_TOOLTIPS.EDIT)}>
              <Button
                onClick={(event) => onMenuOpen(event, publicId, from)}
                aria-controls='instances'
                aria-haspopup='true'
                variant='text'
                className={classes.instanceUpdateButton}
              >
                {isLoadingReplacements ? (
                  <CircularProgress size={14} />
                ) : (
                  <EditIcon style={{ fontSize: 14 }} />
                )}
              </Button>
            </CustomTooltip>

            <Menu
              id='instances'
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                style: {
                  maxHeight: 26 * 6.75,
                  minWidth: 200,
                },
              }}
            >
              {instanceReplacements.map(({ value, label }) => (
                <MenuItem
                  onClick={onMenuItemClick}
                  value={value}
                  key={value}
                  disabled={value === null}
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
      </div>
    </div>
  );
};

TransportInstanceSelectionCell.propTypes = {
  transportType: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  from: PropTypes.string,
  publicId: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
};

export default TransportInstanceSelectionCell;
