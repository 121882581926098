import {
  MenuItem,
  OutlinedInput,
  Select,
  IconButton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  useGridApiContext,
  useGridRootProps,
  gridPageSelector,
  gridPageSizeSelector,
  useGridSelector,
} from '@mui/x-data-grid';

const ROWS_OPTIONS = [
  {
    value: 5,
    label: 5,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 25,
    label: 25,
  },
];

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'sticky',
    left: 0,
    height: 48,
  },
  displayElement: {
    fontFamily: 'GT-America-Standard, sans-serif',
    fontSize: 13,
    padding: '6px 24px 6px 12px',
    lineHeight: 1.83,
    opacity: 0.5,

    '&:focus': {
      outline: 'none',
    },
  },
  pagesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 16,
  },
  firstPageIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  verticalLine: {
    height: 16,
    width: 2,
    backgroundColor: '#000000',
  },
  icon: {
    fontSize: 32,
  },
  text: {
    fontFamily: 'GT-America-Standard, sans-serif',
    fontSize: 13,
    textTransform: 'none',
    opacity: 0.5,
  },
});

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const page = useGridSelector(apiRef, gridPageSelector);
  const rowsPerPage = useGridSelector(apiRef, gridPageSizeSelector);

  const { setPage: handlePageChange, setPageSize: handleMaxRowsChange } =
    apiRef.current;
  const {
    hideFooterSelectedRowCount: isHiddenRowsOption,
    rowCount: dataCount,
  } = rootProps;

  const classes = useStyles();
  const toValue = page * rowsPerPage + rowsPerPage;

  return (
    <div className={classes.container}>
      <div className={classes.pagesContainer}>
        {!isHiddenRowsOption && (
          <Select
            onChange={handleMaxRowsChange}
            value={rowsPerPage}
            displayEmpty
            SelectDisplayProps={{ className: classes.displayElement }}
            input={<OutlinedInput id='rowsPerPage' />}
          >
            {ROWS_OPTIONS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        )}
      </div>
      <div className={classes.pagesContainer}>
        <IconButton
          onClick={() => handlePageChange(0)}
          disabled={page === 0}
          aria-label='first page'
          size='large'
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 0}
          aria-label='previous page'
          size='large'
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant='body1' className={classes.text}>
          {`${page * rowsPerPage || 1}-${
            toValue >= dataCount ? dataCount : toValue
          } of ${dataCount}`}
        </Typography>
        <IconButton
          onClick={() => handlePageChange(page + 1)}
          disabled={toValue >= dataCount}
          aria-label='next page'
          size='large'
        >
          <ChevronRightIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            handlePageChange(Math.ceil(dataCount / rowsPerPage) - 1)
          }
          disabled={toValue >= dataCount}
          aria-label='last page'
          size='large'
        >
          <LastPageIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default CustomPagination;
