import { useState } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import { Box } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { LOCALES } from '../../utils/constants';

const styles = {
  container: (theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '100%',
      '& .DateRangePicker': {
        width: '100%',
      },
      '& .DateRangePickerInput': {
        display: 'flex',
      },
      '& .DateInput': {
        flex: 1,
      },
      '& .DateInput_input': {
        textAlign: 'center!important',
      },
    },
    '& .DateRangePicker_picker': {
      zIndex: 100,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    '& .DateRangePickerInput__withBorder': {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      overflow: 'hidden',
      height: 37,
    },
    '& .DateInput__small': {
      width: '95px',
      '&:nth-child(3) input': {
        paddingRight: '12px',
        textAlign: 'right',
      },
      '&:first-child input': {
        paddingLeft: '12px',
      },
    },
    '& .DateRangePickerInput_calendarIcon': {
      backgroundColor: '#DA4453',
      padding: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 'auto',
      marginRight: 0,
      height: 37,
      width: 34,
      paddingTop: '1px',
      alignItems: 'center',
    },
    '& .DateInput_input__small': {
      textAlign: 'center',
      fontSize: 13.5,
      color: '#2a2929',
      fontFamily: 'GT-America-Standard-Thin, sans-serif',
      letterSpacing: 'normal',
      padding: '8px',
    },
    '& .CalendarDay__hovered_span, & .CalendarDay__hovered_span:hover': {
      backgroundColor: 'inherit!important',
    },
    '& .CalendarDay__selected_span, & .CalendarDay__selected': {
      '&, &:hover': {
        backgroundColor: '#DA4453',
        borderColor: '#ccc',
      },
    },
    '& .DateRangePicker_picker__directionLeft': {
      [theme.breakpoints.up('md')]: {
        left: '-400px!important',
      },
    },
  }),
};

const RangeDatePicker = ({
  startDate,
  endDate,
  onChange,
  uniqId = '',
  ...props
}) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const { t, i18n } = useTranslation();

  const momentLng = LOCALES[i18n.resolvedLanguage].localeCode;

  return (
    <Box sx={styles.container}>
      <DateRangePicker
        firstDayOfWeek={1}
        minimumNights={0}
        startDate={startDate ? moment(startDate).locale(momentLng) : ''}
        startDateId={`start-date-in-range-${uniqId}`}
        endDate={endDate ? moment(endDate).locale(momentLng) : ''}
        endDateId={`end-date-in-range-${uniqId}`}
        onDatesChange={onChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInputArg) => setFocusedInput(focusedInputArg)}
        enableOutsideDays
        small
        isOutsideRange={() => false}
        displayFormat='YYYY-MM-DD'
        inputIconPosition='after'
        startDatePlaceholderText={t('Start date')}
        endDatePlaceholderText={t('End date')}
        customInputIcon={
          <DateRangeIcon
            style={{
              fontSize: 23,
              color: '#FFF',
            }}
          />
        }
        {...props}
      />
    </Box>
  );
};

RangeDatePicker.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  uniqId: PropTypes.string,
};

export default RangeDatePicker;
