import React from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  dialogRoot: {
    minWidth: '300px',
  },
  dialogTitle: {
    textAlign: 'center',
    padding: 20,
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '20px',
  },
  button: {
    minWidth: 130,
    padding: '0px 30px',
  },
  actionMessage: {
    textAlign: 'center',
    padding: '30px 20px 40px',
  },
}));

const StatusChangeWarningDialog = ({
  isOpened = false,
  closeDialog,
  handleAction,
  actionButtonText = '',
  dialogTitle,
  messageText = '',
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={isOpened} onClose={closeDialog}>
      <div className={classes.dialogRoot}>
        <Typography variant='h5' className={classes.dialogTitle}>
          {dialogTitle}
        </Typography>
        <Divider />
        <Typography variant='body2' className={classes.actionMessage}>
          {messageText}
        </Typography>
        <Divider />
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={() => {
              handleAction();
              closeDialog();
            }}
            color='primary'
            variant='contained'
            className={classes.button}
          >
            {actionButtonText.length ? actionButtonText : t('Okay')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

StatusChangeWarningDialog.propTypes = {
  isOpened: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  actionButtonText: PropTypes.string,
  dialogTitle: PropTypes.string,
  messageText: PropTypes.string,
};

export default StatusChangeWarningDialog;
