import { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isNil } from 'lodash';
import { Typography, Link as MuiLink, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import ForwardIcon from '@mui/icons-material/Forward';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useTranslation } from 'react-i18next';
import CustomDataGrid from '../../../../components/CustomDataGrid';
import {
  TRANSPORTATION_TYPE,
  TRANSPORTING_STATUS_OPTIONS,
} from '../../../../utils/constants';
import TransportStatus from '../TransportStatus';
import { volumeUnitConverter } from '../../../../utils';
import TransportsInfoIcon from '../TransportsInfoIcon';
import DeliveryIconEnabled from '../../../../assets/icons/Icon-delivery-enabled.svg';
import DeliveryIconDisabled from '../../../../assets/icons/Icon-delivery-disbaled.svg';
import PickupIconEnabled from '../../../../assets/icons/Icon-pickup-enabled.svg';
import PickupIconDisabled from '../../../../assets/icons/Icon-pickup-disabled.svg';
import BuildingTypeIcon from '../../../../components/CustomComponents/BuildingTypeIcon';
import { hasStatusIssue } from '../../../TransportDetails/helpers';
import { TransportDetailsContext } from '../../../../context/TransportDetails/TransportDetailsContext';

const TypeCell = ({ deliveryType }) => {
  const iconBackgroundColor = {
    [TRANSPORTATION_TYPE.DELIVERY]: '#3AAB83',
    [TRANSPORTATION_TYPE.PICKUP]: '#8B8B8B',
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <ForwardIcon
        style={{
          color: '#FFF',
          paddingLeft: 4,
          paddingRight: 2,
          width: 25,
          height: 25,
          borderRadius: '50%',
          backgroundColor: iconBackgroundColor[deliveryType],
          transform:
            deliveryType === TRANSPORTATION_TYPE.DELIVERY
              ? 'rotate(180deg)'
              : '',
        }}
      />
    </div>
  );
};

TypeCell.propTypes = {
  deliveryType: PropTypes.string.isRequired,
};

const getCustomRowStyles = ({ row: { status } }) => {
  const useStyles = makeStyles({
    cancelledRow: {
      '& > :not(:last-child)': {
        opacity: 0.5,
      },
    },
  });

  const { cancelledRow } = useStyles();

  return status === TRANSPORTING_STATUS_OPTIONS.CANCELLED.value
    ? cancelledRow
    : null;
};

const TransportsTable = ({
  isRelatedTable = false,
  onChangePage = () => {},
  items = [],
  total,
  loading,
  pageSize,
  page,
  hasPagination = true,
}) => {
  const { t, i18n } = useTranslation();

  const { isUpdateLoading } = useContext(TransportDetailsContext);

  const returnTransportTypeIcon = (type, status) => {
    const transportIsCanceled =
      status === TRANSPORTING_STATUS_OPTIONS.CANCELLED.value;
    let iconSrc = '';
    let iconAlt = '';
    if (type === TRANSPORTATION_TYPE.DELIVERY) {
      iconAlt = 'Delivery';
      if (transportIsCanceled) {
        iconSrc = DeliveryIconDisabled;
      } else {
        iconSrc = DeliveryIconEnabled;
      }
    } else if (type === TRANSPORTATION_TYPE.PICKUP) {
      iconAlt = 'Pickup';
      if (transportIsCanceled) {
        iconSrc = PickupIconDisabled;
      } else {
        iconSrc = PickupIconEnabled;
      }
    }
    return (
      <img
        style={{ marginLeft: 5, marginRight: 5 }}
        src={iconSrc}
        alt={iconAlt}
      />
    );
  };

  const initColumns = useMemo(() => {
    const columns = [
      {
        headerName: t('Date'),
        field: 'from',
        sortable: false,
        minWidth: 125,
        flex: 1,
        renderCell: ({ row }) => (
          <Typography variant='body2'>
            {`${moment(row.from).format('D MMM')} (${moment(row.from).format(
              'HH:mm',
            )}-${moment(row.to).format('HH:mm')})`}
          </Typography>
        ),
      },
      {
        headerName: t('Customer'),
        field: 'name',
        sortable: false,
        minWidth: 220,
        flex: 2,
        renderCell: ({
          row: { name, publicId, companyName, buildingType },
        }) => (
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <BuildingTypeIcon buildingType={buildingType} />
            <Link
              style={{
                color: 'black',
                width: 'calc(100% - 30px)',
              }}
              variant='body2'
              to={`/transports/${publicId}`}
              rel='noopener noreferrer'
            >
              <Typography variant='body2' noWrap>
                {`${name}${isNil(companyName) ? '' : ` - ${companyName}`}`}
              </Typography>
            </Link>
          </div>
        ),
      },
      {
        headerName: t('City'),
        field: 'city',
        sortable: false,
        minWidth: 150,
        flex: 2,
        renderCell: ({ row: { type, status, city, location } }) => (
          <div style={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
            <Typography variant='body2' noWrap>
              {location?.city}
            </Typography>
            {returnTransportTypeIcon(type, status)}
            <Typography variant='body2' noWrap>
              {city}
            </Typography>
          </div>
        ),
      },
      {
        headerName: t('TMS'),
        field: 'tms',
        sortable: false,
        minWidth: 100,
        flex: 1,
        renderCell: ({ row: { tms } }) => {
          return (
            <Typography variant='body2' noWrap>
              {tms?.title || t('N/A')}
            </Typography>
          );
        },
      },
      // {
      // headerName: 'Type',
      // align: 'center',
      // headerAlign: 'center',
      // sortable: false,
      // minWidth: 150,
      // flex: 1,
      // renderCell: ({ row }) => <TypeCell deliveryType={row.type} />,
      // },
      {
        headerName: t('Ref'),
        field: 'reference',
        sortable: false,
        minWidth: 70,
        flex: 1,
        renderCell: ({ row: { reference, orderId } }) =>
          reference ? (
            <MuiLink
              href={`${process.env.REACT_APP_WP_ADMIN_URL}/post.php?post=${orderId}&action=edit`}
              variant='body2'
              underline='always'
              rel='noopener noreferrer'
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {reference.split('_')[0]}
            </MuiLink>
          ) : (
            '-'
          ),
      },

      {
        headerName: t('Items'),
        field: 'instances.length',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        minWidth: 60,
        flex: 0.5,
        renderCell: ({ row: { instances, type, status } }) => {
          let isPhoneBoothInTransport = false;
          const shouldShowInfoIcon = instances.some(
            ({ isMountingRequired, warehouseStatus, transports, product }) => {
              // for completed transports check only warehouse status
              if (status === TRANSPORTING_STATUS_OPTIONS.COMPLETED.value) {
                return hasStatusIssue({ status, type }, warehouseStatus);
              }

              const { isPhoneBooth } = product;
              if (isPhoneBooth) {
                isPhoneBoothInTransport = true;
              }

              return (
                isMountingRequired ||
                hasStatusIssue({ status, type }, warehouseStatus) ||
                (transports && transports.length === 1) ||
                product.isPhoneBooth
              );
            },
          );

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant='body2'>{instances.length || '-'}</Typography>
              {shouldShowInfoIcon && (
                <TransportsInfoIcon
                  iconSize={22}
                  isPhoneBoothInTransport={isPhoneBoothInTransport}
                />
              )}
            </div>
          );
        },
      },
      {
        headerName: t('Volume'),
        field: 'volume',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        minWidth: 100,
        flex: 1,
        renderCell: ({ row }) => (
          <Typography variant='body2'>
            {volumeUnitConverter(row.volume)} m<sup>3</sup>
          </Typography>
        ),
      },
      {
        headerName: t('Status'),
        field: 'status',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        minWidth: 175,
        renderCell: ({ row }) => {
          return <TransportStatus transportRecord={row} />;
        },
      },
      {
        headerName: ' ',
        field: 'publicId',
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        flex: 0.5,
        renderCell: ({ row }) => (
          <IconButton
            to={`/transports/${row.publicId}`}
            component={Link}
            style={{ height: 35, width: 35, marginRight: 10 }}
            size='large'
          >
            <CreateOutlinedIcon />
          </IconButton>
        ),
      },
    ];

    // Remove items column
    if (isRelatedTable) columns.splice(5, 1);

    return columns;
  }, [i18n.language]);

  return (
    <CustomDataGrid
      columns={initColumns}
      rows={items}
      rowCount={total}
      pageSize={pageSize}
      loading={loading || isUpdateLoading}
      onPageChange={onChangePage}
      getRowClassName={getCustomRowStyles}
      headerHeight={40}
      rowHeight={40}
      pagination={hasPagination}
      paginationMode='server'
      hideFooterPagination={false}
      page={page}
    />
  );
};

TransportsTable.propTypes = {
  isRelatedTable: PropTypes.bool,
  onChangePage: PropTypes.func,
  items: PropTypes.array,
  total: PropTypes.number,
  // eslint-disable-next-line react/boolean-prop-naming
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  page: PropTypes.number,
  hasPagination: PropTypes.bool,
};

export default TransportsTable;
