import PropTypes from 'prop-types';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { INVISIBILITY_REASONS } from '../../../utils/constants';

const useStyles = makeStyles(({ palette }) => ({
  visibilityMark: {
    color: '#269B62',
    verticalAlign: 'middle',
    margin: 'auto',
  },
  reasonText: {
    color: palette.title.highlighted,
    textTransform: 'uppercase',
    fontSize: 12,
    textAlign: 'center',
  },
}));

const VisibilityComponent = ({
  item: { isVisible, invisibilityReason, bookingsArray = [] },
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const reasonText =
    t(INVISIBILITY_REASONS[invisibilityReason]) || t('No reason');

  if (isVisible) {
    return bookingsArray.find(
      ({ deliveryDate }) => new Date(deliveryDate) > new Date(),
    ) ? (
      <Typography variant='subtitle2' className={classes.reasonText}>
        Booked
      </Typography>
    ) : (
      <CheckOutlinedIcon className={classes.visibilityMark} />
    );
  }

  return (
    <Typography variant='subtitle2' className={classes.reasonText}>
      {reasonText}
    </Typography>
  );
};

VisibilityComponent.propTypes = {
  item: PropTypes.shape({
    isVisible: PropTypes.bool,
    invisibilityReason: PropTypes.string,
    bookingsArray: PropTypes.array,
  }),
};

export default VisibilityComponent;
