import React from 'react';
import { Tooltip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 300,
    maxWidth: 291,
    padding: '12px 21px',
  },
}));

const CustomTooltip = ({ children, title, ...props }) => {
  const classes = useStyles();

  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} title={title} {...props}>
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
