import { Redirect, useHistory } from 'react-router';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { checkSession, login } from '../../store/reducers/auth';
import LoginForm from './LoginForm';

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, authenticated } = useSelector((state) => state.auth);
  const history = useHistory();

  const handleLogin = async (data, { setFieldError }) => {
    await dispatch(login(data)).then((action) => {
      if (!action.error) {
        dispatch(checkSession());
        history.replace('/');
      } else if (action.payload.status === 403) {
        setFieldError('password', t('Incorrect email or password'));
      }
    });
  };

  return authenticated ? (
    <Redirect to='/' />
  ) : (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      style={{ minHeight: '100vh' }}
    >
      <LoginForm onSubmit={handleLogin} isLoading={loading} />
    </Grid>
  );
};

export default Login;
