import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CreateOutlined as CreateOutlinedIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import SearchField from '../../../components/SearchField';
import Thumb from '../../Instances/Thumb';
import CustomDataGrid from '../../../components/CustomDataGrid';
import SectionContainer from '../../../components/CustomComponents/SectionContainer';
import TitleContainer from '../../../components/TitleContainer';

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflowX: 'scroll',
    marginBottom: 30,
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    textTransform: 'uppercase',
    paddingBottom: 5,
    fontSize: 32,
    alignSelf: 'start',
  },
  searchBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataGridClass: {
    '& .MuiDataGrid-row': {
      boxShadow: 'inset 0 0 0 1px #cccccc',
      width: '100%',

      '& .MuiDataGrid-cell': {
        border: 'none',
      },
    },
  },
}));

const ProductsDesktop = ({
  onChangePage,
  items,
  total,
  loading,
  pageSize,
  handleSearch,
  searchValue,
  resetSearch,
  page,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const initColumns = useMemo(
    () => [
      {
        headerName: t('Product'),
        field: 'coverImage.attachmentUrl',
        minWidth: 210,
        flex: 2,
        sortable: false,
        renderCell: ({ row }) => {
          const { coverImage, title } = row;
          const data = {
            image: coverImage.attachmentUrl,
            thumbnail_image: '',
            title,
          };
          return <Thumb data={data} />;
        },
      },
      {
        headerName: t('Product ID'),
        field: 'post_id',
        minWidth: 90,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <NavLink to={`/products/${row.post_id}`} style={{ color: '#000' }}>
            <Typography variant='body2'>{row.post_id}</Typography>
          </NavLink>
        ),
      },
      {
        headerName: t('SKU'),
        field: 'sku',
        minWidth: 90,
        flex: 1,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <Typography variant='body2'>{row.sku || ''}</Typography>
        ),
      },
      {
        headerName: ' ',
        field: '',
        minWidth: 35,
        flex: 0.5,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ row }) => (
          <IconButton
            to={`/products/${row.post_id}`}
            component={Link}
            style={{ height: 35, width: 35, marginRight: 10 }}
            size='large'
          >
            <CreateOutlinedIcon />
          </IconButton>
        ),
      },
    ],
    [i18n.language],
  );

  return (
    <>
      <TitleContainer title={t('Products')}>
        <div className={classes.searchBlock}>
          <SearchField
            disabled={loading}
            onSearch={handleSearch}
            initVal={searchValue}
            resetSearchValue={resetSearch}
          />
        </div>
      </TitleContainer>
      <SectionContainer>
        <CustomDataGrid
          columns={initColumns}
          rows={items}
          rowCount={total}
          pageSize={pageSize}
          loading={loading}
          onPageChange={onChangePage}
          headerHeight={40}
          rowHeight={40}
          rowSpacing={5}
          pagination
          paginationMode='server'
          hideFooterPagination={false}
          dataGridClass={classes.dataGridClass}
          page={page}
        />
      </SectionContainer>
    </>
  );
};

ProductsDesktop.propTypes = {
  onChangePage: PropTypes.func,
  items: PropTypes.array,
  total: PropTypes.number,
  // eslint-disable-next-line react/boolean-prop-naming
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  handleSearch: PropTypes.func,
  searchValue: PropTypes.string,
  resetSearch: PropTypes.func,
  page: PropTypes.number,
};

export default ProductsDesktop;
