import API from './API';

class PayoutsAPI extends API {
  getPayoutsList = (params) => {
    return this.api.get('/payouts', { params });
  };

  getPayoutDetails = (payoutBasisId) => {
    return this.api.get(`/payouts/${payoutBasisId}`);
  };
}

export default PayoutsAPI;
