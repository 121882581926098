import moment from 'moment';
import i18n from '../../localization';
import { INVISIBILITY_REASONS, WAREHOUSE_STATUS } from '../../utils/constants';

const instanceChangelogMap = {
  PRICE: {
    getTemplate: (
      { purchase_price: oldValue },
      { purchase_price: newValue },
    ) => {
      const labelString = `${i18n.t('Purchase Price')}: `;
      const oldValueText = oldValue ? `<b> ${oldValue}</b>` : `<i>Empty</i>`;
      const newValueText = newValue ? `<b> ${newValue}</b>` : `<i>Empty</i>`;
      return `${labelString + oldValueText} → ${newValueText}`;
    },
  },
  DATE_OF_PURCHASE: {
    getTemplate: ({ purchase_date: oldDate }, { purchase_date: newDate }) => {
      let labelString = `${i18n.t('Date of purchase')}: `;
      if (oldDate) {
        labelString += `<b>${moment(oldDate).format('YYYY-MM-DD')}</b> `;
      } else {
        labelString += ` <i>Empty</i> `;
      }
      labelString += `→ <b>${moment(newDate).format('YYYY-MM-DD')}</b>`;
      return labelString;
    },
  },
  VISIBILITY: {
    getTemplate: (
      oldValue,
      { is_visible: isVisible, invisibility_reason: invisibilityReason },
    ) => {
      if (isVisible === 't') {
        return i18n.t('Instance was published');
      }

      if (invisibilityReason) {
        return `${i18n.t('Instance was unpublished')}: </br><b>${i18n.t(
          INVISIBILITY_REASONS[invisibilityReason] || invisibilityReason,
        )}</b>`;
      }

      return i18n.t('Instance was unpublished');
    },
  },
  LOCATION: {
    getTemplate: (
      { location_id: oldLocationId },
      { location_id: locationId },
    ) =>
      `${i18n.t('Location')}: <b>${oldLocationId}</b> → <b>${locationId}</b>`,
  },
  PLACEMENT: {
    getTemplate: (
      { aisle: oldAisle, shelf: oldShelf },
      { aisle: newAisle, shelf: newShelf },
    ) => {
      let placementString = `${i18n.t('Placement')}: `;

      if (newAisle) {
        placementString += ` ${i18n.t('aisle')} - <b>${newAisle}</b>`;
      } else if (newAisle !== oldAisle) {
        placementString += ` ${i18n.t('aisle')} - <i>Empty</i>`;
      }
      if (newShelf) {
        placementString += ` ${i18n.t('shelf')} - <b>${newShelf}</b>`;
      } else if (newShelf !== oldShelf) {
        placementString += ` ${i18n.t('shelf')} - <i>Empty</i> `;
      }
      return placementString;
    },
  },
  CONDITION: {
    getTemplate: ({ condition: oldCondition }, { condition }) =>
      `${i18n.t('Condition')}: <b>${oldCondition}</b> → <b>${condition}</b>`,
  },
  WAREHOUSE_STATUS: {
    getTemplate: (
      { warehouse_status: oldStatus },
      { warehouse_status: warehouseStatus },
    ) =>
      `${i18n.t('Status')}: </br><b>${i18n.t(
        WAREHOUSE_STATUS[oldStatus]?.label,
      )}</b> → <b>${i18n.t(WAREHOUSE_STATUS[warehouseStatus]?.label)}</b>`,
  },
  MOUNTING: {
    getTemplate: (oldValue, { is_mounting_required: isMountingRequired }) =>
      isMountingRequired === 't'
        ? i18n.t('Product needs mounting')
        : i18n.t('Product is mounted'),
  },
  NOTE: {
    getTemplate: (oldValues, { note }) =>
      `${i18n.t('Note added')}: </br><b>${note}</b>`,
  },
  INVISIBILITY_REASON: {
    getTemplate: (oldValue, newValue) => {
      const oldReasonText = oldValue?.invisibility_reason
        ? ` <b>${INVISIBILITY_REASONS[oldValue.invisibility_reason]}</b> `
        : ` <i>Empty</i> `;
      const newValueText = newValue?.invisibility_reason
        ? ` <b>${INVISIBILITY_REASONS[newValue.invisibility_reason]}</b> `
        : ` <i>Empty</i> `;

      return `${i18n.t(
        'Invisibility reason was changed',
      )}:</br> ${oldReasonText}→ ${newValueText}`;
    },
  },
  LABEL_CHANGED: {
    getTemplate: ({ qr_code_url: oldValue }, { qr_code_url: newValue }) => {
      if (!oldValue) {
        return `${i18n.t('Instance label was created.')}`;
      }
      if (oldValue && newValue) {
        return `${i18n.t('Instance label was updated.')}`;
      }
      return `${i18n.t('Instance label was removed')}`;
    },
  },
};

const getInstanceChangelogItem = (oldValue, newValue) => {
  const {
    updated_at: updatedAt,
    // eslint-disable-next-line no-unused-vars
    updated_by: updatedBy = null,
    ...updatedPropertiesObject
  } = newValue;
  const changedProperties = [];

  if (oldValue === null) {
    if (updatedPropertiesObject.is_visible) {
      changedProperties.push(instanceChangelogMap.VISIBILITY);
    }
  } else {
    if (
      updatedPropertiesObject.hasOwnProperty('aisle') ||
      updatedPropertiesObject.hasOwnProperty('shelf')
    ) {
      changedProperties.push(instanceChangelogMap.PLACEMENT);
    }
    Object.keys(updatedPropertiesObject).forEach(function (property) {
      if (oldValue[property] !== updatedPropertiesObject[property]) {
        if (property === 'is_visible') {
          changedProperties.push(instanceChangelogMap.VISIBILITY);
        } else if (
          property === 'invisibility_reason' &&
          (!!updatedPropertiesObject[property] || !!oldValue[property])
        ) {
          changedProperties.push(instanceChangelogMap.INVISIBILITY_REASON);
        } else if (property === 'location_id') {
          changedProperties.push(instanceChangelogMap.LOCATION);
        } else if (property === 'condition') {
          changedProperties.push(instanceChangelogMap.CONDITION);
        } else if (property === 'warehouse_status') {
          changedProperties.push(instanceChangelogMap.WAREHOUSE_STATUS);
        } else if (property === 'is_mounting_required') {
          changedProperties.push(instanceChangelogMap.MOUNTING);
        } else if (property === 'purchase_date') {
          changedProperties.push(instanceChangelogMap.DATE_OF_PURCHASE);
        } else if (property === 'purchase_price') {
          changedProperties.push(instanceChangelogMap.PRICE);
        } else if (property === 'note') {
          changedProperties.push(instanceChangelogMap.NOTE);
        } else if (property === 'qr_code_url') {
          changedProperties.push(instanceChangelogMap.LABEL_CHANGED);
        }
      }
    });
  }
  return changedProperties.length > 0 ? changedProperties : null;
};

export default getInstanceChangelogItem;
