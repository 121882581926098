import { useContext } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { WarehouseDetailsContext } from '../../../WarehouseDetailsContext';

const useStyles = makeStyles((theme) => ({
  selectFieldWrapper: {
    width: 200,
    [theme.breakpoints.between('md', 'lg')]: {
      width: 160,
    },
    [theme.breakpoints.down('sm')]: {
      width: 160,
    },
  },
}));

const AssetProviderSelectField = ({
  formikProps,
  isNewOrder,
  clearListOfSelectedProducts,
}) => {
  const classes = useStyles();

  const {
    warehouseOrderData,
    listOfAssetProviders: assetsProvidersList,
    getOwnersProducts,
    isLoadingProductList,
    isLoadingListOfAssetProviders,
  } = useContext(WarehouseDetailsContext);

  const onFieldChange = (event) => {
    formikProps.onChange(event);

    setTimeout(() => {
      clearListOfSelectedProducts();
      getOwnersProducts(event.target.value);
    }, 0);
  };

  const sortAssetProviders = (arr) => {
    return arr.sort((a, b) => {
      return (a.meta.company_name || a.display_name).localeCompare(
        b.meta.company_name || b.display_name,
      );
    });
  };
  if (isLoadingListOfAssetProviders || assetsProvidersList === null) {
    return null;
  }
  return (
    <TextField
      select
      variant='outlined'
      disabled={!isNewOrder || isLoadingProductList}
      SelectProps={{
        displayEmpty: true,
        size: 'small',
        className: classes.selectFieldWrapper,
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 400,
            },
          },
        },
      }}
      InputProps={{
        error: formikProps.error,
      }}
      {...formikProps}
      onChange={onFieldChange}
    >
      <MenuItem key='default-ship-from' disabled value=''>
        <em>Not selected</em>
      </MenuItem>
      {sortAssetProviders(Object.values(assetsProvidersList)).map((item) => (
        <MenuItem
          key={`apId-${item.ID}`}
          value={Number(item.ID)}
          disabled={warehouseOrderData.ownerId === +item.ID}
        >
          <Typography variant='body2'>
            {item.meta.company_name || item.display_name}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
  );
};

AssetProviderSelectField.propTypes = {
  formikProps: PropTypes.object.isRequired,
  isNewOrder: PropTypes.bool.isRequired,
  clearListOfSelectedProducts: PropTypes.func.isRequired,
};

export default AssetProviderSelectField;
