import { useRef, useState, memo } from 'react';
import { useHistory } from 'react-router';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MobileHeaderBox from '../../../components/CustomComponents/MobileHeaderBox';
import FiltersBlock from '../../../components/CustomComponents/FiltersBlock';
import FilterBox from '../components/FilterBox';
import LoadingOverlay from '../../../components/LoadingOverlay';
import WarehouseDeliveriesListMob from '../components/WarehouseDeliveriesListMob';

const styles = {
  mobileContainer: {
    maxHeight: '100%',
    backgroundColor: 'white',
    overflow: 'auto',
  },
};

const WarehouseDeliveriesMobile = ({
  handleLoadMore,
  resetFiltersToDefaults,
  location,
  assetsProvidersList,
  ownerIfFilterValue,
  setOwnerIdFilterValue,
  formattedStatuses,
  orderStatusFilterValue,
  setOrderStatusFilterValue,
  startDateFilter,
  endDateFilter,
  handleDateFilter,
  handleSearch,
  searchValue,
  rows: listItems,
  total,
  loading,
}) => {
  const { t } = useTranslation();
  const listRef = useRef();
  const { push } = useHistory();
  const [showFilter, setShowFilter] = useState(false);

  const subtitle = t('Shown {{quantity}} from {{total}}', {
    quantity: listItems?.length,
    total,
  });
  // eslint-disable-next-line react/no-unstable-nested-components
  const HeaderForMobile = () => {
    const addNewDelivery = () => push(`/warehouse-orders/create-new-order`);
    return (
      <>
        <MobileHeaderBox
          title={t('Warehouse deliveries')}
          subtitle={subtitle}
          addFuncText={t('New delivery')}
          addFunc={addNewDelivery}
          showFilters={setShowFilter}
        />
        <FiltersBlock
          showFilters={showFilter}
          resetFiltersToDefaults={resetFiltersToDefaults}
        >
          <FilterBox
            {...{
              location,
              loading,
              assetsProvidersList,
              ownerIfFilterValue,
              setOwnerIdFilterValue,
              formattedStatuses,
              orderStatusFilterValue,
              setOrderStatusFilterValue,
              startDateFilter,
              endDateFilter,
              handleDateFilter,
              handleSearch,
              searchValue,
              resetFiltersToDefaults,
            }}
          />
        </FiltersBlock>
      </>
    );
  };

  const handleListScroll = () => {
    if (listRef.current && listItems.length < total) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        handleLoadMore();
      }
    }
  };

  return (
    <Box sx={styles.mobileContainer} ref={listRef} onScroll={handleListScroll}>
      <HeaderForMobile />
      {loading && <LoadingOverlay />}
      <WarehouseDeliveriesListMob listItems={listItems} />
    </Box>
  );
};

WarehouseDeliveriesMobile.propTypes = {
  handleLoadMore: PropTypes.func,
  location: PropTypes.object,
  assetsProvidersList: PropTypes.object,
  ownerIfFilterValue: PropTypes.array,
  setOwnerIdFilterValue: PropTypes.func,
  formattedStatuses: PropTypes.array,
  orderStatusFilterValue: PropTypes.array,
  setOrderStatusFilterValue: PropTypes.func,
  startDateFilter: PropTypes.object,
  endDateFilter: PropTypes.object,
  handleDateFilter: PropTypes.func,
  handleSearch: PropTypes.func,
  searchValue: PropTypes.string,
  resetFiltersToDefaults: PropTypes.func,
  rows: PropTypes.array,
  total: PropTypes.number.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  loading: PropTypes.bool.isRequired,
};
export default memo(WarehouseDeliveriesMobile);
