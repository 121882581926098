import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Link, Tooltip, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useTranslation } from 'react-i18next';
import StatusCell from '../../../../components/StatusCell';
import CustomDataGrid from '../../../../components/CustomDataGrid';
import { ORDER_STATUS } from '../../../../utils/constants';

const wpAdminURL = process.env.REACT_APP_WP_ADMIN_URL;

const useStyles = makeStyles(() => ({
  dataGridClass: {
    '& .MuiDataGrid-row': {
      maxHeight: 'none!important',
      boxShadow: 'inset 0 0 0 1px #cccccc',
      width: '100%',

      '& .MuiDataGrid-cell': {
        border: 'none',
      },
    },
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none!important',
    },
    '& .MuiDataGrid-windowContainer': {
      position: 'relative!important',
      height: 'unset!important',
    },
    '& .MuiDataGrid-window': {
      position: 'relative!important',
    },
    '& .MuiDataGrid-dataContainer': {
      height: 'unset!important',
      minHeight: 'unset!important',
    },
    '& .MuiDataGrid-viewport': {
      maxHeight: 'none!important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-footerContainer': {
      marginTop: 48,
    },
  },
  cancelledRow: {
    '& > :not(:last-child)': {
      opacity: 0.5,
    },
  },
}));

const getCustomRowStyles = ({ row: { orderStatus } }) => {
  const { cancelledRow } = useStyles();

  return orderStatus === ORDER_STATUS.CANCELLED.value ? cancelledRow : null;
};

const WarehouseDeliveriesTable = ({
  onChangePage,
  items,
  total,
  loading,
  pageSize,
  page,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const initColumns = useMemo(
    () => [
      {
        headerName: t('EST. Delivery'),
        field: 'estimatedArrival',
        align: 'left',
        headerAlign: 'left',
        minWidth: 100,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography variant='body2'>{row.estimatedArrival}</Typography>
        ),
      },
      {
        headerName: t('From'),
        field: 'name',
        align: 'left',
        headerAlign: 'left',
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography variant='body2'>{row.name}</Typography>
        ),
      },
      {
        headerName: t('To'),
        field: 'location.city',
        align: 'left',
        headerAlign: 'left',
        minWidth: 100,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography variant='body2'>{row.location.city}</Typography>
        ),
      },
      {
        headerName: t('Order'),
        field: 'publicOrderId',
        align: 'center',
        headerAlign: 'center',
        minWidth: 50,
        flex: 2,
        sortable: false,
        renderCell: ({ row }) =>
          row.orderId ? (
            <Link
              href={`${wpAdminURL}/post.php?post=${row.orderId}&action=edit`}
              variant='body2'
              underline='always'
              rel='noopener noreferrer'
            >
              {row.publicOrderId}
            </Link>
          ) : (
            '-'
          ),
      },
      {
        headerName: t('ID'),
        field: 'publicId',
        align: 'center',
        headerAlign: 'center',
        minWidth: 50,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Tooltip title={row.publicId}>
            <Typography variant='body2'>
              {row.publicId.split('-')[0]}
            </Typography>
          </Tooltip>
        ),
      },
      {
        headerName: t('Items'),
        field: 'instances.length',
        align: 'center',
        headerAlign: 'center',
        minWidth: 40,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography variant='body2'>{row.instances.length}</Typography>
        ),
      },
      {
        headerName: t('Created'),
        field: 'createdAt',
        align: 'center',
        headerAlign: 'center',
        minWidth: 50,
        flex: 2,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography variant='body2'>{row.createdAt}</Typography>
        ),
      },
      {
        headerName: t('Arrival'),
        field: 'arrival',
        align: 'center',
        headerAlign: 'center',
        minWidth: 50,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Typography variant='body2'>{row.arrival || t('N/A')}</Typography>
        ),
      },
      {
        headerName: t('Status'),
        field: 'orderStatus',
        align: 'center',
        headerAlign: 'center',
        minWidth: 180,
        sortable: false,
        renderCell: ({ row }) => <StatusCell status={row.orderStatus} />,
      },
      {
        headerName: ' ',
        field: '',
        align: 'right',
        headerAlign: 'right',
        width: 25,
        sortable: false,
        renderCell: ({ row }) => (
          <IconButton
            to={`/warehouse-orders/${row.publicId}`}
            component={RouterLink}
            style={{
              height: 19,
              width: 19,
              marginRight: 10,
              padding: 9,
            }}
            size='large'
          >
            <CreateOutlinedIcon />
          </IconButton>
        ),
      },
    ],
    [i18n.language],
  );

  return (
    <CustomDataGrid
      columns={initColumns}
      rows={items}
      rowCount={total}
      pageSize={pageSize}
      onPageChange={onChangePage}
      dataGridClass={classes.dataGridClass}
      getRowClassName={getCustomRowStyles}
      headerHeight={40}
      rowHeight={40}
      rowSpacing={5}
      pagination
      paginationMode='server'
      hideFooterPagination={false}
      loading={loading}
      page={page}
    />
  );
};

WarehouseDeliveriesTable.propTypes = {
  onChangePage: PropTypes.func,
  items: PropTypes.array,
  total: PropTypes.number,
  // eslint-disable-next-line react/boolean-prop-naming
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  page: PropTypes.number,
};

export default WarehouseDeliveriesTable;
