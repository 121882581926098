import {
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { Hidden } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import InstancesList from './InstancesList';
import SearchField from '../../components/SearchField';
import MultiSelect from '../../components/MultiSelect';
import { INVISIBILITY_REASONS, WAREHOUSE_STATUS } from '../../utils/constants';
import MobileHeaderBox from '../../components/CustomComponents/MobileHeaderBox';
import FiltersBlock from '../../components/CustomComponents/FiltersBlock';
import LocationContext from '../../context/Location/LocationContext';
import SectionContainer from '../../components/CustomComponents/SectionContainer';
import TitleContainer from '../../components/TitleContainer';
import useNotificator from '../../utils/useNotificator';
import belecoApi from '../../api';

const useStyles = makeStyles((theme) => ({
  filteringControls: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 'auto',
    margin: '-4px',
    '& > div': {
      margin: 4,
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: 'initial',
      marginTop: 15,
    },
  },
}));

const Instances = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const { locations } = useContext(LocationContext);
  const history = useHistory();
  const location = useLocation();
  const { notifyError } = useNotificator();
  const { userLocation } = useContext(LocationContext);
  const notInitialRender = useRef(false);

  const initUrlParams = new URLSearchParams(history.location.search);

  const initFilteringOptions = {
    search: initUrlParams.get('search') ? initUrlParams.get('search') : '',
    invisibilityReason: initUrlParams.get('invisibilityReason')
      ? initUrlParams.get('invisibilityReason')
      : [],
    warehouseStatus: initUrlParams.get('warehouseStatus')
      ? initUrlParams.get('warehouseStatus')
      : [],
    offset: initUrlParams.get('offset') ? initUrlParams.get('offset') : 0,
  };

  const pageSize = 50;
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(initFilteringOptions.offset);
  const [searchFilterValue, setSearchFilterValue] = useState(
    initFilteringOptions.search,
  );
  const [invisibilityReasonFilterValue, setInvisibilityReasonFilterValue] =
    useState(initFilteringOptions.invisibilityReason);
  const [warehouseStatusFilterValue, setWarehouseStatusFilterValue] = useState(
    initFilteringOptions.warehouseStatus,
  );

  const updateUrlFilteringParams = () => {
    const urlSearchParams = {};

    if (searchFilterValue)
      Object.assign(urlSearchParams, { search: searchFilterValue });
    if (invisibilityReasonFilterValue.length)
      Object.assign(urlSearchParams, {
        invisibilityReason: invisibilityReasonFilterValue,
      });
    if (warehouseStatusFilterValue.length)
      Object.assign(urlSearchParams, {
        warehouseStatus: warehouseStatusFilterValue,
      });
    if (offset > 0) Object.assign(urlSearchParams, { offset });
    if (userLocation?.id)
      Object.assign(urlSearchParams, { locationId: userLocation.id });

    const urlParams = new URLSearchParams(urlSearchParams);
    history.push(`${location.pathname}?${urlParams.toString()}`);
  };

  const getInstancesData = async () => {
    setLoading(true);

    try {
      const params = {
        limit: pageSize,
        offset,
        invisibilityReason: invisibilityReasonFilterValue,
        warehouseStatus: warehouseStatusFilterValue,
        search: searchFilterValue,
        locationId: userLocation?.id || '',
      };

      const data = await belecoApi.inv.getInstances(params);

      const ids = data.rows.map((r) => r.productId).filter(Boolean);
      const meta = await belecoApi.wp.getProductsMeta(ids);

      data.rows.forEach((item) => {
        const itemMeta = meta[item.productId];
        item.title = itemMeta ? itemMeta.title : 'No Data';
        item.image = itemMeta ? itemMeta.image : '';
      });

      const { rows, count } = data;

      setItems(rows);
      setTotal(count);
      setPage(offset / pageSize);
    } catch (err) {
      notifyError(err, t('Failed to load instances'));
    } finally {
      setLoading(false);
    }

    updateUrlFilteringParams();
  };

  useEffect(() => {
    getInstancesData();
  }, [offset]);

  useEffect(() => {
    // Skip first render
    if (notInitialRender.current) {
      if (offset === 0) {
        getInstancesData();
      } else {
        setOffset(0);
      }
    } else {
      notInitialRender.current = true;
    }
  }, [
    searchFilterValue,
    invisibilityReasonFilterValue,
    warehouseStatusFilterValue,
    userLocation?.id,
  ]);

  const handleChangePage = useCallback((selectedPage) => {
    setOffset(selectedPage * pageSize);
  }, []);

  const cleanFilterData = useCallback(() => {
    setInvisibilityReasonFilterValue([]);
    setWarehouseStatusFilterValue([]);
    setSearchFilterValue('');
  }, []);

  const formattedReasons = useMemo(() => {
    return Object.entries(INVISIBILITY_REASONS).map(([key, value]) => ({
      title: t(value),
      value: key,
    }));
  }, [i18n.language]);

  const formattedStatuses = useMemo(() => {
    return Object.values(WAREHOUSE_STATUS).map((option) => ({
      title: t(option.label),
      value: option.value,
    }));
  }, [i18n.language]);

  const bookings = items.map((item) => ({
    ...item,
    state: locations[item.locationId]?.state,
  }));

  const [showFilters, setShowFilters] = useState(false);

  return (
    <>
      <Helmet>
        <title>{t('Instances')}</title>
      </Helmet>
      <Hidden mdDown>
        <TitleContainer title={t('Instances')}>
          <div className={classes.filteringControls}>
            <MultiSelect
              label={t('Warehouse Status')}
              minWidth={175}
              disabled={loading}
              options={formattedStatuses}
              onChange={setWarehouseStatusFilterValue}
              value={warehouseStatusFilterValue}
            />
            <MultiSelect
              label={t('Reason')}
              minWidth={175}
              disabled={loading}
              options={formattedReasons}
              onChange={setInvisibilityReasonFilterValue}
              value={invisibilityReasonFilterValue}
            />
            <div>
              <SearchField
                disabled={loading}
                onSearch={(filter, value) => {
                  setSearchFilterValue(value);
                }}
                initVal={searchFilterValue}
                resetSearchValue={() => setSearchFilterValue('')}
              />
            </div>
          </div>
        </TitleContainer>
        <SectionContainer>
          <InstancesList
            className={classes.root}
            onChangePage={handleChangePage}
            {...{ items: bookings, total, loading, pageSize, page }}
          />
        </SectionContainer>
      </Hidden>
      <Hidden mdUp>
        <SectionContainer>
          <div
            style={{
              marginBottom: 15,
              borderBottom: '10px solid #EBEBEB',
            }}
          >
            <MobileHeaderBox
              title={t('Instances')}
              showFilters={setShowFilters}
            />
            <FiltersBlock
              showFilters={showFilters}
              resetFiltersToDefaults={cleanFilterData}
            >
              <MultiSelect
                label={t('Warehouse Status')}
                minWidth={175}
                disabled={loading}
                options={formattedStatuses}
                onChange={setWarehouseStatusFilterValue}
                value={warehouseStatusFilterValue}
              />
              <MultiSelect
                label={t('Reason')}
                minWidth={175}
                disabled={loading}
                options={formattedReasons}
                onChange={setInvisibilityReasonFilterValue}
                value={invisibilityReasonFilterValue}
              />
              <SearchField
                disabled={loading}
                onSearch={(filter, value) => {
                  setSearchFilterValue(value);
                }}
                initVal={searchFilterValue}
              />
            </FiltersBlock>
          </div>
          <InstancesList
            className={classes.root}
            onChangePage={handleChangePage}
            {...{ items: bookings, total, loading, pageSize, page }}
          />
        </SectionContainer>
      </Hidden>
    </>
  );
};

export default Instances;
