import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { Button, TextField, Typography, Fade, Box } from '@mui/material';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { useTranslation } from 'react-i18next';
import LocationContext from '../../../../context/Location/LocationContext';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0px',
  },
  storageItem: {
    display: 'grid',
    gridTemplateColumns: '35% 25% 40%',
    alignItems: 'center',
  },
  storageNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  storageName: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.4',
  },
  storageLocationDetails: {
    display: 'flex',
  },
  storageInput: {
    margin: '5px',
    '& input': {
      textAlign: 'center',
      padding: '8px 10px',
    },
  },
  updateButton: {
    marginLeft: 'auto',
    marginTop: '15px',
  },
};

const StoragePlacementDetails = ({
  availabilityData,
  warehousePlacements,
  handleUpdate,
}) => {
  const { t } = useTranslation();

  const { userLocation } = useContext(LocationContext);

  const neededLocationObj = userLocation
    ? availabilityData.filter((el) => el.location.id === userLocation.id)
    : availabilityData;

  const initialValues = {
    warehousePlacement: warehousePlacements,
  };

  const handlePlacementsUpdate = async (values) => {
    const wpUpdated = Object.entries(values.warehousePlacement)
      .map(([key, value]) => ({
        locationId: key,
        aisle: '',
        shelf: '',
        ...value,
      }))
      .filter((item) => item !== null);

    await handleUpdate({ warehousePlacements: wpUpdated });
  };

  return (
    <Fade in>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={handlePlacementsUpdate}
          enableReinitialize
        >
          {({ handleSubmit, dirty: isDirty, isSubmitting }) => (
            <Box component='form' sx={styles.form} onSubmit={handleSubmit}>
              {neededLocationObj.map((availabilityByLocation) => {
                const {
                  location: { city: name, id: locationId },
                  qtyAvailable: available,
                  qtyTotal: total,
                } = availabilityByLocation;

                return (
                  <Box sx={styles.storageItem} key={`${locationId}-${name}`}>
                    <Box sx={styles.storageNameContainer}>
                      <HomeWorkIcon
                        fontSize='small'
                        style={{ marginRight: '5px' }}
                      />
                      <Typography sx={styles.storageName}>{name}</Typography>
                    </Box>
                    <Typography variant='body2' style={{ textAlign: 'center' }}>
                      {available}/{total}
                    </Typography>
                    <Box sx={styles.storageLocationDetails}>
                      <Field name={`warehousePlacement[${locationId}].aisle`}>
                        {({ field }) => (
                          <TextField
                            variant='outlined'
                            sx={styles.storageInput}
                            placeholder={t('N/A')}
                            {...field}
                            value={field.value}
                          />
                        )}
                      </Field>
                      <Field name={`warehousePlacement[${locationId}].shelf`}>
                        {({ field }) => (
                          <TextField
                            variant='outlined'
                            sx={styles.storageInput}
                            placeholder={t('N/A')}
                            {...field}
                            value={field.value}
                          />
                        )}
                      </Field>
                    </Box>
                  </Box>
                );
              })}
              <Button
                type='submit'
                variant='contained'
                disabled={!isDirty || isSubmitting}
                sx={styles.updateButton}
              >
                {t('Update Placement')}
              </Button>
            </Box>
          )}
        </Formik>
      </div>
    </Fade>
  );
};

StoragePlacementDetails.propTypes = {
  availabilityData: PropTypes.array,
  warehousePlacements: PropTypes.object,
  handleUpdate: PropTypes.func.isRequired,
};

export default StoragePlacementDetails;
