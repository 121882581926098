import { HEADER_HEIGHT } from '../../../utils/constants';

const TopBarStyles = {
  appBar: ({ zIndex }) => ({
    zIndex: zIndex.drawer + 1,
    boxShadow: '0px 0px 3px #00000086',
  }),
  toolbar: {
    padding: '0!important',
  },
  container: {
    height: HEADER_HEIGHT,
    flexWrap: 'nowrap',
  },
  logo: {
    display: 'flex',
    height: '100%',
    width: HEADER_HEIGHT,
    backgroundColor: 'title.highlighted',
    color: 'common.white',
    fontSize: '45px',
    fontFamily: 'Sequel, sans-serif',
    border: 0,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'title.highlighted',
    },
  },
  controlsContainer: ({ breakpoints }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    [breakpoints.up(450)]: {
      justifyContent: 'flex-end',
    },
  }),
  qrScanBlock: ({ breakpoints }) => ({
    marginRight: 0,
    [breakpoints.up(450)]: {
      marginRight: '26px',
    },
  }),
  languageSelectorBlock: {
    marginRight: { xs: '8px', sm: '24px' },
    minWidth: {
      xs: '55px',
      sm: '70px',
    },
  },
};

export default TopBarStyles;
