import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  primary: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Helvetica Neue, sans-serif',
    color: '#555555',
    textDecoration: 'none',
    textTransform: 'none',
  },
  secondary: {
    color: '#8b8b8b',
    textTransform: 'none',
  },
  avatar: {
    background: '#ECECEC',
    color: '#2a2929',
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Helvetica Neue, sans-serif',
  },
  inactive: { color: '#8b8b8b' },
  status: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#3AAB83',
  },
  inactiveStatus: {
    backgroundColor: '#C5C5C5',
  },
});

const CustomListItem = ({
  primary = '',
  secondary = '',
  avatarTextChars = 1,
  shouldDisableGutters = false,
  shouldHideText = false,
  hasDivider = false,
  isBtn = false,
  onClickBtn = null,
  id,
  isActive = true,
  hasStatus = false,
}) => {
  const classes = useStyles();

  return (
    <ListItem
      button={isBtn}
      disableGutters={shouldDisableGutters}
      divider={hasDivider}
      onClick={isBtn && id ? () => onClickBtn(id) : null}
      sx={{ padding: { xs: '8px 0px 8px 8px', sm: '8px 16px' } }}
    >
      <ListItemAvatar style={shouldHideText ? { minWidth: 'auto' } : {}}>
        <Avatar
          className={`${classes.avatar} ${!isActive && classes.inactive}`}
          alt={primary}
        >
          {primary.substring(0, avatarTextChars)}
        </Avatar>
      </ListItemAvatar>
      {!shouldHideText && (
        <ListItemText
          classes={{
            primary: `${classes.primary} ${!isActive && classes.inactive}`,
            secondary: classes.secondary,
          }}
          primary={primary}
          secondary={secondary}
        />
      )}
      {hasStatus && (
        <ListItemSecondaryAction>
          <div
            className={`${classes.status} ${
              !isActive && classes.inactiveStatus
            }`}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

CustomListItem.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  avatarTextChars: PropTypes.number,
  shouldDisableGutters: PropTypes.bool,
  shouldHideText: PropTypes.bool,
  hasDivider: PropTypes.bool,
  isBtn: PropTypes.bool,
  onClickBtn: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isActive: PropTypes.bool,
  hasStatus: PropTypes.bool,
};

export default CustomListItem;
