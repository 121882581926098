import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useNotificator from '../../../../utils/useNotificator';
import CustomDataGrid from '../../../../components/CustomDataGrid';
import belecoApi from '../../../../api';

const styles = {
  blockStyleClass: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '288px',
  },
  baseTypoStyle: {
    fontSize: '12px',
  },
  link: {
    color: '#2a2929',
    display: 'flex',
    minWidth: '0px',
  },
};

const DoubleBookedInstancesTable = () => {
  const { t } = useTranslation();

  const [doubleBookedInstances, setDoubleBookedInstances] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const { notifyError } = useNotificator();

  const pageSize = 5;

  const getDoubleBookedInstances = async () => {
    setLoading(true);

    try {
      const params = {
        deliveryStart: moment().format('YYYY-MM-DD'),
        deliveryEnd: moment().add(20, 'days').format('YYYY-MM-DD'), // next 21 days
      };

      const data = await belecoApi.inv.getDoubleBookedInstances(params);

      setTotal(data?.length || 0);
      setDoubleBookedInstances(data || []);
    } catch (err) {
      notifyError(err, 'Failed to load double booked instances');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDoubleBookedInstances();
  }, []);

  const onChangePage = (selectedPage) => {
    setPage(selectedPage);
  };

  const initColumns = [
    {
      field: 'delivery_date',
      headerName: t('Date'),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: ({ row: { delivery_date } }) => (
        <Typography variant='body2' sx={styles.baseTypoStyle}>
          {moment(delivery_date).format('D MMM YYYY')}
        </Typography>
      ),
    },
    {
      field: 'order_id',
      headerName: t('Order id'),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: ({ row: { order_id: orderId } }) => (
        <Typography variant='body2' sx={styles.baseTypoStyle}>
          {orderId}
        </Typography>
      ),
    },
    {
      field: 'instance_id',
      headerName: t('Instance id'),
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: ({ row: { public_id: publicId } }) => (
        <Link style={styles.link} to={`/instances/${publicId}`}>
          <Typography
            variant='body2'
            component='span'
            noWrap
            sx={styles.baseTypoStyle}
          >
            {publicId}
          </Typography>
        </Link>
      ),
    },
  ];

  return (
    <CustomDataGrid
      rows={doubleBookedInstances}
      columns={initColumns}
      rowCount={total}
      pageSize={pageSize}
      loading={loading}
      hideSpaceRow
      onPageChange={onChangePage}
      headerHeight={40}
      rowHeight={40}
      pagination
      hideFooterPagination={false}
      page={page}
      blockStyleSx={styles.blockStyleClass}
    />
  );
};

export default DoubleBookedInstancesTable;
