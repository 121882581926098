import { useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { each, isNil, isEmpty } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useNotificator from '../../utils/useNotificator';
import belecoApi from '../../api';
import BatchOrderTable from './BatchOrdersTable';
import { FILE_TEMPLATE_OF_BATCH_ORDER_URL } from '../../utils/constants';
import DETAILS_LABELS from './constants/detailsLabels';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: 20,
    border: '1px solid #ccc',
    borderRadius: 7,
    backgroundColor: '#ffffff',
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  title: {
    textTransform: 'uppercase',
    paddingBottom: 5,
    fontSize: 32,
    alignSelf: 'start',
  },
  dialogTitle: {
    color: '#000000',
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 8,
  },
  drop: {
    width: '100%',
    height: 250,
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16,
    marginBottom: 16,
    cursor: 'pointer',
  },
  uploadIcon: {
    fontSize: 48,
  },
  helperText: {
    fontSize: 24,
    marginBottom: 16,
  },
}));

const BatchOrders = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [fileToUpload, setFileToUpload] = useState(null);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [validatedOrder, setValidatedOrder] = useState([]);
  const [orderIsCreating, setOrderIsCreating] = useState(false);
  const [
    allowCreateOrderFromProvidedBatch,
    setAllowCreateOrderFromProvidedBatch,
  ] = useState(false);

  const { notifyError, notifySuccess } = useNotificator();

  const uploadErrorHandler = (errorData) => {
    try {
      const {
        message,
        data: { data: columnName },
        code,
      } = errorData.data;
      const affectedColumns = isNil(columnName)
        ? ''
        : `Check columns: ${columnName.join(', ')}.`;
      notifyError(
        null,
        `Error on uploading file. ${message}. ${affectedColumns} (Error code: ${code})`,
        true,
      );
    } catch (err) {
      notifyError(err, `Error on uploading file.`);
    }
  };

  const validateEachEntity = (entities) => {
    const validatedEntities = entities.map((item) => {
      const entity = item;
      const errors = [];
      each(Object.keys(entity), (fieldId) => {
        if (entity[fieldId] && entity[fieldId].error) {
          const entityLabel = DETAILS_LABELS[fieldId] || fieldId;
          const columnsToCheck = isEmpty(entityLabel.columns)
            ? ''
            : ` Check columns: ${entityLabel.columns.join(',')}.`;
          const errorCodeMessage = isNil(entity[fieldId].error_code)
            ? ''
            : `(Error code: ${entity[fieldId].error_code})`;
          errors.push(
            `${
              entityLabel.label || entityLabel
            } is not approved. ${columnsToCheck} ${errorCodeMessage}`,
          );
        }
      });
      return { ...entity, errors };
    });
    setAllowCreateOrderFromProvidedBatch(
      isEmpty(validatedEntities.filter((item) => item.errors.length)),
    );
    return validatedEntities;
  };

  const createOrderHandler = () => {
    setOrderIsCreating(true);
    const formData = new FormData();
    formData.append('file', fileToUpload[0], fileToUpload[0].name);
    belecoApi.wp
      .createBatchOrderWithUploadedFile(formData)
      .then(() => {
        notifySuccess('Order was created');
        setAllowCreateOrderFromProvidedBatch(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-debugger
        debugger;
        uploadErrorHandler(err);
      })
      .finally(() => {
        setOrderIsCreating(false);
      });
  };

  const handleChange = async (chosenFile) => {
    if (!isEmpty(chosenFile)) {
      setFileIsUploading(true);
      setDialogIsOpen(false);
      const formData = new FormData();
      formData.append('file', chosenFile[0], chosenFile[0].name);
      belecoApi.wp
        .validateBatchOrders(formData)
        .then((orderEntities) => {
          setValidatedOrder(validateEachEntity(orderEntities));
          setFileToUpload(chosenFile);
          if (isEmpty(orderEntities)) {
            notifyError(
              null,
              'No items found in provided order document. Please use downloaded template.',
              true,
            );
          }
        })
        .catch((err) => {
          console.log(err);
          uploadErrorHandler(err);
          setFileToUpload(null);
          setValidatedOrder([]);
        })
        .finally(() => {
          setFileIsUploading(false);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Other Tools</title>
      </Helmet>
      <Paper className={classes.rootContainer}>
        <Box>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>
              {t('Create batch orders')}
            </Typography>
            <Button
              variant='contained'
              onClick={() => setDialogIsOpen(true)}
              disabled={fileIsUploading}
              style={{ alignSelf: 'flex-end' }}
            >
              {fileIsUploading ? (
                <CircularProgress size={17} />
              ) : (
                t('Upload file')
              )}
            </Button>
          </div>

          <Typography variant='body2' style={{ padding: '20px 0px' }}>
            {t(
              "Orders can be created in a batch using file upload. Orders can't be undone after created so please make sure all required fields are filled in properly and that information is correct.",
            )}
          </Typography>

          <Button
            component='a'
            variant='contained'
            href={FILE_TEMPLATE_OF_BATCH_ORDER_URL}
            download
          >
            {t('Download template')}
          </Button>

          <Dialog
            open={dialogIsOpen}
            onClose={() => setDialogIsOpen(false)}
            maxWidth='sm'
            fullWidth
          >
            <DialogTitle>
              <Typography variant='body2' className={classes.dialogTitle}>
                {t('Upload file')}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Dropzone
                onDrop={handleChange}
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className={classes.drop}>
                    <input {...getInputProps()} />
                    <Typography variant='body2' className={classes.helperText}>
                      {t('Drag and Drop a file here or click')}
                    </Typography>
                    <CloudUploadIcon className={classes.uploadIcon} />
                  </div>
                )}
              </Dropzone>
            </DialogContent>
          </Dialog>
        </Box>

        {validatedOrder.length ? (
          <BatchOrderTable items={validatedOrder} />
        ) : null}

        <div
          style={{ display: 'flex', padding: '20px 0px', marginTop: 'auto' }}
        >
          <Button
            variant='contained'
            disabled={
              !fileToUpload ||
              orderIsCreating ||
              !allowCreateOrderFromProvidedBatch
            }
            onClick={createOrderHandler}
            style={{
              marginLeft: 'auto',
            }}
          >
            {orderIsCreating ? (
              <CircularProgress size={17} />
            ) : (
              t('Create Order')
            )}{' '}
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default BatchOrders;
