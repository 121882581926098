export default () => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    minHeight: 200,
    padding: 20,
    border: '1px solid #cecece',
    borderRadius: 7,
    marginBottom: 16,
  },
  header: {
    borderBottom: '2px solid #cecece',
    fontSize: 20,
    height: 28,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  updateButton: {
    alignSelf: 'flex-end',
  },
  placementControlsBox: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '40% 40% 20%',
    width: 172,
  },
  buttonsColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  storageInput: {
    margin: 5,
    '& input': {
      width: 40,
      textAlign: 'center',
      fontSize: 12,
    },
  },
  mountingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  mountingCheckbox: {
    marginLeft: -12,
  },
});
