import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import DetailPanel from './DetailPanel';
import getBillingName from './constants/getBillingName';

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    marginTop: 20,
  },
  listHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 50,

    '& > p': {
      flex: 1,
      textAlign: 'center',
    },

    '& > p:nth-child(1)': {
      minWidth: 200,
      textAlign: 'left',
      paddingLeft: 16,
    },
    '& > p:nth-child(2)': {
      minWidth: 100,
    },
    '& > p:nth-child(6)': {
      minWidth: 50,
    },
  },
  headerName: {
    fontWeight: 500,
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: 12,
    color: '#DA4453',
    textTransform: 'uppercase',
    flex: 1,
  },
  accordionRoot: {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
  },
  summaryRoot: {
    flexDirection: 'row-reverse',
    padding: '16px 0',
    minHeight: 'unset!important',
    width: '100%',
    border: '1px solid rgba(224, 224, 224, 1)',
    marginTop: 5,

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  summaryContent: {
    margin: '0!important',
    display: 'flex',

    '& > div': {
      flex: 1,
      textAlign: 'center',
    },

    '& > div:nth-child(1)': {
      minWidth: 200,
      textAlign: 'left',
      paddingLeft: 16,
    },
    '& > div:nth-child(2)': {
      minWidth: 100,
    },
    '& > div:nth-child(6)': {
      minWidth: 50,
    },

    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },
  detailsRoot: {
    padding: 0,
    transition: 'all .2s linear',
    width: '100%',

    '& > div': {
      boxShadow: 'none',
    },

    '& .MuiDataGrid-row:nth-of-type(2n+1)': {
      backgroundColor: '#F8F9FA',
    },
  },
}));

const BatchOrderTable = ({ items }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.tableWrapper}>
      <div className={classes.listHeader}>
        <Typography className={classes.headerName}>Name</Typography>
        <Typography className={classes.headerName}>Company</Typography>
        <Typography className={classes.headerName}>Commitment</Typography>
        <Typography className={classes.headerName}>Cost Center</Typography>
        <Typography className={classes.headerName}>Products</Typography>
        <Typography className={classes.headerName}>Validation</Typography>
      </div>
      {items.map((rowData) => {
        const {
          billing_first_name,
          billing_last_name,
          billing_company: billingCompany,
          commitment_period: commitmentPeriod,
          is_subscription: isSubscription,
          cost_center: costCenter,
          products,
          errors,
        } = rowData;
        const name = getBillingName(billing_first_name, billing_last_name);
        const companyName =
          isNil(billingCompany) || billingCompany.error ? (
            <em>Not specified</em>
          ) : (
            billingCompany
          );

        return (
          <Accordion
            square
            classes={{
              root: classes.accordionRoot,
            }}
            TransitionComponent={Collapse}
          >
            <AccordionSummary
              expandIcon={null}
              classes={{
                root: classes.summaryRoot,
                content: classes.summaryContent,
              }}
            >
              <div>
                <Typography variant='body2'>
                  {name.error ? <em>Not specified</em> : name}
                </Typography>
              </div>
              <div>
                <Typography variant='body2'> {companyName} </Typography>
              </div>
              <div>
                {commitmentPeriod.error || isSubscription.error ? (
                  <em>Not specified</em>
                ) : null}
                {!isSubscription ? (
                  <Typography variant='body2'>No subscription</Typography>
                ) : (
                  <Typography variant='body2'>
                    {commitmentPeriod || ''}
                  </Typography>
                )}
              </div>
              <div>
                <Typography variant='body2'>
                  {isNil(costCenter) || costCenter.error ? (
                    <em>Not specified</em>
                  ) : (
                    costCenter
                  )}
                </Typography>
              </div>
              <div>
                {isNil(products) || products.error ? (
                  <Typography variant='body2'>{t('N/A')}</Typography>
                ) : (
                  <div>{Object.keys(products).length}</div>
                )}
              </div>
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant='body2'
                    style={{
                      backgroundColor:
                        errors.length === 0 ? '#85CB85' : '#DA4453',
                      borderRadius: '20px',
                      padding: '2px 15px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {' '}
                    {errors.length === 0 ? 'Ok' : 'Failed'}
                  </Typography>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: classes.detailsRoot,
              }}
            >
              <DetailPanel {...rowData} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

BatchOrderTable.propTypes = {
  items: PropTypes.array,
};

export default BatchOrderTable;
