import { Card, CardContent, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import DimensionsBox from './DimensionBox';
import { DimensionsCardStyles } from '../../styles';
import { ProductDetailsContext } from '../../../../context/ProductDetails/ProductDetailsContext';
import LoadingOverlay from '../../../../components/LoadingOverlay';

const useStyles = makeStyles(DimensionsCardStyles);

const DimensionsCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    dimensions,
    isDimensionsLoading,
    updateDimensions,
    isProductLoading,
    setIsDimensionsLoading,
  } = useContext(ProductDetailsContext);

  const handleUpdate = (values) => {
    updateDimensions({ params: values });
  };

  useEffect(() => {
    if (!isProductLoading) {
      setIsDimensionsLoading(false);
    }
  }, [isProductLoading]);

  return (
    <Card className={classes.container}>
      <CardContent>
        <div className={classes.titleBox}>
          <Typography variant='body2' className={classes.titleText}>
            {t('Storage dimensions')}
          </Typography>
        </div>
        <div className={classes.content}>
          {isDimensionsLoading && !dimensions ? (
            <LoadingOverlay />
          ) : (
            <DimensionsBox
              values={dimensions}
              isLoading={isDimensionsLoading}
              handleUpdate={handleUpdate}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default DimensionsCard;
