import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const NoItemsFound = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        minHeight: '50px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant='body2'> {t('No items found')}</Typography>
    </div>
  );
};

export default NoItemsFound;
