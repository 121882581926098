import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isNil } from 'lodash';
import { BachOrderTableWrapper } from './styles';
import ColumnDataRow from './ColumnDataRow';
import getBillingName from './constants/getBillingName';

const useStyles = makeStyles(BachOrderTableWrapper);

/* eslint-disable camelcase,react/prop-types,react/no-unstable-nested-components */

const DetailPanel = (rowData) => {
  const classes = useStyles();

  const { errors } = rowData;

  const SectionTitle = ({ title }) => (
    <Typography
      style={{
        fontSize: 20,
        padding: '20px 0px',
        textTransform: 'uppercase',
      }}
    >
      {' '}
      {title}{' '}
    </Typography>
  );

  const BasicInfoColumn = () => {
    const { billing_email, products } = rowData;

    const Products = () => {
      if (isNil(products) || products.error) {
        return (
          <Typography>
            <em>Not specified</em>
          </Typography>
        );
      }
      return Object.keys(products).map((key) => (
        <ColumnDataRow title={key} data={products[key].qty || ''} />
      ));
    };

    return (
      <div>
        <SectionTitle title='Account' />
        <ColumnDataRow title='Mail' data={billing_email} />
        <div style={{ marginTop: 40 }}>
          <SectionTitle title='Products' />
          <Products />
        </div>
      </div>
    );
  };

  const DeliveryDetailsColumn = () => {
    const {
      order_delivery_date,
      shipping_first_name,
      shipping_last_name,
      shipping_address_1,
      shipping_address_2,
      shipping_postcode,
      shipping_company,
      shipping_city,
      shipping_phone,
      shipping_email,
      location,
    } = rowData;
    return (
      <div>
        <SectionTitle title='Delivery' />
        <ColumnDataRow title='Date' data={order_delivery_date} />
        <ColumnDataRow title='Company' data={shipping_company} />
        <ColumnDataRow
          title='Name'
          data={getBillingName(shipping_first_name, shipping_last_name)}
        />
        <ColumnDataRow title='Address' data={null}>
          <div>
            {[shipping_address_1, shipping_address_2].map((address) => (
              <Typography>{address.error ? '' : address}</Typography>
            ))}
          </div>
        </ColumnDataRow>
        <ColumnDataRow title='Postal address' data={shipping_postcode} />
        <ColumnDataRow title='City' data={shipping_city} />
        <ColumnDataRow title='Phone' data={shipping_phone} />
        <ColumnDataRow title='Mail' data={shipping_email} />
        <ColumnDataRow title='Location' data={location.state} />
      </div>
    );
  };

  const InvoiceDetailsColumn = () => {
    const {
      billing_first_name,
      billing_last_name,
      billing_address_1,
      billing_address_2,
      billing_postcode,
      billing_city,
      billing_company,
      billing_customer_invoice_reference,
      billing_organisation_number,
      billing_email,
      billing_phone,
      payment_method,
    } = rowData;
    return (
      <div>
        <SectionTitle title='Invoice details' />
        <ColumnDataRow
          title='Name'
          data={getBillingName(billing_first_name, billing_last_name)}
        />
        <ColumnDataRow title='Company' data={billing_company} />
        <ColumnDataRow title='Address' data={null}>
          <div>
            {[billing_address_1, billing_address_2].map((address) => (
              <Typography>{address.error ? '' : address}</Typography>
            ))}
          </div>
        </ColumnDataRow>
        <ColumnDataRow title='Postal address' data={billing_postcode} />
        <ColumnDataRow title='City' data={billing_city} />
        <ColumnDataRow title='Phone' data={billing_phone} />
        <ColumnDataRow
          title='Org / Person-nr'
          data={billing_organisation_number}
        />
        <ColumnDataRow title='Payment method' data={payment_method} />
        <ColumnDataRow title='Postal Address' data={billing_postcode} />
        <ColumnDataRow
          title='Reference'
          data={billing_customer_invoice_reference}
        />
        <ColumnDataRow title='Mail' data={billing_email} />
      </div>
    );
  };

  const ValidationErrorMessage = ({ text }) => {
    return (
      <Typography variant='body1' className={classes.validationErrorMessage}>
        {' '}
        {text}
      </Typography>
    );
  };

  return (
    <Box>
      <div className={classes.detailsRowWrapper}>
        <BasicInfoColumn />
        <DeliveryDetailsColumn />
        <InvoiceDetailsColumn />
      </div>
      <div>
        {errors.map((errorMessage) => (
          <ValidationErrorMessage text={errorMessage} />
        ))}
      </div>
    </Box>
  );
};

export default DetailPanel;
