import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { useTranslation } from 'react-i18next';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { LOCALES } from '../../../../utils/constants';
import './styles.css';

const styles = {
  container: {
    position: 'relative',
    width: 130,
  },
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
    pointerEvents: 'none',
  },
  disabledIcon: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
};

const Datepicker = ({
  name,
  value,
  onChange,
  datePickerProps,
  containerClass = '',
}) => {
  const { i18n } = useTranslation();

  const [isDateInputFocused, setDateInputFocus] = useState(false);

  const momentLng = LOCALES[i18n.resolvedLanguage].localeCode;

  return (
    <Box className={containerClass} sx={styles.container}>
      <SingleDatePicker
        readOnly
        firstDayOfWeek={1}
        placeholder=''
        date={value ? moment(value).locale(momentLng) : null}
        onDateChange={(date) => onChange(date.format('YYYY-MM-DD'))}
        focused={isDateInputFocused}
        onFocusChange={({ focused }) => setDateInputFocus(focused)}
        id={name}
        hideKeyboardShortcutsPanel
        displayFormat='YYYY-MM-DD'
        numberOfMonths={1}
        isOutsideRange={() => false}
        {...datePickerProps}
      />
      <CalendarTodayOutlinedIcon
        fontSize='small'
        sx={
          datePickerProps?.disabled
            ? { ...styles.icon, ...styles.disabledIcon }
            : styles.icon
        }
      />
    </Box>
  );
};

Datepicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  datePickerProps: PropTypes.object,
  containerClass: PropTypes.string,
};

export default Datepicker;
