import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import belecoApi from '../../api';
import useNotificator from '../../utils/useNotificator';
import { moveItemInArray } from '../../utils';
import {
  TRANSPORTING_STATUS_OPTIONS,
  TRANSPORTING_STATUS_OPTIONS as TSO,
} from '../../utils/constants';
import { ChangelogContext } from '../Changelog/ChangelogContext';

export const TransportDetailsContext = createContext({});

const TransportDetailsProvider = ({ children }) => {
  const { publicId } = useParams();
  const { notifyError } = useNotificator();
  const [isLoading, setIsLoading] = useState(false);
  const [transport, setTransport] = useState({});
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isPackedLoading, setIsPackedLoading] = useState(null);
  const [instanceUpdateLoadingSKU, setInstancesUpdateLoadingSKU] = useState('');
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [products, setProducts] = useState({});

  const { getChangelog } = useContext(ChangelogContext);

  const updateTransport = async ({ data, shouldUpdateInstances = false }) => {
    setIsUpdateLoading(true);
    try {
      const newTransport = await belecoApi.inv.updateTransport(data, publicId);
      const instances = shouldUpdateInstances
        ? newTransport.instances
        : transport.instances;

      setTransport({ ...newTransport, instances });
    } catch (err) {
      notifyError(err, 'Unknown transport');
    } finally {
      setIsUpdateLoading(false);
    }
  };
  const getTransportDetails = async () => {
    setIsLoading(true);
    try {
      const transportDetails = await belecoApi.inv.getTransportDetails(
        publicId,
      );
      setTransport(transportDetails);
    } catch (err) {
      notifyError(err, 'Failed to get transport details');
    } finally {
      setIsLoading(false);
    }
  };
  const clearTransportDetails = () => {
    setIsLoading(false);
    setTransport({});
    setProducts({});
  };

  const getTransportProductInstances = async () => {
    setIsProductsLoading(true);
    try {
      const { instances } = await belecoApi.inv.getTransportDetails(publicId);
      const sortedInstances = instances
        .slice()
        .sort(
          (a, b) =>
            transport.instances.findIndex(
              (instance) => instance.publicId === a.publicId,
            ) -
            transport.instances.findIndex(
              (instance) => instance.publicId === b.publicId,
            ),
        );
      moveItemInArray(
        sortedInstances,
        0,
        transport.instances.findIndex(
          (instance) => instance.publicId === instanceUpdateLoadingSKU,
        ),
      );
      setTransport({ ...transport, sortedInstances });
    } catch (err) {
      notifyError(err, 'Failed to get instanced');
    } finally {
      setIsProductsLoading(false);
    }
  };

  const replaceTransportInstance = async (params) => {
    try {
      await belecoApi.inv.replaceInstanceInTransport(params);
      const pack = transport.instances.some(
        ({ transportInstances }) =>
          publicId !== params.instanceToReplace && transportInstances.isPacked,
      );

      setInstancesUpdateLoadingSKU(params.instanceToReplace);
      await getTransportProductInstances(params.publicId);
      await updateTransport({
        data: {
          status: pack ? TSO.PROCESSING.value : TSO.CREATED.value,
          isNoShow: false,
        },
        shouldUpdateInstances: true,
      });
      getChangelog(() =>
        belecoApi.inv.getTransportHistory({ id: params.publicId }),
      );
    } catch (err) {
      notifyError(err, 'Failed to replace the instance');
    } finally {
      setInstancesUpdateLoadingSKU('');
    }
  };
  const getTransportProductsMeta = async (ids) => {
    setIsProductsLoading(true);
    try {
      const productsMeta = await belecoApi.wp.getProductsMeta(ids);
      setProducts(productsMeta);
    } catch (err) {
      notifyError(err, 'Failed to get meta');
    } finally {
      setIsProductsLoading(false);
    }
  };

  const cancelTransport = async () => {
    try {
      await belecoApi.inv.cancelTransport(publicId);
      updateTransport({
        data: {
          status: TSO.CANCELLED.value,
        },
      });
    } catch (err) {
      notifyError(err, 'Failed to cancel transport');
    }
  };

  const updateProductPackedStatus = async (params) => {
    isPackedLoading(true);
    try {
      const packet = await belecoApi.inv.updateTransportPackedProduct(params);
      setTransport({ ...transport, packet });
    } catch (err) {
      notifyError(err, 'Failed to update product packed status');
    } finally {
      isPackedLoading(false);
    }
  };

  const updateInstancePackedStatus = async (params) => {
    setIsPackedLoading(params.instanceId);
    try {
      const updatedTransport =
        await belecoApi.inv.updateTransportInstanceIsPacked(params);
      if (
        updatedTransport.instances.every(
          ({ transportInstances: newTransportInstances }) =>
            newTransportInstances.isPacked,
        )
      ) {
        // This duplicates BE logic that sets transport status to READY when all instances are packed
        await updateTransport({
          data: {
            status: TRANSPORTING_STATUS_OPTIONS.READY.value,
            isNoShow: false,
          },
          shouldUpdateInstances: true,
        });
      } else {
        setTransport(updatedTransport);
      }
    } catch (err) {
      notifyError(err, 'Failed to update instance packed status');
    } finally {
      setIsPackedLoading(null);
    }
  };

  const updateInstanceCompletedStatus = async (params) => {
    setIsPackedLoading(params.instanceId);
    try {
      const updatedTransport =
        await belecoApi.inv.updateTransportInstanceIsCompleted(params);
      setTransport(updatedTransport);
    } catch (err) {
      notifyError(err, 'Failed to update instance completed status');
    } finally {
      setIsPackedLoading(null);
    }
  };

  const returnValues = useMemo(
    () => ({
      isLoading,
      transport,
      isUpdateLoading,
      isPackedLoading,
      instanceUpdateLoadingSKU,
      isProductsLoading,
      products,
      updateTransport,
      getTransportDetails,
      clearTransportDetails,
      getTransportProductInstances,
      replaceTransportInstance,
      getTransportProductsMeta,
      cancelTransport,
      updateProductPackedStatus,
      updateInstancePackedStatus,
      updateInstanceCompletedStatus,
    }),
    [
      isLoading,
      transport,
      isUpdateLoading,
      isPackedLoading,
      instanceUpdateLoadingSKU,
      isProductsLoading,
      products,
    ],
  );

  return (
    <TransportDetailsContext.Provider value={returnValues}>
      {children}
    </TransportDetailsContext.Provider>
  );
};
TransportDetailsProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default TransportDetailsProvider;
