import { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Field } from 'formik';
import Select from '@mui/material/Select';
import { isEmpty } from 'lodash';
import moment from 'moment';
import LinkIcon from '@mui/icons-material/Link';
import { useTranslation } from 'react-i18next';
import FormInput from './FormInput';
import Datepicker from '../../InstanceDetails/components/Datepicker';
import TransportControlButtons from './TransportControlButtons';
import { mainBlockStyles } from '../styles';
import ContactBlock from './ContactBlock';
import BuildingTypeIcon from '../../../components/CustomComponents/BuildingTypeIcon';
import { TMSContext } from '../../../context/TMS/TMSContext';

const useStyles = makeStyles(mainBlockStyles);

const TransportDetailsMainDesktop = ({ transport = {}, onUpdateTms }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const wpAdminURL = process.env.REACT_APP_WP_ADMIN_URL;

  const { TMSList, getTMS, loading: isLoadingTMSList } = useContext(TMSContext);

  useEffect(() => {
    if (isEmpty(TMSList) && !isLoadingTMSList) {
      getTMS();
    }
  }, [isLoadingTMSList, TMSList]);

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BuildingTypeIcon
            buildingType={transport.buildingType}
            additionalIconStyle={{ width: 45, marginLeft: -10 }}
          />
          <Typography className={classes.headingTitle}>
            {transport.reference}
          </Typography>
        </div>
        <ContactBlock transport={transport} />
      </div>
      <Formik initialValues={transport} onSubmit={() => {}} enableReinitialize>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.column}>
                <div className={classes.formItemContainer}>
                  <Typography className={classes.formItemName}>
                    {t('Type')}
                  </Typography>
                  <Field
                    name='type'
                    as={(props) => (
                      <FormInput {...props} select>
                        {/* eslint-disable-next-line react/prop-types */}
                        <MenuItem value={props.value}>{props.value}</MenuItem>
                      </FormInput>
                    )}
                  />
                </div>
                <div className={classes.formItemContainer}>
                  <Typography className={classes.formItemName}>
                    {t('Start time')}
                  </Typography>
                  <div className={classes.formDateItem}>
                    <Field name='from'>
                      {({ field, form }) => (
                        <Datepicker
                          name={field.name}
                          value={field.value}
                          onChange={(value) =>
                            form.setFieldValue(field.name, value)
                          }
                          datePickerProps={{
                            displayFormat: 'DD MMM YYYY',
                            disabled: true,
                          }}
                        />
                      )}
                    </Field>
                    <Field
                      name='from'
                      as={(props) => (
                        <FormInput {...props} select>
                          {/* eslint-disable-next-line react/prop-types */}
                          <MenuItem value={props.value}>
                            {/* eslint-disable-next-line react/prop-types */}
                            {moment(props.value).format('HH:mm')}
                          </MenuItem>
                        </FormInput>
                      )}
                    />
                  </div>
                </div>
                <div className={classes.formItemContainer}>
                  <Typography className={classes.formItemName}>
                    {t('End time')}
                  </Typography>
                  <div className={classes.formDateItem}>
                    <Field name='to'>
                      {({ field, form }) => (
                        <Datepicker
                          name={field.name}
                          value={field.value}
                          onChange={(value) =>
                            form.setFieldValue(field.name, value)
                          }
                          datePickerProps={{
                            displayFormat: 'DD MMM YYYY',
                            disabled: true,
                          }}
                        />
                      )}
                    </Field>
                    <Field
                      name='to'
                      as={(props) => (
                        <FormInput {...props} select>
                          {/* eslint-disable-next-line react/prop-types */}
                          <MenuItem value={props.value}>
                            {/* eslint-disable-next-line react/prop-types */}
                            {moment(props.value).format('HH:mm')}
                          </MenuItem>
                        </FormInput>
                      )}
                    />
                  </div>
                </div>
                <div className={classes.formItemContainer}>
                  <Typography className={classes.formItemName}>
                    {t('Warehouse')}
                  </Typography>
                  <Field name='location.state' as={FormInput} />
                </div>
                <div
                  className={classes.formItemContainer}
                  style={{ alignItems: 'baseline' }}
                >
                  <Typography className={classes.formItemName}>
                    {t('Address')}
                  </Typography>
                  <div className={classes.addressContainer}>
                    <Typography variant='body2' className={classes.addressLine}>
                      {transport.location && transport.location.addressLine1}
                    </Typography>
                    <Typography variant='body2' className={classes.addressLine}>
                      {transport.location && transport.location.addressLine2}
                    </Typography>
                  </div>
                </div>
                <div className={classes.formItemContainer}>
                  <Typography className={classes.formItemName}>
                    {t('Transport provider')}
                  </Typography>
                  <Select
                    size='small'
                    value={transport.tms.id}
                    variant='outlined'
                    className={classes.selectFieldWrapper}
                    onChange={(event) => onUpdateTms(event.target.value)}
                  >
                    {TMSList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Typography
                          variant='body1'
                          className={classes.selectFieldTypo}
                        >
                          {option.title}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className={classes.formItemContainer}>
                  <a
                    href={`${wpAdminURL}/post.php?post=${transport.orderId}&action=edit`}
                    style={{ color: '#000' }}
                  >
                    <Typography
                      variant='body2'
                      className={classes.wpReferenceText}
                    >
                      {t('View details in WP admin')}
                      <LinkIcon className={classes.wpReferenceIcon} />
                    </Typography>
                  </a>
                </div>
                <div className={classes.updateWarningContainer}>
                  <Typography variant='body2'>
                    {t(
                      'Information can only be updated from Wordpress interface',
                    )}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className={classes.column}
                style={{ borderLeft: '1px solid #cccccc' }}
              >
                <div className={classes.formItemContainer}>
                  <Typography className={classes.formItemName}>
                    {t('Name')}
                  </Typography>
                  <Field name='name' as={FormInput} />
                </div>
                <div className={classes.formItemContainer}>
                  <Typography className={classes.formItemName}>
                    {t('Company')}
                  </Typography>
                  <Field name='companyName' as={FormInput} />
                </div>
                <div className={classes.formItemContainer}>
                  <Typography className={classes.formItemName}>
                    {t('Address')}
                  </Typography>
                  <Field name='addressLine1' as={FormInput} />
                </div>
                <div className={classes.formItemContainer}>
                  <Typography className={classes.formItemName}>
                    {t('Postal code')}
                  </Typography>
                  <Field name='zipCode' as={FormInput} />
                </div>
                <div className={classes.formItemContainer}>
                  <Typography className={classes.formItemName}>
                    {t('City')}
                  </Typography>
                  <Field name='city' as={FormInput} />
                </div>
                <div className={classes.formItemContainer}>
                  <Typography className={classes.formItemName}>
                    {t('Country')}
                  </Typography>
                  <Field name='country' as={FormInput} />
                </div>
                <div className={classes.formItemContainer}>
                  <Typography className={classes.formItemName}>
                    {t('Phone')}
                  </Typography>
                  <Field name='phone' as={FormInput} />
                </div>
                <div>
                  <Typography
                    className={classes.formItemName}
                    style={{ marginBottom: 8 }}
                  >
                    {t('Transport instructions')}
                  </Typography>
                  <Field
                    name='transportInstructions'
                    as={(formikProps) => (
                      <FormInput
                        fullWidth
                        multiline
                        rows={2}
                        {...formikProps}
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
            <TransportControlButtons transport={transport} />
          </form>
        )}
      </Formik>
    </div>
  );
};

TransportDetailsMainDesktop.propTypes = {
  transport: PropTypes.object.isRequired,
  onUpdateTms: PropTypes.func,
};

export default TransportDetailsMainDesktop;
