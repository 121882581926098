import { TRANSPORTATION_TYPE } from '../../../../utils/constants';
import ForwardIcon from '@mui/icons-material/Forward';
import React from 'react';
import PropTypes from 'prop-types';

const TransportsTypeIcon = ({ deliveryType }) => {
  const commonStyles = {
    width: 25,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  };
  const iconBackgroundColor = {
    [TRANSPORTATION_TYPE.DELIVERY]: '#3AAB83',
    [TRANSPORTATION_TYPE.PICKUP]: '#8B8B8B',
  };

  return (
    <div
      style={{
        ...commonStyles,
        backgroundColor: iconBackgroundColor[deliveryType],
      }}
    >
      <ForwardIcon
        style={{
          color: '#FFF',
          paddingLeft: 2,
          fontSize: 20,
          transform:
            deliveryType === TRANSPORTATION_TYPE.DELIVERY
              ? 'rotate(180deg)'
              : '',
        }}
      />
    </div>
  );
};

TransportsTypeIcon.propTypes = {
  deliveryType: PropTypes.string,
};
export default TransportsTypeIcon;
