import { Typography, Box, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import TransportsChartBlock from './TransportsChartBlock';
import FailedTransportsTable from './FailedTransportsTable';

const styles = {
  transportsBlock: {
    marginTop: '20px',
  },
  title: (theme) => ({
    fontSize: '25px',
    color: '#555555',
    lineHeight: '92px',

    [theme.breakpoints.down('md')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
      fontSize: '16px',
      textTransform: 'uppercase',
      letterSpacing: '0.9px',
      fontWeight: 200,
    },
  }),
  transportsGrid: (theme) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '28px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gap: '16px',
    },
  }),
  gridElem: (theme) => ({
    width: '100%',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '12px 24px',

    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
    },
  }),
  gridElemTitleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '5px',
  },
  gridElemTitle: {
    fontSize: '18px',
    lineHeight: '38px',
    color: 'black',
    fontWeight: 500,
  },
  btnLabel: {
    textTransform: 'none',
    color: '#da4453',
  },
  periodText: {
    paddingBottom: '4px',
    fontSize: '10px',
    lineHeight: '10px',
    color: 'gray',
  },
};

const TransportsBlock = () => {
  return (
    <Box sx={styles.transportsBlock}>
      <Typography sx={styles.title}>Transports</Typography>
      <Box sx={styles.transportsGrid}>
        <Paper sx={styles.gridElem}>
          <Box sx={styles.gridElemTitleBlock}>
            <Typography sx={styles.gridElemTitle}>
              Scheduled transports
            </Typography>
            <Link
              variant='body2'
              style={{
                textDecoration: 'none',
                color: '#da4453',
              }}
              to='/transports'
            >
              View all
            </Link>
          </Box>
          <Typography sx={[styles.periodText, { paddingBottom: '20px' }]}>
            Next 20 days
          </Typography>
          <TransportsChartBlock />
        </Paper>
        <Paper
          sx={[styles.gridElem, { display: 'flex', flexDirection: 'column' }]}
        >
          <Box sx={styles.gridElemTitleBlock}>
            <Typography sx={styles.gridElemTitle}>Failed transports</Typography>
          </Box>
          <Typography sx={styles.periodText}>Last 7 days</Typography>
          <FailedTransportsTable />
        </Paper>
      </Box>
    </Box>
  );
};
export default TransportsBlock;
