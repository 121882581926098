import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Hidden, useMediaQuery } from '@mui/material';
import moment from 'moment';
import { ORDER_STATUS } from '../../utils/constants';
import WDMobileView from './WarehouseDeliveriesMobile';
import WDDeskTopView from './WarehouseDeliveriesDesktop';
import belecoApi from '../../api';
import useNotificator from '../../utils/useNotificator';
import LocationContext from '../../context/Location/LocationContext';
import useDebouncedValue from '../../utils/useDebouncedValue';

const WarehouseDeliveries = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { notifyError } = useNotificator();
  const notInitialRender = useRef(false);
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));
  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));
  const { userLocation } = useContext(LocationContext);

  const initUrlParams = new URLSearchParams(history.location.search);

  const initFilteringOptions = {
    from: initUrlParams.get('from')
      ? moment(initUrlParams.get('from'))
      : moment(),
    to: initUrlParams.get('to')
      ? moment(initUrlParams.get('to'))
      : moment().add(7, 'days'),
    search: initUrlParams.get('search') ? initUrlParams.get('search') : '',
    ownerId: initUrlParams.get('ownerId')
      ? initUrlParams.get('ownerId').split(',')
      : [],
    orderStatus: initUrlParams.get('orderStatus')
      ? initUrlParams.get('orderStatus').split(',')
      : [],
    offset: initUrlParams.get('offset') ? initUrlParams.get('offset') : 0,
    order: 'ASC', // This should be default from FE, BE default is DESC
  };

  const pageSize = 20;
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(initFilteringOptions.offset);
  const [searchValue, setSearchValue] = useState(initFilteringOptions.search);
  const [ownerIfFilterValue, setOwnerIdFilterValue] = useState(
    initFilteringOptions.ownerId,
  );

  const [orderStatusFilterValue, setOrderStatusFilterValue] = useState(
    initFilteringOptions.orderStatus,
  );
  const [startDateFilter, setStartDateFilter] = useState(
    initFilteringOptions.from,
  );
  const [endDateFilter, setEndDateFilter] = useState(initFilteringOptions.to);
  const [assetsProvidersList, setAssetProvidersList] = useState({});

  const updateUrlFilteringParams = () => {
    const urlSearchParams = {};

    if (startDateFilter)
      Object.assign(urlSearchParams, {
        from: moment(startDateFilter).format('YYYY-MM-DD'),
      });
    if (endDateFilter)
      Object.assign(urlSearchParams, {
        to: moment(endDateFilter).format('YYYY-MM-DD'),
      });
    if (searchValue) Object.assign(urlSearchParams, { search: searchValue });
    if (ownerIfFilterValue.length)
      Object.assign(urlSearchParams, { ownerId: ownerIfFilterValue });
    if (orderStatusFilterValue.length)
      Object.assign(urlSearchParams, { orderStatus: orderStatusFilterValue });
    if (offset > 0) Object.assign(urlSearchParams, { offset });
    if (userLocation?.id)
      Object.assign(urlSearchParams, { locationId: userLocation.id });

    const urlParams = new URLSearchParams(urlSearchParams);
    history.push(`${location.pathname}?${urlParams.toString()}`);
  };

  const getWarehouseDeliveriesData = async () => {
    setLoading(true);

    const dateFrom = startDateFilter
      ? moment(startDateFilter).format('YYYY-MM-DD')
      : '';
    const dateTo = endDateFilter
      ? moment(endDateFilter).format('YYYY-MM-DD')
      : '';

    const params = {
      limit: pageSize,
      offset,
      orderStatus: orderStatusFilterValue,
      ownerId: ownerIfFilterValue,
      search: searchValue,
      etaAfter: dateFrom,
      etaBefore: dateTo,
      locationId: userLocation?.id || '',
      order: initFilteringOptions.order,
    };

    try {
      const data = await belecoApi.inv.getWarehouseOrders(params);
      const { rows: apiRows, count } = data;

      setRows(apiRows);
      setTotal(count);
      setPage(offset / pageSize);
    } catch (err) {
      notifyError(err, t('Failed to load warehouse orders'));
    } finally {
      setLoading(false);
    }

    updateUrlFilteringParams();
  };

  const handleLoadMore = async () => {
    if (total > rows.length) {
      try {
        setLoading(true);

        const dateFrom = startDateFilter
          ? moment(startDateFilter).format('YYYY-MM-DD')
          : '';
        const dateTo = endDateFilter
          ? moment(endDateFilter).format('YYYY-MM-DD')
          : '';

        const params = {
          limit: 10,
          offset: rows.length,
          orderStatus: orderStatusFilterValue,
          ownerId: ownerIfFilterValue,
          search: searchValue,
          etaAfter: dateFrom,
          etaBefore: dateTo,
          locationId: userLocation?.id || '',
        };

        const data = await belecoApi.inv.getWarehouseOrders(params);

        const { rows: apiRows } = data;

        setRows((prev) => {
          return [...prev, ...apiRows];
        });
      } catch (err) {
        notifyError(err, t('Failed to load warehouse orders'));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getWarehouseDeliveriesData();
  }, [offset]);

  const startEndDateMemoized = useMemo(() => {
    return { startDateFilter, endDateFilter };
  }, [startDateFilter, endDateFilter]);

  // Debounced two values as one
  const debouncedStartEndDate = useDebouncedValue(startEndDateMemoized, 500);

  useEffect(() => {
    // Skip first render
    if (notInitialRender.current) {
      if (offset === 0) {
        getWarehouseDeliveriesData();
      } else {
        setOffset(0);
      }
    } else {
      notInitialRender.current = true;
    }
  }, [
    searchValue,
    ownerIfFilterValue,
    orderStatusFilterValue,
    debouncedStartEndDate,
    userLocation?.id,
  ]);

  useEffect(() => {
    // Reset pagination when we change view from desktop to mob. Mob view has fetch more functionality
    if (isMobile && offset > 0) {
      setOffset(0);
    }

    // Reset rows data and do request when we change view from mob to desktop. Mob view has fetch more functionality
    if (isDesktop && rows.length > pageSize) {
      getWarehouseDeliveriesData();
    }
  }, [isMobile, isDesktop]);

  const getAPFilteringOptions = async () => {
    try {
      const assetsProviders = await belecoApi.wp.getAllAssetProviders();
      setAssetProvidersList(
        assetsProviders.reduce(
          (acc, curr) => ({ ...acc, [curr.ID]: curr }),
          {},
        ),
      );
    } catch (err) {
      notifyError(err, t('Failed to load asset providers'));
    }
  };

  const resetFiltersToDefaults = () => {
    setSearchValue('');
    setOwnerIdFilterValue([]);
    setOrderStatusFilterValue([]);
    setStartDateFilter('');
    setEndDateFilter('');
    setOffset(0);
  };

  const resetSearchValue = () => {
    setSearchValue('');
  };

  const handleDateFilter = ({ startDate, endDate }) => {
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };

  useEffect(() => {
    getAPFilteringOptions();
  }, []);

  const handleChangePage = useCallback((newPage) => {
    setOffset(newPage * pageSize);
  }, []);

  const handleSearch = (name, debouncedValue) => {
    setSearchValue(debouncedValue);
  };

  const formattedStatuses = useMemo(() => {
    return Object.values(ORDER_STATUS).map((option) => ({
      title: t(option.label),
      value: option.value,
    }));
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <title>{t('Warehouse Orders')}</title>
      </Helmet>
      <Hidden mdDown>
        <WDDeskTopView
          {...{
            location,
            assetsProvidersList,
            ownerIfFilterValue,
            setOwnerIdFilterValue,
            formattedStatuses,
            orderStatusFilterValue,
            setOrderStatusFilterValue,
            startDateFilter,
            endDateFilter,
            handleDateFilter,
            handleSearch,
            searchValue,
            resetFiltersToDefaults,
            resetSearchValue,
            handleChangePage,
            rows,
            total,
            pageSize,
            page,
          }}
          loading={loading}
        />
      </Hidden>
      <Hidden mdUp>
        <WDMobileView
          handleLoadMore={handleLoadMore}
          {...{
            location,
            assetsProvidersList,
            ownerIfFilterValue,
            setOwnerIdFilterValue,
            formattedStatuses,
            orderStatusFilterValue,
            setOrderStatusFilterValue,
            startDateFilter,
            endDateFilter,
            handleDateFilter,
            handleSearch,
            searchValue,
            resetFiltersToDefaults,
            rows,
            total,
          }}
          loading={loading}
        />
      </Hidden>
    </>
  );
};

export default WarehouseDeliveries;
