import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const TitledDataCell = ({ cellName, data, DataTagName = Typography }) => {
  const useStyles = makeStyles(() => ({
    cellWrapper: {
      border: '1px solid #CCC',
      borderRadius: '5px',
      padding: '7px 3px',
      position: 'relative',
      width: '24%',
    },

    cellNameWrapper: {
      position: 'absolute',
      top: '-8px',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },

    cellNameText: {
      zIndex: '2',
      backgroundColor: '#FFF',
      textAlign: 'center',
      padding: '0px 5px',
      textTransform: 'uppercase',
      color: '#D86A75',
      fontSize: 10,
      whiteSpace: 'nowrap',
    },

    cellContent: {
      padding: '5px',
      fontSize: 10,
      whiteSpace: 'nowrap',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.cellWrapper}>
      <div className={classes.cellNameWrapper}>
        <Typography className={classes.cellNameText} variant={'body2'}>
          {cellName}
        </Typography>
      </div>
      {cellName !== 'volume' ? (
        <DataTagName variant={'body2'} className={classes.cellContent}>
          {data}
        </DataTagName>
      ) : (
        <DataTagName variant={'body2'} className={classes.cellContent}>
          {`${data} m`} <sup>3</sup>
        </DataTagName>
      )}
    </div>
  );
};
export default TitledDataCell;
