import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TransportsTable from '../components/TransportsTable';
import SectionContainer from '../../../components/CustomComponents/SectionContainer';
import TitleContainer from '../../../components/TitleContainer';
import TransportsFilters from '../components/TransportsFilters';

const TransportsForDeskTop = ({
  startDateFilter,
  endDateFilter,
  handleDateFilter,
  handleChangePage,
  page,
  pageSize,
  showHideFailedItems,
  setShowHideFailedItems,
  typeFilter,
  setTypeFilter,
  tmsId,
  setTmsId,
  status,
  setStatus,
  transports,
  total,
  isLoadingTransports,
  TMSList,
  isLoadingTMSList,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TitleContainer title={t('Transports')}>
        <TransportsFilters
          {...{
            startDateFilter,
            endDateFilter,
            handleDateFilter,
            showHideFailedItems,
            setShowHideFailedItems,
            typeFilter,
            setTypeFilter,
            tmsId,
            setTmsId,
            status,
            setStatus,
            TMSList,
            isLoadingTMSList,
          }}
        />
      </TitleContainer>
      <SectionContainer>
        <TransportsTable
          loading={isLoadingTransports}
          items={transports}
          total={total}
          onChangePage={handleChangePage}
          page={page}
          pageSize={pageSize}
        />
      </SectionContainer>
    </>
  );
};
TransportsForDeskTop.propTypes = {
  startDateFilter: PropTypes.object,
  endDateFilter: PropTypes.object,
  handleDateFilter: PropTypes.func,
  handleChangePage: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  showHideFailedItems: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setShowHideFailedItems: PropTypes.func,
  typeFilter: PropTypes.string,
  setTypeFilter: PropTypes.func,
  tmsId: PropTypes.string,
  setTmsId: PropTypes.func,
  status: PropTypes.array,
  setStatus: PropTypes.func,
  transports: PropTypes.array,
  total: PropTypes.number.isRequired,
  isLoadingTransports: PropTypes.bool.isRequired,
  TMSList: PropTypes.array,
  isLoadingTMSList: PropTypes.bool,
};
export default TransportsForDeskTop;
