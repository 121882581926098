import { Button, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  filterBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 10,
  },
  resetFilterButton: {
    marginTop: 14,
    borderRadius: 5,
  },
  filteringControls: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: '14px',
  },
}));

const FiltersBlock = ({ showFilters, resetFiltersToDefaults, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Collapse in={showFilters}>
      <div className={classes.filterBox}>
        <div className={classes.filteringControls}>{children}</div>
        <Button
          onClick={resetFiltersToDefaults}
          variant='contained'
          color='secondary'
          className={classes.resetFilterButton}
        >
          {t('Reset Filters')}
        </Button>
      </div>
    </Collapse>
  );
};

FiltersBlock.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  showFilters: PropTypes.bool,
  resetFiltersToDefaults: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export default FiltersBlock;
