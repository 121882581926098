import { useEffect, useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Thumb from './Thumb';
import ConditionComponent from './components/ConditionComponent';
import VisibilityComponent from './components/VisibilityComponent';
import AvailabilityComponent from './components/AvailabilityComponent';
import useAvailabilityHelper from '../../utils/useAvailabilityHelper';
import CustomDataGrid from '../../components/CustomDataGrid';
import { WAREHOUSE_STATUS } from '../../utils/constants';
import LocationContext from '../../context/Location/LocationContext';

const useStyles = makeStyles({
  dataGridClass: {
    '& .MuiDataGrid-row': {
      boxShadow: 'inset 0 0 0 1px #cccccc',
      width: '100%',

      '& .MuiDataGrid-cell': {
        border: 'none',
      },
    },
  },
});

const InstancesList = ({
  onChangePage,
  items,
  total,
  loading,
  pageSize,
  page,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { locations } = useContext(LocationContext);

  const [rows, setRows] = useState([]);

  const { availabilityMap } = useAvailabilityHelper(
    items.map(({ publicId }) => publicId),
  );

  useEffect(() => {
    setRows(
      items.map((item) => ({
        ...item,
        availability: availabilityMap[item.publicId],
      })),
    );
  }, [items, availabilityMap]);

  const initColumns = useMemo(
    () => [
      {
        field: 'title',
        headerName: t('Product'),
        align: 'left',
        headerAlign: 'left',
        flex: 1.5,
        sortable: false,
        minWidth: 150,
        renderCell: ({ row }) => <Thumb data={row} />,
      },
      {
        field: 'productId',
        headerName: t('Product ID'),
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        sortable: false,
        minWidth: 120,
        renderCell: ({ row }) => (
          <Link to={`/products/${row.productId}`}>
            <Typography variant='body2'>{row.productId}</Typography>
          </Link>
        ),
      },
      {
        field: 'publicId',
        headerName: t('Public ID'),
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        sortable: false,
        minWidth: 120,
        renderCell: ({ row }) => (
          <Link to={`/instances/${row.publicId}`}>
            <Typography variant='body2'>{row.publicId}</Typography>
          </Link>
        ),
      },
      {
        field: 'condition',
        headerName: t('Condition'),
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        sortable: false,
        minWidth: 100,
        renderCell: ({ row }) =>
          row.id ? <ConditionComponent item={row} /> : '',
      },
      {
        field: 'state',
        headerName: t('Location'),
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        sortable: false,
        minWidth: 120,
        renderCell: ({ row }) => (
          <Typography variant='body2'>
            {row.id ? locations[row.locationId]?.title || t('Unknown') : ''}
          </Typography>
        ),
      },
      {
        field: 'warehouseStatus',
        headerName: t('Status'),
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        sortable: false,
        minWidth: 120,
        renderCell: ({ row }) => {
          return (
            <Typography variant='body2' style={{ textTransform: 'uppercase' }}>
              {row.id
                ? t(WAREHOUSE_STATUS[row.warehouseStatus]?.label) ||
                  t('Unknown')
                : ''}
            </Typography>
          );
        },
      },
      {
        field: 'isVisible',
        headerName: t('Visible'),
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        sortable: false,
        minWidth: 120,
        renderCell: ({ row }) =>
          row.id ? <VisibilityComponent item={row} /> : '',
      },
      {
        field: 'invisibilityReason',
        headerName: t('Availability'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        minWidth: 120,
        renderCell: ({ row }) => <AvailabilityComponent item={row} />,
      },
      {
        field: 'id',
        headerName: ' ',
        align: 'right',
        flex: 0.5,
        sortable: false,
        minWidth: 50,
        renderCell: ({ row }) => (
          <IconButton
            to={`/instances/${row.publicId}`}
            component={Link}
            style={{ height: 35, width: 35, marginRight: 10 }}
            size='large'
          >
            <CreateOutlinedIcon />
          </IconButton>
        ),
      },
    ],
    [i18n.language],
  );

  return (
    <CustomDataGrid
      columns={initColumns}
      rows={rows}
      headerHeight={40}
      rowHeight={40}
      pagination
      paginationMode='server'
      rowCount={total}
      pageSize={pageSize}
      onPageChange={onChangePage}
      hideFooterPagination={false}
      loading={loading}
      dataGridClass={classes.dataGridClass}
      rowSpacing={5}
      page={page}
    />
  );
};

InstancesList.defaultProps = {
  onChangePage: () => {},
};

InstancesList.propTypes = {
  onChangePage: PropTypes.func,
  items: PropTypes.array,
  total: PropTypes.number,
  // eslint-disable-next-line react/boolean-prop-naming
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  page: PropTypes.number,
};

export default InstancesList;
