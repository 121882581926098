import makeStyles from '@mui/styles/makeStyles';
import BuildIcon from '@mui/icons-material/Build';
import { useTranslation } from 'react-i18next';
import { TRANSPORTS_TOOLTIPS } from '../../utils/constants';
import CustomTooltip from '../CustomComponents/CustomTooltip';

const useStyles = makeStyles({
  requiredMountingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E6CF5F',
    borderRadius: '50%',
    marginLeft: 2,
    padding: 5,
  },
  requiredMountingIcon: {
    fontSize: 10,
    color: 'white',
  },
});

const RequiredMountingIndicator = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <CustomTooltip title={t(TRANSPORTS_TOOLTIPS.MOUNTING)}>
      <div className={classes.requiredMountingContainer}>
        <BuildIcon className={classes.requiredMountingIcon} />
      </div>
    </CustomTooltip>
  );
};

export default RequiredMountingIndicator;
